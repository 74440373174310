import {FinancialEntryEventEventsEnum} from "../../../../domain/entity/financial-entry-event.entity";

export function parseEntryEventContent(item: FinancialEntryEventEventsEnum) {
  switch (item) {
    case FinancialEntryEventEventsEnum.CANCELED:
      return 'Cancelamento';
    case FinancialEntryEventEventsEnum.CREATE:
      return 'Criação';
    case FinancialEntryEventEventsEnum.WRITE_OFF:
      return 'Baixa';
    case FinancialEntryEventEventsEnum.UPDATED:
      return 'Atualização';
    case FinancialEntryEventEventsEnum.REMOVED:
      return 'Exclusão';
    default:
      return item;
  }
}
