import {Button, Col, Form, Input, Row} from "antd";
import React from "react";
import {SearchOutlined} from "@ant-design/icons";

export type FilterListLedgerAccountProps = {
  onFilter: (values: { name?: string; code?: string }) => void,
}

export function FilterListLedgerAccount(props: FilterListLedgerAccountProps) {
  const [form] = Form.useForm();

  function onFilter(values: any) {
    const parsedValues: any = {};
    for(const key of Object.keys(values)) {
      if (values[key]) {
        parsedValues[key] = values[key];
      }
    }
    props.onFilter(parsedValues);
  }

  return (
    <Form
      form={form}
      layout="horizontal"
      size={'middle'}
      onFinish={onFilter}
      initialValues={{ field: 'classId' }}
    >
      <Row gutter={12} style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}} >
        <Col span={7} >
          <Form.Item name={'name'} label={'Nome'}>
            <Input allowClear />
          </Form.Item>
        </Col>
        <Col span={7} >
          <Form.Item name={'code'} label={'Codigo'}>
            <Input allowClear />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item>
            <Button htmlType={'submit'} size={'middle'} icon={<SearchOutlined />}>
              Pesquisar
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}