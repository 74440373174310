import {DreRowReleaseParamEntity} from "./dre-row-release-param.entity";

export enum DreRowType {
    DEDUCTION = 'deduction',
    REVENUE = 'revenue',
    RESULT = 'result',
    CALC = 'calc'
}

export type DreRowEntity = {
    id: number;
    name: string;
    order: number;
    parentRowId?: number;
    type: DreRowType;
    isRowResult: boolean;
    columnsToCalc?: [],
    dreRowReleaseParam?: DreRowReleaseParamEntity[]
}