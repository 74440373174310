import React, {useEffect, useState} from "react";
import {Form, Input, message} from "antd";
import {FormActionsButtons} from "../../../../../core/component/form-actions-buttons";
import {ResetPasswordProfileEntity} from "../../../../../domain/entity/reset-password-profile";
import {useResetPasswordProfileService} from "../../../../../domain/repository/reset-password-profile.repository";
import {useUserStorage} from "../../../../../core/service/user.storage";

export function ResetPassword(props: any) {

    const layout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 16 },
    };

    const validateMessages = {
        required: 'Campo obrigatório',
    };
    const userStorage = useUserStorage();
    const initialValues = userStorage.getUser();

    const [form] = Form.useForm();
    const resetPasswordService = useResetPasswordProfileService();
    const [resetPassword, setResetPassword] = useState<ResetPasswordProfileEntity>();
    const { id } = initialValues;

    useEffect(() => { resetPasswordService.find(id).then(res => setResetPassword(res.data)) }, [1]);
    useEffect(() => { resetPassword && form.setFields([
        { name: 'currentPassword', value: resetPassword.currentPassword },
        { name: 'newPassword', value: resetPassword.newPassword },
        
    ])}, [resetPassword])

    function onFinish(data: any) {
        const resetPasswordEntity: ResetPasswordProfileEntity = {
            currentPassword: data.currentPassword,
            newPassword: data.newPassword,
        }

        resetPasswordService.reset(id, resetPasswordEntity)
            .then(res => {
                message.success(res.message);
            }).catch(err => {
                message.error(err.message);
        })
    }

    return (
        <Form {...layout} onFinish={onFinish} validateMessages={validateMessages}>
            <Form.Item name={'currentPassword'} label="Senha Atual" rules={[{ required: true }]}>
                <Input.Password />
            </Form.Item>
            <Form.Item name={'newPassword'} label="Nova Senha" rules={[{ required: true }]}>
                <Input.Password />
            </Form.Item>
            <Form.Item
                    name="confirm"
                    label="Confirma a nova senha"
                    dependencies={['newPassword']}
                    hasFeedback
                    rules={[
                    {
                        required: true,
                        message: 'Digite sua nova senha novamente!',
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                        if (!value || getFieldValue('newPassword') === value) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('As duas senhas que você digitou não coincidem!'));
                        },
                    }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>
            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 4 }}>
                <FormActionsButtons
                    isLoading={resetPasswordService.loading}
                />
            </Form.Item>
        </Form>
    )
}
