import {Button, Col, Form, Input, Row, Select} from "antd";
import {ExportOutlined, SearchOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import {DatePickerPtBr} from "../../../../core/component/date-picker-ptbr";
import moment, {Moment} from "moment";
import {SelectPerson} from "../../../component/dropdown/select.person";
import {PersonEntity} from "../../../../domain/entity/person.entity";
import {usePersonRepository} from "../../../../domain/repository/person.repository";
import ButtonGroup from "antd/lib/button/button-group";

export type FilterFinancialEntryProps = {
  onNumMovFilter: (id: number) => void,
  onEntryTypeFilter: (entryType: string) => void,
  onPersonFilter: (personId: number) => void,
  onPeriodFilter: (startDate: any, endDate: any) => void,
  onExport: () => void,
}

export function FilterFinancialEntryInstallment(props: FilterFinancialEntryProps) {
  const [form] = Form.useForm();
  const [persons, setPersons] = useState<PersonEntity[]>([]);
  const [currentFilterType, setCurrentFilterType] = useState<string>();

  const personRepo = usePersonRepository();

  useEffect(() => {
    personRepo.findAll().then(res => setPersons(res.data));
  }, [1])

  const fields: Array<{value: string, label: string}> = [
    { value: 'id', label: 'Numero do Mov.' },
    { value: 'period', label: 'Vencimento Parcela' },
    { value: 'entryType', label: 'Tipo' },
    { value: 'personId', label: 'Cli/For' },
  ];

  function onFilter(values: any) {
    if (values.field === 'id') {
      props.onNumMovFilter(values.filter);
    }
    if (values.field === 'period') {
      props.onPeriodFilter(
        values.startDate,
        values.endDate
      );
    }
    if (values.field === 'entryType') {
      props.onEntryTypeFilter(values.filter);
    }
    if (values.field === 'personId') {
      props.onPersonFilter(values.personId);
    }
  }

  function InputFilter() {
    let field = <Input size={'middle'} allowClear />;
    switch (form.getFieldValue('field')) {
      case 'entryType':
        field = (
          <Select
            options={[
              {
                value: 'R',
                label: 'Receita',
              },
              {
                value: 'D',
                label: 'Despesa',
              },
            ]}
          />
        )
        break;
      case 'personId':
        return (
          <SelectPerson data={persons} name={'personId'} label={''} />
        )
      case 'period':
        return (
          <Row>
            <Col span={12}>
              <DatePickerPtBr label={''} name={'startDate'} required />
            </Col>
            <Col span={12}>
              <DatePickerPtBr label={''} name={'endDate'} required />
            </Col>
          </Row>
        )
      default:
        break;
    }
    return (
      <Form.Item name={'filter'}>
        {field}
      </Form.Item>
    );
  }

  return (
    <Form
      form={form}
      layout="horizontal"
      onFinish={onFilter}
      initialValues={{ field: 'id' }}
      size={'middle'}
    >
      <Row style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}} >
        <Col>
          <Form.Item name={'field'}>
            <Select size={'middle'} options={fields} onSelect={val => setCurrentFilterType(val.toString())} />
          </Form.Item>
        </Col>
        <Col span={6} >
          <InputFilter />
        </Col>
        <Col>
          <Form.Item>
            <ButtonGroup>
              <Button htmlType={'submit'} size={'middle'} icon={<SearchOutlined />}>
                Pesquisar
              </Button>
              <Button htmlType={'button'} onClick={props.onExport} size={'middle'} icon={<ExportOutlined />}>
                Exportar
              </Button>
            </ButtonGroup>

          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}