import React, {useMemo} from "react";

export type RenderStatementDescriptionComponentProps = {
  description: string,
  meta?: any,
  onEntryClick: (entryId: number) => any,
}

export function RenderStatementDescriptionComponent(props: RenderStatementDescriptionComponentProps) {
  const { onEntryClick, description, meta } = props;
  return useMemo<JSX.Element>(() => {
    if (meta?.entryId) {
      const onClick = () => onEntryClick(meta?.entryId as number)
      return (
        <>
          {description} (<a onClick={onClick} title={'Visualizar Movimento'}>ver mais</a>)
        </>
      )
    }
    return (
      <>
        {description}
      </>
    );
  }, [meta?.entryId, description, onEntryClick]);
}