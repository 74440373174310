import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {Button, Col, Divider, Drawer, Form, Row, Select, Table} from "antd";
import {Content} from "antd/es/layout/layout";
import {ColumnsType} from "antd/lib/table";
import {Route, Switch as SwitchRouter, useHistory, useRouteMatch} from "react-router-dom";
import {
  FinancialEntryEventEntity, FinancialEntryEventEntityEnum,
  FinancialEntryEventEventsEnum
} from "../../../../domain/entity/financial-entry-event.entity";
import {useDateTimeUtil} from "../../../../core/util/date-time.util";
import {useFinancialEntryRepository} from "../../../../domain/repository/financial-entry.repository";
import React, {useEffect, useState} from "react";
import moment, {Moment} from "moment";
import {parseEntryEventContent} from "../service/parse-entry-event.content";
import {parseEntryEntityService} from "../service/parse-entry-entity.service";
import {DatePickerPtBr} from "../../../../core/component/date-picker-ptbr";
import {SearchOutlined} from "@ant-design/icons";
import {ShowFinancialEntry} from "./show.financial-entry";

export function ListFinancialEntryEvent(props: ModuleRenderProps) {
  const [form] = Form.useForm();
  const [data, setData] = useState<FinancialEntryEventEntity[]>([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [eventsFilter, setEventsFilter] = useState<string[]>([]);
  const [dateTime, setDateTime] = useState<Moment>(moment());
  const [entitiesFilter, setEntitiesFilter] = useState<string[]>([]);

  const routeMatch = useRouteMatch();
  const history = useHistory();
  const { convertToDateTimePtBr } = useDateTimeUtil();
  const financialEntryRepo = useFinancialEntryRepository();

  useEffect(() => {
    form.setFields([
      { name: 'dateTime', value: dateTime }
    ])
    loadData({
      dateTime,
      entities: entitiesFilter,
      events: eventsFilter
    });
  }, [page])

  const columns: ColumnsType<FinancialEntryEventEntity> = [
    {
      title: 'Num. Movimento',
      dataIndex: 'entryId',
      key: 'entryId',
      render: (val: number) => (
        <Button
          size={'small'}
          onClick={() => history.push(`${routeMatch.path}/show/${val}`)}>
          {val}
        </Button>
      )
    },
    {
      title: 'Data',
      dataIndex: 'dateTime',
      render: convertToDateTimePtBr
    },
    {
      title: 'Entidade',
      dataIndex: 'entity',
      key: 'entity',
      render: parseEntryEntityService,
    },
    {
      title: 'Ação',
      dataIndex: 'event',
      key: 'event',
      render: parseEntryEventContent,
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'Usuario',
      dataIndex: 'user',
      key: 'user'
    }
  ]

  function loadData(args: { dateTime: Moment, entities?: string[], events?: string[] }) {
    const { dateTime, events, entities } = args;
    financialEntryRepo.listEvents({
      offset: (page > 0 ? page - 1 : 0) * limit,
      limit,
      entities: entities ? entities : [],
      orderByDirection: 'ASC',
      orderByField: 'id',
      events: events ? events : [],
      dateTime: dateTime.toISOString(),
    }).then(res => {
      setTotal(res.total);
      setData(res.data);
    })
  }

  function onSearch(values: any) {
    const { dateTime, entities, events } = values;
    console.log(entities, events);
    setDateTime(dateTime);
    setEntitiesFilter(entities);
    setEventsFilter(events)
    loadData({ dateTime ,entities, events });
  }

  function getEventOpts() {
    return [
      {
        value: FinancialEntryEventEventsEnum.CREATE,
        label: parseEntryEventContent(FinancialEntryEventEventsEnum.CREATE)
      },
      {
        value: FinancialEntryEventEventsEnum.WRITE_OFF,
        label: parseEntryEventContent(FinancialEntryEventEventsEnum.WRITE_OFF)
      },
      {
        value: FinancialEntryEventEventsEnum.CANCELED,
        label: parseEntryEventContent(FinancialEntryEventEventsEnum.CANCELED)
      },
      {
        value: FinancialEntryEventEventsEnum.REMOVED,
        label: parseEntryEventContent(FinancialEntryEventEventsEnum.REMOVED)
      },
      {
        value: FinancialEntryEventEventsEnum.UPDATED,
        label: parseEntryEventContent(FinancialEntryEventEventsEnum.UPDATED)
      },
    ]
  }

  function getEntitiesOpts() {
    return [
      {
        value: FinancialEntryEventEntityEnum.ENTRY,
        label: parseEntryEntityService(FinancialEntryEventEntityEnum.ENTRY)
      },
      {
        value: FinancialEntryEventEntityEnum.INSTALLMENT,
        label: parseEntryEntityService(FinancialEntryEventEntityEnum.INSTALLMENT)
      },
      {
        value: FinancialEntryEventEntityEnum.ITEM,
        label: parseEntryEntityService(FinancialEntryEventEntityEnum.ITEM)
      },
      {
        value: FinancialEntryEventEntityEnum.APPORTIONMENT,
        label: parseEntryEntityService(FinancialEntryEventEntityEnum.APPORTIONMENT)
      },
      {
        value: FinancialEntryEventEntityEnum.TAX,
        label: parseEntryEntityService(FinancialEntryEventEntityEnum.TAX)
      },
    ]
  }

  return (
    <>
      <Content>
        <Form form={form} onFinish={onSearch} layout={'vertical'} size={'middle'}>
          <Row gutter={12}>
            <Col span={8}>
              <DatePickerPtBr
                label={'Data de Referência'}
                name={'dateTime'}
                required={true}
              />
            </Col>
            <Col span={8}>
              <Form.Item label={'Eventos'} name={'events'}>
                <Select options={getEventOpts()} mode={'tags'} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'Entidades'} name={'entities'}>
                <Select options={getEntitiesOpts()} mode={'tags'} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{textAlign: 'right'}}>
              <Button htmlType={'submit'} icon={<SearchOutlined />}>Pesquisar</Button>
            </Col>
          </Row>
        </Form>
        <Divider />
        <Table
          columns={columns}
          loading={financialEntryRepo.loading}
          dataSource={data}
          pagination={{
            pageSize: limit,
            total,
            onChange: setPage,
            onShowSizeChange: (c, size) => setLimit(size)
          }}
        />
      </Content>
      <SwitchRouter>
        <Route path={`${routeMatch.path}/show/:id`}>
          <Drawer
            placement={'bottom'}
            height={550}
            visible={true}
            bodyStyle={{padding: 0}}
            onClose={() => props.navigator.goToListPage()}
          >
            <ShowFinancialEntry {...props} />
          </Drawer>
        </Route>
      </SwitchRouter>
    </>
  )
}