import {Col, Form, Input, message, Row} from "antd";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import React, {useEffect, useState} from "react";
import {LedgerAccountEntity} from "../../../../domain/entity/ledger-account.entity";
import {useLedgerAccountRepository} from "../../../../domain/repository/ledger-account.repository";
import {useClientRepository} from "../../../../domain/repository/client.repository";
import MaskedInput from "antd-mask-input";


export type FormLedgerAccountProps = {
  onSaved: (entity: LedgerAccountEntity) => any,
  onCancel: () => any,
  entity?: LedgerAccountEntity,
}

export function FormLedgerAccount(props: FormLedgerAccountProps) {
  const [form] = Form.useForm();
  const [mask, setMask] = useState<string>();

  const { entity } = props;
  const ledgerAccountRepo = useLedgerAccountRepository();
  const clientRepo = useClientRepository();

  useEffect(() => {
    clientRepo.getSettings().then(res => {
      if (res.ledgerAccountMask) {
        setMask(res.ledgerAccountMask);
      }
    })
  }, [])

  useEffect(() => {
    if (entity) {
      form.setFields([
        { name: 'name', value: entity.name },
        { name: 'description', value: entity.description },
        { name: 'code', value: entity.code },
      ])
   }
  }, [entity])

  function cleanCode(code: string) {
    const sections = code.split('.');
    const parsedSections: string[] = [];
    for (const item of sections) {
      const parsed = item.split('_').join('')
      if (parsed) {
        parsedSections.push(parsed)
      }
    }
    return parsedSections.join('.');
  }

  function onFinish(values: any) {
    values.code = cleanCode(values.code);
    function send() {
      if (entity) {
        return ledgerAccountRepo.update(entity.id.toString(), values)
      }
      return ledgerAccountRepo.create(values);
    }

    return send().then(res => {
      message.success(res.message)
      props.onSaved(res.data);
    }).catch(err => {
      message.error(err.message);
    })
  }

  return (
    <Form
      layout={'vertical'}
      form={form}
      onFinish={onFinish}
    >
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item name={'name'} label={'Nome'} rules={[{required: true}]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={'code'} label={'Código'} rules={[{required: true}]}>
            { mask ? <MaskedInput mask={mask}  /> : <Input/>}
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name={'description'} label={'Descrição'}>
        <Input />
      </Form.Item>
      <FormActionsButtons
        onCancel={props.onCancel}
        isLoading={ledgerAccountRepo.loading}
      />
    </Form>
  )
}