import {Button} from "antd";
import {FileExcelOutlined} from "@ant-design/icons";
import React from "react";

export type ExportButtonProps = {
  onClick: () => any,
}
export function ExportExcelButton(props: ExportButtonProps) {
  return (
    <Button onClick={() => props.onClick()} shape={'round'} icon={<FileExcelOutlined />}>
      Exportar
    </Button>
  )
}