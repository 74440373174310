export function useParseMonthsCloseDateService() {
    function parse(args: any) {
        const data: { month: number, date: string }[] = []
        for (const arg of Object.keys(args)) {
            if (arg.includes('month')) {
                const params = arg.split('_');
                data.push({
                    month: parseInt(params[1]),
                    date: args[arg].format()
                })
            }
        }
        return data;
    }

    return {
        parse
    }
}