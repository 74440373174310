import {PersonIndividualEntity} from "./person-individual.entity";
import {PersonFunctionEntity} from "./person-function.entity";
import {PersonCompanyEntity} from "./person-company.entity";
import {PersonAddressEntity} from "./person-address.entity";
import {PersonBankDataEntity} from "./person-bank-data.entity";

export type PersonEntity = {
    id: number,
    name: string,
    type: PersonTypeEnum,
    document: string,
    documentType: PersonDocumentEnum
    phoneNumber: string,
    email: string,
    personIndividual?: PersonIndividualEntity,
    personCompany?: PersonCompanyEntity,
    personFunctions?: PersonFunctionEntity[],
    personAddresses?: PersonAddressEntity[],
    personBankData?: PersonBankDataEntity[]
}

export enum PersonDocumentEnum {
    CPF = 'cpf',
    CNPJ = 'cnpj'
}

export enum PersonTypeEnum {
    INDIVIDUAL = 'F',
    COMPANY =  'J'
}