import {BaseResponse, usePlaneduHttpService} from "../service/planedu.http.service";
import {CostCenterEntity} from "../entity/cost-center.entity";
import {FirmEntity} from "../entity/firm.entity";

export function useCostCenterRepository() {
    const httpService = usePlaneduHttpService<CostCenterEntity>('cost-center');

    function getFirms(costCenterId: number): Promise<FirmEntity[]> {
        return httpService.get(`cost-center/${costCenterId}/firms`)
            .then(res => res.data as FirmEntity[]);
    }

    function bindFirm(costCenterId: number, firmId: number) {
        const body = JSON.stringify({ costCenterId, firmId });
        return httpService.post('cost-center/bind-firm', body)
    }

    function removeFirm(costCenterId: number, firmId: number) {
        const body = JSON.stringify({ costCenterId, firmId });
        return httpService.post('cost-center/remove-firm', body)
    }

    function downloadListCostCenter() {
        return httpService.downloadRequest(
          'get',
          `${httpService.resourceName}/export`,
          'centro-custo.xlsx',
        )
    }

    function getEnabled(): Promise<BaseResponse<CostCenterEntity[]>> {
        return httpService.get(
          `${httpService.resourceName}/enabled`
        )
    }

    return {
        ...httpService,
        getFirms,
        bindFirm,
        removeFirm,
        getEnabled,
        downloadListCostCenter
    }
}
