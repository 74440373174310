import {Button, Col, Form, Input, message, Row, Select} from "antd";
import {ExportOutlined, SearchOutlined} from "@ant-design/icons";
import React, {useState} from "react";
import {DatePickerPtBr} from "../../../../core/component/date-picker-ptbr";
import moment, {Moment} from "moment";

export type FilterFinancialProvisionProps = {
  onIntervalFilter: (initialDate: Moment, finalDate: Moment) => void,
  // onExport: (initialDate: Moment, finalDate: Moment) => void,
  onNumMovFilter: (id: number) => void,
}

export function FilterFinancialProvision(props: FilterFinancialProvisionProps) {
  const [form] = Form.useForm();
  const [currentFilterType, setCurrentFilterType] = useState<string>();
  const fields: Array<{value: string, label: string}> = [
    { value: 'id', label: 'Numero do Mov.' },
    { value: 'dateInterval', label: 'Periodo' },
  ];

  function onFilter(values: any) {
    if (values.field === 'dateInterval') {
      props.onIntervalFilter(values.initialDate, values.finalDate);
    }
    if (values.field === 'id') {
      props.onNumMovFilter(values.filter);
    }
  }

  function onExport() {
    const { initialDate, finalDate } = form.getFieldsValue();
    if (!initialDate) {
      form.setFields([ {name: 'initialDate', errors: ['Dado obrigatória']}])
    } else if (!finalDate) {
      form.setFields([ {name: 'finalDate', errors: ['Dado obrigatória']}])
    } else {
      message.info('O arquivo terá no máximo 5000. ' +
        'Caso queira exportar mais do que isso, ' +
        'particione o download em duas ou mais planilhas com um intervalo menor de datas');
      // props.onExport(initialDate, finalDate);
    }
  }


  function InputFilter() {
    let field = <Input size={'middle'} allowClear />;
    switch (currentFilterType) {
      case 'dateInterval':
        return (
          <Row>
            <Col span={12}>
              <DatePickerPtBr
                label={''}
                name={'initialDate'}
                required={true}
                placeholder={'Data Incial'}
              />
            </Col>
            <Col span={12}>
              <DatePickerPtBr
                label={''}
                name={'finalDate'}
                required={true}
                placeholder={'Data Final'}
              />
            </Col>
          </Row>
        );
      default:
        break;
    }

    return <Form.Item name={'filter'}>{field}</Form.Item>;
  }

  return (
    <Form
      form={form}
      layout="horizontal"
      onFinish={onFilter}
      initialValues={{ field: 'id' }}
      size={'middle'}
    >
      <Row style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}} >
        <Col>
          <Form.Item name={'field'}>
            <Select size={'middle'} options={fields} onSelect={val => setCurrentFilterType(val.toString())} />
          </Form.Item>
        </Col>
        <Col span={6} >
          <InputFilter />
        </Col>
        <Col>
          <Form.Item>
            <Button htmlType={'submit'} size={'middle'} icon={<SearchOutlined />}>
              Pesquisar
            </Button>
          </Form.Item>
        </Col>
        {
          currentFilterType === '_dateInterval' && (
            <Col>
              <Form.Item>
                <Button
                  onClick={onExport}
                  size={'middle'}
                  icon={<ExportOutlined />}
                >
                  Exportar
                </Button>
              </Form.Item>
            </Col>
          )
        }
      </Row>
    </Form>
  )
}