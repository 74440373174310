import React, {useCallback, useEffect, useState} from "react";
import {HeaderBankAccount} from "../component/header.bank-account";
import {Button, Col, Divider, Drawer, Form, Layout, message, Radio, Row, Select, Space, Statistic,} from "antd";
import {useBankAccountRepository} from "../../../../domain/repository/bank-account.repository";
import {BankAccountEntity} from "../../../../domain/entity/bank-account.entity";
import {BankAccountStatementEntity} from "../../../../domain/entity/bank-account-statement.entity";
import {useBankAccountStatementRepository} from "../../../../domain/repository/bank-account-statement.repository";
import {Route, Switch, useHistory, useParams, useRouteMatch} from "react-router-dom";
import {DatePickerPtBr} from "../../../../core/component/date-picker-ptbr";
import {ExportOutlined, SearchOutlined} from "@ant-design/icons";
import moment, {Moment} from "moment";
import {MonthsEnum} from "../../../../domain/vo/months.enum";
import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {ShowFinancialEntry} from "../../financial-entry/pages/show.financial-entry";
import {FormInitialBalance} from "../component/form.initial-balance.component";
import {ListSyntheticStatementComponent} from "../component/list.synthetic-statement.component";
import {ListAnalyticStatementsComponent} from "../component/list.analytic-statement.component";

export function ListBankAccountStatement(props: ModuleRenderProps) {
  const [showForm, setShorForm] = useState(false);
  const [bankAccount, setBankAccount] = useState<BankAccountEntity>();
  const bankAccountRepo = useBankAccountRepository();
  const { id: accountId } = useParams<{ id: string }>();

  function testStatus() {
    accountId && bankAccountRepo.find(accountId)
      .then(res => {
        if (!res.data.initialBalanceDateTime) {
          setShorForm(true)
        }
        setBankAccount(res.data);
      })
  }

  useEffect(() => {
    testStatus()
  }, [accountId])

  return (
    <>
      {
        showForm && bankAccount ?
            <FormInitialBalance
              account={bankAccount}
              onFinish={() => testStatus()}
            />
          :
          <ListStatements {...props} />
      }
    </>

  )
}

export function ListStatements(props: ModuleRenderProps) {
  const [form] = Form.useForm();
  const [disabledDateFilter, setDisabledFilter] = useState(false);
  const [totalBalance, setTotalBalance] = useState(0);
  const [previousBalance, setPreviousBalance] = useState(0);
  const [filter, setFilter] = useState<{ initialDateTime: Moment, endDateTime: Moment }>(getInitialFilterValues());
  const [account, setAccount] = useState<BankAccountEntity>()
  const [typeReport, setTypeReport] = useState<'s' | 'a'>('a');
  const [groupStrategy, setGroupStrategy] = useState<'m' | 'd'>('d');
  const [data, setData] = useState<BankAccountStatementEntity[]>([]);
  const { id: accountId } = useParams<{ id: string }>();

  const navigator = useHistory();
  const bankAccountRepo = useBankAccountRepository();
  const routeMatch = useRouteMatch();
  const bankAccountStRepo = useBankAccountStatementRepository();

  function getInitialFilterValues() {
    const startDate = moment()
      .add('d', -30)
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0});
    const endDate = moment().set({
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 999
    });
    return {
      initialDateTime: startDate,
      endDateTime: endDate,
    }
  }

  function loadData() {
    const initialDateTime: string = filter.initialDateTime.toISOString();
    const endDateTime: string = filter.endDateTime.toISOString();
    bankAccountStRepo
      .listAccountStatements(
        accountId,
        undefined,
        undefined,
        {
          endDateTime,
          initialDateTime,
        }
      ).then(res => {
        setPreviousBalance(res.data.previousBalance);
        setData(res.data.statements);
    });
  }

  useEffect(() => {
    accountId && loadData();
  }, [accountId, filter]);

  useEffect(() => {
    accountId && bankAccountRepo.find(accountId)
      .then(res => setAccount(res.data))
    accountId && bankAccountRepo.getBalanceAmount(accountId)
      .then(res => setTotalBalance(res.data));
  }, [accountId])

  const onEntryClick = useCallback<(entryId: number) => any>(
    (entryId: number) => navigator.push(`${accountId}/entry/${entryId}`),
     [navigator]
  );


  function onFinish(params: any) {
    const initialDateTime: Moment = params.initialDateTime.local();
    const endDateTime = params.endDateTime.local();
    const data = { initialDateTime, endDateTime };
    setFilter(data);
    if (params.transform) {
      bankAccountStRepo.exportAccountStatements(
        accountId,
        {
          initialDateTime: initialDateTime.toISOString(),
          endDateTime: endDateTime.toISOString(),
        },
      );
    }
  }

  function getInitialValues() {
    return {
      initialDateTime: moment(filter.initialDateTime),
      endDateTime: moment(filter.endDateTime),
      viewType: 'a',
    }
  }

  function reloadFilters() {
    const month: number = form.getFieldValue('month');
    if (!month) {
      form.resetFields();
      setDisabledFilter(false);
      form.setFields([
        { name: 'initialDateTime', value: moment().add('d', -30) },
        { name: 'endDateTime', value: moment() },
      ])
      return;
    }
    setDisabledFilter(true);
    const year = new Date().getFullYear();
    const initialDate = moment(`${year}-${month.toString().padStart(2, '0')}-01`)
    const finalDate = initialDate.clone().add('month', 1).subtract('d', 1);
    form.setFields([
      { name: 'initialDateTime', value: initialDate },
      { name: 'endDateTime', value: finalDate },
    ])
  }

  function getMonthOpts() {
    return [1,2,3,4,5,6,7,8,9,10,11,12]
      .map(month => ({
        label: MonthsEnum.parseName(month),
        value: month,
      }))
  }

  function exportReport() {
    onFinish({
      ...form.getFieldsValue(),
      transform: true,
    })
  }

  const onTypeReportChange = useCallback<() => void>(() => {
    switch (form.getFieldValue('viewType')) {
      case 'a':
        setTypeReport('a');
        break;
      case 'sm':
        setTypeReport('s');
        setGroupStrategy('m');
        break;
      case 'sd':
        setTypeReport('s');
        setGroupStrategy('d');
        break;
      default: break;
    }
  }, [form])

  return (
    <>
      <HeaderBankAccount
        title={`Extrato Bancario ${account?.alias}`}
        mode={'form'}
        subtitle={`Ag.${account?.agency} / Conta ${account?.account} ${account?.code}`}
        extra={[
          <Statistic
            precision={2}
            prefix={'R$'}
            title={'Saldo Atual'}
            value={totalBalance}
            decimalSeparator={','}
          />
        ]}
      />
      <Divider />
      <Row gutter={12}>
        <Col span={24}>
          <Form
            layout={'horizontal'}
            onFinish={onFinish}
            form={form}
            size={'middle'}
            initialValues={getInitialValues()}
          >
            <Row gutter={12}>
              <Col span={5}>
                <Form.Item name={'month'}>
                  <Select
                    placeholder={'Selecione um mês'}
                    options={getMonthOpts()}
                    onChange={() => reloadFilters()}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={5}>
                <DatePickerPtBr
                  label={''}
                  name={'initialDateTime'}
                  required={true}
                  placeholder={'Selecionar Data Inicial'}
                  disabled={disabledDateFilter}
                />
              </Col>
              <Col span={5}>
                <DatePickerPtBr
                  label={''}
                  name={'endDateTime'}
                  required={true}
                  placeholder={'Selecionar Data Final'}
                  disabled={disabledDateFilter}
                />
              </Col>
              <Col span={5}>
                <Space>
                  <Button
                    icon={<SearchOutlined />}
                    htmlType={'submit'} >
                    Pesquisar
                  </Button>
                  <Button onClick={exportReport} icon={<ExportOutlined />} >
                    Exportar
                  </Button>
                </Space>
              </Col>
            </Row>
            <Row>
              <Form.Item name={'viewType'} label={'Visão dos Dados'}>
                <Radio.Group onChange={onTypeReportChange}>
                  <Radio.Button value={'a'}>Detalhado</Radio.Button>
                  <Radio.Button value={'sd'}>Agrupado Dia</Radio.Button>
                  <Radio.Button value={'sm'}>Agrupado Mês</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Row>
          </Form>
        </Col>
      </Row>
      <Divider />
      <Layout.Content>
        {
          typeReport === 's' && (
            <ListSyntheticStatementComponent
              data={data.map(w => ({...w, dateTime: w.dateTime.toString()}))}
              onEntryClick={onEntryClick}
              previousBalance={previousBalance}
              groupStrategy={groupStrategy}
              loading={bankAccountStRepo.loading}
            />
          )
        }
        {
          typeReport === 'a' && (
            <ListAnalyticStatementsComponent
              onEntryClick={onEntryClick}
              data={data}
              previousBalance={previousBalance}
              loading={bankAccountStRepo.loading}
            />
          )
        }
      </Layout.Content>
      <Switch>
        <Route path={`${routeMatch.path}/entry/:id`}>
          <Drawer
            placement={'bottom'}
            height={550}
            visible={true}
            bodyStyle={{padding: 0}}
            onClose={() => navigator.goBack()}
          >
            <ShowFinancialEntry {...props} />
          </Drawer>
        </Route>
      </Switch>
    </>
  );
}