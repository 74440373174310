import VMasker from "vanilla-masker";

export function useDocumentFormatter() {
    function formatCNPJ(value: string): string {
        return VMasker
            .toPattern(value, {
                pattern: '99.999.999/9999-99'
            });
    }

    function formatCPF(value: string): string {
        return VMasker.toPattern(value, {
            pattern: '999.999.999-99'
        })
    }

    return {
        formatCNPJ,
        formatCPF
    }
}
