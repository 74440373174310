import {BaseResponse, usePlaneduHttpService} from "../service/planedu.http.service";
import {NotificationEntity} from "../entity/notification.entity";

export function useNotificationRepository() {
  const httpService = usePlaneduHttpService<NotificationEntity>('notification');

  function listNotificationUser(): Promise<
    BaseResponse<NotificationEntity[]>
  > {
    return httpService.get(
      `${httpService.resourceName}/user`
    );
  }

  function setSeenNotifications(
    notificationIds: number[]
  ): Promise<BaseResponse<any>> {
    return httpService.post(
      `${httpService.resourceName}/seen`,
      JSON.stringify({ notificationIds })
    )
  }

  return {
    listNotificationUser,
    setSeenNotifications
  }
}