import {useCostCenterRepository} from "../../../../domain/repository/account.repository";
import {CostCenterEntity} from "../../../../domain/entity/cost-center.entity";

export function useCostCenterCrud() {
    const costCenterRepo = useCostCenterRepository();

    function create(costCenter: CostCenterEntity) {
        return costCenterRepo.create(costCenter);
    }

    function update(id: string, costCenter: CostCenterEntity) {
        return costCenterRepo.update(id, costCenter);
    }

    return {
        ...costCenterRepo,
        create,
        update
    }
}