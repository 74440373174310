export function useNumberFormatter() {
  const currencyFormatter = Intl.NumberFormat(
    'pt-BR',
    { style: 'currency', currency: 'BRL'}
  )

  const decimalFormatter = Intl.NumberFormat('pt-BR', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })


  const decimalParser = (value: string): number => {
    const formatted = value
      .replaceAll('.', '')
      .replace(',', '.');
    return parseFloat(formatted);
  }

  const formatter = (value: number | undefined): string => {
    return value ? decimalFormatter.format(value) : '0,00';
  }

  return {
    currencyFormatter,
    decimalFormatter,
    decimalParser: { parser: decimalParser, formatter }
  }
}