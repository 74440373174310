import {Col, Divider, Form, Input, message, Row, Switch} from "antd";
import React, {useEffect} from "react";
import {HeaderSupplyUnit} from "../component/header.supply-unit.";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import { useSupplyUnitRepository } from "../../../../domain/repository/supply-unit.repository";

export function CreateSupplyUnit(props: ModuleRenderProps) {
    const [form] = Form.useForm();
    const supplyUnitRepo = useSupplyUnitRepository();

    useEffect(() => {
        form.setFields([{
            name: 'active', value: true
        }])
    });

    function onFinish(data: any) {
        supplyUnitRepo.create(data).then(res => {
            message.success(res.message);
            props.navigator.goToListPage();
        }).catch(err => {
            message.error(err.message);
        })
    }

    return (
        <>
            <HeaderSupplyUnit mode={'form'} />
            <Divider />
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Row gutter={12}>
                    <Col span={18}>
                        <Form.Item name={'description'} label="Descrição" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name={'alias'} label="Sigla" >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={4}>
                        <Form.Item name={'active'} label="Status" valuePropName={'checked'} >
                            <Switch
                                checkedChildren={'Ativo'}
                                unCheckedChildren={'Inativo'}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <FormActionsButtons
                    onCancel={props.navigator.goToDefaultPage}
                    isLoading={supplyUnitRepo.loading}
                />
            </Form>
        </> 
    )
}
