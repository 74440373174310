import {Alert, Steps} from "antd";
import React, {CSSProperties, useEffect, useState} from "react";
import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {Content} from "antd/es/layout/layout";
import {FormFinancialEntry} from "../component/form.financial-entry";
import {FinancialEntryEntity} from "../../../../domain/entity/financial-entry.entity";
import {FormFinancialEntryItem} from "../component/form.financial-entry-item";
import {FinancialEntryItemEntity} from "../../../../domain/entity/financial-entry-item.entity";
import {ResultFinancialEntry} from "../component/result.financial-entry";
import {FormFinancialEntryTax} from "../component/form.financial-entry-tax";
import {FinancialEntryTaxEntity} from "../../../../domain/entity/financial-entry-tax.entity";
import {FormFinancialInstallment} from "../component/form.financial-installment";
import {useFinancialEntryRepository} from "../../../../domain/repository/financial-entry.repository";
import {useParams} from "react-router-dom";
import {FinancialEntryInstallmentEntity} from "../../../../domain/entity/financial-entry-installment.entity";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";

export function CreateFinancialEntry(props: ModuleRenderProps) {
    const [entry, setEntry] = useState<FinancialEntryEntity>();
    const [entryItems, setEntryItems] = useState<FinancialEntryItemEntity[]>();
    const [entryTaxes, setEntryTaxes] = useState<FinancialEntryTaxEntity[]>();
    const [installments, setInstallments] = useState<FinancialEntryInstallmentEntity[]>();
    const [hideStep, setHideStep] = useState(false);
    const [step, setStep] = useState<number>(0);

    const { id: entryId } = useParams<{ id: string }>();
    const financialEntryRepo = useFinancialEntryRepository();

    useEffect(() => {
        if (entryId) {
            setHideStep(true);
            financialEntryRepo
              .find(entryId)
              .then(res => {
                  setEntry(res.data);
                  setEntryTaxes(res.data.taxes);
                  setEntryItems(res.data.items);
                  setInstallments(res.data.installments);
              }).finally(() => {
                  setHideStep(false);
              });
        }
    }, [entryId]);

    function reloadEntry() {
        if (entry) {
            financialEntryRepo
              .find(entry.id.toString())
              .then(res => {
                  setEntry(res.data)
                  setEntryTaxes(res.data.taxes);
                  setEntryItems(res.data.items);
                  setInstallments(res.data.installments);
              });
        }
    }

    function onFinishEntry(entity: FinancialEntryEntity) {
        setEntry(entity);
        setStep(1);
    }

    function onFinishEntryItems(items: FinancialEntryItemEntity[]) {
        setEntryItems(items);
        reloadEntry();
        setStep(2);
    }

    function onFinishTaxForm(taxes: FinancialEntryTaxEntity[]) {
        setEntryTaxes(taxes);
        reloadEntry();
        setStep(3);
    }

    function onFinishInstallmentsForm() {
        reloadEntry();
        setStep(4);
    }

    function CurrentStep() {
        switch (step) {
            case 0:
                return (
                    <FormFinancialEntry
                        entry={entry}
                        onFinish={onFinishEntry}
                    />
                );
            case 1:
                return (
                    entry === undefined ? null :
                    <FormFinancialEntryItem
                        entry={entry}
                        onFinish={onFinishEntryItems}
                        items={entryItems}
                        setItems={items => setEntryItems(items)}
                        onBack={() => setStep(0)}
                    />
                )
            case 2:
                return (
                  entry === undefined ? null :
                    <FormFinancialEntryTax
                      entry={entry}
                      onFinish={onFinishTaxForm}
                      items={entryItems ?? []}
                      taxes={entryTaxes}
                      onBack={() => setStep(1)}
                    />
                )
            case 3:
                return entry === undefined ? null : (
                  <FormFinancialInstallment
                    entry={entry}
                    items={entryItems ?? []}
                    taxes={entryTaxes ?? []}
                    installments={installments ?? []}
                    onFinish={onFinishInstallmentsForm}
                  />
                )
            case 4:
                return entry === undefined ? null : (
                  <ResultFinancialEntry
                    entry={entry as FinancialEntryEntity}
                    onFinish={() => props.navigator.goToListPage()}
                  />
                )
            default:
                return null;

        }
    }

    function ShowContent() {
        if (!entry || !entry.statusPayment || entry.statusPayment === `pending`) {
          return <CurrentStep />;
        }
        return (
          <>
            <Alert
              showIcon
              message={'Esse lançamento não pode ser alterado, pois o mesmo contém baixas realizadas. ' +
              'Clique para visualizar caso queira ver mais detalhes.'}
              type={'warning'}
            />
            <FormActionsButtons
              hideSubmit
              labelCancel={'Voltar'}
              onCancel={() => props.navigator.goToListPage()}
            />
          </>
        )
    }


    return (
        <>
          <Steps current={step} onChange={step => setStep(step)}>
            <Steps.Step title={'Dados Iniciais'} />
            <Steps.Step title={'Itens do Lançamento'} disabled={step < 1} />
            <Steps.Step title={'Retenção Tributária'} disabled={step < 2} />
            <Steps.Step title={'Pagamento'} disabled={step < 3} />
            <Steps.Step title={'Finalizar'}  disabled={step < 4} />
          </Steps>
          <Content style={stepContent}>
            {!hideStep && <ShowContent/>}
          </Content>
        </> 
    )
}

const stepContent: CSSProperties = {
    minHeight: '200',
    marginTop: '16px',
    paddingTop: '15px',
    borderRadius: '2px'
}