import {usePlaneduHttpService} from "../service/planedu.http.service";
import {BudgetCategoryEntity} from "../entity/budget-category.entity";

export function useBudgetCategoryRepository() {

    const httpService = usePlaneduHttpService<BudgetCategoryEntity>('budget-category');

    return {
        ...httpService,
    }
}