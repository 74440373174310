import {Checkbox, Form, Input, message, Radio, Select} from "antd";
import {CashFlowRowTypeEnum} from "../../../../domain/vo/cash-flow-row-type.enum";
import {useEffect, useState} from "react";
import {CashFlowRowEntity} from "../../../../domain/entity/cash-flow-row.entity";
import {useCashFlowRowRepository} from "../../../../domain/repository/cash-flow-row.repository";
import {getSorter} from "../../../../core/util/sort-array.util";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import {ManagerialAccountEntity} from "../../../../domain/entity/managerial-account.entity";
import {useManagerialRepository} from "../../../../domain/repository/managerial.repository";

export type FormCreateRowProps = {
  onSaved: (row: CashFlowRowEntity) => any,
  onCancel: () => void,
  row?: CashFlowRowEntity,
}
export function FormCashFlowRow(props: FormCreateRowProps) {
  const [form] = Form.useForm();
  const [mode, setMode] = useState<'create' | 'edit'>('create');
  const [rows, setRows] = useState<CashFlowRowEntity[]>([])
  const [maAccounts, setMAAccounts] = useState<ManagerialAccountEntity[]>([])
  const [showParentRow, setShowParentRow] = useState(false);
  const [showCalcRows, setShowCalcRows] = useState(false);
  const [showManagerialAccount, setShowManagerialAccount] = useState(true);

  const cashFlowRowRepo = useCashFlowRowRepository();
  const maAccountRepo = useManagerialRepository();

  useEffect(() => {
    if (props.row) {
      const {
        isResultRow,
        rowsToCalc,
        parentRowId,
        type,
        name,
        managerialAccounts
      } = props.row;
      const rowsToCalcId = rowsToCalc ? rowsToCalc.map(item => item.id) : [];
      form.setFields([
        { name: 'name', value: name },
        { name: 'parentRowId', value: parentRowId },
        { name: 'type', value: type },
        { name: 'isResultRow', value: isResultRow },
        { name: 'managerialAccountsId', value: managerialAccounts?.map(item => item.id) },
        { name: 'rowsToCalcId', value: rowsToCalcId },
      ])
      setMode('edit')
    } else {
      setMode('create')
    }
    updateFormState();
  }, [props.row])

  function loadRows() {
    cashFlowRowRepo.findAll().then(res => setRows(res.data));
  }

  function loadParams() {
    maAccountRepo.listAnalyticalAccounts().then(res => setMAAccounts(res.data));
  }

  useEffect(() => {
    loadRows();
    loadParams();
  }, [1]);

  function getTypeOpts() {
    return [
      { label: 'Receita', value: CashFlowRowTypeEnum.REVENUE },
      { label: 'Dedução', value: CashFlowRowTypeEnum.DEDUCTION },
      { label: 'Calculada', value: CashFlowRowTypeEnum.CALCULATED },
    ]
  }

  function getRowsOpts() {
    return rows.sort(getSorter<CashFlowRowEntity>('order'))
      .map(item => ({
        label: item.name,
        value: item.id,
      }))
  }

  function getMAAccountOpts() {
    return maAccounts.sort(getSorter<ManagerialAccountEntity>('code'))
      .map(item => ({
        label: `${item.code} - ${item.description}`,
        value: item.id,
      }))
  }

  function updateFormState() {
    setShowParentRow(!form.getFieldValue('isResultRow'));
    setShowManagerialAccount(!form.getFieldValue('isResultRow'));
    setShowCalcRows(
      form.getFieldValue('isResultRow') &&
      form.getFieldValue('type') == CashFlowRowTypeEnum.CALCULATED
    )
  }

  function onSubmit(values: any) {
    function send() {
      if (props.row) {
        return cashFlowRowRepo
          .update(props.row.id.toString(), values);
      }
      return cashFlowRowRepo.create(values);
    }
    send().then(res => {
      message.success(res.message);
      props.onSaved(res.data);
    }).catch(err => {
      message.error(err.message);
    })
  }

  return (
    <Form
      onFinish={onSubmit}
      form={form}
      layout={'vertical'}
      initialValues={{ isResultRow: true }}
    >
      <Form.Item name={'name'} label={'Nome'} rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name={'type'} label={'Tipo da Linha'} rules={[{ required: true }]}>
        <Radio.Group
          disabled={mode === 'edit'}
          onChange={updateFormState}
          options={getTypeOpts()}
        />
      </Form.Item>
      <Form.Item name={'isResultRow'} valuePropName={'checked'}>
        <Checkbox
          disabled={mode === 'edit'}
          onChange={updateFormState}
        >
          Linha de Resultado
        </Checkbox>
      </Form.Item>
      {
        showManagerialAccount && (
          <Form.Item name={'managerialAccountsId'} label={'Conta Gerencial'} rules={[{ required: true }]}>
            <Select mode={'multiple'} options={getMAAccountOpts()} />
          </Form.Item>
        )
      }
      {
        showCalcRows && (
          <Form.Item
            name={'rowsToCalcId'}
            label={'Linhas Para Cálculo'}
            rules={[{ required: true }]}
          >
            <Select mode={'multiple'} options={getRowsOpts()} />
          </Form.Item>
        )
      }
      {
        showParentRow && (
          <Form.Item
            name={'parentRowId'}
            label={'Linha Pai'}
            rules={[{ required: true }]}
          >
            <Select options={getRowsOpts()} />
          </Form.Item>
        )
      }
      <FormActionsButtons onCancel={props.onCancel} />
    </Form>
  )
}