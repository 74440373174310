import {BaseResponse, BaseSearchResponse, usePlaneduHttpService} from "../service/planedu.http.service";
import {BankAccountStatementEntity} from "../entity/bank-account-statement.entity";
import {QueryParam} from "../../core/service/http.service";

export function useBankAccountStatementRepository() {
  const httpService = usePlaneduHttpService
    <BankAccountStatementEntity>('bank-account-statement');

  function listAccountStatements(
    accountId: string,
    limit?: number,
    offset?: number,
    filter?: { initialDateTime: string, endDateTime: string }
  ): Promise<BaseResponse<{ previousBalance: number, statements: BankAccountStatementEntity[] }>> {
    const params: QueryParam[] = [];
    params.push({ key: 'limit', value: limit || 'all' });
    params.push({ key: 'offset', value: offset || 0 });
    params.push({ key: 'endDateTime', value: filter?.endDateTime})
    params.push({ key: 'initialDateTime', value: filter?.initialDateTime})
    return httpService
      .get(`${httpService.resourceName}/account/${accountId}`, undefined, params);
  }

  function exportAccountStatements(
    accountId: string,
    filter: { initialDateTime: string, endDateTime: string }
  ): Promise<BaseSearchResponse<BankAccountStatementEntity>> {
    const params: QueryParam[] = [];
    params.push({ key: 'endDateTime', value: filter.endDateTime})
    params.push({ key: 'initialDateTime', value: filter.initialDateTime})
    return httpService.downloadRequest(
      'get',
      `${httpService.resourceName}/account/${accountId}/export`,
      'extrato.xlsx',
      undefined,
      params
    );
  }


  return {
    ...httpService,
    listAccountStatements,
    exportAccountStatements
  }

}