import {Modal} from "antd";
import {DreRowEntity} from "../../../../domain/entity/dre-row.entity";
import {DreTreeView} from "../../../component/dre-tree-view";

export type ModalAddReleaseParamProps = {
    visible: boolean,
    closeModal: () => void,
    dreRows: DreRowEntity[],
    currentCategoryId: number,
    currentManagerialAccountId: number,
    onSelect: (dreRow: DreRowEntity) => void
}

export function ModalAddReleaseParam(props: ModalAddReleaseParamProps) {
    const {
        closeModal,
        visible,
        dreRows,
        onSelect
    } = props;

    function bindRow(row: DreRowEntity) {
        if (row.isRowResult === true) {
            return;
        }

        onSelect(row);
    }

    return (
        <Modal
            width={600}

            onCancel={closeModal}
            title={'Seleciona a Linha que Deseja Vincular'}
            visible={visible}
        >
            <DreTreeView rows={dreRows} onSelect={bindRow} />
        </Modal>
    )
}