import {BaseResponse, usePlaneduHttpService} from "../service/planedu.http.service";
import {LedgerAccountEntity} from "../entity/ledger-account.entity";

export function useLedgerAccountRepository() {
  const httpService = usePlaneduHttpService<LedgerAccountEntity>('ledger-account');

  function listAnalyticalAccounts(): Promise<BaseResponse<LedgerAccountEntity[]>> {
    return httpService.get(
      `${httpService.resourceName}`,
      undefined,
      [{key: 'onlyAnalytical', value: 1}]
    )
  }

  return {
    ...httpService,
    listAnalyticalAccounts

  }
}