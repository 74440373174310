import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {HeaderFirm} from "../component/header.firm";
import React, {useEffect, useState} from "react";
import {Button, Col, Divider, Layout, List, message, Row, Typography} from "antd";
import {useCostCenterRepository} from "../../../../domain/repository/account.repository";
import {CostCenterEntity} from "../../../../domain/entity/cost-center.entity";
import {ArrowRightOutlined, DeleteFilled} from "@ant-design/icons";
import {useFirmRepository} from "../../../../domain/repository/firm.repository";
import {useParams} from "react-router-dom";

export function BindFilialFirm(props: ModuleRenderProps) {
    const [costCenters, setCostCenters] = useState<CostCenterEntity[]>([]);
    const [boundedCostCenter, setBoundedCostCenter] = useState<CostCenterEntity[]>([]);
    const firmRepo = useFirmRepository();
    const ccRepo = useCostCenterRepository();
    const { id } = useParams<{ id: string }>();

    function loadData() {
        ccRepo.search().then(res => setCostCenters(res.data.filter(i => i.active)));
        firmRepo.listCostCenters(id).then(res => setBoundedCostCenter(res.data));
    }

    useEffect(() => {
        loadData();
    }, [1])

    function bindCostCenter(firmId: number, costCenterId: number) {
        ccRepo.bindFirm(costCenterId, firmId).then(res => {
            message.success(res.message);
            loadData();
        })
    }

    function unbindCostCenter(firmId: number, costCenterId: number) {
        ccRepo.removeFirm(costCenterId, firmId).then(res => {
            message.success(res.message);

            loadData();
        })
    }

    function BindCostCenterButton(firmId: number, costCenterId: number) {
        return (
            <Button
                size={'small'}
                type={"ghost"}
                shape={'round'}
                onClick={() => bindCostCenter(firmId, costCenterId)}
                icon={<ArrowRightOutlined />}
            />
        )
    }

    function UnbindCostCenterButton(firmId: number, costCenterId: number) {
        return (
            <Button
                size={'small'}
                shape={'round'}
                onClick={() => unbindCostCenter(firmId, costCenterId)}
                icon={<DeleteFilled />}
            />
        )
    }

    function getAvailableCostCenters() {
        const boundedIds = boundedCostCenter.map(w => w.id);
        return costCenters.filter(
            w => !boundedIds.includes(w.id)
        )
    }

    return (
        <>
            <HeaderFirm
                mode={'form'}
                title={'Vincular Centros de Custo'}
                subTitle={'Vincular centros de custo à filial'}
            />
            <Divider />
            <Layout.Content>
                <Row gutter={38}>
                    <Col span={12}>
                        <List
                            pagination={{pageSize: 10}}
                            loading={ccRepo.loading}
                            header={<Typography.Title level={4}>Centros de Custo Disponíveis</Typography.Title>}
                            dataSource={getAvailableCostCenters()}
                            renderItem={(item: CostCenterEntity) => {
                                return (
                                    <List.Item actions={[BindCostCenterButton(parseInt(id), item.id as number)]}>
                                        {`${item.code} - ${item.name}`}
                                    </List.Item>
                                )
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        <List
                            pagination={{pageSize: 10}}
                            loading={firmRepo.loading}
                            header={<Typography.Title level={4}>Centros de Custo Vinculados</Typography.Title>}
                            dataSource={boundedCostCenter}
                            renderItem={(item: CostCenterEntity) => {
                                return (
                                    <List.Item actions={[UnbindCostCenterButton(parseInt(id), item.id as number)]}>
                                        {`${item.code} - ${item.name}`}
                                    </List.Item>
                                )
                            }}
                        />
                    </Col>
                </Row>
            </Layout.Content>
        </>
    )
}