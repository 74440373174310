import {ClientEntity} from "../../domain/entity/client.entity";

export function useClientStorage() {
  const CLIENT_KEY_STORAGE = '1123_1';

  function setClient(client: ClientEntity) {
    localStorage.setItem(CLIENT_KEY_STORAGE, btoa(JSON.stringify(client)));
  }

  function getClient(): ClientEntity | undefined {
    const item = localStorage.getItem(CLIENT_KEY_STORAGE);
    if(item) {
      return JSON.parse(atob(item));
    }
    return undefined;
  }

  function clear() {
    localStorage.removeItem(CLIENT_KEY_STORAGE);
  }

  return {
    setClient,
    getClient,
    clear
  }

}
