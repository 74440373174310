import {CashFlowRowEntity} from "../../../../domain/entity/cash-flow-row.entity";
import {Button, Descriptions, List, message, Modal, Tag} from "antd";
import {CashFlowRowTypeEnum} from "../../../../domain/vo/cash-flow-row-type.enum";
import ButtonGroup from "antd/es/button/button-group";
import {ArrowDownOutlined, ArrowUpOutlined, DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {useCashFlowRowRepository} from "../../../../domain/repository/cash-flow-row.repository";
import {useState} from "react";
import {FormCashFlowRow} from "./form.cash-flow-row";
import {ManagerialAccountEntity} from "../../../../domain/entity/managerial-account.entity";

export type DetailsCashFlowRowProps = {
  row: CashFlowRowEntity,
  onRemoved: () => void,
  onSaved: (row: CashFlowRowEntity) => void,
}

export function DetailsCashFlowRow(props: DetailsCashFlowRowProps) {
  const { row, onSaved, onRemoved } = props;
  const [editRow, setEditRow] = useState<CashFlowRowEntity>();

  const cashFlowRowRepo = useCashFlowRowRepository();

  function remove(id: number) {
    return cashFlowRowRepo.remove(id.toString()).then(res => {
      message.success(res.message);
      onRemoved();
    }).catch(err => {
      message.error(err.message);
    })
  }

  function parseType(type: CashFlowRowTypeEnum) {
    switch (type) {
      case CashFlowRowTypeEnum.CALCULATED:
        return <Tag color={'cyan'}>Calculada</Tag> ;
      case CashFlowRowTypeEnum.DEDUCTION:
        return <Tag color={'red'}>Dedução</Tag>;
      case CashFlowRowTypeEnum.REVENUE:
        return <Tag color={'green'}>Receita</Tag>;
      default:
        return type;
    }
  }

  function moveUp(id: number) {
    return cashFlowRowRepo.moveUp(id).then(res => {
      message.success(res.message);
      props.onSaved(res.data);
    }).catch(err => {
      message.error(err.message);
    })
  }

  function moveDown(id: number) {
    return cashFlowRowRepo.moveDown(id).then(res => {
      message.success(res.message);
      props.onSaved(res.data);
    }).catch(err => {
      message.error(err.message);
    })
  }

  return (
    <>
      <ButtonGroup size={'small'} style={{textAlign: 'center', display: 'block',marginBottom: 5}}>
        <Button
          onClick={() => setEditRow(row)}
          icon={<EditOutlined />}
        >
          Editar
        </Button>
        <Button
          onClick={() => remove(row.id)}
          icon={<DeleteOutlined />}
        >
          Remover
        </Button>
        <Button
          onClick={() => moveUp(row.id)}
          icon={<ArrowUpOutlined />}
        >
          Reordenar
        </Button>
        <Button
          onClick={() => moveDown(row.id)}
          icon={<ArrowDownOutlined />}
        >
          Reordenar
        </Button>
      </ButtonGroup>
      <Descriptions bordered >
        <Descriptions.Item span={24} label={'Nome'} >
          { row.name }
        </Descriptions.Item>
        <Descriptions.Item span={24}  label={'Conta Gerencial'} >
          <List
            dataSource={row.managerialAccounts}
            renderItem={(item: ManagerialAccountEntity) => (
              <List.Item>
                {item.code} - {item.description}
              </List.Item>
            )}
          />
        </Descriptions.Item>
        <Descriptions.Item span={24}  label={'Tipo'} >
          { parseType(row.type) }
        </Descriptions.Item>
        {
          !row.isResultRow && (
            <Descriptions.Item span={24} label={'Linha Pai'} >
              { row.parentRow?.name }
            </Descriptions.Item>
          )
        }
        {
          row.type === CashFlowRowTypeEnum.CALCULATED && (
            <Descriptions.Item span={24} label={'Linhas Relacionadas'} >
              <List
                dataSource={row.rowsToCalc ?? []}
                renderItem={(item: CashFlowRowEntity) => <List.Item>{item.order} - {item.name}</List.Item>}
              />
            </Descriptions.Item>
          )
        }
      </Descriptions>
      {
        editRow && (
          <Modal
            visible={true}
            footer={null}
            onCancel={() => setEditRow(undefined)}
          >
            <FormCashFlowRow
              onCancel={() => setEditRow(undefined)}
              onSaved={(row) => {
                onSaved(row)
                setEditRow(undefined);
              }}
              row={props.row}
            />
          </Modal>
        )
      }
    </>

  )
}