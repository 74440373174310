import {CashFlowRowAuditDto} from "../../../../domain/dto/cash-flow-row-audit.dto";
import {HeaderCashFlow} from "./header.cash-flow";
import {CashFlowRowResultDto} from "../../../../domain/dto/cash-flow-row-result.dto";
import {Divider, Table} from "antd";
import {useDateTimeUtil} from "../../../../core/util/date-time.util";
import {ColumnsType} from "antd/es/table/Table";
import {useNumberFormatter} from "../../../service/number-formatter.service";
import {ExportExcelButton} from "../../../../core/component/export-button";
import {useCashFlowRowRepository} from "../../../../domain/repository/cash-flow-row.repository";

export type CashFlowAuditDateComponentProps = {
  data: CashFlowRowAuditDto[],
  row: CashFlowRowResultDto,
  dateTime: Date,
  dateFormat: 'd' | 'm' | 'y',
  costCenterIds?: number[],
  firmId?: number,
  bankAccountId?: number,
}

export function CashFlowAuditDateComponent(props: CashFlowAuditDateComponentProps) {
  const {
    data,
    dateTime,
    row,
    dateFormat,
    firmId,
    costCenterIds,
    bankAccountId
  } = props;
  const { convertToDatePtBr } = useDateTimeUtil();
  const { decimalFormatter } = useNumberFormatter();
  const cashFlowRepo = useCashFlowRowRepository();

  const columns: ColumnsType<CashFlowRowAuditDto> = [
    {
      title: 'Num. Mov',
      dataIndex: 'entryId',
      key: 'entryId'
    },
    {
      title: 'Cli/For',
      dataIndex: 'personName',
      key: 'personName'
    },
    {
      title: 'Entrada',
      dataIndex: 'dateTimeEmission',
      key: 'dateTimeEmission',
      render: convertToDatePtBr
    },
    {
      title: 'Vencimento',
      dataIndex: 'dueTimeEmission',
      key: 'dueTimeEmission',
      render: convertToDatePtBr
    },
    {
      title: 'Item',
      dataIndex: 'itemName',
      key: 'itemName',
    },
    {
      title: 'Valor Bruto',
      dataIndex: 'documentGrossValue',
      key: 'documentGrossValue',
      render: decimalFormatter.format
    },
    {
      title: 'Valor Baixado',
      dataIndex: 'writeOffAmount',
      key: 'writeOffAmount',
      render: decimalFormatter.format
    },
    {
      title: 'Valor Item',
      dataIndex: 'itemValue',
      key: 'itemValue',
      render: decimalFormatter.format
    },
    {
      title: 'Valor Relativo',
      dataIndex: 'itemValueRelative',
      key: 'itemValueRelative',
      render: decimalFormatter.format
    },
    {
      title: 'Rateio',
      dataIndex: 'id',
      key: 'apportionment',
      render: (id: any, row: any) => renderApportionment(row)
    },
  ]

  function renderApportionment(row: CashFlowRowAuditDto) {
    if (!row.valueApportionment) {
      return null
    }
    return `${row.costCenterApportionment} (${decimalFormatter.format(row.valueApportionment)})`;
  }

  function downloadData() {
    return cashFlowRepo.exportAuditReport({
      cashFlowRowId: row.id,
      dateTime: dateTime.toISOString(),
      dateFormat: props.dateFormat,
      costCenterIds,
      firmId,
      bankAccountId,
    })
  }

  return (
    <>
      <HeaderCashFlow
        title={`Detalhamento da linha: ${row.name}`}
        subtitle={`Data ${convertToDatePtBr(dateTime)}`}
        mode={'form'}
        extra={[
          <ExportExcelButton onClick={downloadData} />
        ]}
      />
      <Divider />
      <Table
        columns={columns}
        dataSource={data}
        pagination={{pageSize: 5}}
      />
    </>
  )
}
