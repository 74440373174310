import { Col, Divider, Form, message, Row } from "antd";
import { useBankAccountRepository } from "../../../../domain/repository/bank-account.repository";
import { HeaderBankAccount } from "./header.bank-account";
import moment from "moment";
import { BrlInputNumber } from "../../../../core/component/brl-input-number";
import { DatePickerPtBr } from "../../../../core/component/date-picker-ptbr";
import { FormActionsButtons } from "../../../../core/component/form-actions-buttons";
import React, { useEffect, useState } from "react";
import { BankAccountEntity } from "../../../../domain/entity/bank-account.entity";

export type FormInitialBalanceProps = {
  account: BankAccountEntity,
  onFinish: () => any,
}

export function FormInitialBalance(props: FormInitialBalanceProps) {
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState(false);
  const bankAccountRepo = useBankAccountRepository();
  const { account } = props

  useEffect(() => {
    if (account.initialBalanceDateTime) {
      setDisabled(true);
      form.setFields([
        { name: 'balance', value: account.initialBalanceAmount },
        { name: 'dateTime', value: moment(account.initialBalanceDateTime) },
      ])
    }
  }, [account, form])

  function onFinish(data: any) {
    const { balance: creditValue, dateTime } = data;
    bankAccountRepo
      .addInitialBalance({
        creditValue,
        dateTime,
        bankAccountId: account.id,
      }).then(res => {
      message.success(res.message);
      props.onFinish();
    }).catch(err => {
      message.error(err.message);
    })
  }

  return (
    <>
      <HeaderBankAccount
        title={'Adicionar Saldo Inicial'}
        subtitle={'Adicionar primeiro lancamento de credito'}
        mode={'form'}
      />
      <Divider />
      <Form
        form={form}
        layout={'vertical'}
        onFinish={onFinish}
        initialValues={{ dateTime: moment() }}
      >
        <Row gutter={12}>
          <Col span={12}>
            <BrlInputNumber
              disabled={disabled}
              label={'Saldo Inicial'}
              name={'balance'}
              required={true}
            />
          </Col>
          <Col span={12}>
            <DatePickerPtBr
              disabled={disabled}
              label={'Data'}
              name={'dateTime'}
              required={true}
            />
          </Col>
        </Row>
        {
          !disabled && (
            <FormActionsButtons />
          )
        }
      </Form>
    </>
  )
}