import React from 'react';
import {Button, Dropdown, Menu} from "antd";
import {UserOutlined, ContainerOutlined, LogoutOutlined} from '@ant-design/icons';
import {useAuthService} from "../../../admin/service/auth.service";
import {useUserStorage} from "../../service/user.storage";
import {useHistory} from "react-router-dom";

export type ProfileButtonProps = {

}

export function ProfileButton(props: ProfileButtonProps) {
  const userStorage = useUserStorage();

  return (
    <Dropdown overlay={<OptionProfile />}>
      <Button icon={<UserOutlined />} type={'text'}>
        {userStorage.getUser()?.name}
      </Button>
    </Dropdown>
  )
}

const OptionProfile = () => {
  const authService = useAuthService();
  const history = useHistory();

  const onClick = (click: any) => {
    const { key } = click;
    switch (key) {
      case "0":
        logoutSystem();
        break;
      case "1":
        goToProfilePage();
        break;
      case "2":
        history.push('/auth/login', { step: 'swapContext' });
        break;
      default: break;
    }
  }

  const goToProfilePage = () => history.push('/admin/profile/me')
  const logoutSystem = () => authService.logout();



  return (
    <Menu onClick={onClick} title={'Opções do Usuário'} selectable={false}>
      <Menu.Item key="1" icon={<UserOutlined />}>Perfil de Acesso</Menu.Item>
      <Menu.Item key="2" icon={<ContainerOutlined />}>Trocar Contexto</Menu.Item>
      <Menu.Divider />
      <Menu.Item key="0" icon={<LogoutOutlined />}>Sair do Sistema</Menu.Item>
    </Menu>
  )
};

