import {useRouteMatch} from "react-router-dom";
import {ModuleResource, ModuleResourceTypeEnum, ModuleRoot} from "../../../core/component/module/module.root";
import React from "react";
import {CreateFinancialEntry} from "./pages/create.financial-entry";
import {FinancialEntryIndex} from "./pages";
import {SaveFinancialProvision} from "./pages/save.financial-provision";
import {SaveApportionment} from "./pages/save.apportionment";
import {ListFinancialEntryEvent} from "./pages/list.financial-entry-event";
import {RenegotiateInstallments} from "./pages/renegotiate.installments";

export function FinancialEntryModule() {
    const routeMatch = useRouteMatch();
    const resources: ModuleResource[] = [
        {
            type: ModuleResourceTypeEnum.CREATE,
            render: (props) => <CreateFinancialEntry {...props} />
        },
        {
            type: ModuleResourceTypeEnum.CUSTOM,
            customPath: 'provision/:id',
            render: (props) => <SaveFinancialProvision {...props} />
        },
        {
            type: ModuleResourceTypeEnum.CUSTOM,
            customPath: 'provision',
            render: (props) => <SaveFinancialProvision {...props} />
        },
        {
            type: ModuleResourceTypeEnum.CUSTOM,
            customPath: 'events',
            render: (props) => <ListFinancialEntryEvent {...props} />
        },
        {
            type: ModuleResourceTypeEnum.CUSTOM,
            customPath: 'apportionment/:id',
            render: (props) => <SaveApportionment {...props} />
        },
        {
            type: ModuleResourceTypeEnum.CUSTOM,
            customPath: 'renegotiate/:id',
            render: (props) => <RenegotiateInstallments {...props} />
        },
        {
            type: ModuleResourceTypeEnum.EDIT,
            render: (props) => <CreateFinancialEntry {...props} />
        },
        {
            type: ModuleResourceTypeEnum.DEFAULT,
            render: (props) => <FinancialEntryIndex {...props} />
        },
    ]

    return (
        <ModuleRoot
            resource={routeMatch.url}
            moduleResources={resources}
        />
    );
}
