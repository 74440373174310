import {useRouteMatch} from "react-router-dom";
import React from 'react';
import {ModuleResource, ModuleResourceTypeEnum, ModuleRoot} from "../../../core/component/module/module.root";
import {CreateRecurrenceEntry} from "./pages/create.recurrence-entry";
import {ListRecurrenceEntry} from "./pages/list.recurrence-entry";
import {EditRecurrenceEntry} from "./pages/edit.recurrence-entry"
import {FormRecurrenceItem} from "./pages/form.recurrence-item";
import {ParseRecurrenceEntry} from "./pages/parse.recurrence-entry";

export function RecurrenceModule() {
    const routeMatch = useRouteMatch();
    const resources: ModuleResource[] = [
        {
            type: ModuleResourceTypeEnum.CREATE,
            render: (props) => <CreateRecurrenceEntry {...props} />
        },
        {
            type: ModuleResourceTypeEnum.EDIT,
            render: (props) => <EditRecurrenceEntry {...props} />
        },
        {
            type: ModuleResourceTypeEnum.CUSTOM,
            customPath: `items/:id`,
            render: (props) => <FormRecurrenceItem {...props} />
        },
        {
            type: ModuleResourceTypeEnum.CUSTOM,
            customPath: `parse/:id`,
            render: (props) => <ParseRecurrenceEntry {...props} />
        },
        {
            type: ModuleResourceTypeEnum.DEFAULT,
            render: (props) => <ListRecurrenceEntry {...props} />
        },
    ]

    return (
        <ModuleRoot
            resource={routeMatch.url}
            moduleResources={resources}
        />
    )
}
