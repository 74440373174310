import React, {useEffect, useState} from "react";
import {HeaderBankAccount} from "../component/header.bank-account";
import { Button, Divider, Layout, message, Table } from "antd";
import {ListActions} from "../../../../core/component/list-actions";
import {BankEntity} from "../../../../domain/entity/bank.entity";
import {useBankAccountRepository} from "../../../../domain/repository/bank-account.repository";
import {BankAccountEntity} from "../../../../domain/entity/bank-account.entity";
import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import { SendOutlined } from "@ant-design/icons";
import {ExportExcelButton} from "../../../../core/component/export-button";

export function ListBankAccount(props: ModuleRenderProps) {
  const bankAccountRepo = useBankAccountRepository();
  const [data, setData] = useState<BankAccountEntity[]>([]);

  function loadData() {
    bankAccountRepo.search().then(res => {
      setData(res.data)
    });
  }

  useEffect(() => {
    loadData()
  }, [1]);

  function removeGroup(id: string) {
    bankAccountRepo.remove(id)
      .then(res => {
        message.success(res.message);
        loadData();
      }).catch(err => {
      message.error(err.message);
    })
  }

  const columnsBudget = [
    {
      title: 'Nome',
      dataIndex: 'alias' ,
      key: 'alias',
    },
    {
      title: 'Banco',
      dataIndex: 'bank' ,
      key: 'bank',
      render: (bank: BankEntity) => bank?.name
    },
    {
      title: 'Ag.',
      dataIndex: 'agency' ,
      key: 'agency',
    },
    {
      title: 'Conta',
      dataIndex: 'account' ,
      key: 'account',
    },
    {
      title: 'Dig.',
      dataIndex: 'code' ,
      key: 'code',
    },
    {
      dataIndex: 'id',
      render: (val: any) => (
        <>
          <ListActions
            onEditClick={() => props.navigator.goToEditPage(val) }
            onRemoveClick={() => removeGroup(val) }
            options={[
              { name: 'EXTRATO', onClick: () => props.navigator.goToCustomPage(`statements/${val}`) },
              { name: 'TRANSFERÊNCIAS', onClick: () => props.navigator.goToCustomPage(`${val}/transference`) },
              { name: 'SALDO INICIAL', onClick: () => props.navigator.goToCustomPage(`initial-balance/${val}`) },
            ]}
          />
        </>
      )
    }
  ]
  return (
    <>
      <HeaderBankAccount
        extra={[
          <Button
            onClick={() => props.navigator.goToCustomPage('transfer-account')}
            shape={'round'} icon={<SendOutlined />}>
            Trasf. Entre Contas
          </Button>,
          <ExportExcelButton onClick={() => bankAccountRepo.exportToExcel()} />
        ]}
      />
      <Divider />
      <Layout.Content>
        <Table
          columns={columnsBudget}
          dataSource={data}
          loading={bankAccountRepo.loading}
        />
      </Layout.Content>
    </>
  );
}