import React, {useEffect, useState} from "react";
import {HeaderUser} from "../component/header.user";
import {Divider, Layout, message, Table,} from "antd";
import {UserEntity} from "../../../../domain/entity/user.entity";
import {useUserRepository} from "../../../../domain/repository/user.repository";
import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {ListActions} from "../../../../core/component/list-actions";
import {ListProfileModal} from "./list.profile.modal";
import {ListCostCenterModal} from "./list.costCenter.modal"

export function ListUser(props: ModuleRenderProps) {
    const userService = useUserRepository();
    const [data, setData] = useState<UserEntity[]>([]);
    const [profileModalVisible, setProfileModalVisible] = useState(false);
    const [profileModalCCId, setProfileModalCCId] = useState<number>();
    const [costCenterModalVisible, setCostCenterModalVisible] = useState(false);
    const [costCenterModalCCId, setCostCenterModalCCId] = useState<number>();

    function loadData() {
        userService.search().then(res => setData(res.data));
    }

    useEffect(() => {
        loadData()
    }, [1]);

    function removeUser(id: string) {
        userService.remove(id)
            .then(res => {
                message.success(res.message);
                loadData();
            }).catch(err => {
                message.error(err.message);
        })
    }

    function onProfileClick(id: number) {
        setProfileModalCCId(id);
        setProfileModalVisible(true);
    }

    function closeProfileModal() {
        setProfileModalVisible(false);
        setProfileModalCCId(undefined);
    }

    function onCostCenterClick(id: number) {
        setCostCenterModalCCId(id);
        setCostCenterModalVisible(true);
    }

    function closeCostCenterModal() {
        setCostCenterModalVisible(false);
        setCostCenterModalCCId(undefined);
    }



    const columnsUser = [
        {
            title: 'Nome',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Usuario',
            dataIndex: 'username',
            key: 'usermane',
        },
        {
            dataIndex: 'id',
            render: (val: any, user: UserEntity) => (
                <>
                    <ListActions
                        onEditClick={() => props.navigator.goToEditPage(val) }
                        onRemoveClick={() => removeUser(val) }
                        options={[
                            { name: 'ALTERAR SENHA', onClick: () => props.navigator.goToCustomPage("password/"+val)},
                            {
                                name: 'PERFIS',
                                onClick: () => onProfileClick(val)
                            },
                            {
                                name: 'CENTROS DE CUSTO',
                                onClick: () => onCostCenterClick(val)
                            }
                        ]}
                    />
                </>
            )
        }
    ]
    return (
        <>
            <HeaderUser />
            <Divider />
            <Layout.Content>
                <Table
                    columns={columnsUser}
                    dataSource={data}
                    loading={userService.loading} />
            </Layout.Content>
            {
                profileModalVisible
                && profileModalVisible
                && <ListProfileModal
                        onClose={closeProfileModal}
                        visible={profileModalVisible}
                        userId={profileModalCCId as number}
                    />
            }
            {
                costCenterModalVisible
                && costCenterModalVisible
                && <ListCostCenterModal
                        onClose={closeCostCenterModal}
                        visible={costCenterModalVisible}
                        userId={costCenterModalCCId as number}
                    />
            }

        </>
    );
}
