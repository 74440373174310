import React, {useEffect, useState} from 'react';
import {LoginFormStyle, LoginWallpaperStyle, Wrapper} from "./styles";
import {message} from 'antd';
import {Route, Switch, useHistory, useRouteMatch} from "react-router-dom";
import {AuthResponse, useAuthService} from "../../../../service/auth.service";
import {FormLogin} from "./form-login";
import {ClientEntity} from "../../../../../domain/entity/client.entity";
import {FormClient} from "./form-client";
import {ProfileEntity} from "../../../../../domain/entity/profile.entity";
import {FormRecoverPassword} from "./form-recover-password";
import {FormRequestChange} from "./form-request-change";

export function LoginPage(props: any) {
  const history = useHistory();
  const authService = useAuthService();
  const [authData, setAuthData] = useState<AuthResponse>()
  const [step, setStep] = useState(1);
  const [clients, setClients] = useState<ClientEntity[]>([]);
  const [profiles, setProfiles] = useState<ProfileEntity[]>([]);
  const [clientId, setClientId] = useState<number>();
  const [profileId, setProfileId] = useState<number>();
  const [allProfiles, setAllProfiles] = useState<ProfileEntity[]>([]);
  const [username, setCurrentUsername] = useState<string>('');
  const [password, setCurrentPassword] = useState<string>('');
  const [bearerToken, setBearerToken] = useState<string>();

  const route = useRouteMatch();

  useEffect(() => {
    const tempAuthData = authService.getAuthResponseFromStorage();
    if(tempAuthData) {
      setAuthData(tempAuthData as AuthResponse);
      setClients([tempAuthData.client])
      setBearerToken(tempAuthData.bearerToken);
      authService.me(tempAuthData.bearerToken).then(res => {
        setClients(res.data.clients)
        setAllProfiles(res.data.profiles)
      });
      setStep(2);
    }
  }, [1])

  function onAuth(props: { username: string, password: string, clientKey: string }) {
    const { username, password } = props;
    setCurrentUsername(username);
    authService.authUser(username, password).then((res) => {
      if (!res) {
        return message.error('usuário não encontrado');
      }
      setCurrentUsername(username);
      setCurrentPassword(password);
      setClients(res.data.clients);
      setAllProfiles(res.data.profiles);
      setStep(2);
    }).catch(err => {
      message.error(err.message);
    });
  }

  function refreshAuth(clientId: number) {
    if (!authData) {
      return;
    }
    authService.changeClient(authData.bearerToken, clientId)
      .then(res => {
        setProfiles([res.profile]);
        setAuthData({
          ...authData,
          bearerToken: res.bearerToken,
          refreshToken: res.refreshToken,
          client: res.client,
          profile: res.profile
        })
        authService.listProfiles(res.bearerToken).then(res => {
          setProfiles(res);
          setStep(2);
        })
      })
  }

  function refreshProfile(profileId: number) {
    if(!authData) {
      return;
    }
    authService.changeProfile(
      authData.bearerToken,
      profileId
    ).then(res => {
      setAuthData({
        ...authData,
        bearerToken: res.bearerToken,
        refreshToken: res.refreshToken,
        profile: res.profile
      })
    })
  }

  function finish() {
    function _auth() {
      if (!bearerToken) {
        return authService.auth(
          username,
          password,
          clients.find(w => w.id === clientId)?.key ?? '',
          allProfiles.find(w => w.id === profileId)?.alias ?? ''
        );
      }
      return authService.changeToken(
        profileId as number,
        clientId as number,
        bearerToken
      );
    }
    _auth().then(res => {
      message.success('Usuário autenticado com sucesso !')
      authService.setStorage(res);
      history.push('/admin');
    }).catch(err => {
      message.error(err.message);
    })
  }

  function StepLogin() {
    switch (step) {
      case 1:
        return (
          <FormLogin
            onAuth={onAuth}
            loading={authService.loading}
            username={username}
          />
        )
      case 2:
        return (
          <FormClient
            clients={clients}
            clientId={clientId as number}
            profileId={profileId as number}
            profiles={profiles}
            refreshAuth={refreshAuth}
            refreshProfile={refreshProfile}
            loading={authService.loading}
            onFinish={finish}
            clientSelected={(id) => {
              setClientId(id)
              setProfileId(undefined);
              setProfiles(allProfiles.filter(w => w.clientId === id));
            }}
            profileSelected={(id) => setProfileId(id)}
          />
        )
      default:
        return null;
    }
  }


  return (
    <div style={Wrapper}>
      <div style={LoginWallpaperStyle} />
      <div style={LoginFormStyle}>
        <div style={{width: '100%', padding: 25}}>
          <Switch>
            <Route path={`${route.path}/recover-password`}>
              <FormRecoverPassword />
            </Route>
            <Route path={`${route.path}/request-change`}>
              <FormRequestChange />
            </Route>
            <Route path={`${route.url}`}>
              <StepLogin />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}
