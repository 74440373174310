import {Steps} from "antd";
import {Content} from "antd/es/layout/layout";
import {useState} from "react";
import {FinancialEntryInstallmentEntity} from "../../../../../domain/entity/financial-entry-installment.entity";
import {FinancialEntryEntity} from "../../../../../domain/entity/financial-entry.entity";
import {SelectInstallmentsComponent} from "./select-installments.component";
import {RenegotiateInstallmentsFormComponent} from "./renegotiate-installments-form.component";
import {ConfirmRenegotiateComponent} from "./confirm-renegotiate.component";
import {InstallmentViewModel} from "./installment.view-model";

export type RenegotiateFormProps = {
  entry: FinancialEntryEntity,
  onFinish: () => any
}
export function RenegotiateFormComponent(props: RenegotiateFormProps) {
  const [step, setCurrentStep] = useState(0);
  const [prevInstallments, setPrevInstallments] = useState<InstallmentViewModel[]>([]);
  const [selectedInstallments, setSelectedInstallments] = useState<FinancialEntryInstallmentEntity[]>([])

  const { entry } = props;

  function selectInstallment(installment: FinancialEntryInstallmentEntity) {
    setSelectedInstallments([...selectedInstallments, installment]);
  }

  function removeInstallment(installment: FinancialEntryInstallmentEntity) {
    setSelectedInstallments(selectedInstallments.filter(w => w.id !== installment.id));
  }

  return (
    <>
      <Steps current={step} onChange={setCurrentStep}>
        <Steps.Step title={'Selecione as Parcelas'} />
        <Steps.Step title={'Renegociação'} key={2} />
        <Steps.Step title={'Confirmação'} key={3} />
      </Steps>
      <Content style={{paddingTop: 10}}>
        {
          step === 0 && (
            <SelectInstallmentsComponent
              installments={entry?.installments ?? []}
              onFinish={() => setCurrentStep(step + 1)}
              onInstallmentSelected={selectInstallment}
              onInstallmentRemoved={removeInstallment}
              selectedInstallments={selectedInstallments}
            />
          )
        }
        {
          step === 1 && (
            <RenegotiateInstallmentsFormComponent
              onFinish={() => setCurrentStep(step + 1)}
              onCancel={() => setCurrentStep(step - 1)}
              entryInstallments={entry.installments ?? []}
              setPrevInstallments={setPrevInstallments}
              selectedInstallments={selectedInstallments}
            />
          )
        }
        {
          step === 2 && (
            <ConfirmRenegotiateComponent
              entryId={entry.id}
              onCancel={() => setCurrentStep(step - 1)}
              onFinish={props.onFinish}
              selectInstallments={selectedInstallments}
              prevInstallments={prevInstallments}
              currentInstallments={entry?.installments ?? []}
            />
          )
        }
      </Content>
    </>
  )
}