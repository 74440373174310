import {FinancialEntryEntity} from "../../../../../domain/entity/financial-entry.entity";
import {Timeline} from "antd";
import {useEffect, useState} from "react";
import {FinancialEntryEventEntity} from "../../../../../domain/entity/financial-entry-event.entity";
import {useDateTimeUtil} from "../../../../../core/util/date-time.util";
import {useFinancialEntryRepository} from "../../../../../domain/repository/financial-entry.repository";
import Text from "antd/es/typography/Text";

export function HistoryEntry(props: { entry: FinancialEntryEntity }) {
  const [events, setEvents] = useState<FinancialEntryEventEntity[]>([]);
  const { convertToDateTimePtBr } = useDateTimeUtil();
  const entryRepo = useFinancialEntryRepository();

  useEffect(() => {
    entryRepo.listEvents({
      entryId: props.entry.id,
      offset: 0,
      limit: 1000,
      orderByDirection: 'ASC',
      orderByField: 'id'
    }).then(res => setEvents(res.data));
  }, [props?.entry?.id])

  return (
    <>
      <Timeline mode={'alternate'}>
        {
          events.map(event => (
            <Timeline.Item label={convertToDateTimePtBr(event.dateTime)} position={'left'}>
              {event.description} <br />
              usuario <Text strong>{event.user}</Text>
            </Timeline.Item>
          ))
        }
      </Timeline>
    </>
  )
}