import {FirmEntity} from "../../../../domain/entity/firm.entity";

export function useAddressFormat() {
    function showAddressComplete(entity: FirmEntity): string {
        return `${entity.addressStreet} ${entity.addressNumber}, ${entity.addressDistrict} ${entity.addressCity} - ${entity.addressState}`
    }

    return {
        showAddressComplete
    }
}
