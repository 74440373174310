import {Col, Form, Input, message, Radio, Row, Select} from "antd";
import React, {useEffect, useState} from "react";
import {FirmEntity} from "../../../../domain/entity/firm.entity";
import {useFirmRepository} from "../../../../domain/repository/firm.repository";
import {CostCenterEntity} from "../../../../domain/entity/cost-center.entity";
import {PersonEntity} from "../../../../domain/entity/person.entity";
import {usePersonRepository} from "../../../../domain/repository/person.repository";
import {DatePickerPtBr} from "../../../../core/component/date-picker-ptbr";
import TextArea from "antd/es/input/TextArea";
import {FinancialDocumentTypeEntity} from "../../../../domain/entity/financial-document-type.entity";
import {useFinancialDocumentTypeRepository} from "../../../../domain/repository/financial-document-type.repository";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import {useFinancialEntryRepository} from "../../../../domain/repository/financial-entry.repository";
import {FinancialEntryEntity} from "../../../../domain/entity/financial-entry.entity";
import {ErrorMessageResource} from "../resource/error-message.resource";
import moment from "moment";
import {InputSearch} from "../../../../core/component/input-search";

export type FormFinancialEntryProps = {
  entry?: FinancialEntryEntity,
  onFinish: (entity: FinancialEntryEntity) => void,
  initialValues?: any,
}

export function FormFinancialEntry(props: FormFinancialEntryProps) {
  const [form] = Form.useForm();
  const [firms, setFirms] = useState<FirmEntity[]>([]);
  const [costCenters, setCostCenters] = useState<CostCenterEntity[]>([]);
  const [persons, setPersons] = useState<PersonEntity[]>([]);
  const [documentTypes, setDocumentTypes] = useState<FinancialDocumentTypeEntity[]>([]);

  const firmRepo = useFirmRepository();
  const personRepo = usePersonRepository();
  const documentTypeRepo = useFinancialDocumentTypeRepository();
  const financialEntryRepo = useFinancialEntryRepository();

  function loadCostCenters(firmId: string) {
    firmRepo.listCostCenters(firmId.toString(), true)
      .then(res => setCostCenters(res.data));
  }

  function loadParams() {
    firmRepo.findAll().then(res => setFirms(res.data));
    personRepo.findAll().then(res => setPersons(res.data));
    documentTypeRepo.findAll().then(res => setDocumentTypes(res.data));
  }

  function loadInitialValues() {
    const { initialValues } = props;
    form.setFieldsValue(initialValues)
    if (initialValues.firmId) {
      loadCostCenters(initialValues.firmId.toString());
    }
  }

  function loadDefaultEntity() {
    const { entry } = props;
    if (entry) {
      form.setFields([
        { name: 'dateTimeEntry', value: moment(entry.dateTimeEntry) },
        { name: 'firmId', value: entry.firmId },
        { name: 'costCenterId', value: entry.costCenterId },
        { name: 'type', value: entry.type },
        { name: 'personId', value: entry.personId },
        { name: 'documentTypeId', value: entry.documentTypeId },
        { name: 'documentSeries', value: entry.documentSeries },
        { name: 'documentNumber', value: entry.documentNumber },
        { name: 'dateTimeEmission', value: moment(entry.dateTimeEmission) },
        { name: 'observation', value: entry.observation },
      ])
      if (entry.firmId) {
        loadCostCenters(entry.firmId.toString());
      }
    }
  }

  useEffect(() => {
    loadParams();
    loadDefaultEntity()
  }, [1]);

  useEffect(() => {
    if (props.initialValues && !props.entry) {
      loadInitialValues();
    }
  }, [props.initialValues])

  function getFirmsOpts() {
    return firms
      .sort((a,b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      })
      .map(item => ({
        label: item.name,
        value: item.id as number
      }))
  }

  function loadClientsOrProviders() {
    const type = form.getFieldValue('type');
    switch (type) {
      case 'D':
        personRepo.listProviders().then(res => setPersons(res.data));
        break;
      case 'R':
        personRepo.listClients().then(res => setPersons(res.data));
        break;
    }
  }

  function getCostCentersOpts() {
    return costCenters
      .sort((a,b) => {
        if (a.code > b.code) return 1;
        if (a.code < b.code) return -1;
        return 0;
      })
      .map(item => ({
        label: `${item.code} - ${item.name}`,
        value: item.id as number
      }))
  }

  function getPersonsOpts() {
    return persons
      .sort((a,b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      })
      .map(item => ({
        label: `${item.name}`,
        value: item.id as number
      }))
  }

  function getDocumentTypeOpts() {
    return documentTypes.sort(
      (a,b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0
      }
    ).map(item => ({
      label: item.name,
      value: item.id
    }))
  }

  function onFinish(values: any) {
    function send() {
      if (props.entry?.id) {
        return financialEntryRepo.update(props.entry.id.toString(), values);
      }
      return financialEntryRepo.create(values);
    }

    send().then(res => {
      message.success(res.message);
      props.onFinish(res.data);
    }).catch(err => {
      message.error(err.message);
      if (err.messageKey === ErrorMessageResource.EXPIRED_ENTRY_DATE_ERROR) {
        form.setFields([{
          name: 'dateTimeEntry',
          errors: ['Data fora do limite']
        }])
      }
    })
  }


  return (
    <Form initialValues={props.initialValues} form={form} layout={'vertical'} onFinish={onFinish}>
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item name='firmId' label={'Filial'} rules={[{ required: true }]}>
            <Select onSelect={val => loadCostCenters(val.toString())} options={getFirmsOpts()} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <InputSearch
            name={'costCenterId'}
            label={'Centro de Custo'}
            data={getCostCentersOpts()}
            required={true}
            loading={firmRepo.loading}
          />
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={6}>
          <Form.Item name={'type'} label={'Tipo de Lançamento'} rules={[{ required: true }]}>
            <Radio.Group onChange={() => loadClientsOrProviders()}>
              <Radio value={'R'}>Receita</Radio>
              <Radio value={'D'}>Despesa</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={18}>
          <InputSearch
            name={'personId'}
            label={'Cliente / Forncedor'}
            data={getPersonsOpts()}
            required={true}
            loading={personRepo.loading}
          />
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={8}>
          <Form.Item name={'documentTypeId'} label={'Espécie Documento'} rules={[{ required: true }]}>
            <Select options={getDocumentTypeOpts()} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name={'documentSeries'} label={'Série'}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name={'documentNumber'} label={'Documento'} rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={12}>
          <DatePickerPtBr
            label={'Data de Emissão'}
            name={'dateTimeEmission'}
            onChange={val => form.setFieldsValue({ dateTimeEntry: val })}
            required={true}
          />
        </Col>
        <Col span={12}>
          <DatePickerPtBr
            label={'Data de Entrada'}
            name={'dateTimeEntry'}
            required={true}
          />
        </Col>
      </Row>
      <Form.Item name={'observation'} label={'Observação'}>
        <TextArea />
      </Form.Item>

      <FormActionsButtons label={'Salvar'} />
    </Form>
  )
}