import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {useParams} from "react-router-dom";
import {ShowFullFinancialEntry} from "../component/show-full-financial-entry";

export function ShowFinancialEntry(props: ModuleRenderProps) {
  const { id: entryId } = useParams<{ id: string }>();

  return (
    <ShowFullFinancialEntry entryId={parseInt(entryId)} />
  )
}