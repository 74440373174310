import {BaseResponse, usePlaneduHttpService} from "../service/planedu.http.service";
import {PersonEntity} from "../entity/person.entity";
import {PersonAddressEntity} from "../entity/person-address.entity";
import {PersonBankDataEntity} from "../entity/person-bank-data.entity";
import {PersonFunctionEntity} from "../entity/person-function.entity";
import {ImportEntity} from "../entity/import.entity";
import {ImportMessageEntity} from "../entity/import-message.entity";
import {PersonIndividualEntity} from "../entity/person-individual.entity";
import {PersonCompanyEntity} from "../entity/person-company.entity";

export function usePersonRepository() {
    const httpService = usePlaneduHttpService<PersonEntity>('person');

    function getEducationLevels(): Promise<BaseResponse<string[]>> {
        return httpService.get('person/education-levels');
    }

    function savePersonIndividual(personId: number, data: any): Promise<BaseResponse<PersonIndividualEntity>> {
        return httpService.put('person/person-individual', JSON.stringify({ personId, ...data }));
    }

    function savePersonCompany(personId: number, data: any): Promise<BaseResponse<PersonCompanyEntity>> {
        return httpService.put('person/person-company', JSON.stringify({ personId, ...data }));
    }

    function addAddress(personAddress: PersonAddressEntity): Promise<BaseResponse<PersonAddressEntity>> {
        return httpService.post('person/address', JSON.stringify(personAddress));
    }

    function updateAddress(id: number | string, personAddress: PersonAddressEntity): Promise<BaseResponse<PersonAddressEntity>> {
        return httpService.put('person/address/' + id, JSON.stringify(personAddress));
    }

    function addBankData(entity: PersonBankDataEntity): Promise<BaseResponse<PersonBankDataEntity>> {
        return httpService.post('person/bank-data', JSON.stringify(entity));
    }

    function updateBankData(id: string | number, entity: PersonBankDataEntity): Promise<BaseResponse<PersonBankDataEntity>> {
        return httpService.put('person/bank-data/' + id, JSON.stringify(entity));
    }

    function addFunction(personId: number, personFunction: string, meta?: object): Promise<BaseResponse<PersonFunctionEntity>> {
        return httpService.post('person/function', JSON.stringify({ personId, personFunction, meta }));
    }

    function removeFunction(personFunctionId: number): Promise<BaseResponse<PersonFunctionEntity>> {
        return httpService.delete('person/function/' + personFunctionId);
    }

    function findByDocument(document: string): Promise<BaseResponse<PersonEntity>> {
        return httpService.get(`person/document/${encodeURI(document)}`)
    }

    function listProviders(): Promise<BaseResponse<PersonEntity[]>> {
        return httpService.get(`person/function/provider`);
    }

    function listClients(): Promise<BaseResponse<PersonEntity[]>> {
        return httpService.get(`person/function/client`);
    }

    function exportPersons() {
        return httpService.downloadRequest(
          'get',
          `${httpService.resourceName}/export`,
          'cli-for.xlsx',
        );
    }


    function downloadImportModel() {
        return httpService.downloadRequest(
          'get',
          `${httpService.resourceName}/import-model`,
          'import-person.model.csv',
        );
    }

    function importPersons(content: string): Promise<BaseResponse<any>> {
        return httpService.post(`${httpService.resourceName}/import`, JSON.stringify({ content }));
    }


    function getImporting(): Promise<BaseResponse<ImportEntity[]>> {
        return httpService.get(`${httpService.resourceName}/importing`);
    }


    function getImportingMessages(id: string | number): Promise<BaseResponse<ImportMessageEntity[]>> {
        return httpService.get(`${httpService.resourceName}/importing/${id}/messages`);
    }

    return {
        ...httpService,
        addAddress,
        addBankData,
        updateBankData,
        updateAddress,
        getEducationLevels,
        addFunction,
        removeFunction,
        findByDocument,
        listProviders,
        listClients,
        exportPersons,
        importPersons,
        getImporting,
        getImportingMessages,
        downloadImportModel,
        savePersonCompany,
        savePersonIndividual
    }
}