import React, {useEffect, useState} from "react";
import {HeaderProfile} from "../component/header.profile";
import {Divider, Row, Col, Select, Form, Input, message, Table, Checkbox} from "antd";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import {DisabledResource, ProfileEntity} from "../../../../domain/entity/profile.entity";
import {useProfileRepository} from "../../../../domain/repository/profile.repository";
import {useParams} from "react-router-dom";
import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {ProfileFormTable} from "../component/profile.form.table";

export function EditProfile(props: ModuleRenderProps) {
    const [form] = Form.useForm();
    const profileRepository = useProfileRepository();
    const [profile, setProfile] = useState<ProfileEntity>();
    const { id } = useParams<{id: string}>();
    const [resources, setResources] = useState<string[]>([]);
    const [disabledResources, setDisabledResources] = useState<DisabledResource[]>([]);
    
    function loadProfile() {
        profileRepository.find(id)
            .then(res => {
                setProfile(res.data)
                setDisabledResources(res.data.disabledResources)
            });
    }

    function loadResources() {
        profileRepository.getResources()
            .then(res => setResources(res.data));
    }

    function fillForm() {
        form.setFields([
            { name: 'name' , value: profile?.name },
            { name: 'alias' , value: profile?.alias },
            { name: 'description' , value: profile?.description },
        ])
    }

    useEffect(() => loadProfile(), [id]);
    useEffect(() => loadResources(), []);
    useEffect(() => fillForm(), [profile]);

    function onFinish(data: any) {
        const values: ProfileEntity = {
            ...data,
            disabledResources: disabledResources
        }
        profileRepository.update(id, values).then(
            res => {
                form.resetFields();
                message.success(res.message);
                props.navigator.goToDefaultPage();
            }
        ).catch(err => {
            message.error(err.message);
                if(err.messageKey === 'profile:duplicated_profile_alias') {
                    form.setFields([{
                        name: 'alias',
                        errors: [err.message]
                    }])
                }
        })
    }
      
    return (
        <>
            <HeaderProfile mode={'form'} title={'Perfil'} subtitle={'Edição de perfil'}/>
            <Divider />
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Row gutter={12}>
                    <Col xs={24} sm={24} md={24} lg={20} xl={12}>
                        <Form.Item label="Nome" name={'name'} style={{width: '100%'}} rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={20} xl={12}>
                        <Form.Item label="Apelido" name={'alias'} style={{width: '100%'}} rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={20} xl={24}>
                        <Form.Item label="Descrição" name={'description'} style={{width: '100%'}} rules={[{ required: true }]}>
                        <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <ProfileFormTable
                    disabledResources={disabledResources}
                    setDisabledResources={setDisabledResources}
                    resources={resources}
                />

                <FormActionsButtons
                    onCancel={props.navigator.goToDefaultPage}
                    isLoading={profileRepository.loading}
                />
            </Form>
        </>
    )
}
