import React from "react";
import {HeaderFirm} from "../component/header.firm";
import {Col, Divider, Form, Input, InputNumber, message, Row} from "antd";
import {FirmEntity} from "../../../../domain/entity/firm.entity";
import {StateField} from "../component/state.field";
import MaskedInput from "antd-mask-input";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import {useFirmRepository} from "../../../../domain/repository/firm.repository";
import {PostalCodeField} from "../component/postal-code.field";
import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {useClientStorage} from "../../../../core/service/client.storage";
import {useDocumentPrefix} from "../service/document.prefix";

export function CreateFirm(props: ModuleRenderProps) {
    const [form] = Form.useForm();
    const firmService = useFirmRepository();
    const clientStorage = useClientStorage();
    const documentPrefix = useDocumentPrefix(clientStorage.getClient()?.document as string);

    function onFinish(data: FirmEntity) {
        data.document = documentPrefix.getPrefix() + data.document;
        firmService.create(data).then(
            res => {
                form.resetFields();
                message.success(res.message)
                props.navigator.goToListPage();
            }
        ).catch(err => {
            message.error(err.message);
        })
    }

    function setDocumentError(error: string) {
        form.setFields([{
            name: 'document', errors: [error]
        }])
    }

    return (
        <>
            <HeaderFirm mode={'form'} />
            <Divider />
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Row gutter={12}>
                    <Col span={14}>
                        <Form.Item label="Nome" name={'name'} style={{width: '100%'}} rules={[{required: true}]}>
                            <Input style={{width: '100%'}} />
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item
                            label="Documento (CNPJ)"
                            name={'document'}
                            rules={[{ required: true }]} >
                            <MaskedInput
                                mask="/####-##"
                                prefix={documentPrefix.getPrefix()}
                                onBlur={(event) =>
                                    documentPrefix.validateDocument(event.target.value, setDocumentError)}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={12}>
                    <Col span={6} >
                        <PostalCodeField setFields={form.setFields} />
                    </Col>
                    <Col span={11} >
                        <Form.Item label="Endereço" name={`addressStreet`} rules={[{ required: true }]}>
                            <Input placeholder="Rua 1° de janeiro" />
                        </Form.Item>
                    </Col>
                    <Col span={7} >
                        <Form.Item label="Número" required name={'addressNumber'} rules={[{ required: true }]} >
                            <InputNumber placeholder="123" style={{width: '100%'}} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={12}>
                    <Col span={12} >
                        <Form.Item label="Bairro" name={'addressDistrict'} rules={[{ required: true }]}>
                            <Input placeholder="Oliveiras" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Cidade" name={'addressCity'} rules={[{ required: true }]}>
                            <InputNumber placeholder="123" style={{width: '100%'}} />
                        </Form.Item>
                    </Col>
                </Row>

                <StateField name={'addressState'} label={'Estado'} />

                <FormActionsButtons
                    onCancel={props.navigator.goToDefaultPage}
                    isLoading={firmService.loading}
                />
            </Form>
        </>
    )
}
