export function useDocumentValidator() {
    function isValidCNPJ(document: string) {
        document = sanitizeCNPJ(document);
        if (document === '') return false;
        if (document.length !== 14) return false;
        if (
            document === '00000000000000' ||
            document === '11111111111111' ||
            document === '22222222222222' ||
            document === '33333333333333' ||
            document === '44444444444444' ||
            document === '55555555555555' ||
            document === '66666666666666' ||
            document === '77777777777777' ||
            document === '88888888888888' ||
            document === '99999999999999'
        ) {
            return false;
        }

        let size = document.length - 2;
        let numbers = document.substring(0, size);
        const digits = document.substring(size);
        let soma: any = 0;
        let pos = size - 7;
        for (let i = size; i >= 1; i--) {
            soma += parseInt(numbers.charAt(size - i)) * pos--;
            if (pos < 2) pos = 9;
        }
        let result = soma % 11 < 2 ? 0 : 11 - (soma % 11);
        if (result !== parseInt(digits.charAt(0))) return false;

        size = size + 1;
        numbers = document.substring(0, size);
        soma = 0;
        pos = size - 7;
        for (let i = size; i >= 1; i--) {
            soma += parseInt(numbers.charAt(size - i)) * pos--;
            if (pos < 2) pos = 9;
        }
        result = soma % 11 < 2 ? 0 : 11 - (soma % 11);
        if (result !== parseInt(digits.charAt(1))) return false;

        return true;
    }

    function sanitizeCNPJ(document: string) {
        return document.replace(/[^\d]+/g, '');
    }

    function isValidCPF(document: string): boolean {
        if (typeof document !== "string") {
            return false
        }
        document = sanitizeCNPJ(document);
        if (
            !document ||
            document.length != 11 ||
            document == "00000000000" ||
            document == "11111111111" ||
            document == "22222222222" ||
            document == "33333333333" ||
            document == "44444444444" ||
            document == "55555555555" ||
            document == "66666666666" ||
            document == "77777777777" ||
            document == "88888888888" ||
            document == "99999999999"
        ) {
            return false;
        }
        let sum = 0;
        let rest = 0;
        for (let i = 1; i <= 9; i++) {
            sum = sum + parseInt(document.substring(i - 1, i)) * (11 - i)
        }
        rest = (sum * 10) % 11;
        if (rest == 10 || rest == 11) {
            rest = 0;
        }
        if (rest != parseInt(document.substring(9, 10)) ) {
            return false
        }
        sum = 0;
        for (let i = 1; i <= 10; i++) {
            sum = sum + parseInt(document.substring(i - 1, i)) * (12 - i);
        }
        rest = (sum * 10) % 11;
        if ((rest == 10) || (rest == 11)) {
            rest = 0
        }
        if (rest != parseInt(document.substring(10, 11) ) ) {
            return false
        }

        return true
    }

    return {
        sanitizeCNPJ,
        isValidCNPJ,
        isValidCPF
    }
}