import React, {useEffect, useState} from "react";
import {HeaderCashFlow} from "../component/header.cash-flow";
import {Button, Col, Divider, Layout, Modal, Row} from "antd";
import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {useCashFlowRowRepository} from "../../../../domain/repository/cash-flow-row.repository";
import {CashFlowRowEntity} from "../../../../domain/entity/cash-flow-row.entity";
import {PlusOutlined} from "@ant-design/icons";
import {FormCashFlowRow} from "../component/form.cash-flow-row";
import {CashFlowTreeView} from "../../../component/cash-flow-tree-view";
import {DetailsCashFlowRow} from "../component/details.cash-flow-row";

export function ListCashFlowRow(props: ModuleRenderProps) {
  const cashFlowRepo = useCashFlowRowRepository();
  const [data, setData] = useState<CashFlowRowEntity[]>([]);
  const [showCreateModal, setShowCreateForm] = useState(false);
  const [selectedRow, setSelectedRow] = useState<CashFlowRowEntity>();

  function loadData() {
    cashFlowRepo.findAll().then(res => {
      setData(res.data);
    });
  }

  useEffect(() => {
    loadData()
  }, [1]);

  function onRowSelected(rowId: number | undefined) {
    if (!rowId) {
      setSelectedRow(undefined)
    }
    rowId && cashFlowRepo.find(rowId.toString()).then(res => setSelectedRow(res.data));
  }

  return (
    <>
      <HeaderCashFlow mode={'form'} extra={[
        <Button
          type={'primary'}
          icon={<PlusOutlined />}
          shape={'round'}
          onClick={() => setShowCreateForm(true)}
        >
          Adicionar Linha
        </Button>
      ]} />
      <Divider />
      <Layout.Content>
        <Row gutter={12}>
          <Col span={12}>
            <CashFlowTreeView
              onSelectKey={onRowSelected}
              data={data}
            />
          </Col>
          <Col span={12}>
            {
              selectedRow && (
                <DetailsCashFlowRow
                  row={selectedRow}
                  onRemoved={() => { setSelectedRow(undefined); loadData(); }}
                  onSaved={(row) => {
                    loadData();
                    setSelectedRow(row)
                  }}
                />
              )
            }
          </Col>
        </Row>

      </Layout.Content>
      {
        showCreateModal && (
          <Modal
            title={'Adicionar Linha'}
            visible={true}
            onCancel={() => setShowCreateForm(false)}
            footer={null}>
            <FormCashFlowRow
              onCancel={() => setShowCreateForm(false)}
              onSaved={() => { loadData(); setShowCreateForm(false) }}
            />
          </Modal>
        )
      }
    </>
  );
}
