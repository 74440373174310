import {BaseResponse, BaseSearchResponse, usePlaneduHttpService} from "../service/planedu.http.service";
import {IntegrationEntity} from "../entity/integration.entity";
import {IntegrationParamEntity} from "../entity/integration-param.entity";
import {IntegrationBatchEntity, IntegrationBatchEntityError} from "../entity/integration-batch.entity";
import {FinancialEntryEntity} from "../entity/financial-entry.entity";

export function useIntegrationRepository() {
  const httpService = usePlaneduHttpService<IntegrationEntity>('integration');

  function editParam(entity: IntegrationParamEntity): Promise<BaseResponse<IntegrationParamEntity>> {
    return httpService.put(`${httpService.resourceName}/param/${entity.id}`, JSON.stringify(entity));
  }

  function importFile(integrationId: number, file: any) {
    const formData = new FormData();
    formData.append('file', file);
    console.log('import', formData, file);
    return httpService.post(
      `${httpService.resourceName}/${integrationId}/import-batch`,
      formData,
      undefined,
      {
        "Content-Type": `multipart/form-data`
      }
    )
  }

  function listBatches(args: {
    integrationId: number,
    limit: number,
    offset: number
  }): Promise<BaseSearchResponse<IntegrationBatchEntity>> {
    const { integrationId, offset, limit } = args;
    return httpService.get(
      `${httpService.resourceName}/${integrationId}/batches`,
      undefined,
      [
        { key: 'limit', value: limit },
        { key: 'offset', value: offset },
      ]
    )
  }

  function listBatchEntries(batchId: number): Promise<BaseResponse<FinancialEntryEntity[]>> {
    return httpService.get(
      `${httpService.resourceName}/batch/${batchId}/entries`,
    );
  }

  function listBatchErrors(batchId: number): Promise<BaseResponse<IntegrationBatchEntityError[]>> {
    return httpService.get(
      `${httpService.resourceName}/batch/${batchId}/errors`,
    );
  }

  return {
    ...httpService,
    listBatches,
    editParam,
    importFile,
    listBatchEntries,
    listBatchErrors
  }
}