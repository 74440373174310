import React from 'react';
import {Tabs} from "antd";
import {PersonalDataPage} from "./personal-data.page";
import {ProfilesPage} from "./profiles.page";
import {FirmsPage} from "./firms.page";
import {ResetPassword} from "./reset-password.page";

export function ProfileMe(props: any) {

    return (
        <Tabs tabPosition={'left'} >
            <Tabs.TabPane tab="Dados Pessoais" key="1">
                <PersonalDataPage />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Perfis de Acesso" key="2">
                <ProfilesPage />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Empresas Vinculadas" key="3">
                <FirmsPage />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Resetar Senha" key="4">
                <ResetPassword />
            </Tabs.TabPane>
        </Tabs>
    )
}
