import {Result} from "antd";
import React from "react";
import {PersonEntity} from "../../../../domain/entity/person.entity";
import {FormConfirm} from "./form.confirm";

export type StepConfirmProps = {
    person: PersonEntity,
    onFunctionCreated: () => void,
    onFunctionRemoved: () => void,
    onFinish: () => void
}
export function StepConfirm(props: StepConfirmProps) {
    return (
        <Result
            status="success"
            title="Dados Salvos com Sucesso. Habilite as funções necessárias"
            subTitle="Marque a seguir as funções que deseja habilitar"
        >
            <FormConfirm
                person={props.person}
                onFunctionCreated={props.onFunctionCreated}
                onFunctionRemoved={props.onFunctionRemoved}
                onFinish={props.onFinish}
            />
        </Result>
    )
}