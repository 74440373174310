import {FinancialEntryEntity} from "../../../../../domain/entity/financial-entry.entity";
import {useNumberFormatter} from "../../../../service/number-formatter.service";
import {Table} from "antd";
import {ColumnsType} from "antd/es/table/Table";

export function ApportionmentEntry(props: { entry: FinancialEntryEntity }) {
  const { currencyFormatter } = useNumberFormatter();

  function calcPercent(val: number) {
    return `${(val * 100 / props.entry.documentGrossValue).toFixed(0)}%`;
  }

  const columns: ColumnsType<any> = [
    {
      title: 'Centro de Custo',
      key: 'costCenter',
      dataIndex: 'costCenter',
      render: (val: any) => `${val?.code} - ${val?.name}`
    },
    {
      title: 'Valor',
      dataIndex: 'apportionment',
      key: 'apportionment',
      render: (value: any) => value ? currencyFormatter.format(value) : value
    },
    {
      title: 'Percentual',
      dataIndex: 'apportionment',
      key: 'percent',
      render: calcPercent
    },
  ]

  return (
    <>
      <Table
        pagination={false}
        columns={columns}
        dataSource={props.entry?.apportionment ?? []}
      />
    </>

  )
}