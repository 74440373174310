import {Tree} from "antd";
import {BoxPlotOutlined, CalculatorTwoTone, MinusCircleOutlined, PlusCircleOutlined} from "@ant-design/icons";
import React from "react";
import {CashFlowRowEntity} from "../../../domain/entity/cash-flow-row.entity";
import {DataNode} from "rc-tree/lib/interface";
import {CashFlowRowTypeEnum} from "../../../domain/vo/cash-flow-row-type.enum";
import {getSorter} from "../../../core/util/sort-array.util";

export type CashFlowTreeViewProps = {
  data: CashFlowRowEntity[],
  onSelectKey: (rowId: number | undefined) => void,
}

export function CashFlowTreeView(props: CashFlowTreeViewProps) {
  const { data } = props;

  function getIcon(type: CashFlowRowTypeEnum) {
    switch (type) {
      case CashFlowRowTypeEnum.CALCULATED:
        return <CalculatorTwoTone style={{color: 'darkcyan'}} />
      case CashFlowRowTypeEnum.DEDUCTION:
        return <MinusCircleOutlined  style={{color: 'red'}} />
      case CashFlowRowTypeEnum.REVENUE:
        return <PlusCircleOutlined style={{color: 'darkgreen'}} size={5} />
      default:
        return <BoxPlotOutlined />
    }
  }

  function parseRow(item: CashFlowRowEntity, parentItem?: CashFlowRowEntity): DataNode {
    const order = parentItem ? `${parentItem.order}.${item.order}` : item.order;
    return {
      checkable: true,
      icon: getIcon(item.type),
      title: `${order} ${item.name}`,
      key: item.id,
      // style: { color: getColor(item.type) }
    }
  }

  function sorterRow(a: CashFlowRowEntity, b: CashFlowRowEntity) {
      if (parseFloat(a.order) > parseFloat(b.order)) return 1;
      if (parseFloat(a.order) < parseFloat(b.order)) return -1;
      return 0;
  }

  function getTreeData(): DataNode[] {
    return data.sort(sorterRow)
      .map(item => ({
      ...parseRow(item),
      children: item.children?.sort(sorterRow)
        ?.map(child => parseRow(child, item))
    }))
  }

  function onSelectKeys(keys: Array<string | number>) {
    if (!keys.length) {
      props.onSelectKey(undefined);
    }
    props.onSelectKey(keys[0] as number);
  }

  return (
    <Tree
      defaultExpandAll
      showIcon
      treeData={getTreeData()}
      onSelect={onSelectKeys}
    />
  )
}