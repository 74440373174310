import {BaseResponse, usePlaneduHttpService} from "../service/planedu.http.service";
import {DreRowEntity} from "../entity/dre-row.entity";
import {DreRowReleaseParamEntity} from "../entity/dre-row-release-param.entity";
import {AddReleaseParamDto} from "../dto/add-release-param.dto";
import {DreRowDto} from "../dto/dre-row.dto";

export function useDRERowRepository() {
  const httpService = usePlaneduHttpService<DreRowEntity>('dre-row');

  function swapDreRowOrder(dreRowIdX: number, dreRowIdY: number) {
    const body = JSON.stringify({
      dreRowIdX,
      dreRowIdY
    })
    return httpService.post('dre-row/swap-order', body)
  }

  function removeReleaseParam(dreRowReleaseParamId: number): Promise<BaseResponse<DreRowReleaseParamEntity>> {
    return httpService.delete(`dre-row/release-param/${dreRowReleaseParamId}`);
  }

  function addReleaseParam(data: AddReleaseParamDto): Promise<BaseResponse<DreRowReleaseParamEntity>> {
    return httpService.post(`dre-row/release-param`, JSON.stringify(data));
  }

  function downloadDRECSV(): Promise<void> {
    return httpService.downloadRequest(
      'get',
      `${httpService.resourceName}/export`,
      'dre.xlsx',
    )
  }

  function downloadDReleaseParams(): Promise<void> {
    return httpService.downloadRequest(
      'get',
      `${httpService.resourceName}/export/release-params`,
      'dre-params.xlsx',
    )
  }

  function generateEntryReport(args: { year: number, costCenterId: number }): Promise<BaseResponse<DreRowDto[]>> {
    return httpService.post(
      `${httpService.resourceName}/generate-report/entry`,
      JSON.stringify(args)
    )
  }

  function exportEntryReport(args: { year: number, costCenterId: number }): Promise<BaseResponse<DreRowDto[]>> {
    return httpService.downloadRequest(
      `post`,
      `${httpService.resourceName}/export-report/entry`,
      `dre-entry-report.xlsx`,
      JSON.stringify(args)
    )
  }

  return {
    ...httpService,
    swapDreRowOrder,
    addReleaseParam,
    removeReleaseParam,
    downloadDRECSV,
    generateEntryReport,
    exportEntryReport,
    downloadDReleaseParams
  }
}