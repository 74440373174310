import {FinancialEntryEventEntityEnum} from "../../../../domain/entity/financial-entry-event.entity";

export function parseEntryEntityService(item: FinancialEntryEventEntityEnum) {
  switch (item) {
    case FinancialEntryEventEntityEnum.APPORTIONMENT:
      return 'Rateio';
    case FinancialEntryEventEntityEnum.ENTRY:
      return 'Lançamento';
    case FinancialEntryEventEntityEnum.INSTALLMENT:
      return 'Parcela';
    case FinancialEntryEventEntityEnum.ITEM:
      return 'Item';
    case FinancialEntryEventEntityEnum.TAX:
      return 'Tributos';
    default:
      return item;
  }
}