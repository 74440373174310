import {DatePicker, Form} from "antd";
import React, {CSSProperties} from "react";
import {Moment} from "moment";
import 'moment/locale/pt-br';

export type DatePickerPtBrProps = {
  label: string,
  name: string | string[],
  required: boolean,
  initialDateSelection?: Moment,
  defaultValue?: Moment,
  style?: CSSProperties,
  size?: 'small' | 'middle' | 'large',
  placeholder?: string,
  showTime?: boolean,
  disabled?: boolean,
  hideSuffixIcon?: boolean,
  help?: string,
  picker?: 'date' | 'year' | 'month',
  onChange?: (val: Moment | null) => void,
}

export function DatePickerPtBr(props: DatePickerPtBrProps) {
  function disableDate(dt: Moment) {
    if (!props.initialDateSelection) {
      return false;
    }
    return dt.isBefore(props.initialDateSelection);
  }

  const style = {
    width: '100%',
    ...props.style,
  }

  let mask = props.showTime ? `DD/MM/YYYY HH:mm:ss` : 'DD/MM/YYYY';
  switch (props.picker) {
    case "month":
      mask = `MM/YYYY`;
      break;
    case "year":
      mask = 'YYYY';
      break;
    default: break;
  }

  function onChange(val: Moment | null) {
    props.onChange && props.onChange(val);
  }

  return (
    <Form.Item
      label={props.label}
      name={props.name}
      style={style}
      rules={[{ required: props.required }]}
      help={props.help}
    >
      <DatePicker
        showTime={props.showTime}
        showHour={props.showTime}
        showMinute={props.showTime}
        disabledDate={disableDate}
        format={mask}
        picker={props.picker ? props.picker : 'date'}
        placeholder={props.placeholder}
        style={{width: '100%'}}
        size={props.size}
        defaultValue={props.defaultValue}
        disabled={props.disabled}
        onChange={onChange}
      />
    </Form.Item>
  )
}