import React, {useEffect, useState} from "react";
import {HeaderClientProvider} from "../component/header.client-provider";
import {Button, Divider, Layout, message, Table, Tag,} from "antd";
import {ListActions} from "../../../../core/component/list-actions";
import {usePersonRepository} from "../../../../domain/repository/person.repository";
import {PersonEntity, PersonTypeEnum} from "../../../../domain/entity/person.entity";
import {useDocumentFormatter} from "../../../service/document.formatter.service";
import {FilterListClientProvider} from "../component/filter-list.client-provider";
import {PersonFunctionEntity} from "../../../../domain/entity/person-function.entity";
import {ExportExcelButton} from "../../../../core/component/export-button";
import {ImportOutlined} from "@ant-design/icons";
import {ModuleRenderProps} from "../../../../core/component/module/module.root";

export function ListClientProvider(props: ModuleRenderProps) {
    const [data, setData] = useState<PersonEntity[]>([]);
    const [dataFiltered, setDataFiltered] = useState<PersonEntity[]>([]);
    const [limit, setLimit] = useState(10);
    const [filter, setFilter] = useState<any>()
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const personRepository = usePersonRepository();
    const documentFormatter = useDocumentFormatter();

    function loadData() {
        personRepository.search({ filter, limit, offset: (page - 1) * limit }).then(res => {
            setData(res.data)
            setTotal(res.total);
        });
    }

    useEffect(() => {
        loadData()
    }, [page, filter]);

    useEffect(() => {
        setDataFiltered(data);
    }, [data])

    function removeItem(id: string) {
        personRepository.remove(id)
            .then(res => {
                message.success(res.message);
                loadData();
            }).catch(err => {
                message.error(err.message);
        })
    }

    function renderDocument(type: PersonTypeEnum, value: string) {
        switch (type) {
            case PersonTypeEnum.COMPANY:
                return documentFormatter.formatCNPJ(value);
            case PersonTypeEnum.INDIVIDUAL:
                return documentFormatter.formatCPF(value);
            default: return null;
        }
    }

    function filterName(name: string) {
        setFilter({ name })
    }

    function filerDoc(document: string) {
        setFilter({ document })
    }

    function translateFunction(name: string) {
        switch (name) {
            case 'provider':
                return 'Fornecedor';
            case 'client':
                return 'Cliente';
            default:
                return null;
        }
    }

    const columnsBudget = [
        {
            title: 'Nome',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Documento',
            dataIndex: 'document',
            key: 'document',
            render: (val: string, row: any) => renderDocument(row.type, val)
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',

        },
        {
            title: 'Telefone',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',

        },
        {
            title: 'Funções',
            dataIndex: 'personFunctions',
            key: 'personFunctions',
            render: (val: PersonFunctionEntity[]) => {
                return val.map(i => <Tag color={'default'} children={translateFunction(i.personFunction)}/>)
            }
        },
        {
            dataIndex: 'id',
            render: (val: any) => (
                <>
                    <ListActions
                        onEditClick={() => props.navigator.goToEditPage(val) }
                        onRemoveClick={() => removeItem(val) }
                    />
                </>
            )
        }
    ]

    return (
        <>
            <HeaderClientProvider
                extra={[
                  <ExportExcelButton onClick={() => personRepository.exportPersons()} />,
                  <Button
                    onClick={() => props.navigator.goToCustomPage('import')}
                    shape={'round'} icon={<ImportOutlined />}>Importar</Button>,
                ]}
            />
            <Divider />
            <Layout.Content>
                <FilterListClientProvider
                  onFilterDocument={filerDoc}
                  onFilterName={filterName}
                />
                <Table 
                    columns={columnsBudget} 
                    dataSource={dataFiltered}
                    loading={personRepository.loading}
                    pagination={{
                        pageSize: limit,
                        onChange: setPage,
                        pageSizeOptions: undefined,
                        total
                    }}
                />
            </Layout.Content>
        </>
    );
}