import {PersonEntity} from "../../../../domain/entity/person.entity";
import {Checkbox, Form, message, Result} from "antd";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import React, {useEffect} from "react";
import {usePersonRepository} from "../../../../domain/repository/person.repository";

export type FormConfirmProps = {
    person: PersonEntity,
    onFunctionCreated: () => void,
    onFunctionRemoved: () => void,
    onFinish: () => void
}

export function FormConfirm(props: FormConfirmProps) {
    const [form] = Form.useForm();

    const personRepo = usePersonRepository();

    useEffect(() => {
        console.log('person', props.person);
    }, [props.person])

    function addFunction(func: string) {
        personRepo.addFunction(props.person.id, func).then(res => {
            message.success(res.message)
            props.onFunctionCreated();
        }).catch(err => {
            message.error(err.message);
        })
    }

    function removeFunction(func: string) {
        const entityFunc = props.person.personFunctions?.find(w => w.personFunction === func);
        if (!entityFunc) {
            return;
        }
        personRepo.removeFunction(entityFunc.id).then(res => {
            message.success(res.message)
            props.onFunctionRemoved();
        }).catch(err => {
            message.error(err.message);
        })
    }

    function checkChanged(func: string, checked: boolean) {
        if (checked) {
            addFunction(func);
        } else {
            removeFunction(func)
        }
    }

    function isChecked(func: string): boolean {
        const filter = props.person.personFunctions?.find(w => w.personFunction === func);
        if (!filter) {
            return false;
        }
        return true;
    }

    return (
        <Form form={form} size={'large'} layout={'vertical'} onFinish={props.onFinish}>
            <Form.Item label={'Funções Disponíveis'}>
                <Checkbox
                    value={'provider'}
                    onChange={e => checkChanged('provider', e.target.checked)}
                    checked={isChecked('provider')}
                >
                    Fornecedor
                </Checkbox>
                <Checkbox
                    value={'client'}
                    onChange={e => checkChanged('client', e.target.checked)}
                    checked={isChecked('client')}
                >
                    Cliente
                </Checkbox>
            </Form.Item>

            <FormActionsButtons hideCancel label={'Finalizar'} />
        </Form>
    )
}