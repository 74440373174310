import {IntegrationParamEntityEnum} from "../../../../domain/vo/integration-param-entity.enum";
import {OptionsValue} from "../../../../core/types/option.type";
import {useCostCenterRepository} from "../../../../domain/repository/account.repository";
import {useFirmRepository} from "../../../../domain/repository/firm.repository";
import {usePaymentMethodRepository} from "../../../../domain/repository/payment-method.repository";
import {useFinancialItemRepository} from "../../../../domain/repository/financial-item.repository";
import {usePersonRepository} from "../../../../domain/repository/person.repository";
import {useFinancialDocumentTypeRepository} from "../../../../domain/repository/financial-document-type.repository";
import {useSupplyUnitRepository} from "../../../../domain/repository/supply-unit.repository";
import {useBudgetCategoryRepository} from "../../../../domain/repository/budget-category.repository";
import {useBankAccountRepository} from "../../../../domain/repository/bank-account.repository";

export function useResolveProxyValuesService() {
  const costCenterRepo = useCostCenterRepository();
  const firmRepo = useFirmRepository();
  const paymentMethodRepo = usePaymentMethodRepository();
  const itemRepo = useFinancialItemRepository();
  const personRepo = usePersonRepository();
  const documentTypeRepo = useFinancialDocumentTypeRepository();
  const supplyUnityRepo = useSupplyUnitRepository();
  const categoryRepo = useBudgetCategoryRepository();
  const bankAccountRepo = useBankAccountRepository();

  function resolveValues(type: IntegrationParamEntityEnum): Promise<OptionsValue> {
    switch (type) {
      case IntegrationParamEntityEnum.BUDGET_CATEGORY:
        return categoryRepo.search({ limit: 9999 })
          .then(res => res.data.map(item => ({ label: item.name, value: item.id })))
      case IntegrationParamEntityEnum.COST_CENTER:
        return costCenterRepo.search({ limit: 9999, filter: { acceptUpdate: 1, } })
          .then(res => res.data.map(item => ({
            label: `${item.code} - ${item.name}`,
            value: item.id as number,
          })))
      case IntegrationParamEntityEnum.FINANCIAL_DOCUMENT_TYPE:
        return documentTypeRepo.findAll().then(res => res.data.map(item => ({
          label: item.name,
          value: item.id,
        })))
      case IntegrationParamEntityEnum.FINANCIAL_ITEM:
        return itemRepo.search({ limit: 9999 }).then(res => res.data.map(item => ({
          label: `${item.description}(${item.code})`,
          value: item.id as number,
        })))
      case IntegrationParamEntityEnum.FIRM:
        return firmRepo.findAll().then(res => res.data.map(item => ({
          label: item.name,
          value: item.id
        })));
      case IntegrationParamEntityEnum.PAYMENT_METHOD:
        return paymentMethodRepo.findAll().then(res => res.data.map(item => ({
          label: item.description,
          value: item.id,
        })))
      case IntegrationParamEntityEnum.PERSON:
        return personRepo.search({ limit: 9999 }).then(res => res.data.map(item => ({
          label: item.name,
          value: item.id,
        })))
      case IntegrationParamEntityEnum.SUPPLY_UNITY:
        return supplyUnityRepo.search({ limit: 9999 }).then(res => res.data.map(item => ({
          label: item.description,
          value: item.id,
        })));
      case IntegrationParamEntityEnum.BANK_ACCOUNT:
        return bankAccountRepo.findAll(true).then(res => res.data.map(item => ({
          label: item.alias,
          value: item.id,
        })));
      default:
        return Promise.resolve([]);
    }
  }

  return {
    resolveValues
  }
}