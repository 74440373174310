import {DreRowEntity} from "../../../../domain/entity/dre-row.entity";
import {BudgetEntity} from "../../../../domain/entity/budget.entity";
import {Button, Col, Divider, Form, FormInstance, InputNumber, message, Row, Select, Typography} from "antd";
import React, {useEffect, useState} from "react";
import {FirmEntity} from "../../../../domain/entity/firm.entity";
import {CostCenterEntity} from "../../../../domain/entity/cost-center.entity";
import {BudgetReleaseEntity} from "../../../../domain/entity/budget-release.entity";
import {useNumberFormatter} from "../../../service/number-formatter.service";
import {useFirmRepository} from "../../../../domain/repository/firm.repository";
import {useBudgetRepository} from "../../../../domain/repository/budget.repository";
import {MonthsEnum} from "../../../../domain/vo/months.enum";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import {CopyOutlined} from "@ant-design/icons";

export type FormReleaseProps = {
    dreRow: DreRowEntity,
    budget: BudgetEntity,
    competenceId: number,
}

export function FormRelease(props: FormReleaseProps) {
    const [form] = Form.useForm();
    const [firmId, setFirmId] = useState<number>();
    const [costCenterId, setCostCenterId] = useState<number>();
    const [firms, setFirms] = useState<FirmEntity[]>([]);
    const [costCenters, setCostCenters] = useState<CostCenterEntity[]>([]);
    const [releaseBudget, setReleaseBudget] = useState<BudgetReleaseEntity>();
    const { decimalFormatter, decimalParser } = useNumberFormatter();

    const firmRepo = useFirmRepository();
    const budgetRepository = useBudgetRepository();

    useEffect(() => {
        firmRepo.search()
            .then(res => setFirms(res.data))
    }, []);

    useEffect(() => {
        if (firmId) {
            firmRepo
                .listCostCenters(firmId.toString())
                .then(res => setCostCenters(res.data.filter(w => w.acceptUpdate && w.active)));
        }
    }, [firmId]);

    useEffect(() => {
        if (props.budget?.id && props.dreRow?.id && firmId && costCenterId) {
            budgetRepository.getReleaseBudget(
                props.budget.id,
                props.dreRow.id,
                costCenterId,
                firmId
            ).then(res => setReleaseBudget(res.data));
        }
    }, [props.budget?.id, props.dreRow?.id, firmId, costCenterId])

    useEffect(() => {
        if (releaseBudget) {
            form.setFields(releaseBudget.values.map(item => ({
                name: `month_${item.month}`,
                value: decimalFormatter.format(item.value)
            })))
        } else {
            resetForm();
        }
    }, [releaseBudget])

    function resetForm() {
        form.resetFields();
        setCostCenterId(undefined);
        setFirmId(undefined);
        setReleaseBudget(undefined);
    }

    function getFirmsOpts() {
        return firms
            .sort((a,b) => {
                if(a.name > b.name) return 1;
                if(a.name < b.name) return -1;
                return 0;
            })
            .map(item => ({
                label: item.name,
                value: item.id as number
            }))
    }

    function getCostCentersOpts() {
        return costCenters
            .sort((a,b) => {
                if(a.code > b.code) return 1;
                if(a.code < b.code) return -1;
                return 0;
            })
            .map(item => ({
                label: `${item.code} - ${item.name}`,
                value: item.id as number
            }))
    }

    function onFinish(values: any) {
        const monthValues: Array<{ month: number, value: number }> = [];
        for (const key of Object.keys(values)) {
            if (key.includes('month')) {
                if (!values[key]) continue;
                const data = key.split('_');
                monthValues.push({
                    month: parseInt(data[1]),
                    value: parseFloat(values[key])
                })
            }
        }

        budgetRepository.releaseBudget({
            ...values,
            budgetId: props.budget.id,
            dreRowId: props.dreRow.id,
            values: monthValues
        }).then(res => {
            message.success(res.message);
            resetForm();
        }).catch(err => {
            message.error(err.message);
        })
    }

    function isMonthDisabled(month: number) {
        if (!props.budget || !props.budget.months) {
            return true;
        }
        return !props.budget.months.includes(month);
    }

    function replicateValues(startMonth: number) {
        const value = form.getFieldValue(`month_${startMonth}`);
        for (let x = startMonth + 1; x <= 12; x++) {
            form.setFields([{ name: `month_${x}` , value: value }])
        }
    }

    return (
        <>
            <Typography.Title level={5}>Lançar Orçamento em - {props.dreRow.name}</Typography.Title>
            <Divider />
            <Form form={form} size={'middle'} layout="vertical" onFinish={onFinish} >
                <Form.Item name={'firmId'} label={'Filial'} rules={[{ required: true }]}>
                    <Select onSelect={val => setFirmId(val as number)} options={getFirmsOpts()} />
                </Form.Item>
                <Form.Item name={'costCenterId'} label={'Centro de Custo'} rules={[{ required: true }]}>
                    <Select onSelect={val => setCostCenterId(val as number)} options={getCostCentersOpts()} />
                </Form.Item>
                <Row gutter={12}>
                    {
                        firmId && costCenterId && MonthsEnum.getValues().map(month => (
                            <Col span={8}>
                                <Form.Item
                                    name={`month_${month.value}`}
                                    label={month.label}
                                    rules={[{ required: !isMonthDisabled(month.value) }]}
                                    help={<ButtonReplicate onClick={() => replicateValues(month.value)} />}
                                >
                                    <InputNumber
                                        placeholder={'R$ 99,99'}
                                        parser={value => value ? decimalParser.parser(value.toString()) : ''}
                                        lang={'pt_BR'}
                                        style={{width: '100%'}}
                                        decimalSeparator={','}
                                        disabled={isMonthDisabled(month.value) || budgetRepository.loading}
                                    />

                                </Form.Item>
                            </Col>
                        ))
                    }
                </Row>
                <FormActionsButtons
                    onCancel={() => null}
                    isLoading={budgetRepository.loading}
                />
            </Form>
        </>
    )
}

export function ButtonReplicate(props: {  onClick: () => void }) {

    return (
        <Button
            type={'link'}
            style={{ color: 'gray', position: 'absolute', right: 1 }}
            size={'small'}
            icon={<CopyOutlined />}
            shape={'circle'}
            title={'Replicar esse valor para os próximos meses'}
            onClick={() => props.onClick()}
        />
    )
}