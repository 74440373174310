import {CostCenterEntity} from "./cost-center.entity";
import {FirmEntity} from "./firm.entity";
import {RecurrenceEntryItemEntity} from "./recurrence-entry-item.entity";
import {RecurrenceEntryApportionmentEntity} from "./recurrence-entry-apportionment.entity";

export type RecurrenceEntryEntity = {
  id: number;
  contractType: RecurrenceEntryContractType;
  name: string;
  typeEntry: 'D' | 'R';
  description: string;
  firmId: number;
  costCenterId: number;
  dateTimeBeginContract: Date;
  dateTimeEndContract?: Date;
  firstDueDate: Date;
  periodRecurrence: RecurrenceEntryPeriodEnum;
  clientId: number;
  costCenter: CostCenterEntity;
  personId: number;
  canceled?: boolean;
  firm: FirmEntity;
  items: RecurrenceEntryItemEntity[];
  apportionment: RecurrenceEntryApportionmentEntity[];
}

export enum RecurrenceEntryPeriodEnum {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  SEMESTER = 'semester',
  YEARLY = 'yearly',
}

export enum RecurrenceEntryContractType {
  FIXED = 'f',
  VARIABLE = 'v',
}
