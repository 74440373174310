import {Moment} from "moment";
import {assertNumber} from "../../../../../core/util/numer.util";

export type InstallmentViewModel = {
  id: number | string,
  order: number,
  dueDate: Moment,
  paymentMethodId: number,
  value: number,
  fineAmount: number,
  interestAmount: number,
  discountAmount: number,
  status?: InstallmentStatusViewModel;
}

export enum InstallmentStatusViewModel {
  CANCELED = 'Cancelado',
  TO_CANCEL = 'A Cancelar',
  WRITE_OFF = 'Baixado',
  OPENED = 'Em Aberto',
  RENEGOTIATED = 'Renegociação',
}

export function calcAmountInstallmentViewModel(item: InstallmentViewModel): number {
  const { value, discountAmount, fineAmount, interestAmount } = item;
  return assertNumber(value)
    + assertNumber(fineAmount)
    + assertNumber(interestAmount)
    - assertNumber(discountAmount);
}