import React, {useEffect, useState} from "react";
import {HeaderPaymentMethod} from "../component/header.payment-method";
import {Divider, Layout, message, Table, Tag,} from "antd";
import {ListActions} from "../../../../core/component/list-actions";
import {usePaymentMethodRepository} from "../../../../domain/repository/payment-method.repository";
import {PaymentMethodEntity} from "../../../../domain/entity/payment-method.entity";

export function ListPaymentMethod(props: any) {
    const paymentMethodRepo = usePaymentMethodRepository();
    const [data, setData] = useState<PaymentMethodEntity[]>([]);

    function loadData() {
        paymentMethodRepo.search().then(res => {
            setData(res.data)
        });
    }

    useEffect(() => {
        loadData()
    }, [1]);

    function removeItem(id: string) {
        paymentMethodRepo.remove(id)
            .then(res => {
                message.success(res.message);
                loadData();
            }).catch(err => {
                message.error(err.message);
        })
    }

    const columnsBudget = [
        {
            title: 'Descrição',
            dataIndex: 'description' ,
            key: 'name',
        },
        {
            title: 'Sigla',
            dataIndex: 'alias' ,
            key: 'alias',
        },
        {
            dataIndex: 'id',
            render: (val: any) => (
                <>
                    <ListActions
                        onEditClick={() => props.navigator.goToEditPage(val) }
                        onRemoveClick={() => removeItem(val) }
                    />
                </>
            )
        }
    ]
    return (
        <>
            <HeaderPaymentMethod />
            <Divider />
            <Layout.Content>
                <Table 
                    columns={columnsBudget} 
                    dataSource={data} 
                    loading={paymentMethodRepo.loading} />
            </Layout.Content>
        </>
    );
}