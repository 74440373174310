import {PersonAddressEntity} from "../../../../domain/entity/person-address.entity";
import {Button, Col, Form, Input, InputNumber, message, Row, Typography} from "antd";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import React, {useEffect} from "react";
import {PersonEntity} from "../../../../domain/entity/person.entity";
import {usePersonRepository} from "../../../../domain/repository/person.repository";
import {useViaCepAddressRepository} from "../../../service/via-cep.address.repository";
import {ArrowRightOutlined} from "@ant-design/icons";

export type FormPersonAddressType = {
    onFinish: (entity: PersonAddressEntity | undefined) => void,
    onBack: () => void,
    personAddress?: PersonAddressEntity,
    person: PersonEntity,
    hideSkipStep?: boolean;
}

export function FormPersonAddress(props: FormPersonAddressType) {
    const [form] = Form.useForm();
    const viaCepService = useViaCepAddressRepository();
    const personRepo = usePersonRepository();

    function searchAddressByPostalCode(value: string) {
        viaCepService.findByPostalCode(value).then((res) => {
            console.log(res);
            form.setFields([
                { name: 'street', value: res.logradouro },
                { name: 'state', value: res.uf },
                { name: 'district', value: res.bairro },
                { name: 'city', value: res.localidade },
                { name: 'country', value: 'Brasil' },
                { name: 'postalCode', value: res.cep?.replace('-', '') },
            ])
        })
    }

    useEffect(() => {
        if (props.personAddress) {
            const { personAddress } = props;
            form.setFields([
                { name: 'addressAlias', value: personAddress.addressAlias },
                { name: 'personId', value: personAddress.personId },
                { name: 'id', value: personAddress.id },
                { name: 'number', value: personAddress.number },
                { name: 'city', value: personAddress.city },
                { name: 'country', value: personAddress.country },
                { name: 'complement', value: personAddress.complement },
                { name: 'district', value: personAddress.district },
                { name: 'state', value: personAddress.state },
                { name: 'street', value: personAddress.street },
                { name: 'postalCode', value: personAddress.postalCode },
            ])
        } else {
            form.setFields([
                { name: 'personId' , value: props.person.id },
                { name: 'addressAlias' , value: 'Endereço Principal' },
            ])
        }
    }, [props.personAddress])

    function onFinish(values: any) {
        values = {
            ...values,
            personId: props.person.id,
            postalCode: values.postalCode ? values.postalCode.toString().replace('-', '') : null,
        }
        function send() {
            if (props.personAddress?.id) {
                return personRepo.updateAddress(props.personAddress.id, values);
            }
            return personRepo.addAddress(values);
        }

        send().then((res) => {
            message.success(res.message);
            props.onFinish(res.data);
        }).catch(err => {
            message.error(err.message);
        })
    }

    function skipStep() {
        props.onFinish(undefined);
    }

    function SkipStep() {
        if (props.hideSkipStep) {
            return null;
        }

        return (
          <Button
            onClick={skipStep}
            shape={'round'}
            icon={<ArrowRightOutlined />}
          >
              Pular
          </Button>
        )
    }

    return (

        <Form form={form} layout={'vertical'} onFinish={onFinish}>
            <Form.Item name={'addressAlias'} label={'Descrição do Endereço'} rules={[{required: true}]}>
                <Input  />
            </Form.Item>
            <Row gutter={12}>
                <Col span={4}>
                    <Form.Item name={'postalCode'} label={'CEP'} >
                        <Input onBlur={event => searchAddressByPostalCode(event.target.value)} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name={'country'} label={'País'} rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item name={'state'} label={'Estado'} rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={10}>
                    <Form.Item name={'city'} label={'Cidade'} rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={12}>
                <Col span={8}>
                    <Form.Item name={'district'} label={'Bairro'} rules={[{ required: true }]}>
                        <Input  />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name={'street'} label={'Rua'} rules={[{ required: true }]}>
                        <Input  />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item name={'number'} label={'Número'} rules={[{ required: true },{ type: 'integer', message: 'Somente valores numericos'}]}>
                        <InputNumber style={{width: '100%'}} type={'number'}  />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={12}>
                <Col span={24}>
                    <Form.Item name={'complement'} label={'Complemento'}>
                        <Input  />
                    </Form.Item>
                </Col>
            </Row>
            <FormActionsButtons
                label={'Salvar'}
                onCancel={() => props.onBack()}
                labelCancel={'Voltar'}
                moreAction={[<SkipStep />]}
            />
        </Form>
    )
}