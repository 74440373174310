import React, {useContext} from 'react';
import {Route, Switch, useHistory, useParams} from "react-router-dom";
import {ApplicationContext} from "../../context/application.context.props";

export enum ModuleResourceTypeEnum {
    LIST,
    CREATE,
    EDIT,
    DELETE,
    SHOW,
    CUSTOM,
    DEFAULT
}

export type ModuleResource = {
    type: ModuleResourceTypeEnum
    customPath?: string,
    render: (props: ModuleRenderProps) => any,
}

export type ModuleRenderProps = {
    moduleProps: ModuleResource,
    params: any,
    navigator: {
        goToCreatePage: () => void,
        goToEditPage: (id: string) => void,
        goToDeletePage: (id: string) => void,
        goToShowPage: (id: string) => void,
        goToListPage: () => void,
        goToDefaultPage: () => void,
        goToCustomPage: (path: string) => void
    }
}

export type ModuleRootProps = {
    resource: string,
    moduleResources: ModuleResource[],
}

export function ModuleRoot(props: ModuleRootProps) {
    const history = useHistory();
    const params = useParams<any>();
    const { moduleResources, resource } = props;
    const { profile } = useContext(ApplicationContext);

    function getPath(moduleResource: ModuleResource) : string {
        let path = '';
        switch (moduleResource.type) {
            case ModuleResourceTypeEnum.CREATE:
                path = `${resource}/create`;
                break;
            case ModuleResourceTypeEnum.DEFAULT:
                path = `${resource}`;
                break;
            case ModuleResourceTypeEnum.LIST:
                path = `${resource}/list`;
                break;
            case ModuleResourceTypeEnum.DELETE:
                path = `${resource}/remove/:id`;
                break;
            case ModuleResourceTypeEnum.EDIT:
                path = `${resource}/edit/:id`;
                break;
            case ModuleResourceTypeEnum.SHOW:
                path = `${resource}/show/:id`;
                break;
            case ModuleResourceTypeEnum.CUSTOM:
                path = `${resource}/${moduleResource.customPath}`;
                break;
            default: break;
        }
        return path;
    }

    function getNavigator() {
        return {
            goToCreatePage: () => history.push(`${resource}/create`),
            goToEditPage: (id: string) => history.push(`${resource}/edit/${id}`),
            goToDeletePage: (id: string) => history.push(`${resource}/remove/${id}`),
            goToShowPage: (id: string) => history.push(`${resource}/show/${id}`),
            goToListPage: () => history.push(`${resource}/list`),
            goToDefaultPage: () => history.push(`${resource}`),
            goToCustomPage: (path: string) => history.push(`${resource}/${path}`),
        }
    }

    function getRenderProps(module: ModuleResource): ModuleRenderProps {
        return {
            moduleProps: module,
            navigator: getNavigator(),
            params: params
        }
    }

    function secureRender(module: ModuleResource) {
        return module.render(getRenderProps(module))
    }

    return (
        <>
            <Switch>
                {
                    moduleResources.map((module, key) => (
                        <Route
                            key={key}
                            path={getPath(module)}
                            render={() => secureRender(module)}
                        />
                    ))
                }
            </Switch>
        </>
    )
}
