import {useRouteMatch} from "react-router-dom";
import {ModuleResource, ModuleResourceTypeEnum, ModuleRoot} from "../../../core/component/module/module.root";
import React from "react";
import {LoginPage} from "./pages/login";

export function AuthModule() {
    const routeMatch = useRouteMatch();
    const resources: ModuleResource[] = [
        {
            type: ModuleResourceTypeEnum.CUSTOM,
            customPath: 'login',
            render: (props) => <LoginPage {...props} />
        },
        {
            type: ModuleResourceTypeEnum.DEFAULT,
            render: (props) => <LoginPage {...props} />
        },
    ]
    return (
        <ModuleRoot
            resource={routeMatch.url}
            moduleResources={resources}
        />
    )
}
