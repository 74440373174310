import {Button, Col, Form, Input, message, Row, Select, Typography} from "antd";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import React, {useEffect, useState} from "react";
import {DatePickerPtBr} from "../../../../core/component/date-picker-ptbr";
import {PersonEntity} from "../../../../domain/entity/person.entity";
import {usePersonRepository} from "../../../../domain/repository/person.repository";
import moment from "moment";
import {LabelNamePerson} from "./label.name-person";
import {ArrowRightOutlined} from "@ant-design/icons";
import {PersonIndividualEntity} from "../../../../domain/entity/person-individual.entity";

export type FormPersonIndividual = {
    person: PersonEntity,
    onFinish: (person: PersonIndividualEntity | undefined) => void,
    onBack: () => void,
    hideSkipButton?: boolean,
}
export function FormPersonIndividual(props: FormPersonIndividual) {
    const [form] = Form.useForm();
    const [educationLevels, setEducationLevels] = useState<string[]>([]);

    const personRepo = usePersonRepository();

    useEffect(() => {
        personRepo.getEducationLevels().then(res => setEducationLevels(res.data))
        const { person } = props;
        if (person && person.personIndividual) {
            form.setFields([
                { name: 'genre', value: person.personIndividual.genre },
                { name: 'fatherName', value: person.personIndividual.fatherName },
                { name: 'motherName', value: person.personIndividual.motherName },
                { name: 'birthDate', value: moment(person.personIndividual.birthDate) },
                { name: 'deficiency', value: person.personIndividual.deficiency },
                { name: 'educationLevel', value: person.personIndividual.educationLevel },
                { name: 'birthCountry', value: person.personIndividual.birthCountry },
                { name: 'birthState', value: person.personIndividual.birthState },
                { name: 'birthCity', value: person.personIndividual.birthCity },
            ])
        }
    }, [props.person])

    function getTypePersonOpts() {
        return [
            { label: 'Masculino', value: 'M' },
            { label: 'Feminino', value: 'F' },
            { label: 'Outro', value: 'O'}
        ]
    }

    function getEducationLevelsOpts() {
        return educationLevels.sort().map(item => ({
            label: item, value: item
        }))
    }

    function onFinish(values: any) {
        const entity: PersonEntity = {
            ...props.person,
            personIndividual: values
        }

        function send() {
            return personRepo.savePersonIndividual(props.person.id, values);
        }

        send().then(res => {
            message.success('Registro Salvo com Sucesso');
            props.onFinish(res.data);
        }).catch(err => {
            message.error(err.message);
        })
    }

    function skipStep() {
        props.onFinish(undefined);
    }

    function SkipButton() {
        if (props.hideSkipButton) {
            return null;
        }
        return (
          <Button
            onClick={skipStep}
            shape={'round'}
            icon={<ArrowRightOutlined />}
          >
              Pular
          </Button>
        )
    }


    return (
        <Form form={form} layout={'vertical'} onFinish={onFinish}>
            <LabelNamePerson person={props.person} />
            <Row gutter={12}>
                <Col span={8}>
                    <Form.Item name={'genre'} label={'Gênero'} rules={[{ required: true }]}>
                        <Select options={getTypePersonOpts()}  />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <DatePickerPtBr label={'Data de Nascimento'} name={'birthDate'} required />
                </Col>
                <Col span={10}>
                    <Form.Item name={'educationLevel'} label={'Gráu de escolaridade'} rules={[{ required: true }]}>
                        <Select options={getEducationLevelsOpts()}  />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={12}>
                <Col span={8}>
                    <Form.Item name={'birthCountry'} label={'País de Origem'} rules={[{ required: true }]}>
                        <Input  />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name={'birthState'} label={'Estado de Origem'} rules={[{ required: true }]}>
                        <Input  />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name={'birthCity'} label={'Cidade de Origem'} rules={[{ required: true }]}>
                        <Input  />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={12}>
                <Col span={12}>
                    <Form.Item name={'motherName'} label={'Nome da Mãe'} rules={[{ required: true }]}>
                        <Input  />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name={'fatherName'} label={'Nome do Pai'} >
                        <Input  />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={12}>
                <Col span={24}>
                    <Form.Item name={'deficiency'} label={'Deficiência (Caso Exista)'}>
                        <Input  />
                    </Form.Item>
                </Col>
            </Row>
            <FormActionsButtons
                label={'Salvar'}
                onCancel={() => props.onBack()}
                labelCancel={'Voltar'}
                moreAction={[<SkipButton />]}
            />
        </Form>
    )
}