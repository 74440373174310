import {useRouteMatch} from "react-router-dom";
import React from 'react';
import {ModuleResource, ModuleResourceTypeEnum, ModuleRoot} from "../../../core/component/module/module.root";
import {CreateManagerial} from "./pages/create.managerial-account";
import {ListManagerial} from "./pages/list.managerial-account";
import {EditManagerial} from "./pages/edit.managerial-accounts"

export function ManagerialModule() {
    const routeMatch = useRouteMatch();
    const resources: ModuleResource[] = [
        {
            type: ModuleResourceTypeEnum.CREATE,
            render: (props) => <CreateManagerial {...props} />
        },
        {
            type: ModuleResourceTypeEnum.EDIT,
            render: (props) => <EditManagerial {...props} />
        },
        {
            type: ModuleResourceTypeEnum.DEFAULT,
            render: (props) => <ListManagerial {...props} />
        },
    ]

    return (
        <ModuleRoot
            resource={routeMatch.url}
            moduleResources={resources}
        />
    )
}
