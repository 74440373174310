import {Col, Form, message, Row, Select} from "antd";
import {useEffect, useState} from "react";
import {FinancialEntryEntity} from "../../../../domain/entity/financial-entry.entity";
import {BankAccountEntity} from "../../../../domain/entity/bank-account.entity";
import {PaymentMethodEntity} from "../../../../domain/entity/payment-method.entity";
import {useDateTimeUtil} from "../../../../core/util/date-time.util";
import {useFinancialEntryRepository} from "../../../../domain/repository/financial-entry.repository";
import {useBankAccountRepository} from "../../../../domain/repository/bank-account.repository";
import {usePaymentMethodRepository} from "../../../../domain/repository/payment-method.repository";
import {FinancialEntryInstallmentEntity} from "../../../../domain/entity/financial-entry-installment.entity";
import moment from "moment";
import {getSorter} from "../../../../core/util/sort-array.util";
import {DatePickerPtBr} from "../../../../core/component/date-picker-ptbr";
import TextArea from "antd/es/input/TextArea";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import {BrlInputNumber} from "../../../../core/component/brl-input-number";

export function FormWriteOff(props: { entryId: string | number, installmentId?: number, onSaved?: () => void }) {
  const [form] = Form.useForm();
  const [entry, setEntry] = useState<FinancialEntryEntity>();
  const [bankAccounts, setBankAccounts] = useState<BankAccountEntity[]>([]);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethodEntity[]>([]);
  const { entryId } = props;

  const { convertToDatePtBr } = useDateTimeUtil();
  const entryRepo = useFinancialEntryRepository();
  const bankAccountRepo = useBankAccountRepository();
  const paymentMethodRepo = usePaymentMethodRepository();

  function loadParams() {
    bankAccountRepo.findAll(true).then(res => setBankAccounts(res.data));
    paymentMethodRepo.findAll().then(res => setPaymentMethods(res.data));
    entryId && entryRepo.find(entryId.toString()).then(res => setEntry(res.data));
  }

  useEffect(() => {
    loadParams();
  }, [1]);

  useEffect(() => {
    if (props.installmentId) {
      updatePaymentMethod(props.installmentId);
    }
  }, [entry, props.installmentId])

  function updatePaymentMethod(installmentId: number) {
    const installment: FinancialEntryInstallmentEntity | undefined =
      entry?.installments?.find(item => item.id === installmentId);
    if (installment) {
      form.setFields([
        { name: 'paymentMethodId' , value: installment.paymentMethodId },
        { name: 'writeOffDate' , value: moment() },
        { name: 'effectiveDate' , value: moment().add(`day`, 1) },
      ])
    }
  }

  function getPaymentMethodsOpts() {
    return paymentMethods.sort(getSorter<PaymentMethodEntity>('description'))
      .map(item => ({
        label: `${item.description} - ${item.alias}`,
        value: item.id
      }))
  }

  function getInstallmentsOpts() {
    return (entry?.installments ?? [])
      .filter(item => !item.paid)
      .sort(getSorter<FinancialEntryInstallmentEntity>('order'))
      .map(item => ({
        label: `${item.order} - ${convertToDatePtBr(item.dueDate)}`,
        value: item.id
      }))
  }

  function onFinish(values: any) {
    entryRepo.writeOffEntryInstallment(
      values.installmentId,
      values
    ).then(res => {
      message.success(res.message);
      form.resetFields();
      loadParams();
      props.onSaved && props.onSaved()
    }).catch(err => {
      message.error(err.message);
    })
  }

  function getBankAccountOpts() {
    return bankAccounts.sort(getSorter<BankAccountEntity>('alias'))
      .map(item => ({
        label: `${item.alias}`,
        value: item.id
      }))
  }

  return (
    <Form
      form={form}
      layout={'vertical'}
      style={{padding: 25}}
      onFinish={onFinish}
      initialValues={{ fineAmount: 0, interestAmount: 0, discountAmount: 0, installmentId: props.installmentId }}
    >
      <Row gutter={12}>
        <Col span={8}>
          <Form.Item
            name={'installmentId'}
            label={'Selecione a Parcela'}
            rules={[{ required: true }]}
          >
            <Select
              options={getInstallmentsOpts()}
              onSelect={(installmentId: number) => updatePaymentMethod(installmentId)}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name={'paymentMethodId'}
            label={'Selecione a forma de Pagemento'}
            rules={[{ required: true }]}
          >
            <Select options={getPaymentMethodsOpts()} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name={'bankAccountId'}
            label={'Seleciona a Conta Bancaria'}
            rules={[{ required: true }]}
          >
            <Select options={getBankAccountOpts()} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={6}>
          <DatePickerPtBr
            label={'Data de Pagamento'}
            name={'writeOffDate'}
            required={true}
          />
        </Col>
        <Col span={6}>
          <DatePickerPtBr
            label={'Data de Disponibilidade no Banco'}
            name={'effectiveDate'}
            required={false}
          />
        </Col>
        <Col span={4}>
          <BrlInputNumber
            label={'Juros'}
            name={'interestAmount'}
            required={true}
          />
        </Col>
        <Col span={4}>
          <BrlInputNumber
            label={'Multa'}
            name={'fineAmount'}
            required={true}
          />
        </Col>
        <Col span={4}>
          <BrlInputNumber
            label={'Desconto'}
            name={'discountAmount'}
            required={true}
          />
        </Col>
      </Row>
      <Form.Item name={'paymentData'} label={'Dados de Pagamento'}>
        <TextArea placeholder={'Informe Código de Barras ou o Código PIX'} />
      </Form.Item>
      <FormActionsButtons isLoading={entryRepo.loading} />
    </Form>
  )
}