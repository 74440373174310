import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {HeaderDRERealized} from "../component/header.dre-realized";
import React, {useEffect, useState} from "react";
import {DreRowDto} from "../../../../domain/dto/dre-row.dto";
import {useFinancialEntryRepository} from "../../../../domain/repository/financial-entry.repository";
import {Button, Col, Divider, Form, Row, Select, Space} from "antd";
import {useCompetenceRepository} from "../../../../domain/repository/competence.repository";
import {CompetenceEntity} from "../../../../domain/entity/competence.entity";
import {getSorter} from "../../../../core/util/sort-array.util";
import {DownloadOutlined, SearchOutlined} from "@ant-design/icons";
import {useFirmRepository} from "../../../../domain/repository/firm.repository";
import {FirmEntity} from "../../../../domain/entity/firm.entity";
import {CostCenterEntity} from "../../../../domain/entity/cost-center.entity";
import {useDocumentFormatter} from "../../../service/document.formatter.service";
import {DreList} from "../../../component/dre-list";
import {useCostCenterRepository} from "../../../../domain/repository/account.repository";
import {useDRERowRepository} from "../../../../domain/repository/dre-row.repository";

export function ReportDreRealized(props: ModuleRenderProps) {
  const [form] = Form.useForm();
  const [data, setData] = useState<DreRowDto[]>([]);
  const [competences, setCompetences] = useState<CompetenceEntity[]>([]);
  const [firms, setFirms] = useState<FirmEntity[]>([]);
  const [costCenters, setCostCenters] = useState<CostCenterEntity[]>([]);

  const { formatCNPJ } = useDocumentFormatter();
  const financialEntryRepo = useFinancialEntryRepository();
  const dreRowRepo = useDRERowRepository();
  const competenceRepo = useCompetenceRepository();
  const firmRepo = useFirmRepository();
  const costCenterRepo = useCostCenterRepository();

  function loadParams() {
    firmRepo.findAll().then(res => setFirms(res.data));
    competenceRepo.findAll().then(res => setCompetences(res.data));
    costCenterRepo.findAll().then(res => setCostCenters(res.data));
  }

  function loadCostCenters() {
    if (form.getFieldValue('firmId')) {
      firmRepo.listCostCenters(form.getFieldValue('firmId'))
        .then(res => setCostCenters(res.data));
    }
  }

  useEffect(() => {
    loadParams();
  }, [1]);

  function loadData(args: any) {
    const { competenceId, firmId, costCenterId } = args;
    dreRowRepo.generateEntryReport({
      year: competenceId,
      costCenterId
    }).then(res => setData(res.data));
  }

  function exportReport() {
    const { competenceId, firmId, costCenterId } = form.getFieldsValue();
    dreRowRepo.exportEntryReport({
      year: competenceId,
      costCenterId
    });
  }

  function getCompetenceOpts() {
    return competences
      .sort(getSorter<CompetenceEntity>('year'))
      .map(item => ({
        label: item.year,
        value: item.year
      }))
  }

  function getFirmOpts() {
    return firms.sort(getSorter<FirmEntity>('name'))
      .map(item => ({
        label: `${item.name} - ${formatCNPJ(item.document)}`,
        value: item.id
      }))
  }

  function getCostCenterOpts() {
    return costCenters.sort(getSorter<CostCenterEntity>('code'))
      .map(item => ({
        label: `${item.code} - ${item.name}`,
        value: item.id as number,
      }))
  }

  return (
    <>
      <HeaderDRERealized />
      <Divider />
      <Form form={form} layout={'vertical'} onFinish={loadData} size={'middle'} >
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item name={'competenceId'} label={'Selecione uma competência'} rules={[{ required: true }]}>
              <Select  options={getCompetenceOpts()} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name={'firmId'} label={'Filial'}>
              <Select allowClear options={getFirmOpts()} onSelect={(val: number) => loadCostCenters()} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name={'costCenterId'} label={'Centro de Custo'}>
              <Select allowClear options={getCostCenterOpts()} />
            </Form.Item>
          </Col>
        </Row>
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
          <Space>
            <Form.Item name={'transform'}>
              <Button
                icon={<SearchOutlined />}
                type={'primary'}
                size={'middle'}
                onClick={() => {
                  form.setFields([{name: 'transform', value: false}]);
                  form.submit();
                }}>
                Pesquisar
              </Button>
            </Form.Item>
            <Form.Item name={'transform'}>
              <Button
                icon={<DownloadOutlined />}
                size={'middle'}
                onClick={exportReport}>
                XLSX
              </Button>
            </Form.Item>
          </Space>
        </div>
      </Form>
      <Divider style={{marginTop: 0}} />
      <DreList data={data} loading={financialEntryRepo.loading} />
    </>
  )
}
