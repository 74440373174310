import {Divider} from "antd";
import {HeaderRecurrenceEntry} from "../component/header.recurrence-entry";
import React from "react";
import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {FormRecurrenceEntry} from "../component/form.recurrence-entry";
import {RecurrenceEntryEntity} from "../../../../domain/entity/recurrence-entry.entity";

export function CreateRecurrenceEntry(props: ModuleRenderProps) {
  function onFinish(recurrence: RecurrenceEntryEntity) {
    props.navigator.goToCustomPage(`edit/${recurrence.id.toString()}?tab=2`);
  }
  return (
    <>
      <HeaderRecurrenceEntry mode={'form'} />
      <Divider/>
      <FormRecurrenceEntry
        mode={'create'}
        onFinish={onFinish}
        onCancel={props.navigator.goToListPage}
      />
    </>
  )
}