export const Wrapper = {
    width: '100%',
    height: '100vh',
    display: 'flex',
    backgroundColor: '#1b1b1b',
}

export const LoginWallpaperStyle = {
    flex: 5,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#1b1b1b',
    backgroundHeight: '100vh',
    backgroundImage: `url('/logo.png')`, 
    backgroundSize: `contain`,
}

export const LoginFormStyle = {
    flex: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}
