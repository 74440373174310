import {Button, Col, Form, Input, Row, Select} from "antd";
import React, {useState} from "react";
import {SearchOutlined} from "@ant-design/icons";

export type FilterListManagerialAccountProps = {
    onFilterCode: (filter: string) => void,
    onFilterDesc: (filter: string) => void,
}

export function FilterListManagerialAccount(props: FilterListManagerialAccountProps) {
    const [form] = Form.useForm();
    const fields: Array<{value: string, label: string}> = [
        { value: 'code', label: 'Código' },
        { value: 'description', label: 'Descrição' },
    ];
    function onFilter(values: any) {
        console.log(values);
        if (values.field == 'code') {
            props.onFilterCode(values.filter);
        }
        if (values.field == 'description') {
            props.onFilterDesc(values.filter);
        }
    }


    return (
        <Form
            form={form}
            layout="horizontal"
            onFinish={onFilter}
            initialValues={{ field: 'code' }}
        >
            <Row style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}} >
                <Col>
                    <Form.Item name={'field'}>
                        <Select size={'middle'} options={fields} />
                    </Form.Item>
                </Col>
                <Col span={6} >
                    <Form.Item name={'filter'}>
                        <Input size={'middle'} />
                    </Form.Item>
                </Col>
                <Col>
                    <Form.Item>
                        <Button htmlType={'submit'} size={'middle'} icon={<SearchOutlined />}>
                            Pesquisar
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}