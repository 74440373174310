import React from 'react';
import {Table} from "antd";

export function FirmsPage(props: any) {

    return (
        <Table
            columns={[
                {
                    title: 'Empresa',
                    dataIndex: 'name',
                },
                {
                    title: 'CNPJ',
                    dataIndex: 'document',
                },
                {
                    title: 'Insc. Estadual',
                    dataIndex: 'document_ime',
                },
                {
                    title: 'Insc. Municipal',
                    dataIndex: 'document_imu',
                },
            ]}
            dataSource={[
                { name: 'INSIS', document: '53.439.079/0001-18', document_ime: '12332123', document_imu: '663771652' },
                { name: 'Juristta', document: '32.439.087/0001-19', document_ime: '12332323', document_imu: '343771652' },
            ]}
        />
    )
}
