import {useDateTimeUtil} from "../../../../core/util/date-time.util";
import {Table} from "antd";
import {ColumnsType} from "antd/lib/table";
import {useNumberFormatter} from "../../../service/number-formatter.service";
import {BankAccountStatementEntity} from "../../../../domain/entity/bank-account-statement.entity";
import {RenderStatementDescriptionComponent} from "./render.statement-description.component";


export type ListAnalyticStatementsComponentProps = {
  data: BankAccountStatementEntity[],
  previousBalance: number,
  onEntryClick: (entryId: number) => any,
  loading?: boolean,
}
export function ListAnalyticStatementsComponent(props: ListAnalyticStatementsComponentProps) {
  const { convertToDatePtBr, } = useDateTimeUtil();
  const { decimalFormatter } = useNumberFormatter();


  const parentColumns: ColumnsType<BankAccountStatementEntity> = [
    {
      title: 'Cod.',
      dataIndex: 'id',
    },
    {
      title: 'Data',
      dataIndex: 'dateTime',
      render: convertToDatePtBr,
    },
    {
      title: 'Usuário',
      dataIndex: 'createdBy',
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      render: (val, row) => (
        <RenderStatementDescriptionComponent
          description={val}
          meta={row.meta}
          onEntryClick={props.onEntryClick}
        />
      )
    },
    {
      title: 'Crédito',
      dataIndex: 'creditValue',
      render: decimalFormatter.format
    },
    {
      title: 'Débito',
      dataIndex: 'debitValue',
      render: decimalFormatter.format
    },
    {
      title: 'Saldo',
      dataIndex: 'balance',
      render: decimalFormatter.format
    },
  ]

  return (
    <>
      <Table
        columns={parentColumns}
        dataSource={props.data}
        loading={props.loading}
      />
    </>
  )
}