import {Col, DatePicker, Divider, Form, message, Row, Typography} from "antd";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import React, {useEffect, useState} from "react";
import {useCompetenceRepository} from "../../../../domain/repository/competence.repository";
import {CompetenceEntity} from "../../../../domain/entity/competence.entity";
import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {MonthsEnum} from "../../../../domain/vo/months.enum";
import {DatePickerPtBr} from "../../../../core/component/date-picker-ptbr";
import {useParseMonthsCloseDateService} from "../service/parse-months-close-dates.service";
import {useUpdateFieldsByYearService} from "../service/update-fields-by-year.service";

export function CreateCompetence(props: ModuleRenderProps) {
    const [form] = Form.useForm();
    const [year, setYear] = useState<number>();
    const competenceRepository = useCompetenceRepository();
    const parseMonthsCloseDates = useParseMonthsCloseDateService();
    const updateFieldsByYear = useUpdateFieldsByYearService();

    useEffect(() => {
        if (year) {
            updateFieldsByYear.setFields(year, form)
        }

    }, [year])

    function onFinish(data: any) {

        const competenceEntity = {
            active: true,
            canUpdate: true,
            endDate: data.endDate.format(),
            initialDate: data.initialDate.format(),
            year: data.year.format('YYYY'),
            monthsCloseDates: parseMonthsCloseDates.parse(data)
        }

        competenceRepository.create(competenceEntity as CompetenceEntity)
            .then(res => {
                message.success(res.message);
                props.navigator.goToListPage();
            }).catch(err => {
                message.error(err.message);
        })
    }

    function parseYear(value: any) {
        setYear(parseInt(value.format('YYYY')))
    }

    return (
        <>
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Row gutter={12}>
                    <Col span={8}>
                        <Form.Item label="Ano" name={'year'} style={{width: '100%'}} rules={[{ required: true }]}>
                            <DatePicker picker={'year'} style={{width: '100%'}} onSelect={parseYear} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <DatePickerPtBr
                            label="Data Início"
                            name={'initialDate'}
                            required={true}
                        />
                    </Col>
                    <Col span={8}>
                        <DatePickerPtBr
                            label="Data Final"
                            name={'endDate'}
                            required={true}
                        />
                    </Col>
                </Row>
                <Divider />
                <Typography.Text>Selecionar a Data de Encerramento De Cada Mês: </Typography.Text>
                <Row gutter={12}>
                    {
                        MonthsEnum.getValues().map(month => (
                            <Col span={6}>
                                <DatePickerPtBr
                                    name={`month_${month.value}`}
                                    label={`${month.label}`}
                                    required={true}
                                />
                            </Col>
                        ))
                    }
                </Row>

                <FormActionsButtons
                    onCancel={props.navigator.goToDefaultPage}
                    isLoading={competenceRepository.loading}
                />
            </Form>
        </>
    )
}