import {Divider} from "antd";
import React from "react";
import {HeaderLedgerAccount} from "../component/header.ledger-account.";
import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {FormLedgerAccount} from "../component/form.ledger-account";

export function CreateLedgerAccount(props: ModuleRenderProps) {
  return (
    <>
      <HeaderLedgerAccount mode={'form'} />
      <Divider />
      <FormLedgerAccount
        onSaved={props.navigator.goToListPage}
        onCancel={props.navigator.goToListPage}
      />
    </>
  )
}
