import {SelectBase} from "./select.base";
import {CostCenterEntity} from "../../../domain/entity/cost-center.entity";

export type SelectCostCenterProps = {
  data: CostCenterEntity[],
  label?: string,
  name?: string,
  placeholder?: string,
  required?: boolean;
  onChange?: (value: string | number) => void,
}

export function SelectCostCenter(props: SelectCostCenterProps) {
  function getOpts() {
    return props.data.map(item => ({
      label: `${item.code} - ${item.name}`,
      value: item.id as number,
    }))
  }

  return (
    <SelectBase
      label={props.label ?? 'Centro de Custo'}
      name={props.name ?? 'costCenterId'}
      data={getOpts()}
      placeholder={props.placeholder}
      onChange={props.onChange}
      required={props.required}
    />
  )
}