import React, {useContext, useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {Menu} from 'antd';
import {ApplicationContext} from "../../context/application.context.props";

export type MenuItem = {
  resource: string,
  label: string,
  icon: any,
  uri?: string,
  children?: MenuItem[],
}

export type SkeletonMenuProps = {
  menus: MenuItem[],
  collapsed: boolean,
  prefixUrl: string,
}


export function SkeletonMenu(props: SkeletonMenuProps) {
  const { prefixUrl } = props;
  const [itemSelected, setItemSelected] = useState<string[]>([]);
  const [menuOpened, setMenuOpened] = useState<React.Key[]>([]);
  const { profile } = useContext(ApplicationContext);

  function hasPermission(resourceName: string): boolean {
    const resource = profile?.disabledResources.find(w => w.resource === resourceName);
    if (!resource) {
      return true;
    }
    return !resource.actions.includes('read');
  }

  function getMenuParsed(): MenuItem[] {
    const parsedItems: MenuItem[] = [];
    for (const item of props.menus) {
      const parsedChildren: MenuItem[] = [];
      if (!hasPermission(item.resource)) continue;
      for(const child of item?.children ?? []) {
        if (!hasPermission(child.resource)) continue;
        parsedChildren.push(child);
      }
      if (!parsedChildren.length) continue;
      parsedItems.push({
        ...item,
        children: parsedChildren,
      });
    }

    return parsedItems;
  }
  function mountLink(uri: string): string {
    return `${prefixUrl}/${uri}`;
  }

  function mountMenu(key: number, menu: MenuItem) {
    if (menu.children && menu.children.length > 0) {
      return mountSubMenu(key, menu);
    }
    return mountMenuItem(key, menu);
  }

  function mountMenuItem(key: any, menu: MenuItem) {
    if (profile) {
      if (profile.disabledResources.find(w => w.resource === menu.resource)) {
        return null;
      }
    }
    const onClick = () => {
      setItemSelected([key])
    };
    const isSelected = () => itemSelected.includes(key.toString())
    return (
      <Menu.Item
        key={key}
        icon={menu.icon}
        isSelected={isSelected()}
      >
        <Link
          to={mountLink(menu.uri ?? '')}
          onClick={onClick}
        >
          {menu.label}
        </Link>
      </Menu.Item>
    )
  }

  function mountSubMenu(subKey: any, menu: MenuItem) {
    if (profile) {
      if (profile.disabledResources.find(w => w.resource === menu.resource)) {
        return null;
      }
    }

    return (
      <Menu.SubMenu key={subKey} icon={menu.icon} title={menu.label} >
        {menu.children?.map((item,key) =>
          mountMenu(subKey * 100 + key, item))}
      </Menu.SubMenu>
    )
  }

  function onOpenChange(keys: React.Key[]) {
    console.log(keys);
    const last = keys.pop();
    last  ? setMenuOpened([last]) : setMenuOpened([]);
  }

  return (
    <Menu
      theme="dark"
      mode="inline"
      siderCollapsed={props.collapsed}
      openKeys={menuOpened.map(item => item.toString())}
      onOpenChange={onOpenChange}
    >
      {
        getMenuParsed().map((menu, key) => mountMenu(key + 1, menu))
      }
    </Menu>
  )
}
