import {FinancialEntryInstallmentEntity} from "../../../../../domain/entity/financial-entry-installment.entity";
import {Button, Col, Descriptions, Form, InputNumber, List, Row, Typography} from "antd";
import {useEffect, useMemo, useState} from "react";
import {PaymentMethodEntity} from "../../../../../domain/entity/payment-method.entity";
import {usePaymentMethodRepository} from "../../../../../domain/repository/payment-method.repository";
import {useNumberFormatter} from "../../../../service/number-formatter.service";
import {useDateTimeUtil} from "../../../../../core/util/date-time.util";
import {SelectPaymentMethod} from "../../../../component/dropdown/select.payment-method";
import {FormInstallmentComponent} from "./form-installment.component";
import {DatePickerPtBr} from "../../../../../core/component/date-picker-ptbr";
import moment from "moment";
import {FormActionsButtons} from "../../../../../core/component/form-actions-buttons";
import {InstallmentViewModel} from "./installment.view-model";

export type RenegotiateInstallmentsFormComponentProps = {
  onCancel: () => any,
  onFinish: () => any,
  setPrevInstallments: (installments: InstallmentViewModel[]) => any,
  selectedInstallments: FinancialEntryInstallmentEntity[],
  entryInstallments: FinancialEntryInstallmentEntity[],
}
export function RenegotiateInstallmentsFormComponent(props: RenegotiateInstallmentsFormComponentProps) {
  const [form] = Form.useForm();
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethodEntity[]>([]);
  const [prevInstallments, setPrevInstallments] = useState<InstallmentViewModel[]>([]);

  const paymentMethodRepo = usePaymentMethodRepository();
  const { currencyFormatter } = useNumberFormatter();
  const { convertToDatePtBr } = useDateTimeUtil();

  useEffect(() => {
    paymentMethodRepo.findAll()
      .then(res => setPaymentMethods(res.data));
  },[])

  const lastInstallmentOrder = useMemo<number>(() => {
    return props.entryInstallments.map(w => w.order)
      .reduce((p, c) => p > c ? p : c, 0)
  }, [props, props.entryInstallments])

  function onFinish(values: any) {
    setPrevInstallments(Object.values(values.prevInstallments));
    props.setPrevInstallments(Object.values(values.prevInstallments));
    props.onFinish();
  }

  function generateInstallments() {
    const { installmentsQuantity, defaultPaymentMethod, firstDueDate } = form.getFieldsValue();
    const prevInstallments = new Array<InstallmentViewModel>();
    for (let i = 1; i <= installmentsQuantity; i++) {
      prevInstallments.push({
        id: lastInstallmentOrder + i,
        discountAmount: 0,
        dueDate: moment(firstDueDate).add((i - 1), 'month'),
        paymentMethodId: defaultPaymentMethod,
        fineAmount: 0,
        interestAmount: 0,
        order: lastInstallmentOrder + i,
        value: divideTotalAmount(installmentsQuantity, installmentsQuantity === i)
      })
    }
    setPrevInstallments(prevInstallments);
  }

  const amountToRenegotiate = useMemo<number>(() => {
    return props.selectedInstallments.map(w => w.value)
      .reduce((p,c) => p + parseFloat(c.toString()), 0) ?? 0;
  }, [props.selectedInstallments])

  function divideTotalAmount(quantity: number, includeRemainder: boolean) {
    const prev = parseFloat((amountToRenegotiate / quantity).toFixed(2));
    if (!includeRemainder) {
      return prev;
    }
    return parseFloat((prev + (amountToRenegotiate - prev * quantity))
      .toFixed(2));
  }

  function onChangeInstallment(installment: InstallmentViewModel) {
    setPrevInstallments([
      ...prevInstallments.filter(w => w.id !== installment.id),
      installment,
    ])
  }

  return (
    <>
      <Row gutter={22}>
        <Col span={5}>
          <List
            header={<Typography.Title level={4}>Parcelas Para Renegociar</Typography.Title> }
            dataSource={props.selectedInstallments}
            split
            renderItem={(item: FinancialEntryInstallmentEntity) => (
              <List.Item>
                <List.Item.Meta
                  title={`Parcela ${item.order} (${convertToDatePtBr(item.dueDate)})`}
                  description={`Valor ${currencyFormatter.format(item.value)}`}
                />
              </List.Item>
            )}
          />
        </Col>
        <Col span={19}>
          <Descriptions bordered size={'default'}>
            <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={'Valor Total'}>
              {currencyFormatter.format(amountToRenegotiate)}
            </Descriptions.Item>
            <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={'Total de Pareclas'}>
              {props.selectedInstallments.length}
            </Descriptions.Item>
          </Descriptions>
          <br />
          <Form form={form} layout={'vertical'} onFinish={onFinish}>
            <Row gutter={12}>
              <Col span={4}>
                <Form.Item label={'Númeto de Parcelas'} name={'installmentsQuantity'} rules={[{ required: true }]}>
                  <InputNumber style={{width: '100%'}} />
                </Form.Item>
              </Col>
              <Col span={10}>
                <SelectPaymentMethod data={paymentMethods} name={'defaultPaymentMethod'} />
              </Col>
              <Col span={6}>
                <DatePickerPtBr label={'Primeiro Vencimento'} name={'firstDueDate'} required />
              </Col>
              <Col span={4}>
                <div style={{position: 'absolute', bottom: 24}}>
                  <Button onClick={generateInstallments} >Gerar Parcelas</Button>
                </div>
              </Col>
            </Row>
            {
              prevInstallments.map(w => (
                <FormInstallmentComponent
                  installment={w}
                  paymentMethods={paymentMethods}
                  setValues={form.setFields}
                  onChange={onChangeInstallment}
                />
              ))
            }
            <FormActionsButtons
              label={'Continuar'}
              labelCancel={'Voltar'}
              onCancel={props.onCancel}
            />
          </Form>
        </Col>
      </Row>
    </>
  )
}