import {DreRowDto} from "../../../domain/dto/dre-row.dto";
import {Checkbox, Table, Typography} from "antd";
import React, {useMemo, useState} from "react";
import {useNumberFormatter} from "../../service/number-formatter.service";
import {DreRowType} from "../../../domain/entity/dre-row.entity";
import {FullscreenExitOutlined, MinusCircleOutlined, PlusCircleOutlined} from "@ant-design/icons";
import {ColumnsType} from "antd/es/table";
import {MonthsEnum} from "../../../domain/vo/months.enum";
import {getSorter} from "../../../core/util/sort-array.util";

export function DreList(props: { data: DreRowDto[], loading?: boolean }) {
  const { decimalFormatter } = useNumberFormatter();
  const [isFormatValue, setIsFormatValue] = useState<boolean>(true);
  function getIcon(type: DreRowType) {
    switch (type) {
      case DreRowType.REVENUE:
        return <PlusCircleOutlined style={{color: 'green'}} />;
      case DreRowType.DEDUCTION:
        return <MinusCircleOutlined style={{color: 'red'}} />;
      case DreRowType.CALC:
        return <FullscreenExitOutlined style={{color: 'blue'}} />;
      default:
        break;
    }
    return null;
  }

  function renderLineName(name: string, entity: DreRowDto) {
    return <Typography> {getIcon(entity.type as DreRowType)} {name}</Typography>
  }

  const columns: ColumnsType<any> = [
    {
      title: 'Ordem',
      key: 'order',
      dataIndex: 'order',
      width: 100,
      fixed: 'left',
    },
    {
      title: 'Linha DRE',
      key: 'dreRow',
      dataIndex: 'name',
      fixed: 'left',
      width: 150,
      ellipsis: true,
      render: renderLineName
    },
  ]

  for (let month = 1; month <= 13; month++) {
    columns.push({
      key: `month-${month}`,
      title: month === 13 ? 'Total' : MonthsEnum.parseName(month),
      dataIndex: 'values',
      ellipsis: true,
      render: (values) => decimalFormatter
        .format(values.find((item: any) => item.month === month)?.value ?? 0)
    })
  }

  function formatValue(value: number) {
    return isFormatValue ? value / 1000 : value;
  }

  const parsedDate = useMemo<any>(() => {
    const { data } = props;

    const sortedData = data.map(item => ({
      ...item,
      children: item.children?.sort(getSorter('order')) ?? null
    }))
      .sort(getSorter('order'))

    return sortedData.map(item => ({
      ...item,
      values: item.values?.map(monthValue => ({
        ...monthValue, value: formatValue(monthValue.value)
      })),
      key: item.rowId,
      children: item.children?.map(child => ({
        ...child,
        values: child.values?.map(monthChildValue => ({
          ...monthChildValue,
          value: formatValue(monthChildValue.value),
        })),
        key: child.rowId
      }))
    }))
  }, [formatValue, props])

  return (
    <>
      <div style={{display: 'flex', justifyContent: 'flex-end'}} >
        <Typography.Text keyboard strong>
          <Checkbox
            checked={isFormatValue}
            onChange={(e) => setIsFormatValue(e.target.checked)}
          >
            {'{valor}'} / 1000
          </Checkbox>
        </Typography.Text>
      </div>

      <Table
        loading={props.loading}
        columns={columns}
        dataSource={parsedDate}
        pagination={false}
        scroll={{x: true, y: 'calc(100vh - 270px)'}}
      />
    </>
  )
}
