import {Button, Col, Form, Input, message, Row, Select} from "antd";
import {ExportOutlined, SearchOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import {DatePickerPtBr} from "../../../../core/component/date-picker-ptbr";
import {Moment} from "moment";
import {PersonEntity} from "../../../../domain/entity/person.entity";
import {InputSearch} from "../../../../core/component/input-search";
import {usePersonRepository} from "../../../../domain/repository/person.repository";

export type FilterFinancialEntryProps = {
  onTypeFilter: (value: string) => void,
  onDocumentFilter: (value: string) => void,
  onIntervalFilter: (initialDate: Moment, finalDate: Moment) => void,
  onExport: (initialDate: Moment, finalDate: Moment) => void,
  onPersonFilter: (personId: number, initialDate: Moment, finalDate: Moment) => void,
  onNumMovFilter: (id: number) => void,
}

export function FilterFinancialEntry(props: FilterFinancialEntryProps) {
  const [form] = Form.useForm();
  const [currentFilterType, setCurrentFilterType] = useState<string>();
  const [persons, setPersons] = useState<PersonEntity[]>([]);
  const fields: Array<{value: string, label: string}> = [
    { value: 'id', label: 'Numero do Mov.' },
    { value: 'type', label: 'Tipo de Lançamento' },
    { value: 'document', label: 'Número do Dcumento' },
    { value: 'dateInterval', label: 'Periodo' },
    { value: 'personId', label: 'Fornecedor' },
  ];

  const personRepo = usePersonRepository();

  useEffect(() => {
    personRepo.findAll().then(res => setPersons(res.data));
  }, [])

  function onFilter(values: any) {
    if (values.field === 'type') {
      props.onTypeFilter(values.filter);
    }
    if (values.field === 'document') {
      props.onDocumentFilter(values.filter);
    }
    if (values.field === 'dateInterval') {
      props.onIntervalFilter(values.initialDate, values.finalDate);
    }
    if (values.field === 'id') {
      props.onNumMovFilter(values.filter);
    }
    if (values.field === 'personId') {
      props.onPersonFilter(values.personId, values.initialDate, values.finalDate);
    }
  }

  function onExport() {
    const { initialDate, finalDate } = form.getFieldsValue();
    if (!initialDate) {
      form.setFields([ {name: 'initialDate', errors: ['Dado obrigatória']}])
    } else if (!finalDate) {
      form.setFields([ {name: 'finalDate', errors: ['Dado obrigatória']}])
    } else {
      message.info('O arquivo terá no máximo 5000. ' +
        'Caso queira exportar mais do que isso, ' +
        'particione o download em duas ou mais planilhas com um intervalo menor de datas');
      props.onExport(initialDate, finalDate);
    }
  }


  function InputFilter() {
    let field = <Input size={'middle'} allowClear />;
    switch (currentFilterType) {
      case 'type':
        field = (
          <Select
            allowClear
            size={'middle'}
            options={[{ label: 'Receita' , value: 'R'},{ label: 'Despesa' , value: 'D'}]}
          />
        )
        break;
      case 'personId':
        field = (
          <>
            <Row>
              <Col span={8}>
                <InputSearch
                  name={'personId'}
                  required={true}
                  label={''}
                  data={persons.map(w => ({ value: w.id, label: w.name }))}
                />
              </Col>
              <Col span={8}>
                <DatePickerPtBr
                  label={''}
                  name={'initialDate'}
                  required={true}
                  placeholder={'Data de Entrada Incial'}
                />
              </Col>
              <Col span={8}>
                <DatePickerPtBr
                  label={''}
                  name={'finalDate'}
                  required={true}
                  placeholder={'Data de Entrada Final'}
                />
              </Col>
            </Row>
          </>
        )
        break;
      case 'dateInterval':
        return (
          <Row>
            <Col span={12}>
              <DatePickerPtBr
                label={''}
                name={'initialDate'}
                required={true}
                placeholder={'Data de Entrada Incial'}
              />
            </Col>
            <Col span={12}>
              <DatePickerPtBr
                label={''}
                name={'finalDate'}
                required={true}
                placeholder={'Data de Entrada Final'}
              />
            </Col>
          </Row>
        );
      default:
        break;
    }

    return <Form.Item name={'filter'}>{field}</Form.Item>;
  }

  function getSizeInput(): number {
    return currentFilterType === 'personId' ? 12 : 6;
  }

  return (
    <Form
      form={form}
      layout="horizontal"
      onFinish={onFilter}
      initialValues={{ field: 'id' }}
      size={'middle'}
    >
      <Row style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}} >
        <Col>
          <Form.Item name={'field'}>
            <Select
              size={'middle'}
              options={fields}
              onSelect={val => setCurrentFilterType(val.toString())}
            />
          </Form.Item>
        </Col>
        <Col span={getSizeInput()} >
          <InputFilter />
        </Col>
        <Col>
          <Form.Item>
            <Button htmlType={'submit'} size={'middle'} icon={<SearchOutlined />}>
              Pesquisar
            </Button>
          </Form.Item>
        </Col>
        {
          currentFilterType === 'dateInterval' && (
            <Col>
              <Form.Item>
                <Button
                  onClick={onExport}
                  size={'middle'}
                  icon={<ExportOutlined />}
                >
                  Exportar
                </Button>
              </Form.Item>
            </Col>
          )
        }
      </Row>
    </Form>
  )
}