import {UserEntity} from "../../domain/entity/user.entity";
import {ProfileEntity} from "../../domain/entity/profile.entity";
import {ClientEntity} from "../../domain/entity/client.entity";
import React, {useEffect, useState} from "react";
import {useClientStorage} from "../service/client.storage";
import {useUserStorage} from "../service/user.storage";
import {useProfileStorage} from "../service/profile.storage";

export type ApplicationContextProps = {
  user?: UserEntity,
  profile?: ProfileEntity,
  client?: ClientEntity,
}

export function useContextValue() {
  const [user, setUser] = useState<UserEntity>();
  const [profile, setProfile] = useState<ProfileEntity>();
  const [client, setClient] = useState<ClientEntity>();

  const { getClient } = useClientStorage();
  const { getUser } = useUserStorage();
  const { getProfile } = useProfileStorage();

  useEffect(() => {
    setUser(getUser());
    setProfile(getProfile());
    setClient(getClient());
  }, [1])

  return { user, profile, client };
}

export const ApplicationContext = React.createContext<ApplicationContextProps>({
  user: undefined,
  client: undefined,
  profile: undefined,
});
