import {IntegrationParamEntity} from "../../../../domain/entity/integration-param.entity";
import {useResolveProxyValuesService} from "../service/resolve-proxy-values.service";
import {Button, Col, Form, Input, message, Row, Select, Space, Table, Typography} from "antd";
import {useForm} from "antd/es/form/Form";
import {useEffect, useMemo, useState} from "react";
import {OptionsValue} from "../../../../core/types/option.type";
import {useIntegrationRepository} from "../../../../domain/repository/integration.repository";
import {ColumnsType} from "antd/lib/table";
import Highlighter from "react-highlight-words";
import {ExportableButtonComponent} from "../../../../core/component/exportable-button";

export type FormConfigProxyComponentType = {
  param: IntegrationParamEntity,
  onSaved?: () => void,
}

export function FormConfigProxyComponent(props: FormConfigProxyComponentType) {
  const [form] = useForm();
  const [internalData, setInternalData] = useState<OptionsValue>([]);
  const [filter, setFilter] = useState<string>();
  const [internalKeyFilter, setInternalKeyFilter] = useState<string | number>();

  const integrationRepo = useIntegrationRepository();
  const { resolveValues } = useResolveProxyValuesService();

  function save(entity: any) {
    integrationRepo.editParam(entity).then(res => {
      message.success(res.message);
      props.onSaved && props.onSaved()
      form.resetFields();
    })
  }

  function onFinish(values: any) {
    const temp = props.param?.keysProxy ? props.param?.keysProxy : [];
    temp?.push(values);
    const entity: IntegrationParamEntity = {
      ...props.param,
      keysProxy: temp
    }
    return save(entity);
  }

  function removeParamConfig(externalKey: string) {
    const params = props.param.keysProxy?.filter(w => w.externalKey != externalKey);
    const entity: IntegrationParamEntity = {
      ...props.param,
      keysProxy: params
    }
    return save(entity);
  }

  useEffect(() => {
    form.resetFields();
    props.param?.entity && resolveValues(props.param.entity)
      .then(res => setInternalData(res));
  }, [props.param?.entity]);

  const cols: ColumnsType<any> = [
    {
      title: 'Chave Externa',
      dataIndex: 'externalKey',
      render: (text: string) => (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[filter ?? '']}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      )
    },
    {
      title: 'Valor Correspondente',
      dataIndex: 'internalKey',
      // render: value => internalData.find(w => w.value == value)?.label
    },
    {
      title: '',
      dataIndex: 'externalKey',
      render: value => (
        <Button
          size={'small'}
          onClick={() => removeParamConfig(value)}
        >
          Remover
        </Button>
      )
    }
  ]
  const filteredData = useMemo(() => {
    if (internalKeyFilter) {
      return props.param.keysProxy?.filter(
        w => w.internalKey == internalKeyFilter
      )
    }
    if (!filter) {
      return props.param.keysProxy;
    }
    return props.param.keysProxy?.filter(
      w => w.externalKey.toLowerCase().includes(filter.toLowerCase())
    );
  }, [filter, internalKeyFilter, props.param.keysProxy])

  const parsedInternalDate = useMemo(() => {
    if (!filteredData) {
      return [];
    }
    return filteredData?.map(item => ({
      ...item,
      internalKey: internalData.find(w => w.value === item.internalKey)?.label,
    }))
  }, [filteredData, internalData])

  return (
    <>
      <Form form={form} layout={'horizontal'} onFinish={onFinish} labelAlign={'right'}>
        <Typography.Title level={5}>
          Configurar Parametro {props.param.name}
        </Typography.Title>
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item name={'externalKey'} rules={[{ required: true, message: 'Dado Obrigatório' }]}  >
              <Input placeholder={'Chave Externa'} width={'100%'} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name={'internalKey'} rules={[{ required: true }]}>
              <Select
                options={internalData}
                placeholder={'Parametro Interno'}
                onChange={(w) => setInternalKeyFilter(w.toString())}
              />
            </Form.Item>
          </Col>
          <Col>
            <Space>
              <Button htmlType={'submit'}>Adicionar</Button>
              <Button
                title={'Pesquisar pela chavce externa'}
                onClick={() => {
                  setInternalKeyFilter(undefined)
                  setFilter(form.getFieldValue('externalKey'))
                }}
                htmlType={'button'}
              >
                Pesquisar
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
      <Table
        columns={cols}
        dataSource={parsedInternalDate}
        footer={
          () => (
            <ExportableButtonComponent
              size={'small'}
              data={parsedInternalDate.map(w => ({
                INTERNO: w.internalKey,
                EXTERNO: w.externalKey,
              }))}
              fileName={'config-integracao.csv'}
            />
          )
        }
      />
    </>
  )
}