import {AppPageHeader} from "../../../../core/component/page-header";
import {useHistory, useRouteMatch} from 'react-router-dom';
import React from "react";

export type HeaderBudgetProps = {
    createRoute?: string;
    mode?: 'list' | 'form';
    title?: string,
    subtitle?:string,
    hideCreateAction?: boolean
}

export function HeaderBudget(props: HeaderBudgetProps) {
    const urlMatch = useRouteMatch();
    const history = useHistory();


    function getCreateLink(): string | undefined {
        if (props.hideCreateAction) {
            return undefined;
        }
        return `${urlMatch.url}/create`;
    }

    function getListLink(): string {
        return `list`;
    }
    return (
        <>
            <AppPageHeader
                title={props.title ?? 'Orçamento'}
                mode={props.mode}
                subtitle={props.subtitle ?? 'Cadastro de todos os orçamentos'}
                onCreateLink={getCreateLink()}
                onListLink={getListLink()}
                onBack={() => history.goBack()}
                onCreateLabel={'Adicionar Orçamento'}
            />
        </>
    );

}