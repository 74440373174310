import {SelectBase} from "./select.base";
import {PersonEntity} from "../../../domain/entity/person.entity";

export type SelectPersonProps = {
  data: PersonEntity[],
  label?: string,
  name?: string,
  required?: boolean;
  onChange?: (value: string | number) => void,
}

export function SelectPerson(props: SelectPersonProps) {
  function getOpts() {
    return props.data.map(item => ({
      label: item.name,
      value: item.id,
    }))
  }
  return (
    <SelectBase
      label={props.label ?? 'Client/Fornecedor'}
      name={props.name ?? 'personId'}
      data={getOpts()}
      onChange={props.onChange}
      required={props.required}
    />
  )
}