import React from "react";
import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {DataListRecurrenceComponent} from "../component/data-list.recurrence.component";
import {Tabs} from "antd";
import {HeaderRecurrenceEntry} from "../component/header.recurrence-entry";
import {DataListNextToParseComponent} from "../component/data-list.next-to-parse.component";

export function ListRecurrenceEntry(props: ModuleRenderProps) {
  return (
    <>
      <HeaderRecurrenceEntry />
      <Tabs>
        <Tabs.TabPane tab={'Recorrências'} key={1}>
          <DataListRecurrenceComponent navigator={props.navigator} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={'Próximos Vencimento'} key={2}>
          <DataListNextToParseComponent navigator={props.navigator} />
        </Tabs.TabPane>
      </Tabs>
    </>
  )
}
