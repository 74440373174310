import {BaseResponse, usePlaneduHttpService} from "../service/planedu.http.service";
import {ProfileEntity} from "../entity/profile.entity";

export function useProfileRepository() {
  const httpService = usePlaneduHttpService<ProfileEntity>('profile');

  async function getResources() {
    return httpService.get('profile/resource').then(
      res => res as BaseResponse<string[]>
    )
  }
  return {
    ...httpService,
    getResources,
  }
}

