import {SelectBase} from "./select.base";
import {LedgerAccountEntity} from "../../../domain/entity/ledger-account.entity";

export type SelectLedgerAccountType = {
  data: LedgerAccountEntity[],
  label?: string,
  name?: string,
  required?: boolean;
  onChange?: (value: string | number) => void,
  disabled?: boolean;
}

export function SelectLedgerAccount(props: SelectLedgerAccountType) {
  function getOpts() {
    return props.data.map(item => ({
      label: `${item.code} - ${item.name}`,
      value: item.id,
    }))
  }
  return (
    <SelectBase
      label={props.label ?? 'Conta Contábil'}
      name={props.name ?? 'ledgerAccountId'}
      data={getOpts()}
      onChange={props.onChange}
      required={props.required}
      disabled={props.disabled}
    />
  )
}