import {Col, Collapse, List, Row, Typography} from "antd";
import {FinancialEntryRenegotiationEntity} from "../../../../../domain/entity/financial-entry-renegotiation.entity";
import {useDateTimeUtil} from "../../../../../core/util/date-time.util";
import {useNumberFormatter} from "../../../../service/number-formatter.service";
import {FinancialEntryInstallmentEntity} from "../../../../../domain/entity/financial-entry-installment.entity";

export type RenegotiationHistoryProps = {
  renegotiation: FinancialEntryRenegotiationEntity[]
}
export function RenegotiationHistory(props: RenegotiationHistoryProps) {
  const { renegotiation } = props;

  const { currencyFormatter } = useNumberFormatter();
  const { convertToDatePtBr } = useDateTimeUtil();

  const getDescriptionValues = (installment: FinancialEntryInstallmentEntity) => (
    `Valor ${currencyFormatter.format(installment.value)}; ` +
    `Juros ${currencyFormatter.format(installment.interestAmount)}; ` +
    `Multa ${currencyFormatter.format(installment.fineAmount)}; ` +
    `Desconto ${currencyFormatter.format(installment.discountAmount)}`
  )

  const renderItem = (item: any) => (
    <List.Item>
      <List.Item.Meta
        title={`Parcela ${item?.installment?.order} - ${convertToDatePtBr(item?.installment?.dueDate)}`}
        description={getDescriptionValues(item.installment)} />
    </List.Item>
  )

  return (
    <Collapse>
      {
        renegotiation.map(item => (
          <Collapse.Panel key={1} header={`Renegociação ${item.id} - ${convertToDatePtBr(item.dateTime)}`}>
            <Row gutter={12}>
              <Col span={12}>
                <List
                  header={<Typography.Title level={4}>Parcelas Canceladas</Typography.Title>}
                  dataSource={item.renegotiationInstallments.filter(w => w.relation === 'canceled')}
                  renderItem={renderItem}
                />
              </Col>
              <Col span={12}>
                <List
                  header={<Typography.Title level={4}>Parcelas Geradas</Typography.Title>}
                  dataSource={item.renegotiationInstallments.filter(w => w.relation === 'generated')}
                  renderItem={renderItem}
                />
              </Col>
            </Row>
          </Collapse.Panel>
        ))
      }
    </Collapse>
  )
}