import {Button, Col, Form, Input, message, Row, Typography} from "antd";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import React, {useEffect} from "react";
import {LabelNamePerson} from "./label.name-person";
import {PersonEntity} from "../../../../domain/entity/person.entity";
import {usePersonRepository} from "../../../../domain/repository/person.repository";
import {ArrowRightOutlined} from "@ant-design/icons";
import {PersonCompanyEntity} from "../../../../domain/entity/person-company.entity";

export type FormPersonCompanyProps = {
    person: PersonEntity,
    personCompany?: PersonCompanyEntity,
    onFinish: (entity: PersonCompanyEntity | undefined) => void,
    onBack: () => void,
    hideSkipButton?: boolean,
}

export function FormPersonCompany(props: FormPersonCompanyProps) {
    const [form] = Form.useForm();

    const personRepo = usePersonRepository();

    useEffect(() => {
        const { personCompany, person } = props;

        if (personCompany) {
            form.setFields([
                { name: 'legalNature', value: personCompany.legalNature },
                { name: 'stateInscription', value: personCompany.stateInscription },
                { name: 'fantasyName', value: personCompany.fantasyName },
                { name: 'socialReason', value: personCompany.socialReason },
            ])
        } else {
            form.setFields([
                { name: 'fantasyName', value: person?.name }
            ])
        }
    }, [props.person])

    function onFinish(values: any) {
        function send() {
            return personRepo.savePersonCompany(props.person.id, values);
        }

        send().then(res => {
            message.success(res.message);
            props.onFinish(res.data);
        }).catch(err => {
            message.error(err.message);
        })
    }

    function skipStep() {
        props.onFinish(undefined);
    }

    function SkipButton() {
        if (props.hideSkipButton) {
            return null;
        }
        return (
          <Button
            onClick={skipStep}
            shape={'round'}
            icon={<ArrowRightOutlined />}
          >
              Pular
          </Button>
        )
    }

    return (
        <Form form={form} layout={'vertical'} onFinish={onFinish}>
            <Row>
                <Col span={24}>
                    <Typography.Title mark level={5}>
                        <LabelNamePerson person={props.person} />
                    </Typography.Title>
                </Col>
            </Row>
            <Form.Item name={'fantasyName'} label={'Nome Fantasia'} rules={[{ required: true }]}>
                <Input  />
            </Form.Item>
            <Form.Item name={'socialReason'} label={'Razão Social'} rules={[{ required: true }]}>
                <Input  />
            </Form.Item>
            <Row gutter={12}>
                <Col span={16}>
                    <Form.Item name={'legalNature'} label={'Natureza Jurídica'}>
                        <Input  />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name={'stateInscription'} label={'Inscrição Estadual'} rules={[{ required: true }]}>
                        <Input  />
                    </Form.Item>
                </Col>
            </Row>
            <FormActionsButtons
                label={'Salvar'}
                onCancel={() => props.onBack()}
                labelCancel={'Voltar'}
                moreAction={[<SkipButton />]}
            />
        </Form>
    )
}