import {Button, PageHeader} from "antd";
import {PlusOutlined} from '@ant-design/icons';
import {useHistory} from "react-router-dom";
import { ReactNode } from "react";

export type AppPageHeaderProps = {
    title: string,
    subtitle: string,
    onBack?: () => any,
    mode?: 'form' | 'list',
    onCreateLink?: string,
    onCreateLabel?: string,
    onListLink?: string,
    onListLabel?: string,
    extra?: ReactNode[],
    children?: ReactNode,
}

export function AppPageHeader(props: AppPageHeaderProps) {
    let { subtitle, title, mode, onBack, onCreateLink, onCreateLabel, onListLink } = props;
    const history = useHistory();
    onBack = onBack ?? (() => null);
    mode = mode ?? 'list';

    function getExtra() {
        const extra = []
        if (props.extra) {
            extra.push(...props.extra);
        }
        if(mode === 'list' && onCreateLink) {
            extra.push(getAddButton());
        }
        if(mode === 'form' && onListLink) {
            // extra.push(getListButton());
        }
        return extra;
    }

    function getAddButton() {
        onCreateLabel = onCreateLabel ?? 'Adicionar Registro';
        if (!onCreateLink) {
            return null;
        }

        return (
            <Button
                key="1"
                type={'primary'}
                shape={'round'}
                icon={<PlusOutlined />}
                onClick={() => history.push(onCreateLink ?? '')}
            >
                {onCreateLabel}
            </Button>
        )
    }

    return (
        <PageHeader
            title={title}
            subTitle={subtitle}
            onBack={onBack}
            extra={getExtra()}
            children={props.children}
        />
    )
}
