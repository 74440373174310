import React, {useEffect, useState} from "react";
import {HeaderAccount} from "../component/header.account";
import {Divider, Form, message, Switch, Input, Row, Col} from "antd";
import {CostCenterEntity} from "../../../../domain/entity/cost-center.entity";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import {useClientRepository} from "../../../../domain/repository/client.repository";
import MaskedInput from "antd-mask-input";
import {useCostCenterCrud} from "../service/cost-center.crud";
import {ErrorMessages} from "../resources/error-messages";
import {useCodeValidator} from "../service/code.validator";

export function CreateCostCenter(props: any) {
    const [form] = Form.useForm();
    const costCenterCrud = useCostCenterCrud();
    const clientRepository = useClientRepository();
    const [costCenterMask, setCostCenterMask] = useState<string>('');
    const validator = useCodeValidator();

    useEffect(() => {
        form.setFields([
            { name: 'active' , value: true } ,
            { name: 'acceptUpdate' , value: false } ,
            { name: 'costCenterOfResult' , value: true } ,
        ])
        clientRepository.getSettings()
            .then(res => setCostCenterMask(res.costCenterMask));
    }, [1])

    function onFinish(data: CostCenterEntity) {
        costCenterCrud.create(data).then(
          res => {
              form.resetFields();
              message.success(res.message)
              props.navigator.goToListPage();
          }
      ).catch(err => {
        switch (err.messageKey) {
            case ErrorMessages.DUPLICATED_COST_CENTER:
            case ErrorMessages.INVALID_COD_FORMAT:
                form.setFields([{
                    name: 'code',
                    errors: [err.message]
                }])
                break;
            default:
                message.error(err.message);
                break;
        }
      })
  }

  function checkCode(value: string) {
     if(validator.isCodeCompleted(costCenterMask, value)) {
         form.setFields([{
             name: 'acceptUpdate', value: true
         }])
     } else  {
         form.setFields([{
             name: 'acceptUpdate', value: false
         }])
     }
  }

  function getRequiredRule(message?: string) {
    return { required: true , message };
  }

    return (
        <>
            <HeaderAccount mode={'form'} />
            <Divider />
            <Form form={form} layout="vertical"  onFinish={onFinish}>
                <Row gutter={12}>
                    <Col span={12}>
                        <Form.Item name={['name']} label="Nome" rules={[getRequiredRule()]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name={'code'} label="Código" rules={[{required: true}]}>
                            {
                                costCenterMask
                                    ? <MaskedInput mask={costCenterMask} onBlur={event => checkCode(event.target.value)} />
                                    : <Input />
                            }
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name={'externalKey'} label="Chave de Integração">
                          <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={42}>
                    <Col>
                      <Form.Item label={'Lançamentos'} name={'acceptUpdate'} valuePropName={'checked'} >
                        <Switch
                          checkedChildren={'Permitir lançamentos neste Centro de Custo'}
                          unCheckedChildren={'Não Permitir lançamentos neste Centro de Custo'}
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label={'Centro de Custo de Resultado'} name={'costCenterOfResult'} valuePropName={'checked'}>
                            <Switch
                                unCheckedChildren={'Não'}
                                checkedChildren={'Sim'}
                            />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item name={'active'} valuePropName={'checked'} label={'Situação'}>
                            <Switch
                                checkedChildren={'Ativo'}
                                unCheckedChildren={'Inativo'}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <FormActionsButtons
                    onCancel={props.navigator.goToDefaultPage}
                    isLoading={costCenterCrud.loading}
                />
            </Form>
        </> 
    )
}
