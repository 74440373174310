import {Button, Divider, Drawer, List, message} from "antd";
import {FirmEntity} from "../../../../domain/entity/firm.entity";
import {CreateDocumentFirm} from "./create.document.firm";
import {useEffect, useState} from "react";
import {DocumentType} from "../../../../domain/vo/document.type";
import {useFirmRepository} from "../../../../domain/repository/firm.repository";
import {DeleteOutlined} from '@ant-design/icons'

export type ListDocumentFirmProps = {
    visible: boolean,
    onClose: () => void,
    onDocumentCreated: () => void,
    onDocumentRemoved: () => void,
    firm: FirmEntity,
}
export function ListDocumentFirm(props: ListDocumentFirmProps) {
    const { documents } = props.firm;
    const firmRepository = useFirmRepository();
    const [documentTypes, setDocumentTypes] = useState<DocumentType[]>([]);

    useEffect(() => {
        firmRepository.getDocumentTypes().then(res => setDocumentTypes(res.data));
    }, [1]);

    function parseName(name: string) {
        const type = documentTypes.find(w => w.documentName === name);
        if(type) {
            return type.documentValue;
        }
        return name;
    }

    function removeDocument(id: number) {
        firmRepository.removeDocument(id)
            .then(res => {
                message.success('Document Removido com sucesso');
                props.onDocumentRemoved();
            })
    }

    return (
        <Drawer
            title={'Dados Adicionais'}
            placement={'right'}
            closable={false}
            onClose={props.onClose}
            width={400}
            visible={props.visible}
        >
            <List
                dataSource={documents}
                renderItem={item => (
                    <List.Item key={item.id}>
                        <List.Item.Meta
                            title={parseName(item.documentName)}
                            description={item.documentValue}
                        />
                        <Button
                            onClick={key => removeDocument(item.id)}
                            size={'middle'}
                            shape={'circle'}
                            icon={<DeleteOutlined />}
                        />
                    </List.Item>
                )}
            />

            <Divider />

            <CreateDocumentFirm firmId={props.firm.id} onCreated={props.onDocumentCreated} />
        </Drawer>
    )
}