import {useNumberFormatter} from "../../../service/number-formatter.service";
import {Button, Table, Typography} from "antd";
import {ColumnsType} from "antd/es/table";
import {FinancialItemEntity} from "../../../../domain/entity/financial-item.entity";
import {DeleteOutlined} from "@ant-design/icons";
import {RecurrenceEntryItemEntity} from "../../../../domain/entity/recurrence-entry-item.entity";

export type ListRecurrenceEntryItemProps = {
  items: RecurrenceEntryItemEntity[],
  removeItem: (id: string) => void,
  loading?: boolean;
}

export function ListRecurrenceEntryItem(props: ListRecurrenceEntryItemProps) {
  const { items, removeItem } = props;
  const numberFormatter = useNumberFormatter();

  function calcAmount(row: RecurrenceEntryItemEntity) {
    const { singleValue, discount, quantity } = row;
    return singleValue * quantity - discount;
  }

  function showTotalValue(row: RecurrenceEntryItemEntity) {
    return numberFormatter.currencyFormatter.format(
      calcAmount(row)
    );
  }

  function showTotal() {
    const total = items.map(item => calcAmount(item))
      .reduce((prev, current) => prev + current, 0)
    return numberFormatter.currencyFormatter.format(total);
  }

  const columns: ColumnsType<RecurrenceEntryItemEntity> = [
    {
      title: 'Item',
      dataIndex: 'financialItem',
      key: 'financialItem',
      render: (item: FinancialItemEntity) => item?.description
    },
    {
      title: 'Valor Unitário',
      dataIndex: 'singleValue',
      key: 'singleValue',
      render: val => numberFormatter.currencyFormatter.format(val)
    },
    {
      title: 'Quantidade',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Desconto',
      dataIndex: 'discount',
      key: 'discount',
      render: val => numberFormatter.currencyFormatter.format(val)
    },
    {
      title: 'Valor Líquido',
      dataIndex: 'id',
      key: 'id',
      render: (id, row) => showTotalValue(row)
    },
    {
      dataIndex: 'id',
      key: 'id',
      render: (val: string) => (
        <Button onClick={() => removeItem(val)} size={'small'} icon={<DeleteOutlined />} />
      )
    }
  ]

  return (
    <>
      <Table
        loading={props.loading}
        columns={columns}
        dataSource={items ?? []}
        pagination={false}
        footer={() => (
          <Typography.Text
            style={{textAlign: 'right', display: 'block', fontWeight: 'bold'}}
          >
            Total: {showTotal()}
          </Typography.Text>
        )}
      />
    </>

  )
}