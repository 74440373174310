import {useRouteMatch} from "react-router-dom";
import React from 'react';
import {ModuleResource, ModuleResourceTypeEnum, ModuleRoot} from "../../../core/component/module/module.root";
import {ProfileMe} from "./pages/me";
import {CreateProfile} from "./pages/create.profile";
import {ListProfiles} from "./pages/list.profile";
import {EditProfile} from "./pages/edit.profile"

export function ProfileModule() {
  const routeMatch = useRouteMatch();
  const resources: ModuleResource[] = [
    {
      type: ModuleResourceTypeEnum.CREATE,
      render: (props) => <CreateProfile {...props} />
    },
    {
      type: ModuleResourceTypeEnum.CUSTOM,
      customPath: 'me',
      render: (props) => <ProfileMe {...props} />
    },

    {
      type: ModuleResourceTypeEnum.EDIT,
      render: (props) => <EditProfile {...props} />
    },
    {
      type: ModuleResourceTypeEnum.DEFAULT,
      render: (props) => <ListProfiles {...props} />
    },
  ]

  return (
    <ModuleRoot
      resource={routeMatch.url}
      moduleResources={resources}
    />
  )
}
