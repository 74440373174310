import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {HeaderFinancialEntry} from "../component/header.financial-entry";
import {Content} from "antd/es/layout/layout";
import {useParams} from "react-router-dom";
import {FormCancelEntryComponent} from "../component/form.cancel-entry.component";

export interface CancelFinancialEntryProps extends ModuleRenderProps {
  onSaved: () => any
}

export function CancelFinancialEntry(props: CancelFinancialEntryProps) {
  const { id: entryId } = useParams<any>();

  function onSaved() {
    props.onSaved();
    props.navigator.goToListPage();
  }

  return (
    <>
      <HeaderFinancialEntry
        title={'Cancelar Movimento ' + entryId}
        subtitle={'Ao cancelar o movimento, todas as parcelas baixadas serão estornadas.'}
        mode={'form'}
      />
      <Content style={{padding: 15}}>
        <FormCancelEntryComponent
          entryId={entryId}
          onSaved={onSaved}
        />
      </Content>
    </>
  )
}