import {BaseResponse, usePlaneduHttpService} from "../service/planedu.http.service";
import {BudgetEntity} from "../entity/budget.entity";
import {BudgetReleaseEntity} from "../entity/budget-release.entity";
import {DreRowDto} from "../dto/dre-row.dto";
import {QueryParam} from "../../core/service/http.service";

export function useBudgetRepository() {
    const httpService = usePlaneduHttpService<BudgetEntity>('budget');

    async function getNextVersion(competenceId: number) {
        return httpService.get('budget/competence/'+ competenceId + '/next-version')
    }

    async function getActiveVersions(competenceId: number): Promise<BaseResponse<BudgetEntity[]>> {
        return httpService.get(`budget/competence/${competenceId}/active-versions`)
    }


    async function releaseBudget(budgetRelease: BudgetReleaseEntity): Promise<BaseResponse<BudgetReleaseEntity>> {
        return httpService.post('budget/release', JSON.stringify(budgetRelease));
    }

    async function getReleaseBudget(budgetId: number, dreRowId: number, costCenterId: number, firmId: number)
        : Promise<BaseResponse<BudgetReleaseEntity>> {
        return httpService.get(
            `budget/release/budget/${budgetId}/dre-row/${dreRowId}/firm/${firmId}/cost-center/${costCenterId}`
        );
    }

    async function getReleasesByBudget(budgetId: number): Promise<BaseResponse<BudgetReleaseEntity[]>> {
        return httpService.get(`budget/${budgetId}/releases`)
    }

    async function getDreResult(
      budgetId: number,
      firmId?: number,
      costCenterId?: number,
      transformCSV?: boolean
    ): Promise<BaseResponse<DreRowDto[]>> {
        const queryParams: QueryParam[] = [];
        firmId && queryParams.push({ key: 'firmId', value: firmId });
        costCenterId && queryParams.push({ key: 'costCenterId', value: costCenterId });

        return httpService.get(`budget/${budgetId}/dre-report`)
    }

    return {
        ...httpService,
        getNextVersion,
        getActiveVersions,
        releaseBudget,
        getReleaseBudget,
        getReleasesByBudget,
        getDreResult
    }
}