import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import React, {useEffect, useState} from "react";
import {Button, Divider, Layout, message, Table} from "antd";
import {ListActions} from "../../../../core/component/list-actions";
import {useFinancialEntryRepository} from "../../../../domain/repository/financial-entry.repository";
import {FinancialEntryEntity} from "../../../../domain/entity/financial-entry.entity";
import {HeaderFinancialEntry} from "../component/header.financial-entry";
import {PlusOutlined} from "@ant-design/icons";
import {FirmEntity} from "../../../../domain/entity/firm.entity";
import {CostCenterEntity} from "../../../../domain/entity/cost-center.entity";
import {useDateTimeUtil} from "../../../../core/util/date-time.util";
import {useNumberFormatter} from "../../../service/number-formatter.service";
import {useDocumentFormatter} from "../../../service/document.formatter.service";
import {FilterFinancialProvision} from "../component/filter.financial-provision";
import {Moment} from "moment";

export function ListProvisionEntry(props: ModuleRenderProps) {
  const [data, setData] = useState<FinancialEntryEntity[]>([]);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState<any>();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const { convertToDatePtBr } = useDateTimeUtil();
  const { decimalFormatter } = useNumberFormatter();
  const { formatCNPJ } = useDocumentFormatter();
  const financialEntryRepo = useFinancialEntryRepository();

  function loadData() {
    financialEntryRepo.listProvision({ filter, limit, offset: (limit * (page - 1)) }).then(res => {
      setData(res.data)
      setTotal(res.total);
    });
  }

  useEffect(() => {
    loadData()
  }, [page, filter]);

  function remove(id: string) {
    financialEntryRepo.remove(id)
      .then(res => {
        message.success(res.message);
        loadData();
      }).catch(err => {
      message.error(err.message);
    })
  }

  const columns = [
    {
      title: 'Movimento',
      dataIndex: 'id' ,
      key: 'id',
    },
    {
      title: 'Filial',
      dataIndex: 'firm' ,
      key: 'firm',
      render: (value: FirmEntity) => `${value.name} - ${formatCNPJ(value.document)}`,
    },
    {
      title: 'Centro de Custo',
      dataIndex: 'costCenter' ,
      key: 'costCenter',
      render: (value: CostCenterEntity) => `${value.code} - ${value.name}`,
    },
    {
      title: 'Competência',
      dataIndex: 'dateTimeEntry' ,
      key: 'dateTimeEntry',
      render: (val: string) => convertToDatePtBr(val),
    },
    {
      title: 'Valor',
      dataIndex: 'documentGrossValue' ,
      key: 'documentGrossValue',
      render: (val: number) => decimalFormatter.format(val),
    },
    {
      dataIndex: 'id',
      render: (val: any) => (
        <>
          <ListActions
            onEditClick={() => props.navigator.goToCustomPage(`provision/${val}`) }
            onRemoveClick={() => remove(val) }
          />
        </>
      )
    }
  ]

  function filterInterval(initialDate: Moment, finalDate: Moment) {
    if (initialDate) {
      setFilter({
        period: `${initialDate.format('YYYY-MM-DD')},${finalDate.format('YYYY-MM-DD')}`
      });
    } else {
      setFilter(undefined);
    }
  }

  function filterNumMov(id: number) {
    if (id) {
      setFilter({ id })
    } else {
      setFilter(undefined);
    }
  }

  return (
    <>
      <HeaderFinancialEntry
        title={'Provisões Financeiras'}
        subtitle={'Gestão dos Lancamentos de Provisões Financeiras'}
        mode={'form'}
        extra={[
          <Button
            onClick={() => props.navigator.goToCustomPage('provision')}
            type={'primary'}
            icon={<PlusOutlined />}
            shape={'round'}
          >
            Adicionar
          </Button>
        ]}
      />
      <Divider />

      <FilterFinancialProvision
        onIntervalFilter={filterInterval}
        onNumMovFilter={filterNumMov}
      />
      <Layout.Content>
        <Table
          columns={columns}
          dataSource={data}
          loading={financialEntryRepo.loading}
          pagination={{
            onChange: setPage,
            total,
            pageSize: limit,
            showSizeChanger: false,
          }}
        />
      </Layout.Content>
    </>
  );
}