import {BaseResponse, usePlaneduHttpService} from "../service/planedu.http.service";
import {UserEntity} from "../entity/user.entity";
import {ProfileEntity} from "../entity/profile.entity";
import {CostCenterEntity} from "../entity/cost-center.entity"

export function useUserRepository() {
  const httpService = usePlaneduHttpService<UserEntity>('users');

  function getProfiles(userId: number): Promise<ProfileEntity[]> {
    return httpService.get(`users/${userId}/profiles`)
      .then(res => res.data as ProfileEntity[]);
  }

  function addProfile(userId: number, profileId: number) {
    const body = JSON.stringify({ userId, profileId });
    return httpService.post('users/profile', body)
  }

  function removeProfile(userId: number, profileId: number) {
    const body = JSON.stringify({ userId, profileId});
    return httpService.delete('users/profile', body)
  }

  function getCostCenter(userId: number): Promise<CostCenterEntity[]> {
    return httpService.get(`users/${userId}/cost-center`)
      .then(res => res.data as CostCenterEntity[]);
  }

  function getMyCostCenter(): Promise<CostCenterEntity[]> {
    return httpService.get(`users/my-cost-centers`)
      .then(res => res.data as CostCenterEntity[]);
  }

  function addCostCenter(userId: number, costCenterId: number) {
    const body = JSON.stringify({ userId, costCenterId });
    return httpService.post('users/cost-center', body)
  }

  function removeCostCenter(userId: number, costCenterId: number) {
    const body = JSON.stringify({ userId, costCenterId});
    return httpService.delete('users/cost-center', body)
  }

  async function create(entity: UserEntity) {
    const body = {
      ...entity,
      profiles: entity.profiles.map(item => item.id)
    }
    return httpService.post(`${httpService.resourceName}`, JSON.stringify(body))
      .then(res => res as BaseResponse<UserEntity>)
  }

  async function update(id: string, entity: UserEntity) {
    const body = {
      ...entity,
      profiles: entity.profiles.map(item => item.id)
    }
    return httpService.put(`${httpService.resourceName}/${id}`, JSON.stringify(body))
      .then(res => res as BaseResponse<UserEntity>)
  }

  async function importUser(param: string, type: string): Promise<BaseResponse<UserEntity>> {
    return httpService.post(
      `${httpService.resourceName}/client`,
      JSON.stringify({ param, type })
    )
  }

  return {
    ...httpService,
    create,
    update,
    addProfile,
    getProfiles,
    removeProfile,
    getCostCenter,
    addCostCenter,
    removeCostCenter,
    importUser,
    getMyCostCenter
  }
}

