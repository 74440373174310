import React, {useEffect, useState} from "react";
import {Descriptions, Divider} from "antd";
import {useClientStorage} from "../../../../core/service/client.storage";
import {useClientRepository} from "../../../../domain/repository/client.repository";
import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {HeaderClient} from "../component/header.client";
import {useDocumentFormatter} from "../../../service/document.formatter.service";
import { stringify } from "node:querystring";

export function ShowClient(props: ModuleRenderProps) {
    const clientStorage = useClientStorage();
    const clientRepository = useClientRepository();
    const documentFormat = useDocumentFormatter();

    const [costCenterMask, setCostCenterMask] =  useState("");
    const [managerialAccountMask, setManagerialAccountMask] = useState("");
    const document = clientStorage.getClient()?.document;
 

    function loadData() {
        clientRepository.getSettings()
            .then((res) => {
                setCostCenterMask(res.costCenterMask)
                setManagerialAccountMask(res.managerialAccountMask)
                }
            )
    }

    useEffect(() => {
        loadData()
    }, []);

    return (
        <>
            <HeaderClient />
            <Divider />
            <Descriptions title="Informações do Cliente" column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
                <Descriptions.Item label="Nome">{clientStorage.getClient()?.name}</Descriptions.Item>
                <Descriptions.Item label="Documento (CNPJ)">{documentFormat.formatCNPJ(document as string)}</Descriptions.Item>
                <Descriptions.Item label="Máscara de centro de custo">{costCenterMask}</Descriptions.Item>
                <Descriptions.Item label="Máscara de conta gerencial">{managerialAccountMask}</Descriptions.Item>
            </Descriptions>
        </>
    );
}
