import {Button} from "antd";
import {BorderHorizontalOutlined } from '@ant-design/icons';
import {useClientStorage} from "../../service/client.storage";
import {useHistory} from "react-router-dom";
import { useDocumentFormatter } from "../../../admin/service/document.formatter.service";

export function ClientButton() {
    const clientStorage = useClientStorage();
    const history = useHistory();
    const goToClientPage = () => history.push('/admin/client/settings')
    const documentFormatter = useDocumentFormatter();

    function getClientLabel() {
        const client = clientStorage.getClient();
        if (!client) {
            return '';
        }
        const { name, document  } = client;

        return `${name} - ${documentFormatter.formatCNPJ(document)}`;
    }

    return <Button type={'text'} icon={<BorderHorizontalOutlined />} onClick={goToClientPage}>{getClientLabel()}</Button>;
}
