import {HeaderBudget} from "../component/header.budget";
import {Affix, Col, Divider, Form, Row, Select} from "antd";
import {DreTreeView} from "../../../component/dre-tree-view";
import React, {useEffect, useState} from "react";
import {DreRowEntity} from "../../../../domain/entity/dre-row.entity";
import {useDRERowRepository} from "../../../../domain/repository/dre-row.repository";
import {useCompetenceRepository} from "../../../../domain/repository/competence.repository";
import {CompetenceEntity} from "../../../../domain/entity/competence.entity";
import {useBudgetRepository} from "../../../../domain/repository/budget.repository";
import {BudgetEntity} from "../../../../domain/entity/budget.entity";
import {FormRelease} from "../component/form.release";

export function ReleaseBudget(props: any) {
    const [competenceSelected, setCompetence] = useState<number>();
    const [budgetVersion, setBudgetVersion] = useState<number>();
    const [dreRows, setDreRows] = useState<DreRowEntity[]>([]);
    const [budgetVersions, setBudgetVersions] = useState<BudgetEntity[]>([]);
    const [rowSelected, setRowSelected] = useState<DreRowEntity>();
    const [competencies, setCompetencies] = useState<CompetenceEntity[]>([]);

    const competenceRepo = useCompetenceRepository();
    const dreRowRepo = useDRERowRepository();
    const budgetRepo = useBudgetRepository();

    useEffect(() => {
        dreRowRepo.search().then(res => setDreRows(res.data));
        competenceRepo.getActiveDatesCompetencies().then(res => setCompetencies(res.data))
    }, []);

    useEffect(() => {
        if(competenceSelected) {
            budgetRepo.getActiveVersions(competenceSelected).then(res => setBudgetVersions(res.data));
        }
    }, [competenceSelected])

    function getCompetencesOpts() {
        return competencies.map(item => ({
            label: item.year.toString(),
            value: item.id
        }))
    }

    function getBudgetVersionsOpts() {
        return budgetVersions.map(item => ({
            label:  `${item.version}`,
            value: item.id as number
        }))
    }

    function selectRow(row: DreRowEntity) {
        if (row.isRowResult) {
            setRowSelected(undefined);
        } else {
            setRowSelected(row);
        }

    }

    return (
        <>
            <HeaderBudget title={'Lançar Orçamento'} hideCreateAction />
            <Divider />
            <Row gutter={12}>
                <Col span={12}>
                    <Form.Item name={'competenceId'} label={'Competência'} rules={[{ required: true }]}>
                        <Select
                            size={'middle'}
                            onSelect={val => setCompetence(val as number)}
                            options={getCompetencesOpts()}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name={'budgetId'} label={'Versão'} rules={[{ required: true }]}>
                        <Select
                            size={'middle'}
                            onSelect={val => setBudgetVersion(val as number)}
                            options={getBudgetVersionsOpts()}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Divider />
            <Row gutter={12}>
                <Col span={12}>
                    {
                        competenceSelected && budgetVersion && (
                            <DreTreeView
                                onSelect={selectRow}
                                rows={dreRows}
                            />
                        )
                    }
                </Col>
                <Col span={12}>
                    {
                        rowSelected && (
                            <Affix offsetTop={10}>
                                <FormRelease
                                    budget={budgetVersions.find(item => item.id === budgetVersion) as BudgetEntity}
                                    dreRow={rowSelected}
                                    competenceId={competenceSelected as number}
                                />
                            </Affix>
                        )
                    }
                </Col>
            </Row>
        </>
    )
}

