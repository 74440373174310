import {FinancialEntryEntity} from "../../../../../domain/entity/financial-entry.entity";
import {useNumberFormatter} from "../../../../service/number-formatter.service";
import {Button, Descriptions, Modal, Popover, Table, Tag} from "antd";
import {useDateTimeUtil} from "../../../../../core/util/date-time.util";
import {ColumnsType} from "antd/es/table/Table";
import {FinancialEntryInstallmentEntity} from "../../../../../domain/entity/financial-entry-installment.entity";
import React, {CSSProperties, useMemo, useState} from "react";
import {getSorter} from "../../../../../core/util/sort-array.util";
import {ListActions} from "../../../../../core/component/list-actions";
import {FormCancelInstallmentComponent} from "../../form.cancel-installment.component";

export function InstallmentsEntry(props: { entry: FinancialEntryEntity, onInstallmentCanceled: () => any }) {
  const [installmentToCancel, setInstallmentToCancel] = useState<FinancialEntryInstallmentEntity>();
  const { currencyFormatter } = useNumberFormatter();
  const { convertToDatePtBr } = useDateTimeUtil();

  const showPaymentMethod = (row: FinancialEntryInstallmentEntity) => {
    if (row.writeOffBankAccount) {
      return `${row.writeOffBankAccount?.alias} - ${row?.paymentMethod?.description}`
    }
    return row?.paymentMethod?.description;
  }

  const installments = useMemo<FinancialEntryInstallmentEntity[]>(() => {
    if (!props.entry?.installments?.length) {
      return [];
    }
    return props.entry.installments
      .sort(getSorter<FinancialEntryInstallmentEntity>('order'));
  }, [props.entry])

  const infoInstallment = (installment: FinancialEntryInstallmentEntity) => (
    <div style={{width: 300}}>
      <Descriptions bordered size={'middle'} layout={'horizontal'}>
        <Descriptions.Item label={'Ativa'} span={24}>
          {installment.canceled ? 'Não' : 'Sim'}
        </Descriptions.Item>
        <Descriptions.Item label={'Renegociada'} span={24}>
          {installment.renegotiated ? 'Sim' : 'Não'}
        </Descriptions.Item>
        <Descriptions.Item label={'Baixada'} span={24}>
          {installment.paid ? 'Sim' : 'Não'}
        </Descriptions.Item>
      </Descriptions>
    </div>

  )

  const columns: ColumnsType<any> = [
    {
      title: 'Parcela',
      key: 'order',
      dataIndex: 'order',
      render: (value, row) => (
        <>
          <Popover content={() => infoInstallment(row)}>
            <Button shape={'circle'} size={'small'}>
              {value}
            </Button>
          </Popover>
        </>
      )
    },
    {
      title: 'Conta / Forma Pagamento',
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
      render: (value, row: FinancialEntryInstallmentEntity) => showPaymentMethod(row)
    },
    {
      title: 'Vencimento',
      dataIndex: 'dueDate',
      key: 'duaDate',
      render: (value) => convertToDatePtBr(value)
    },
    {
      title: 'Valor',
      dataIndex: 'value',
      key: 'value',
      render: (value) => currencyFormatter.format(value)
    },
    {
      title: 'Desconto',
      dataIndex: 'discountAmount',
      key: 'discountAmount',
      render: (value) => currencyFormatter.format(value)
    },
    {
      title: 'Multa',
      dataIndex: 'fineAmount',
      key: 'finaAmount',
      render: (value) => currencyFormatter.format(value)
    },
    {
      title: 'Juros',
      dataIndex: 'interestAmount',
      key: 'interestAmount',
      render: (value) => currencyFormatter.format(value),
    },
    {
      title: 'Pago',
      dataIndex: 'paid',
      key: 'paid',
      render: (val) => val ? <Tag color={'success'}>Sim</Tag> : <Tag color={'warning'}>Não</Tag>
    },
    {
      title: 'Data de Baixa',
      dataIndex: 'writeOffDate',
      key: 'paid',
      render: (value) => value ? convertToDatePtBr(value) : value
    },
    {
      dataIndex: 'id',
      render: (val: any, row) => (
        <>
          <ListActions
            options={[
              { name: 'CANCELAR BAIXA' , onClick: () => setInstallmentToCancel(row)},
            ]}
          />
        </>
      )
    }
  ]

  return (
    <>
      <Table
        onRow={(record, rowIdx) => {
          return {
            style: record.canceled ? rowCanceledStyle : {},
          }
        }}
        pagination={false}
        columns={columns}
        dataSource={installments}
      />
      {
        installmentToCancel && (
          <Modal
            title={`Cancelar Baixa - Parcela ${installmentToCancel.order} / Lançamento ${installmentToCancel.financialEntryId}`}
            onCancel={() => setInstallmentToCancel(undefined)}
            visible={true}
            width={550}
            footer={null}
          >
            <FormCancelInstallmentComponent
              onCancel={() => setInstallmentToCancel(undefined)}
              onFinish={() => {
                setInstallmentToCancel(undefined);
                props.onInstallmentCanceled();
              }}
              installment={installmentToCancel}
            />
          </Modal>
        )
      }
    </>
  )
}
const rowCanceledStyle: CSSProperties = {
  textDecoration: 'line-through',
  textDecorationColor: 'red'
}