import {Request, useHttpService} from "../../core/service/http.service";
import {useTokenStorage} from "../../core/service/token.storage";
import {useAuthService} from "../../admin/service/auth.service";
import {useMessageTranslateService} from "../../core/service/message.tranlate";
import {useState} from "react";
import {Observable} from "rxjs";

export function usePlaneduHttpService<TBase>(resourceName: string) {
  const [waitRefresh, setWaitRefresh] = useState(false);
  const tokenStorage = useTokenStorage();
  const translateMessage = useMessageTranslateService();
  const authService = useAuthService();
  const httpService = useHttpService({
    server: process.env.REACT_APP_SERVER_HOST as string,
    onRequestError: onRequestError,
    onResponse: onResponse,
    onRequestHeader: onRequestHeader,
  });

  function onRequestHeader() {
    return {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${tokenStorage.getBearerToken()}`
    }
  }

  function onRequestError(req: Request, res: any, err: null) {
    if(res.status === 422) {
      // if (waitRefresh) {
      //   return new Promise((resolve, reject) => {
      //     return new Observable(subs => {
      //       while(waitRefresh);
      //       subs.complete();
      //     }).subscribe({
      //       complete: () => {
      //         return httpService
      //           .request(req.method, req.uri, req.body)
      //           .then(res => resolve(res))
      //           .catch(err => reject(err));
      //       }
      //     })
      //   })
      // }
      return refreshToken()
        .then(res => httpService.request(req.method, req.uri, req.body))
    }

    if(res.status === 401) {
      authService.logout();
    }

    return Promise.reject({
      ...res,
      messageKey: res?.message,
      message: res?.message
        ? translateMessage.translate(res.message)
        : 'Erro ao processar requisição'
    })
  }

  async function onResponse(req: Request, res: any, err: null): Promise<BaseResponse<TBase>> {
    return {
      ...res,
      message: translateMessage.translate(res.message)
    }
  }

  function refreshToken() {
    setWaitRefresh(true);
    const body = JSON.stringify({ refreshToken: tokenStorage.getRefreshToken() });
    return httpService.post('auth/refresh', body)
      .then(res => {
        tokenStorage.setBearerToken(res.bearerToken as string);
        tokenStorage.setRefreshToken(res.refreshToken as string);
      }).catch(err => {
        console.log('refresh error', err);
        authService.logout();
      }).finally(() => setWaitRefresh(false))
  }

  async function search(args?: SearchArgs) {
    const queryParams = getUriWithSearchArgs(args ?? {});
    return httpService.get(`${resourceName}?${queryParams}`,undefined)
      .then(res => res as BaseSearchResponse<TBase>);
  }

  async function findAll() {
    return httpService.get(resourceName,undefined)
      .then(res => res as BaseSearchResponse<TBase>);
  }

  async function find(id: string): Promise<BaseResponse<TBase>> {
    return httpService.get(`${resourceName}/${id}`)
      .then(res => res as BaseResponse<TBase>)
  }

  async function create(entity: TBase) {
    return httpService.post(`${resourceName}`, JSON.stringify(entity))
      .then(res => res as BaseResponse<TBase>)
  }

  async function update(id: string, entity: TBase) {
    return httpService.put(`${resourceName}/${id}`, JSON.stringify(entity))
      .then(res => res as BaseResponse<TBase>)
  }

  async function reset(id: string, entity: TBase) {
    return httpService.post(`${resourceName}/${id}`, JSON.stringify(entity))
      .then(res => res as BaseResponse<TBase>)
  }

  async function remove(id: string) {
    return httpService.delete(`${resourceName}/${id}`)
      .then(res => res as BaseResponse<TBase>)
  }

  function getDefaultSearchArgs() : SearchArgs {
    return {
      limit: 10,
      offset: 0,
      orderByDirection: 'DESC',
      orderByField: 'id'
    }
  }

  function getUriWithSearchArgs(args: SearchArgs) {
    const { filter, ..._args } = args;
    args = {
      ...getDefaultSearchArgs(),
      ..._args
    };
    let uri = '';
    for (const key of Object.keys(args)) {
      if (key === '') { continue; }
      uri += `${key}=${args[key as keyof SearchArgs]}&`
    }
    for (const key of Object.keys(filter ?? {})) {
      if (key === '') { continue; }
      uri += `${key}=${filter[key]}&`
    }
    return uri;
  }

  return {
    ...httpService,
    search,
    find,
    create,
    update,
    remove,
    reset,
    findAll,
    resourceName,
    getUriWithSearchArgs,
    getDefaultSearchArgs
  }
}

export type BaseSearchResponse<T> = {
  data: T[],
  filter: any,
  message: string,
  offset: number,
  limit: number,
  orderByDirection: string,
  orderByField: string,
  total: number
}

export type BaseResponse<T> = {
  data: T,
  message: string
}

export type RefreshTokenResponse = {
  bearerToken: string;
  refreshToken: string;
}

export type SearchArgs = {
  limit?: number,
  offset?: number,
  orderByField?: string,
  orderByDirection?: string,
  filter?: any
}