import React, {useEffect, useState} from "react";
import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {Button, Divider, Layout, Table} from "antd";
import {HeaderFirm} from "../../firm/component/header.firm";
import {useIntegrationRepository} from "../../../../domain/repository/integration.repository";
import {IntegrationEntity} from "../../../../domain/entity/integration.entity";
import ButtonGroup from "antd/es/button/button-group";
import {ColumnsType} from "antd/lib/table";
import {EditOutlined, ImportOutlined} from "@ant-design/icons";
import {useHistory} from "react-router-dom";
import {HeaderIntegration} from "../component/header-integration";

export function ListIntegration(props: ModuleRenderProps) {
  const [data, setData] = useState<IntegrationEntity[]>([]);

  const history = useHistory();
  const integrationRepo = useIntegrationRepository();

  function loadData() {
    integrationRepo.findAll().then(res => {
      setData(res.data);
    });
  }

  useEffect(() => {
    loadData()
  }, [1]);

  const columns: ColumnsType<IntegrationEntity> = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Alias',
      dataIndex: 'alias',
      key: 'alias',
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'actions',
      align: 'right',
      render: (id: string) => (
        <ButtonGroup size={'small'}>
          <Button onClick={() => props.navigator.goToCustomPage(`config/${id}`)} icon={<EditOutlined />}>Configurar</Button>
          <Button onClick={() => props.navigator.goToCustomPage(`import/${id}`)} icon={<ImportOutlined />}>Importar</Button>
        </ButtonGroup>
      )
    },
  ]

  return (
    <>
      <HeaderIntegration mode={'form'}/>
      <Divider />
      <Layout.Content>
        <Table
          columns={columns}
          dataSource={data}
          loading={integrationRepo.loading}
        />
      </Layout.Content>
    </>
  );
}
