export type State = {
    name: string,
    sg: string,
}

export class StatesEnum {
    static STATES: State[] = [
        { name: 'Acre', sg: 'AC'},
        { name: 'Alagoas', sg: 'AL'},
        { name: 'Amapá', sg: 'AP'},
        { name: 'Amazonas', sg: 'AM'},
        { name: 'Bahia', sg: 'BA'},
        { name: 'Ceará', sg: 'CE'},
        { name: 'Distrito Federal', sg: 'DF'},
        { name: 'Espirito Santo', sg: 'ES'},
        { name: 'Goiás', sg: 'GO'},
        { name: 'Maranhão', sg: 'MA'},
        { name: 'Mato Grosso', sg: 'MT'},
        { name: 'Mato Grosso do Sul', sg: 'MS'},
        { name: 'Minas Gerais', sg: 'MG'},
        { name: 'Pará', sg: 'PA'},
        { name: 'Paraíba', sg: 'PB'},
        { name: 'Paraná', sg: 'PR'},
        { name: 'Pernambuco', sg: 'PE'},
        { name: 'Piauí', sg: 'PI'},
        { name: 'Rio de Janeiro', sg: 'RJ'},
        { name: 'Rio Grande do Sul', sg: 'RS'},
        { name: 'Rio Grande do Norte', sg: 'RN'},
        { name: 'Rondônia', sg: 'RO'},
        { name: 'Roraima', sg: 'RR'},
        { name: 'Santa Catarina', sg: 'SC'},
        { name: 'São Paulo', sg: 'SP'},
        { name: 'Sergipe', sg: 'SE'},
        { name: 'Tocatings', sg: 'TO'},
    ]
}
