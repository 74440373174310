import {Form, Switch, Table} from "antd";
import React from "react";
import {DisabledResource, DisabledResourceAction} from "../../../../domain/entity/profile.entity";
import {useMessageTranslateService} from "../../../../core/service/message.tranlate";

export type ProfileFormTableProps = {
  disabledResources: DisabledResource[],
  setDisabledResources: (values: DisabledResource[]) => void,
  resources: string[]
}
export function ProfileFormTable (props: ProfileFormTableProps) {
  const { setDisabledResources, disabledResources, resources } = props;
  const { translate } = useMessageTranslateService();

  function removeDisabledResourceAction(resource: string, action: DisabledResourceAction) {
    const filter: DisabledResource[] = [];
    for (let disabledResource of disabledResources) {
      if(disabledResource.resource === resource) {
        if(disabledResource.actions.includes(action)) {
          disabledResource.actions = disabledResource
            .actions
            .filter(item => item !== action);
        }
        if(disabledResource.actions.length > 0) {
          filter.push(disabledResource);
        }
      } else {
        filter.push(disabledResource);
      }
    }

    setDisabledResources(filter);
  }

  function addDisabledResource(resource: string, action: DisabledResourceAction) {
    const filter: DisabledResource[] = [];
    let hasResource = false;
    for (let disabledResource of disabledResources) {
      if (disabledResource.resource === resource) {
        hasResource = true;
        if (disabledResource.actions.includes(action)) {
          continue;
        } else {
          disabledResource.actions.push(action);
        }
      }
    }
    if(!hasResource) {
      filter.push({ resource, actions: [action] });
    }
    setDisabledResources([
      ...disabledResources,
      ...filter
    ])
  }

  function getValue(resource: string, action: DisabledResourceAction) {
    const res = disabledResources
      .find(item => item.resource === resource && item.actions.includes(action));
    return res !== undefined;

  }

  function checkBoxOnChange(checked: boolean, resource: string, action: DisabledResourceAction) {
    if(checked) {
      addDisabledResource(resource, action);
    } else {
      removeDisabledResourceAction(resource, action);
    }
  }

  return (
    <Table
      columns={[
        {
          title: 'Recurso',
          dataIndex: 'resource',
          key: 'resource',
          render: val => translate(`resource:${val}`)
        },
        {
          title: 'Escrita',
          key: 'action-write',
          dataIndex: 'resource',
          render: (val: string) => (
            <Form.Item>
              <Switch
                size={'default'}
                onChange={(checked) => checkBoxOnChange(
                  checked,
                  val,
                  'write'
                )}
                checked={getValue(val, 'write')}
              />
            </Form.Item>
          )
        },
        {
          title: 'Leitura',
          key: 'resource',
          dataIndex: 'resource',
          render: (val: string) => (
            <Form.Item>
              <Switch
                size={'default'}
                onChange={(checked) => checkBoxOnChange(
                  checked,
                  val,
                  'read'
                )}
                checked={getValue(val, 'read')}
              />
            </Form.Item>
          )

        },
      ]}
      dataSource={resources.map(item => ({resource: item}))}
    />

  )
}
