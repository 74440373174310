import {BaseResponse, usePlaneduHttpService} from "../service/planedu.http.service";
import {CompetenceEntity} from "../entity/competence.entity";

export function useCompetenceRepository() {
    const httpService = usePlaneduHttpService<CompetenceEntity>('competence');

    function getActiveDatesCompetencies(): Promise<BaseResponse<CompetenceEntity[]>> {
        return httpService.get('competence/active')
    }
    function getCurrentCompetence(): Promise<BaseResponse<CompetenceEntity>> {
        return httpService.get('competence/current')
    }

    return {
        ...httpService,
        getActiveDatesCompetencies,
        getCurrentCompetence
    }
}