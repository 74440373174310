import {BaseResponse, usePlaneduHttpService} from "../service/planedu.http.service";
import {ManagerialAccountEntity} from "../entity/managerial-account.entity";

export function useManagerialRepository() {
    const httpService = usePlaneduHttpService<ManagerialAccountEntity>('managerial-account');

    function listAnalyticalAccounts(): Promise<BaseResponse<ManagerialAccountEntity[]>> {
        return httpService.get(`${httpService.resourceName}/analytical`);
    }

    function downloadListMA() {
        return httpService.downloadRequest(
          'get',
          `${httpService.resourceName}/export`,
          `contas-gerenciais.xlsx`,
        )
    }

    return {
        ...httpService,
        listAnalyticalAccounts,
        downloadListMA
    }
}