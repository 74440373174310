import {Select,DatePicker, Divider, Form, message, Col, Row, Input, Checkbox} from "antd";
import ptBr from "antd/lib/locale/pt_BR";
import React, {useEffect, useState} from "react";
import {HeaderBudget} from "../component/header.budget";
import {BudgetEntity} from "../../../../domain/entity/budget.entity";
import {useBudgetRepository} from "../../../../domain/repository/budget.repository";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {useCompetenceRepository} from "../../../../domain/repository/competence.repository";
import {CompetenceEntity} from "../../../../domain/entity/competence.entity";
import {MonthsEnum} from "../../../../domain/vo/months.enum";

  const validateMessages = {
    required: '${label} é um dado obrigatório!',
    types: {
      email: '${label} não é um email valido!',
    },
  };

export function CreateBudget(props: ModuleRenderProps) {
    const [form] = Form.useForm();
    const budgetService = useBudgetRepository();

    const [version, setVersion] = useState<number>();
    const [competences, setCompetence] = useState<CompetenceEntity[]>([]);
    const competenceRepository = useCompetenceRepository();
    const [months, setMonths] = useState<Array<number>>([]);

    function mapCompetences() {
        return competences.map(item => ({
            label: item.year.toString(), value: item.id as number
        }))
    }

    function nextVersion(competenceId: any) {
        budgetService.getNextVersion(competenceId)
            .then(response => {
                setVersion(response.data.nextVersion)
            })
    }

    useEffect(() => {
        form.setFields([{
            name: 'months', value: months
        }])
    },[months])

    useEffect(() => {
        competenceRepository.search()
            .then(res => {
                setCompetence(res.data)
            });
    }, [1]);

    function onFinish(data: any) {
        console.log(data);

        const budgetEntity: BudgetEntity = {
            competenceId: data.competenceId,
            version: version as number,
            months: months,
            finalDateRevision: data.finalDateRevision.format(),
            competence: data.competence,
            initialDateEdit: data.initialDateEdit.format(),
            finalDateEdit: data.finalDateEdit.format()
        }

        if (months.length === 0) {
            message.error("Selecione o(s) mes(es)");
            return;
        }

        budgetService.create(budgetEntity).then(res => {
            form.resetFields();
            message.success(res.message)
            props.navigator.goToListPage();
        }).catch(err => {
            message.error(err.message);
            if(err.messageKey === 'budget:duplicated_version_error') {
                form.setFields([{
                    name: 'code',
                    errors: [err.message]
                }])
            }
        })
  }


  function getRequiredRule(message?: string) {
    return { required: true , message };
  }

  function onChange(values: number[]) {
    const _months = [];
    if (values.length === 0) {
        return
    }
    const lastValue = values.reduce((a, b) => a < b ? a : b);
    for (let i = 12; i >= lastValue; i--) {
        _months.push(i);
    }
    setMonths(_months)
  }

    return (
        <>
            <HeaderBudget mode={'form'} />
            <Divider />
            <Form form={form} layout="vertical" name="nest-messages" onFinish={onFinish} validateMessages={validateMessages}>
                <Row gutter={12}>
                    <Col span={12}>
                        <Form.Item name={['competenceId']} label="Competência" rules={[getRequiredRule()]}>
                            <Select onChange={nextVersion} options={mapCompetences()} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={['version']} label="Versão" >
                            <Input style={{width: '100%'}} readOnly={true} prefix={version} value={version}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name={'months'} label="Meses" >
                            <Checkbox.Group
                                options={MonthsEnum.getValues()}    
                                style={{ width: '100%' }} 
                                onChange={_months => onChange(_months as number[])} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Data Inicial da Edição"
                            name={'initialDateEdit'}
                            style={{width: '100%'}}
                            rules={[{ required: true }]}
                        >
                            <DatePicker format='DD/MM/YYYY' style={{width: '100%'}} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Data Final da Edição"
                            name={'finalDateEdit'}
                            style={{width: '100%'}} rules={[{ required: true }]}
                        >
                            <DatePicker format='DD/MM/YYYY' style={{width: '100%'}} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Data Final de Revisão"
                            name={'finalDateRevision'}
                            style={{width: '100%'}}
                            rules={[{ required: true }]}
                        >
                            <DatePicker format='DD/MM/YYYY' style={{width: '100%'}} />
                        </Form.Item>
                    </Col>
                </Row>
                <FormActionsButtons
                    onCancel={props.navigator.goToDefaultPage}
                    isLoading={budgetService.loading}
                />
            </Form>
    
            
        </> 
    )
}
