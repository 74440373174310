import {Divider, message, Table, Tag} from "antd";
import React, {useEffect, useState} from "react";
import {HeaderAccount} from "../component/header.account";
import {useCostCenterRepository} from "../../../../domain/repository/account.repository";
import {CostCenterEntity} from "../../../../domain/entity/cost-center.entity";
import {ListActions} from "../../../../core/component/list-actions";
import {ListFilialModal} from "./list.filial.modal";
import {FilterCostCenter} from "../component/filter.cost-center";
import {ExportExcelButton} from "../../../../core/component/export-button";

export function ListCostCenter(props: any) {
    const costCenterRepo = useCostCenterRepository();
    const [filialModalVisible, setFilialModalVisible] = useState(false);
    const [filialModalCCId, setFilialModalCCId] = useState<number>();
    const [data, setData] = useState<CostCenterEntity[]>([]);
    const [filteredData, setFilteredData] = useState<CostCenterEntity[]>([]);

    function loadData() {
        costCenterRepo.search()
            .then(res => {
                setData(res.data)
                setFilteredData(res.data);
            });
    }

    function removeCostCenter(id: string) {
        costCenterRepo.remove(id)
            .then(res => {
                message.success(res.message);
                loadData();
            }).catch(err => {
                message.error(err.message);
        })
    }

    function onFilialClick(id: number) {
        setFilialModalCCId(id);
        setFilialModalVisible(true);
    }

    function closeFilialModal() {
        setFilialModalVisible(false);
        setFilialModalCCId(undefined);
    }

    useEffect(() => loadData(), []);

    function renderStatus(active: boolean) {
        return <Tag color={active ? 'green' : 'red'}>{active ? 'Ativo' : 'Inativo' }</Tag>
    }

    function renderCanUpdate(val: boolean) {
        return (
            <Tag color={val ? 'cyan' : 'red'}>
                {val ? 'Sim' : 'Não'}
            </Tag>
        )
    }

    function renderResultCC(val: boolean) {
        return (
            <Tag color={val ? 'cyan' : 'red'}>
                {val ? 'Sim' : 'Não'}
            </Tag>
        )
    }

    function onFilterCode(filter: string) {
        let temp: CostCenterEntity[] = [];
        for(let item of data) {
            if (item.code.includes(filter)) {
                temp.push(item);
            }
        }

        setFilteredData(temp);
    }

    function onFilterName(filter: string) {
        let temp: CostCenterEntity[] = [];
        console.log(filter)
        for(let item of data) {
            if (item.name.toLowerCase().includes(filter.toLowerCase())) {
                temp.push(item);
            }
        }

        setFilteredData(temp);
    }

    return (
        <>
            <HeaderAccount
              extra={[<ExportExcelButton onClick={() => costCenterRepo.downloadListCostCenter()} />]}
            />
            <Divider />
            <FilterCostCenter onFilterCode={onFilterCode} onFilterDesc={onFilterName} />
            <Table
                columns={[
                    {
                        title: 'Nome',
                        dataIndex: 'name',
                    },
                    {
                        title: 'Código',
                        dataIndex: 'code',
                    },
                    {
                        title: 'Permite Lançamentos',
                        dataIndex: 'acceptUpdate',
                        align: 'center',
                        render: (val) => renderCanUpdate(val)
                    },
                    {
                        title: 'Centro de Custo de Resultados',
                        dataIndex: 'costCenterOfResult',
                        align: 'center',
                        render: (val) => renderResultCC(val)
                    },
                    {
                        title: 'Ativo',
                        dataIndex: 'active',
                        align: 'center',
                        render: (val) => renderStatus(val)
                    },
                    {
                        dataIndex: 'id',
                        render: (val) => (
                            <ListActions
                                onEditClick={() => props.navigator.goToEditPage(val) }
                                onRemoveClick={() => removeCostCenter(val) }
                                options={[
                                    {
                                        name: 'FILIAIS',
                                        onClick: () => onFilialClick(val)
                                    }
                                ]}
                            />
                        )
                    },
                ]}
                dataSource={filteredData}
            />
            {
                filialModalVisible
                && filialModalVisible
                && <ListFilialModal
                        onClose={closeFilialModal}
                        visible={filialModalVisible}
                        costCenterId={filialModalCCId as number}
                    />
            }

        </>
    )
}
