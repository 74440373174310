import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {useParams} from "react-router-dom";
import {FormApportionmentEntryComponent} from "../component/form.apportionment-entry.component";
import {HeaderFinancialEntry} from "../component/header.financial-entry";
import {Content} from "antd/es/layout/layout";
import {Divider} from "antd";
import {DetailsEntry} from "../component/details.entry";
import {useEffect, useState} from "react";
import {FinancialEntryEntity} from "../../../../domain/entity/financial-entry.entity";
import {useFinancialEntryRepository} from "../../../../domain/repository/financial-entry.repository";

export function SaveApportionment(props: ModuleRenderProps) {
  const [entry, setEntry] = useState<FinancialEntryEntity>()
  const { id: entryId } = useParams<any>();

  const financialEntryRepo = useFinancialEntryRepository();

  useEffect(() => {
    financialEntryRepo.find(entryId).then(res => setEntry(res.data))
  }, [entryId]);

  return (
    <>
      <HeaderFinancialEntry
        title={`Rateio do Movimento ${entryId}`}
        subtitle={'Salvar rateio do movimento entre centros de custo'}
        mode={'form'}
      />
      <Divider />
      <Content>
        {
          entry && (
            <Content>
              <DetailsEntry entry={entry} />
              <Divider />
              <FormApportionmentEntryComponent
                entry={entry}
                onFinish={() => props.navigator.goToListPage()}
              />
            </Content>
          )
        }

      </Content>
    </>
  )
}