import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import React, {useCallback, useEffect, useState} from "react";
import {IntegrationEntity} from "../../../../domain/entity/integration.entity";
import {useParams} from "react-router-dom";
import {useIntegrationRepository} from "../../../../domain/repository/integration.repository";
import {HeaderIntegration} from "../component/header-integration";
import {Button, Divider, Dropdown, Form, Input, List, Menu, message, Modal, Table, Tag} from "antd";
import {IntegrationBatchEntity, IntegrationBatchEntityError} from "../../../../domain/entity/integration-batch.entity";
import {ColumnsType} from "antd/es/table/Table";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import {useDateTimeUtil} from "../../../../core/util/date-time.util";
import Paragraph from "antd/es/typography/Paragraph";
import {useMessageTranslateService} from "../../../../core/service/message.tranlate";
import {MoreOutlined} from "@ant-design/icons";
import {ListBatchEntriesComponent} from "../component/list.batch-entries.component";
import {CSVLink} from "react-csv";
import {ExportableButtonComponent} from "../../../../core/component/exportable-button";

export function ImportIntegration(props: ModuleRenderProps) {
  const [form] = Form.useForm();
  const [total, setTotal] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [limit, setLimit] = useState<number>(10)
  const [integration, setIntegration] = useState<IntegrationEntity>();
  const [currentBatchId, setCurrentBatchId] = useState<number>();
  const [batches, setBatches] = useState<IntegrationBatchEntity[]>([]);
  const [currentFile, setCurrentFile] = useState<File>();
  const [errors, setErrors] = useState<IntegrationBatchEntityError[]>([]);
  const { id: integrationId } = useParams<any>();

  const { convertToDateTimePtBr } = useDateTimeUtil();
  const integrationRepo = useIntegrationRepository();
  const { translate } = useMessageTranslateService();

  function loadData() {
    integrationRepo.listBatches({
      integrationId, limit, offset: (page > 0 ? page - 1 : 0) * limit,
    }).then(res => {
      setBatches(res.data)
      setTotal(res.total)
    })
      .catch(err => message.error(err?.message))
  }

  useEffect(() => {
    integrationId && integrationRepo.find(integrationId).then(res => setIntegration(res.data))
    integrationId && loadData();
  }, [integrationId, page, limit])

  useEffect(() => {
    const timer = setInterval(() => loadData(), 60000);
    return () => clearInterval(timer);
  }, [])

  function renderStatus(status: string) {
    switch (status) {
      case 'pending':
        return <Tag color={'orange'}>Pendente</Tag>;
      case 'in_progress':
        return <Tag color={'cyan'}>Em Processamento</Tag>;
      case 'finished':
        return <Tag color={'green'}>Finalizado</Tag>;
      default:
        return <Tag>{status}</Tag>;
    }
  }

  const Options = (props: {batchId: React.Key}) => (
    <Menu>
      <Menu.Item key={1} onClick={() => setCurrentBatchId(props.batchId as number)} >
        LANÇAMENTOS
      </Menu.Item>
      <Menu.Item key={2} >
        ERROS
      </Menu.Item>
    </Menu>

  )

  const columns: ColumnsType<any> = [
    {
      title: 'Data Criacao',
      dataIndex: 'createdAt',
      render: value => convertToDateTimePtBr(value)
    },
    {
      title: 'Nome do Arquivo',
      dataIndex: 'fileName',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: renderStatus
    },
    {
      title: 'Linhas Processadas',
      dataIndex: 'totalRows',
    },
    {
      title: '',
      dataIndex: 'id',
      render: (id: number, row: IntegrationBatchEntity) => (
        <Button size={'small'} onClick={() => findErrors(id)} >{row.totalErrorRows} Erros</Button>
      )
    },
    {
      title: '',
      dataIndex: 'id',
      render: (id: number, row: IntegrationBatchEntity) => (
        <div style={{ textAlign: 'right'}}>
          <Dropdown overlay={<Options batchId={id}  />} placement={'bottomLeft'} >
            <MoreOutlined style={{cursor: 'pointer'}} />
          </Dropdown>
        </div>
      )
    }
  ]

  function onFinish() {
    integrationRepo.importFile(integrationId, currentFile).then(res => {
      form.resetFields();
      message.success(res.message);
      loadData();
    }).catch(err => {
      message.error(err.message);
    });
  }

  function checkFile(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      setCurrentFile(file);
    }
    if (file.size > (250 * 1024 * 1024)) {
      alert('O arquivo excede o tamanho máximo de 250MB');
      form.resetFields();
      return;
    }
    file.text().then(content => {
      if (content.split('\n').length < 2) {
        form.setFields([{
          name: 'file', errors: ['Formato do arquivo selecionado não é csv'], value: null
        }])
      }
    })
  }

  const findErrors = useCallback<any>((batchId: number) => {
    integrationRepo.listBatchErrors(batchId)
      .then(res => setErrors(res.data ?? []))
  }, [integrationRepo])


  function ModalErrors() {
    if (!errors.length) {
      return null;
    }
    const errorsToExport = errors.map(w => ({
      ERRO: w.error,
      LINHA: w.rowNumber,
      ...JSON.parse(w.row),
    }))

    return (
      <Modal
        visible={true}
        footer={null}
        title={'Erros de Importação'}
        onCancel={() => setErrors([])}>
        <>
          <List
            footer={
              <div style={{textAlign: 'right'}}>
                <ExportableButtonComponent
                  size={'small'}
                  fileName={`integration_errors_${Date.now()}.csv`}
                  data={errorsToExport}
                />
              </div>
            }
            pagination={{pageSize: 5, showSizeChanger: false}}
            dataSource={errors}
            renderItem={(item: IntegrationBatchEntityError) => (
              <List.Item>
                <List.Item.Meta
                  title={`${item.rowNumber ? `${item.rowNumber} -` : '' }${translate(item.error)}`}
                  description={
                    <Paragraph
                      ellipsis={{ rows: 2, expandable: true, symbol:'ver mais', tooltip: true }}>
                      {item.row}
                    </Paragraph>
                  }
                />
              </List.Item>
            )}
          />
        </>
      </Modal>
    )
  }

  return (
    <>
      <HeaderIntegration title={`${integration?.name}`}/>
      <Divider />
      <Form form={form} layout={'vertical'} onFinish={onFinish}>
        <Form.Item
          name={'file'}
          label={'Selecione um Arquivo CSV para importacao'}
          rules={[{ required: true }]} extra={''}
          help={<div style={{whiteSpace: 'pre-wrap'}}>{integration?.help}</div>}
        >
          <Input
            type={'file'}
            id={'file'}
            onChange={(event) => checkFile(event)}
          />
        </Form.Item>
        <FormActionsButtons />
      </Form>
      <ModalErrors />
      <Table
        pagination={{pageSize: limit, onShowSizeChange: (a, b) => setLimit(b), onChange: setPage, total}}
        columns={columns}
        dataSource={batches}
        loading={integrationRepo.loading}
      />
        {
          currentBatchId && (
            <Modal
              title={'Lançamentos Gerados'}
              visible={true}
              width={700}
              onCancel={() => setCurrentBatchId(undefined)}
            >
              <ListBatchEntriesComponent batchId={currentBatchId} />
            </Modal>
          )
        }
    </>
  )
}