import {Checkbox, message, Modal, Table, Button, Space,Divider} from "antd";
import {useEffect, useState} from "react";
import {ProfileEntity} from "../../../../domain/entity/profile.entity";
import {useProfileRepository} from "../../../../domain/repository/profile.repository";
import {useUserCrud} from "../service/profile.crud";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import {RollbackOutlined} from '@ant-design/icons';

export type ListProfileModalProps = {
    visible: boolean,
    userId: number,
    onClose: () => void
}
export function ListProfileModal(props: ListProfileModalProps) {
    const [profile, setProfile] = useState<ProfileEntity[]>([]);
    const [userProfiles, setUsersProfiles] = useState<ProfileEntity[]>([]);
    const profileRepo = useProfileRepository();
    const userCrud = useUserCrud();

    function loadProfiles() {
        profileRepo
            .search()
            .then(res => setProfile(res.data));
    }

    function loadProfilesUser() {
        userCrud.getProfiles(props.userId)
            .then(res => setUsersProfiles(res));
    }

    function isBound(id: number) {
        if (userProfiles.find(item => item.id === id)) {
            return true;
        }
        return false;
    }

    function onChange(value: any, profileId: number) {
        if(value) {
            userCrud
                .addProfile(props.userId, profileId)
                .then(res => {
                    message.success(res.message);
                    loadProfilesUser();
                })
        } else {
            userCrud
                .removeProfile(props.userId, profileId)
                .then(res => {
                    message.success(res.message);
                    loadProfilesUser();
                })
        }
    }

    useEffect(() => {
        loadProfiles()
        loadProfilesUser();
    }, [1]);

    return (
        <Modal
            title={'Perfis do usuário'}
            width={750}
            visible={props.visible}
            footer={null}
        >
            <Table
                pagination={false}
                loading={userCrud.loading}
                columns={[
                    {
                        title: 'Nome',
                        key: 'name',
                        dataIndex: 'name',
                    },
                    {
                        title: 'Vinculado',
                        key: 'id',
                        dataIndex: 'id',
                        align: 'center',
                        render: (val) => (
                            <Checkbox
                                onChange={e => onChange(e.target.checked, val)}
                                checked={isBound(val)}
                            />
                        )
                    },

                ]}
                dataSource={profile}
                
            />
            <Divider />
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Space>
                    <Button
                        onClick={props.onClose}
                        htmlType={'button'}
                        icon={<RollbackOutlined />}
                        shape={'round'}
                        type={'ghost'}>
                        Fechar
                    </Button>
                </Space>
            </div>
        </Modal>
    )
}