import {AppPageHeader} from "../../../../core/component/page-header";
import {useHistory, useRouteMatch} from 'react-router-dom';
import React from "react";

export type CompetenceFilialProps = {
    createRoute?: string;
    mode?: 'list' | 'form'
}

export function CompetenceHeader(props: CompetenceFilialProps) {
    const urlMatch = useRouteMatch();
    const history = useHistory();

    function getCreateLink(): string {
        return `${urlMatch.url}/create`;
    }

    function getListLink(): string {
        return ``;
    }
    return (
        <>
            <AppPageHeader
                title={'Competências'}
                mode={props.mode}
                subtitle={'Cadastro de todas as competências'}
                onCreateLink={getCreateLink()}
                onListLink={getListLink()}
                onBack={() => history.goBack()}
                onCreateLabel={'Adicionar Competência'}
            />
        </>
    );

}
