import {Col, Divider, Form, Input, message, Row} from "antd";
import React from "react";
import {HeaderPaymentMethod} from "../component/header.payment-method";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {usePaymentMethodRepository} from "../../../../domain/repository/payment-method.repository";
import {ErrorMessageResource} from "../resource/error-message.resource";

export function CreatePaymentMethod(props: ModuleRenderProps) {
    const [form] = Form.useForm();
    const paymentMethodRepo = usePaymentMethodRepository();

    function onFinish(data: any) {
        paymentMethodRepo.create(data).then(res => {
            message.success(res.message);
            props.navigator.goToListPage();
        }).catch(err => {
            switch (err.messageKey) {
                case ErrorMessageResource.DUPLICATED_ALIAS:
                    form.setFields([{ name: 'alias', errors: ['Ja existe um registro com essa sigla']}]);
                    break;
                default:
                    message.error(err.message);
                    break;
            }

        })
    }

    return (
        <>
            <HeaderPaymentMethod mode={'form'} />
            <Divider />
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Row gutter={12}>
                    <Col span={18}>
                        <Form.Item name={'description'} label="Descrição" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name={'alias'} label="Sigla" rules={[{ required: true }]} >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <FormActionsButtons
                    onCancel={props.navigator.goToDefaultPage}
                    isLoading={paymentMethodRepo.loading}
                />
            </Form>
        </> 
    )
}
