import {Col, Divider, Form, Input, InputNumber, message, Row, Select, Switch} from "antd";
import React, {useEffect, useState} from "react";
import {HeaderFinancialClass} from "../component/header.financial-class";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {useParams} from "react-router-dom";
import {BudgetCategoryEntity} from "../../../../domain/entity/budget-category.entity";
import {ManagerialAccountEntity} from "../../../../domain/entity/managerial-account.entity";
import {useBudgetCategoryRepository} from "../../../../domain/repository/budget-category.repository";
import {useManagerialRepository} from "../../../../domain/repository/managerial.repository";
import {useFinancialClassRepository} from "../../../../domain/repository/financial-class.repository";
import {FinancialClassEntity} from "../../../../domain/entity/financial-class.entity";

export function EditFinancialClass(props: ModuleRenderProps) {
    const [form] = Form.useForm();
    const [entity, setEntity] = useState<FinancialClassEntity>();
    const financialClassRepo = useFinancialClassRepository();
    const [categories, setCategories] = useState<BudgetCategoryEntity[]>([]);
    const [managerialAccounts, setManagerialAccounts] = useState<ManagerialAccountEntity[]>([]);
    const budgetCategoryRepo = useBudgetCategoryRepository();
    const managerialAccountRepo = useManagerialRepository();

    const { id } = useParams<{id: string}>();

    useEffect(() => {
        financialClassRepo.find(id)
            .then(res => {
                setEntity(res.data);
            })
        budgetCategoryRepo.search().then(res => { setCategories(res.data) });
        managerialAccountRepo.search().then(res => { setManagerialAccounts(res.data) });
    }, [1]);

    useEffect(() => {
        if(entity) {
            form.setFields([
                { name: 'name', value: entity.name },
                { name: 'code', value: entity.code },
                { name: 'active', value: entity.active },
                { name: 'managerialAccountId', value: entity.managerialAccountId },
                { name: 'budgetCategoriesIds', value: entity.budgetCategories.map(item => item.id)}
            ])
        }
    }, [entity]);


    function onFinish(data: any) {
        financialClassRepo.update(id, data).then(res => {
            message.success(res.message);
            props.navigator.goToListPage();
        }).catch(err => {
            message.error(err.message);
        })
    }


    function getCategoriesOptions() {
        return categories
            .sort((a,b)  => {
                if (a.name > b.name) return 1;
                if (a.name < b.name) return -1;
                return 0
            })
            .map(item => ({
                label: item.name,
                value: item.id
            }))
    }

    function getManagerialAccountsOpts() {
        return managerialAccounts
            .sort((a,b)  => {
                if (a.code > b.code) return 1;
                if (a.code < b.code) return -1;
                return 0
            })
            .map(item => ({
                label: `${item.code} - ${item.description}`,
                value: item.id
            }))
    }

    return (
        <>
            <HeaderFinancialClass mode={'form'} />
            <Divider />
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Row gutter={12}>
                    <Col span={24}>
                        <Form.Item name={'name'} label="Nome" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={12}>
                    <Col span={12}>
                        <Form.Item name={'managerialAccountId'} label="Conta Gerencial" rules={[{ required: true }]}>
                            <Select options={getManagerialAccountsOpts()} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'budgetCategoriesIds'} label="Categorias Orçamentárias" >
                            <Select options={getCategoriesOptions()} mode={'multiple'} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={4}>
                        <Form.Item name={'active'} label="Status" valuePropName={'checked'} >
                            <Switch
                                checkedChildren={'Ativo'}
                                unCheckedChildren={'Inativo'}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <FormActionsButtons
                    onCancel={props.navigator.goToDefaultPage}
                    isLoading={financialClassRepo.loading}
                />
            </Form>
        </>
    )
}
