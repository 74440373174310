import {Button, Form, Select} from "antd";
import React, {useEffect} from "react";
import {ClientEntity} from "../../../../../domain/entity/client.entity";
import {ProfileEntity} from "../../../../../domain/entity/profile.entity";

export type FormClientProps = {
    clients: ClientEntity[],
    profiles: ProfileEntity[],
    clientId: number,
    profileId: number,
    refreshAuth: (clientId: number) => void,
    refreshProfile: (profileId: number) => void,
    clientSelected: (clientId: number) => void,
    profileSelected: (profileId: number) => void,
    loading: boolean,
    onFinish: () => void
}

export function FormClient(props: FormClientProps) {
    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        props.onFinish()
    }

    useEffect(() => {
        form.setFields([ { value: props.clientId, name: 'clientId' } ])
    }, [props.clientId])

    useEffect(() => {
        form.setFields([ { value: props.profileId, name: 'profileId' } ])
    }, [props.profileId])

    function onCancel() {
        window.location.href = '/login';
    }

    return (
        <Form
            form={form}
            layout={'vertical'}
            style={{width: '100%', backgroundColor: 'white', padding: 50, borderRadius: 10}}
            initialValues={{ remember: true }}
            onFinish={onFinish}
        >
            <Form.Item name="clientId" label={'Selecione Contexto'}>
                <Select
                    placeholder={'Selecione o Contexto'}
                    value={props.clientId}
                    options={props.clients.map(item => ({ label: item.name, value: item.id as number }))}
                    onSelect={(value: number) => props.clientSelected(value)}
                    loading={props.loading}
                />
            </Form.Item>
            <Form.Item label={'Selecione o perfil de acesso'} name="profileId">
                <Select
                    placeholder={'Selecione um Perfil Para Acesso'}
                    value={props.profileId}
                    options={props.profiles.map(item => ({ label: item.name, value: item.id as number }))}
                    onSelect={(value: number) => props.profileSelected(value as number)}
                    loading={props.loading}
                />
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit" style={{width: '100%', }}>
                    Confirmar
                </Button>
            </Form.Item>

            <Form.Item>
                <Button  onClick={() => onCancel()} style={{width: '100%', backgroundColor: '#1b1b1b', color: 'white'}}>
                    Cancelar
                </Button>
            </Form.Item>
        </Form>
    )
}