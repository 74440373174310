import {FinancialEntryEntity} from "../../../../../domain/entity/financial-entry.entity";
import {useDateTimeUtil} from "../../../../../core/util/date-time.util";
import {useNumberFormatter} from "../../../../service/number-formatter.service";
import {Descriptions, Tag} from "antd";
import {StatusPaymentComponent} from "../status-payment.component";

export function BasicDataEntry(props: { entry: FinancialEntryEntity }) {
  const { entry } = props;
  const { convertToDatePtBr } = useDateTimeUtil();
  const { currencyFormatter } = useNumberFormatter();

  function parseName(type: string | null) {
    switch (type) {
      case 'D':
        return 'Dedução';
      case 'R':
        return 'Receita';
      default:
        break;
    }
  }

  return (
    <Descriptions bordered column={4} size={`middle`} layout={'vertical'}>
      <Descriptions.Item label={`Lançamento`} >
        {entry.id}
      </Descriptions.Item>
      <Descriptions.Item label={`Tipo`} >
        {parseName(entry?.type ?? null)}
      </Descriptions.Item>
      <Descriptions.Item label={entry?.documentType?.name ?? `Documento`}>
        {entry?.documentNumber}
      </Descriptions.Item>
      <Descriptions.Item label={'Serie'}>
        {entry?.documentSeries}
      </Descriptions.Item>
      <Descriptions.Item label={`Cliente / Fornecedor`} >
        {entry?.person?.name}
      </Descriptions.Item>
      <Descriptions.Item label={`Filial`} span={2}>
        {entry?.firm?.name}
      </Descriptions.Item>
      <Descriptions.Item label={`Centro de Custo`} span={2}>
        {entry?.costCenter?.code} {entry?.costCenter?.name}
      </Descriptions.Item>
      <Descriptions.Item label={"Data de emissão"} >
        {entry && convertToDatePtBr(entry.dateTimeEmission)}
      </Descriptions.Item>
      <Descriptions.Item label={`Data de Entrada`}>
        {entry && convertToDatePtBr(entry.dateTimeEntry)}
      </Descriptions.Item>
      <Descriptions.Item label={`Data de Criação`}>
        {entry && convertToDatePtBr(entry.createdAt)}
      </Descriptions.Item>
      <Descriptions.Item label={`Status de Pagamento`}>
        <StatusPaymentComponent
          status={entry.statusPayment}
          isCanceled={entry.isCanceled}
        />
      </Descriptions.Item>
      <Descriptions.Item label={`Contém Rateio`}>
        {entry.apportionment?.length ? <Tag color={'cyan'}>Sim</Tag> : <Tag color={'warning'}>Não</Tag>}
      </Descriptions.Item>
      <Descriptions.Item label={`Valor Bruto`}>
        {entry.documentGrossValue && currencyFormatter.format(entry.documentGrossValue)}
      </Descriptions.Item>
      <Descriptions.Item label={`Valor Líquido`}>
        {entry.documentNetValue && currencyFormatter.format(entry.documentNetValue)}
      </Descriptions.Item>
      <Descriptions.Item label={`Total de Items`}>
        {entry.items?.length}
      </Descriptions.Item>
      <Descriptions.Item label={`Observações`} span={12} style={{whiteSpace: 'pre-line'}}>
        {entry?.observation}
      </Descriptions.Item>
    </Descriptions>
  )
}