import {UserEntity} from "../../domain/entity/user.entity";

export function useUserStorage() {
    const USER_KEY_STORAGE = '182__03';

    function setUser(userEntity: UserEntity) {
        localStorage.setItem(USER_KEY_STORAGE, btoa(JSON.stringify(userEntity)));
    }

    function getUser() {
        const item = localStorage.getItem(USER_KEY_STORAGE);
        if(item) {
            return JSON.parse(atob(item));
        }
        return null;
    }

    function clear() {
        localStorage.removeItem(USER_KEY_STORAGE);
    }

    return {
        setUser,
        getUser,
        clear
    }
}
