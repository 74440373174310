import {Form, Input, message, Select} from "antd";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import React, {useEffect, useState} from "react";
import {useFirmRepository} from "../../../../domain/repository/firm.repository";
import {DocumentType} from "../../../../domain/vo/document.type";

export function CreateDocumentFirm(props: {  firmId: number , onCreated: () => void }) {
    const [form] = Form.useForm();
    const firmRepository = useFirmRepository();
    const [documentTypes, setDocumentTypes] = useState<DocumentType[]>([]);

    useEffect(() => { firmRepository.getDocumentTypes().then(res => setDocumentTypes(res.data))}, [1])

    function onFinish(data: any) {
        data = {
            ...data,
            firmId: props.firmId
        }

        firmRepository.createDocument(data)
            .then(res => {
                form.resetFields();
                message.success('Documenta criado com sucesso');
                props.onCreated();
            }).catch(err => {
                message.error(err.message ?? 'Erro ao criar documento');
        })
    }

    return (
        <>
            <Form form={form} size={'middle'} layout="vertical" onFinish={onFinish}>
                <Form.Item name={'documentName'} rules={[{ required: true }]}>
                    <Select placeholder={'Tipo de Documento'}>
                        {
                            documentTypes.map(type => (
                                <Select.Option
                                    key={type.documentName}
                                    value={type.documentName}
                                >
                                    {type.documentValue}
                                </Select.Option>
                            ))
                        }
                    </Select>
                </Form.Item>
                <Form.Item name={'documentValue'} style={{width: '100%'}}>
                    <Input placeholder={'Informe o documento'} style={{width: '100%'}} />
                </Form.Item>
                <FormActionsButtons
                    onCancel={() => null}
                    isLoading={false}
                />
            </Form>
        </>
    )
}