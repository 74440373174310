import {Button, Form, Input} from "antd";
import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";

export type FormLoginProps = {
  onAuth: (args: any) => void,
  loading: boolean,
  username: string
}
export function FormLogin(props: FormLoginProps) {
  const [form] = Form.useForm();
  const history = useHistory();

  function onFinish(values: any) {
    values = {
      ...values,
      clientKey: process.env.REACT_APP_CLIENT_KEY as string
    }
    props.onAuth(values);
  }

  useEffect(() => {
    form.setFields([{ name: 'username' , value: props.username }]);
  }, [props.username])

  function goToRequestChange() {
    history.push(`/auth/request-change`)
  }

  return (
    <Form
      form={form}
      style={{width: '100%', backgroundColor: 'white', padding: 50, borderRadius: 10}}
      initialValues={{ remember: true }}
      onFinish={onFinish}
    >
      <Form.Item
        name="username"
        rules={[{ required: true, message: 'Informe seu usuário' }]}
      >
        <Input style={{width: '100%'}} placeholder={'Informe seu usuário'} value={props.username} />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[{ required: true, message: 'Informe sua senha' }]}
      >
        <Input.Password placeholder={'Informe sua senha.'} />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" style={{width: '100%', }} loading={props.loading}>
          Entrar
        </Button>
      </Form.Item>

      <Form.Item>
        <Button onClick={goToRequestChange} style={{width: '100%', backgroundColor: '#1b1b1b', color: 'white'}}>
          Esqueci Minhas Senha
        </Button>
      </Form.Item>
    </Form>
  )
}