export type ManagerialAccountType = {
    label: string;
    value: string
}

export class ManagerialAccountTypeEnum {

    public static CREDIT: ManagerialAccountType = {
        label: 'Credito',
        value: 'credit'
    }

    public static DEBIT: ManagerialAccountType = {
        label: 'Débito',
        value: 'debit'
    }

    public static getValues() {
        return [
            ManagerialAccountTypeEnum.CREDIT,
            ManagerialAccountTypeEnum.DEBIT
        ]
    }
}