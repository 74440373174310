import {IntegrationParamEntity} from "../../../../domain/entity/integration-param.entity";
import {Button, Col, Divider, Form, message, Row, Select, Typography} from "antd";
import {useEffect, useState} from "react";
import {OptionsValue} from "../../../../core/types/option.type";
import {useResolveProxyValuesService} from "../service/resolve-proxy-values.service";
import {useIntegrationRepository} from "../../../../domain/repository/integration.repository";

export type FormConfigDefaultValueComponentType = {
  onSaved: () => void,
  param: IntegrationParamEntity,
}

export function FormConfigDefaultValueComponent(props: FormConfigDefaultValueComponentType) {
  const [form] = Form.useForm();
  const [internalData, setInternalData] = useState<OptionsValue>([]);

  const { resolveValues } = useResolveProxyValuesService();
  const integrationRepo = useIntegrationRepository();

  useEffect(() => {
    resolveValues(props.param.entity).then(res => setInternalData(res));
    form.setFields([
      { name: 'defaultValue', value: parseInt(props.param.defaultValue ?? '') }
    ])
  }, [props.param]);

  function onFinish(values: any) {
    integrationRepo.editParam({ ...props.param, defaultValue: values.defaultValue }).then(res => {
      form.resetFields();
      message.success(res.message);
      props.onSaved();
    }).catch(err => {
      message.error(err.message);
    });
  }

  return (
    <Form form={form} layout={'horizontal'} onFinish={onFinish} >
      <Typography.Title level={5}>Configurar {props.param.name}</Typography.Title>
      <Divider />
      <Row gutter={12}>
        <Col span={18}>
          <Form.Item name={'defaultValue'}>
            <Select options={internalData} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Button htmlType={'submit'}>Salvar</Button>
        </Col>
      </Row>
    </Form>
  )
}