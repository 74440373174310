import {useHttpService} from "../../core/service/http.service";

export type ViaCepResult = {
    cep?: string,
    logradouro?: string,
    complemento?: string,
    bairro: string,
    localidade?: string,
    uf?: string,
}

export function useViaCepAddressRepository() {
    const httpService = useHttpService({ headers: {
            "Content-Type": "application/json"
        }, server: "https://viacep.com.br"});


    function findByPostalCode(postalCode: string): Promise<ViaCepResult> {
        return httpService.get(`ws/${postalCode}/json`)
            .then(res => res as ViaCepResult)
    }

    return {
        findByPostalCode,
        loading: httpService.loading
    }

}
