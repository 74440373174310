import {Button} from "antd";
import React from "react";
import {EditOutlined} from '@ant-design/icons'

export type EditButtonProps = {
    onClick: () => void,
    label?: string,
}

export function EditButton(props: EditButtonProps) {
    let { label } = props;
    label = label ?? 'Editar';
    return (
        <Button
            icon={<EditOutlined />}
            size={'small'}
            shape={"round"}
            onClick={props.onClick} >
            {label}
        </Button>
    )
}