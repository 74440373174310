import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {Tabs} from "antd";
import {ListFinancialEntry} from "./list.financial-entry";
import {ListFinancialEntryInstallment} from "./list.financial-entry-installments";
import React, {useEffect, useState} from "react";
import {ListProvisionEntry} from "./list.provision-entry";
import {useLocation} from "react-router-dom";
import {ListFinancialEntryEvent} from "./list.financial-entry-event";

export function FinancialEntryIndex(props: ModuleRenderProps) {
  const [tab, setTab] = useState<string>(`1`);
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);

  useEffect(() => {
    if (urlSearchParams.get('tab')) {
      setTab(urlSearchParams.get('tab') ?? '')
    }
  }, [location.search])

  return (
    <>
      <Tabs type={'line'} tabBarGutter={12} activeKey={tab} onChange={setTab}>
        <Tabs.TabPane tab={'Lançamentos'} key={1}>
          <ListFinancialEntry {...props} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={'Baixas Pendentes'} key={2}>
          <ListFinancialEntryInstallment {...props} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={'Provisões'} key={3}>
          <ListProvisionEntry {...props} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={'Auditoria'} key={4}>
          <ListFinancialEntryEvent {...props} />
        </Tabs.TabPane>
      </Tabs>
    </>
  )
}