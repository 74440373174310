import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {BudgetCategoryEntity} from "../../../../domain/entity/budget-category.entity";
import {useEffect, useState} from "react";
import {Button, message, Table, Tag} from "antd";
import {ColumnsType} from "antd/es/table";
import {useBudgetCategoryRepository} from "../../../../domain/repository/budget-category.repository";
import {SaveOutlined} from "@ant-design/icons";
import {ManagerialAccountEntity} from "../../../../domain/entity/managerial-account.entity";
import {useManagerialRepository} from "../../../../domain/repository/managerial.repository";
import {DreRowEntity, DreRowType} from "../../../../domain/entity/dre-row.entity";
import {useDRERowRepository} from "../../../../domain/repository/dre-row.repository";
import {ModalAddReleaseParam} from "../component/modal.add-release-param";
import {AddReleaseParamDto} from "../../../../domain/dto/add-release-param.dto";
import {useDreRowReleaseParamAdapterService} from "../service/dre-row-release-param.adapter.service";

export function ParametersDreRow(props: ModuleRenderProps) {
  const [categories, setCategories] = useState<BudgetCategoryEntity[]>([]);
  const [managerialAccounts, setManagerialAccounts] = useState<ManagerialAccountEntity[]>([]);
  const [columns, setColumns] = useState<ColumnsType<object>>([]);
  const [addParamModalVisible, setAddParamModalVisible] = useState<boolean>(false);
  const [currentCategoryId, setCurrentCategoryId] = useState<number>()
  const [currentManagerialAccountId, setCurrentManagerialAccountId] = useState<number>()

  const dreRowReleaseParamsAdapter = useDreRowReleaseParamAdapterService();
  const managerialAccountRepo = useManagerialRepository();
  const budgetCategoryRepo = useBudgetCategoryRepository();
  const dreRowRepo = useDRERowRepository();

  function loadDreRows() {
    dreRowRepo.findAll().then(res => {
      dreRowReleaseParamsAdapter.setRows(res.data);
    }).finally(() => {
      budgetCategoryRepo.findAll().then(res => setCategories(res.data));
      managerialAccountRepo.findAll().then(res => setManagerialAccounts(res.data));
    });
  }

  useEffect(() => {
    loadDreRows()
  }, [1]);

  useEffect(() => {
    const _columns: ColumnsType<object> = [];
    _columns.push({
      title: '',
      fixed: 'left',
      key: 'managerialAccountName',
      dataIndex: 'managerialAccountName',
      ellipsis: true
    })
    for (let category of categories) {
      _columns.push({
        title: category.name,
        key: category.id,
        ellipsis: true,
        dataIndex: 'managerialAccountId',
        render: (managerialAccountId: number) => renderCell(category.id, managerialAccountId)
      })
    }
    setColumns(_columns);
  }, [categories]);

  function renderCell(categoryId: number, managerialAccountId: number) {
    const rowBounded = dreRowReleaseParamsAdapter.getRowBounded(
      categoryId,
      managerialAccountId
    )
    if (rowBounded) {
      return (
        <RowBounded
          rowName={rowBounded.dreRow?.name || 'name'}
          dreRowParamId={rowBounded.id}
          typeRow={rowBounded.dreRow?.type}
        />
      )
    }

    return (
      <AddDreRow
        categoryId={categoryId}
        managerialAccountId={managerialAccountId}
      />
    )
  }

  function RowBounded(props: { rowName: string, dreRowParamId: number, typeRow?: DreRowType}) {
    const color = props.typeRow === DreRowType.DEDUCTION ? 'red' : 'green';
    return (
      <div style={{textAlign: 'center'}}>
        <Tag
          color={color}
          closable
          onClose={() => removeParam(props.dreRowParamId)}
        >
          {props.rowName}
        </Tag>
      </div>
    )
  }

  function AddDreRow(props: { categoryId: number, managerialAccountId: number }) {

    function openModal() {
      setCurrentManagerialAccountId(props.managerialAccountId);
      setCurrentCategoryId(props.categoryId);
      setAddParamModalVisible(true);
    }

    return (
      <div style={{width: '100%', textAlign: 'center', color: 'gray'}}>
        <Button
          style={{color: 'gray'}}
          size={'small'}
          type={'link'}
          icon={<SaveOutlined />}
          onClick={openModal}
        >
          Vincular Linha
        </Button>
      </div>
    )
  }

  function removeParam(paramId: number) {
    dreRowRepo.removeReleaseParam(paramId).then(res => {
      message.success(res.message);
      loadDreRows();
    })
  }

  function onRowSelect(dreRow: DreRowEntity) {
    if (undefined === dreRow || undefined === currentManagerialAccountId || undefined === currentCategoryId) {
      return;
    }
    const args: AddReleaseParamDto = {
      dreRowId: dreRow.id,
      budgetCategoryId: currentCategoryId,
      managerialAccountId: currentManagerialAccountId
    }

    dreRowRepo.addReleaseParam(args).then(res => {
      message.success(res.message);
      loadDreRows();
      closeModal()
    }).catch(err => {
      message.error(err.message);
    })
  }

  function closeModal() {
    setCurrentCategoryId(undefined);
    setCurrentManagerialAccountId(undefined);
    setAddParamModalVisible(false)
  }

  return (
    <>
      <Table
        loading={dreRowRepo.loading || managerialAccountRepo.loading || budgetCategoryRepo.loading}
        scroll={{y: '60vh', x: true}}
        columns={columns}
        bordered
        pagination={false}
        dataSource={managerialAccounts.map(item => ({
          managerialAccountName: `${item.code} - ${item.description}`,
          managerialAccountId: item.id
        }))}
      />
      {
        currentCategoryId && currentManagerialAccountId && (
          <ModalAddReleaseParam
            visible={addParamModalVisible}
            closeModal={closeModal}
            dreRows={dreRowReleaseParamsAdapter.dreRows}
            currentCategoryId={currentCategoryId}
            currentManagerialAccountId={currentManagerialAccountId}
            onSelect={onRowSelect}
          />
        )
      }
    </>
  )
}