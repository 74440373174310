import {usePlaneduHttpService} from "../service/planedu.http.service";
import {ClientEntity} from "../entity/client.entity";
import {ClientSettingsEntity} from "../entity/client.settings.entity";

export function useClientRepository() {
  const httpService = usePlaneduHttpService<ClientEntity>('client');

  function getSettings (): Promise<ClientSettingsEntity> {
    return httpService.get('client/settings')
      .then(res => res.data as ClientSettingsEntity);
  }

  return {
    getSettings
  }
}