import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {Col, Form, message, Row, Select} from "antd";
import {DatePickerPtBr} from "../../../../core/component/date-picker-ptbr";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import {useEffect, useState} from "react";
import {FirmEntity} from "../../../../domain/entity/firm.entity";
import {CostCenterEntity} from "../../../../domain/entity/cost-center.entity";
import {useFirmRepository} from "../../../../domain/repository/firm.repository";
import {getSorter} from "../../../../core/util/sort-array.util";
import {useDocumentFormatter} from "../../../service/document.formatter.service";
import {useFinancialEntryRepository} from "../../../../domain/repository/financial-entry.repository";
import {FinancialEntryEntity} from "../../../../domain/entity/financial-entry.entity";
import moment from "moment";
import { InputSearch } from "../../../../core/component/input-search";

export type FormProvisionEntryProps  = {
  entry?: FinancialEntryEntity,
  onFinish: () => void,
  setEntry: (entry: FinancialEntryEntity) => void,
}
export function FormProvisionEntry(props: FormProvisionEntryProps) {
  const [form] = Form.useForm();
  const [firms, setFirms] = useState<FirmEntity[]>([]);
  const [costCenters, setCostCenters] = useState<CostCenterEntity[]>([]);

  const { formatCNPJ } = useDocumentFormatter();
  const firmRepo = useFirmRepository();
  const entryRepo = useFinancialEntryRepository();

  function loadCostCenters(firmId: string) {
    firmRepo.listCostCenters(firmId,true).then(res => setCostCenters(res.data));
  }

  function loadParams() {
    firmRepo.findAll().then(res => setFirms(res.data));
  }

  useEffect(() => {
    loadParams();
  }, [1]);

  useEffect(() => {
    form.setFields([{
      name: 'type', value: 'R',
    }])
    if (props.entry) {
      form.setFields([
        { name: 'firmId', value: props.entry?.firmId},
        { name: 'costCenterId', value: props.entry?.costCenterId},
        { name: 'dateTimeEntry', value: moment(props.entry.dateTimeEntry) },
      ])
      loadCostCenters(props.entry.firmId.toString());
    }
  }, [props.entry])

  function getFirmsOpts() {
    return firms.sort(getSorter<FirmEntity>('name'))
      .map(item => ({
        label: `${item.name} - ${formatCNPJ(item.document)}`,
        value: item.id as number,
      }))
  }

  function getCostCenterOpts() {
    return costCenters.sort(getSorter<CostCenterEntity>('code'))
      .map(item => ({
        label: `${item.code} - ${item.name}`,
        value: item.id as number
      }))
  }

  function onFinish(values: any) {
    function send() {
      if (props.entry?.id) {
        return entryRepo.update(props.entry.id.toString(), values);
      }
      return entryRepo.addProvisionEntry(values);
    }
    send().then(res => {
      message.success(res.message)
      props.setEntry(res.data);
      props.onFinish();
    }).catch(err => {
      message.error(err.message)
    })
  }

  return (
    <Form form={form} layout={"vertical"} onFinish={onFinish}>
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item name={'firmId'} label={'Filial'} required>
            <Select
              options={getFirmsOpts()}
              onSelect={(firmId: string) => loadCostCenters(firmId)}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <InputSearch
            name={'costCenterId'}
            label={'Centro de Custo'}
            data={getCostCenterOpts()}
            required={true}
            loading={firmRepo.loading}
          />
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={12}>
          <DatePickerPtBr
            name={'dateTimeEntry'}
            label={'Data Competeência'}
            required
          />
        </Col>
      </Row>
      <FormActionsButtons />
    </Form>
  )
}