import React, {useEffect, useState} from "react";
import {HeaderFinancialItem} from "../component/header.financial-item";
import {Divider, Layout, message, Table, Tag,} from "antd";
import {ListActions} from "../../../../core/component/list-actions";
import {useFinancialItemRepository} from "../../../../domain/repository/financial-item.repository";
import {FinancialItemEntity} from "../../../../domain/entity/financial-item.entity";
import { FilterListFinancialClass } from "../component/filter-list.financial-item";
import { FinancialClassEntity } from "../../../../domain/entity/financial-class.entity";
import {ExportExcelButton} from "../../../../core/component/export-button";

export function ListFinancialItem(props: any) {
  const financialItemRepo = useFinancialItemRepository();
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [classFilter, setClassFilter] = useState<number>();
  const [data, setData] = useState<FinancialItemEntity[]>([]);
  const [filter, setFilter] = useState<any>({});

  function loadData() {
    const offset = limit * (page -1);
    let parsedValues: any = {};
    if (filter.groupId) {
      parsedValues['groupId'] = filter.groupId
    }
    if (filter.classId) {
      parsedValues['classId'] = filter.classId
    }
    if (filter.description) {
      parsedValues['description'] = filter.description
    }
    console.log(parsedValues);
    financialItemRepo.search({ offset, limit, filter }).then(res => {
      setData(res.data)
      setTotal(res.total);
    });
  }

  useEffect(() => {
    loadData()
  }, [page, filter, limit]);

  function removeItem(id: string) {
    financialItemRepo.remove(id)
      .then(res => {
        message.success(res.message);
        loadData();
      }).catch(err => {
      message.error(err.message);
    })
  }

  const columnsBudget = [
    {
      title: 'Grupo',
      dataIndex: 'class' ,
      key: 'group',
      render: (val: FinancialClassEntity) => `${val.group.code} - ${val.group.name}`,
    },
    {
      title: 'Classe',
      dataIndex: 'class' ,
      key: 'class',
      render: (val: FinancialClassEntity) => `${val.code} - ${val.name}`,
    },
    {
      title: 'Item',
      dataIndex: 'description' ,
      key: 'description',
    },
    {
      title: 'Código',
      dataIndex: 'code' ,
      key: 'code',
      render: (code: string, row: FinancialItemEntity) => `${row.class?.group.code}.${row.class?.code}.${code}`
    },
    {
      title: 'Status',
      dataIndex: 'active',
      key: 'active',
      render: (value: boolean) => (
        <Tag color={value ? 'green' : 'red'}>
          {value ? 'Ativo' : 'Inativo'}
        </Tag>
      )
    },
    {
      dataIndex: 'id',
      render: (val: any) => (
        <>
          <ListActions
            onEditClick={() => props.navigator.goToEditPage(val) }
            onRemoveClick={() => removeItem(val) }
          />
        </>
      )
    }
  ]
  return (
    <>
      <HeaderFinancialItem
        extra={[<ExportExcelButton onClick={() => financialItemRepo.downloadListItems()} /> ]}
      />
      <Divider />
      <FilterListFinancialClass
        onFilter={setFilter}
      />
      <Layout.Content>
        <Table
          columns={columnsBudget}
          dataSource={data}
          loading={financialItemRepo.loading}
          pagination={{
            total,
            onShowSizeChange: (c,b) => setLimit(b),
            current: page,
            onChange: setPage
          }}
        />
      </Layout.Content>
    </>
  );
}