import {Col, Divider, Form, Input, InputNumber, message, Row, Switch} from "antd";
import React, {useEffect} from "react";
import {HeaderFinancialGroup} from "../component/header.financial-group";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {ErrorMessageResource} from "../resource/error-message.resource";
import {useFinancialGroupRepository} from "../../../../domain/repository/financial-group.repository";

export function CreateFinancialGroup(props: ModuleRenderProps) {
    const [form] = Form.useForm();
    const financialGroupRepository = useFinancialGroupRepository();

    useEffect(() => {
        form.setFields([{
            name: 'active', value: true
        }])
    }, [1]);

    function onFinish(data: any) {
        financialGroupRepository.create(data).then(res => {
            message.success(res.message);
            props.navigator.goToListPage();
        }).catch(err => {
            switch (err.messageKey) {
                case ErrorMessageResource.DUPLICATED_CODE:
                    form.setFields([{
                        name: 'code', errors: [err.message]
                    }])
                    break;
                default:
                    message.error(err.message)
                    break;
            }
        })
    }

    return (
        <>
            <HeaderFinancialGroup mode={'form'} />
            <Divider />
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item name={'name'} label="Nome" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Row>
                    <Col span={4}>
                        <Form.Item name={'active'} label="Status" valuePropName={'checked'} >
                            <Switch
                                checkedChildren={'Ativo'}
                                unCheckedChildren={'Inativo'}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <FormActionsButtons
                    onCancel={props.navigator.goToDefaultPage}
                    isLoading={financialGroupRepository.loading}
                />
            </Form>
        </> 
    )
}
