import {Col, Form, message, Row} from "antd";
import {SelectPaymentMethod} from "../../../component/dropdown/select.payment-method";
import {useEffect, useState} from "react";
import {PaymentMethodEntity} from "../../../../domain/entity/payment-method.entity";
import {usePaymentMethodRepository} from "../../../../domain/repository/payment-method.repository";
import {DatePickerPtBr} from "../../../../core/component/date-picker-ptbr";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import {FinancialEntryEntity} from "../../../../domain/entity/financial-entry.entity";
import {FinancialEntryInstallmentEntity} from "../../../../domain/entity/financial-entry-installment.entity";
import {RecurrenceEntryEntity} from "../../../../domain/entity/recurrence-entry.entity";
import {useFinancialEntryRepository} from "../../../../domain/repository/financial-entry.repository";
import moment from "moment";
import {BrlInputNumber} from "../../../../core/component/brl-input-number";

export type FormCheckInstallmentComponentProps = {
  entry: FinancialEntryEntity,
  installment?: FinancialEntryInstallmentEntity,
  recurrenceEntry: RecurrenceEntryEntity,
  currentDueData: Date,
  onFinish: () => any,
}
export function FormCheckInstallmentComponent(props: FormCheckInstallmentComponentProps) {
  const [form] = Form.useForm();
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethodEntity[]>([]);

  const { installment, entry, recurrenceEntry, currentDueData } = props;
  const paymentMethodRepo = usePaymentMethodRepository();
  const entryRepo = useFinancialEntryRepository();

  useEffect(() => {
    form.setFields([
      { name: 'dueDate', value: moment(currentDueData)},
      { name: 'value', value: entry.documentNetValue },
    ])
    if (installment) {
      form.setFields([
        { name: 'dueDate', value: moment(installment.dueDate)},
        { name: 'value', value: installment.value},
        { name: 'paymentMethodId', value: installment.paymentMethodId},
      ])
    }
  })

  function loadParams() {
    paymentMethodRepo.findAll().then(res => setPaymentMethods(res.data));
  }

  useEffect(() => {
    loadParams();
  }, [1])

  function onFinish(values: any) {
    const { dueDate, paymentMethodId, value } = values;
    return entryRepo.saveFinancialEntryInstallments(
      entry.id,
      'cash',
      [{
        value,
        paymentMethodId,
        order: 1,
        dueDate: dueDate.local(),
      }]
    ).then(res => {
      message.success(res.message)
      props.onFinish();
    }).catch(err => {
      message.error(err.message);
    })
  }

  return (
    <Form
      form={form}
      layout={'vertical'}
      onFinish={onFinish}
    >
      <Row gutter={12}>
        <Col span={8}>
          <SelectPaymentMethod data={paymentMethods} />
        </Col>
        <Col span={8}>
          <DatePickerPtBr
            label={'Data de Vencimento'}
            name={'dueDate'}
            required={true}
          />
        </Col>
        <Col span={6}>
          <BrlInputNumber
            label={'Valor'}
            name={'value'}
            required={true}
          />
        </Col>
      </Row>
      <FormActionsButtons />
    </Form>
  )
}