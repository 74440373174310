import {FormInstance} from "antd";
import moment from "moment";

export function useUpdateFieldsByYearService() {

    function setFields(year: number, form: FormInstance) {
        const fields = [];
        for (let month = 1; month < 13; month++) {
            let dtStr = '';
            if (month === 12) {
                dtStr = `${year + 1}-01-05`;
            } else {
                dtStr = `${year}-${(month + 1).toString().padStart(2, '0')}-05`;
            }

            fields.push({ name: `month_${month}`, value: moment(dtStr)})
        }
        fields.push({ name: 'initialDate', })
        form.setFields(fields);
    }

    return {
        setFields
    }
}