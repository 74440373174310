import {Col, Divider, Form, Input, InputNumber, message, Row, Select, Switch} from "antd";
import React, {useEffect, useState} from "react";
import {HeaderFinancialItem} from "../component/header.financial-item";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {useParams} from "react-router-dom";
import {useFinancialClassRepository} from "../../../../domain/repository/financial-class.repository";
import {FinancialClassEntity} from "../../../../domain/entity/financial-class.entity";
import {SupplyUnitEntity} from "../../../../domain/entity/supply-unit.entity";
import {useSupplyUnitRepository} from "../../../../domain/repository/supply-unit.repository";
import {useFinancialItemRepository} from "../../../../domain/repository/financial-item.repository";
import {FinancialItemEntity} from "../../../../domain/entity/financial-item.entity";
import {ManagerialAccountEntity} from "../../../../domain/entity/managerial-account.entity";
import {useManagerialRepository} from "../../../../domain/repository/managerial.repository";
import TextArea from "antd/es/input/TextArea";

export function EditFinancialItem(props: ModuleRenderProps) {
  const [form] = Form.useForm();
  const [entity, setEntity] = useState<FinancialItemEntity>();
  const [supplyUnits, setSupplyUnits] = useState<SupplyUnitEntity[]>([]);
  const [classes, setClasses] = useState<FinancialClassEntity[]>([]);
  const [managerialAccounts, setManagerialAccounts] = useState<ManagerialAccountEntity[]>([]);

  const supplyUnitRepo = useSupplyUnitRepository();
  const financialClassRepo = useFinancialClassRepository();
  const financialItemRepo = useFinancialItemRepository();
  const managerialAccountRepo = useManagerialRepository();
  const { id } = useParams<{id: string}>();

  function loadParams() {
    financialClassRepo.findAll().then(res => setClasses(res.data))
    supplyUnitRepo.findAll().then(res => setSupplyUnits(res.data));
    financialItemRepo.find(id).then(res => setEntity(res.data));
    managerialAccountRepo.findAll().then(res => setManagerialAccounts(res.data));
  }

  useEffect(() => {
    loadParams()
  }, [1]);

  useEffect(() => {
    if(entity) {
      form.setFields([
        { name: 'description', value: entity.description },
        { name: 'code', value: entity.code },
        { name: 'active', value: entity.active },
        { name: 'classId', value: entity.classId },
        { name: 'observation', value: entity.observation },
        { name: 'supplyUnitsIds', value: entity.supplyUnits.map(item => item.id) },
        { name: 'managerialAccountId', value: entity.managerialAccountId },
        { name: 'externalKey', value: entity.meta?.externalKey },
      ])
    }
  }, [entity]);


  function onFinish(data: any) {
    financialItemRepo.update(id, data).then(res => {
      message.success(res.message);
      props.navigator.goToListPage();
    }).catch(err => {
      message.error(err.message);
    })
  }

  function getSupplyUnitsOpts() {
    return supplyUnits
      .sort((a,b)  => {
        if (a.description > b.description) return 1;
        if (a.description < b.description) return -1;
        return 0
      })
      .map(item => ({
        label: `${item.description} - ${item.alias}`,
        value: item.id
      }))
  }

  function getClassesOpts() {
    return classes
      .sort((a,b)  => {
        if (a.code > b.code) return 1;
        if (a.code < b.code) return -1;
        return 0
      })
      .map(item => ({
        label: `${item.code} - ${item.name}`,
        value: item.id
      }))
  }

  function getManagerialAccountOpts() {
    return managerialAccounts
      .sort((a,b)  => {
        if (a.code > b.code) return 1;
        if (a.code < b.code) return -1;
        return 0
      })
      .map(item => ({
        label: `${item.code} - ${item.description}`,
        value: item.id
      }))
  }

  function updateManagerialAccount() {
    const financialClass = classes.find(w => w.id === form.getFieldValue('classId'));
    if (financialClass) {
      form.setFields([
        { name: 'managerialAccountId' , value: financialClass.managerialAccount.id }
      ])
    }
  }

  return (
    <>
      <HeaderFinancialItem mode={'form'} />
      <Divider />
      <Form form={form} layout="vertical" onFinish={onFinish}><Row gutter={12}>
        <Col span={8}>
          <Form.Item name={'description'} label="Descrição" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name={'supplyUnitsIds'} label="Unidades de Fornecimento" rules={[{ required: true }]}>
            <Select mode={'multiple'} options={getSupplyUnitsOpts()} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name={'externalKey'} label="Chave de Integração">
            <Input />
          </Form.Item>
        </Col>
      </Row>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item name={'classId'} label="Classe" rules={[{ required: true }]}>
              <Select options={getClassesOpts()} onChange={() => updateManagerialAccount()} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={'managerialAccountId'} label="Conta Gerencial" rules={[{ required: true }]}>
              <Select options={getManagerialAccountOpts()} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name={'observation'} label={'Observação'}>
          <TextArea />
        </Form.Item>
        <Row>
          <Col span={4}>
            <Form.Item name={'active'} label="Status" valuePropName={'checked'} >
              <Switch
                checkedChildren={'Ativo'}
                unCheckedChildren={'Inativo'}
              />
            </Form.Item>
          </Col>
        </Row>
        <FormActionsButtons
          onCancel={props.navigator.goToDefaultPage}
          isLoading={financialItemRepo.loading}
        />
      </Form>
    </>
  )
}
