import {Col, Divider, Form, Input, message, Row, Switch} from "antd";
import React, {useEffect, useState} from "react";
import {HeaderPaymentMethod} from "../component/header.payment-method";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {useParams} from "react-router-dom";
import { useSupplyUnitRepository } from "../../../../domain/repository/supply-unit.repository";
import { SupplyUnitEntity } from "../../../../domain/entity/supply-unit.entity";
import {PaymentMethodEntity} from "../../../../domain/entity/payment-method.entity";
import {usePaymentMethodRepository} from "../../../../domain/repository/payment-method.repository";

export function EditPaymentMethod(props: ModuleRenderProps) {
    const [form] = Form.useForm();
    const [entity, setEntity] = useState<PaymentMethodEntity>();
    const paymentMethodRepo = usePaymentMethodRepository();
    const { id } = useParams<{id: string}>();

    useEffect(() => {
        paymentMethodRepo.find(id)
            .then(res => {
                setEntity(res.data);
            })
    }, [1]);

    useEffect(() => {
        if(entity) {
            form.setFields([
                { name: 'description', value: entity.description },
                { name: 'alias', value: entity.alias },
            ])
        }
    }, [entity]);


    function onFinish(data: any) {
        paymentMethodRepo.update(id, data).then(res => {
            message.success(res.message);
            props.navigator.goToListPage();
        }).catch(err => {
            message.error(err.message);
        })
    }

    return (
        <>
            <HeaderPaymentMethod mode={'form'} />
            <Divider />
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Row gutter={12}>
                    <Col span={18}>
                        <Form.Item name={'description'} label="Descrição" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name={'alias'} label="Sigla" rules={[{ required:true }]} >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <FormActionsButtons
                    onCancel={props.navigator.goToDefaultPage}
                    isLoading={paymentMethodRepo.loading}
                />
            </Form>
        </>
    )
}
