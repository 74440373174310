import {Button, Descriptions, List, message, Typography} from "antd";
import {useDRERowRepository} from "../../../../domain/repository/dre-row.repository";
import {Key, useEffect, useState} from "react";
import {DreRowEntity, DreRowType} from "../../../../domain/entity/dre-row.entity";
import {CaretDownOutlined, CaretUpOutlined, LoadingOutlined} from "@ant-design/icons";
import {DeleteButton} from "../../../../core/component/remove-button";
import {EditButton} from "../../../../core/component/edit-button";
import {dreRowNameFormatter} from "../service/dre-row.name.formatter";
import {dreRowSort} from "../service/dre-row.sort";

export type ShowDreRowProps = {
    dreRowId: Key,
    navigator: any,
    afterRowId?: Key,
    beforeRowId?: Key,
    onRemoved: () => void
}
export function ShowDreRow(props: ShowDreRowProps) {
    const dreRowRepo = useDRERowRepository();
    const [currentRow, setCurrentRow] = useState<DreRowEntity>();
    const [rows, setRows] = useState<DreRowEntity[]>([]);

    useEffect(() => { loadData() }, [props.dreRowId]);
    useEffect(() => {
        dreRowRepo.search()
            .then(res => setRows(res.data));
    }, [1])

    function parseRowsCalc(ids: string[]) {
        const rowsFiltered = rows
            .filter(w => ids.includes(w.id.toString()))
            .sort(dreRowSort)
            .map(item => dreRowNameFormatter(item));

        return (
            <List
                dataSource={rowsFiltered}
                renderItem={(item: string) => {
                    return (
                        <List.Item>
                            <Typography.Text ellipsis={true}>
                                {item}
                            </Typography.Text>
                        </List.Item>
                    )
                }}
            />
        )
    }

    function loadData() {
        dreRowRepo.find(props.dreRowId as string)
            .then(res => setCurrentRow(res.data));
    }

    function parseType(type: string) {
        switch (type) {
            case DreRowType.CALC:
                return 'Calculada';
            case DreRowType.RESULT:
                return 'Resultado';
            case DreRowType.REVENUE:
                return 'Receita';
            case DreRowType.DEDUCTION:
                return 'Dedução';
            default:
                return type;
        }
    }

    function removeDreRow(id: string) {
        dreRowRepo.remove(id)
            .then(res => {
                message.success(res.message);
                props.onRemoved();
            }).catch(err => {
                message.error(err.message);
        })
    }

    function MoveToUpButton() {
        const disabled = !props.beforeRowId;
        function onClick() {
            dreRowRepo.swapDreRowOrder(
                props.beforeRowId as number,
                props.dreRowId as number
            )
                .then(res => {
                    message.success(res.message);
                    props.onRemoved();
                }).catch(err => {
                    message.error(err.message)
            })
        }

        return (
            <Button
                icon={<CaretUpOutlined />}
                size={'small'}
                shape={'round'}
                onClick={onClick}
                disabled={disabled}
            >
                Mover Para Cima
            </Button>
        )
    }

    function MoveToDownButton() {
        const disabled = !props.afterRowId;
        function onClick() {
            dreRowRepo.swapDreRowOrder(
                props.afterRowId as number,
                props.dreRowId as number
            )
                .then(res => {
                    message.success(res.message);
                    props.onRemoved();
                })
        }

        return (
            <Button
                icon={<CaretDownOutlined />}
                size={'small'}
                shape={'round'}
                onClick={onClick}
                disabled={disabled}
            >
                Mover Para Baixo
            </Button>
        )
    }

    return (
        <>
            {
                dreRowRepo.loading ?
                    <LoadingOutlined /> :
                    (
                        <>
                            <Descriptions
                                title={`${currentRow && dreRowNameFormatter(currentRow)}`}
                                bordered
                                size={'middle'}
                            >
                                <Descriptions.Item span={24} label="Nome">
                                    {currentRow?.name}
                                </Descriptions.Item>
                                <Descriptions.Item span={24} label="Ordem">
                                    {currentRow?.order}
                                </Descriptions.Item>
                                <Descriptions.Item span={24} label="Tipo">
                                    {parseType(currentRow ? currentRow.type : '')}
                                </Descriptions.Item>
                                <Descriptions.Item span={24} label="Linha de Resultado">
                                    {currentRow?.isRowResult ? 'Sim' : 'Não'}
                                </Descriptions.Item>
                                {
                                    currentRow && currentRow.type === DreRowType.CALC && (
                                        <Descriptions.Item span={24} label="Linhas Incluídas">
                                            {parseRowsCalc(currentRow.columnsToCalc ?? [])}
                                        </Descriptions.Item>
                                    )
                                }
                                <Descriptions.Item span={24} label="Alterar Ordem">
                                    <MoveToUpButton />
                                    <MoveToDownButton />
                                </Descriptions.Item>
                                <Descriptions.Item span={24} label={'Ações'}>
                                    <EditButton
                                        label={'Editar'}
                                        onClick={() => props.navigator.goToEditPage(props.dreRowId)}
                                    />

                                    <DeleteButton
                                        label={'Remover'}
                                        onClick={() => currentRow && removeDreRow(currentRow.id.toString())}
                                    />
                                </Descriptions.Item>
                            </Descriptions>
                        </>

                    )

            }
        </>
    )
}