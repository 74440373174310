import {ConfigProvider, Layout} from "antd";
import React, {useState} from "react";
import {getAppMenu} from './menu'
import {SkeletonMenu} from "../core/component/skeleton/skeleton.menu";
import {SkeletonHeader} from "../core/component/skeleton/skeleton.header";
import {SkeletonContent} from "../core/component/skeleton/skeleton.content";
import {getAppModules} from "./modules";
import {useHistory, useRouteMatch} from "react-router-dom";
import {ApplicationContext, useContextValue} from "../core/context/application.context.props";
import ptBr from "antd/lib/locale/pt_BR";

const { Sider } = Layout;

export function AdminModule() {
    const [collapsed, setCollapsed] = useState(false);
    const routeMatch = useRouteMatch();
    const history = useHistory();
    const appContext = useContextValue();

    return (
      <ConfigProvider locale={ptBr} >
        <ApplicationContext.Provider value={appContext}>
          <Layout>
            <Sider trigger={null} collapsible collapsed={collapsed} style={{minHeight: '100vh'}}>
              <div style={{width: '100%', height: 'auto', padding: 15, cursor: 'pointer'}}>
                <div
                  onClick={() => history.push('/')}
                  style={{backgroundImage: 'url("/logo.png")', width: '100%', backgroundRepeat: 'no-repeat', height: 50, backgroundSize: 'contain'}}
                />
              </div>
              <SkeletonMenu menus={getAppMenu(routeMatch.url)} collapsed={collapsed} prefixUrl={routeMatch.url} />
            </Sider>
            <Layout className="site-layout">
              <SkeletonHeader collapsed={collapsed} setCollapsed={setCollapsed} />
              <SkeletonContent modules={getAppModules(routeMatch.url)} />
            </Layout>
          </Layout>
        </ApplicationContext.Provider>
      </ConfigProvider>
    )
}
