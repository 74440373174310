import {Tabs} from "antd";
import {ListBudget} from "./list.budget";
import { ListReleaseBudget } from "./list.release-budget";
import {ReleaseBudget} from "./release.budget";
import { ShowDre } from "./show.dre";
import {useState} from "react";

export function BudgetIndex(props: any) {
    const [tab, setTab] = useState<number>();

    return (
        <Tabs tabPosition={'top'} onChange={event => setTab(parseInt(event))}>
            <Tabs.TabPane tab={'Cadastro dos Dados'} key={1}>
                <ListBudget {...props} />
            </Tabs.TabPane>
            <Tabs.TabPane tab={'Lançamento do Orçamento'} key={2}>
                {tab === 2 && <ReleaseBudget {...props} />}
            </Tabs.TabPane>
            <Tabs.TabPane tab={'Pesquisar Lançamentos'} key={3}>
                {tab === 3 && <ListReleaseBudget {...props} />}
            </Tabs.TabPane>
            <Tabs.TabPane tab={'Visualizar Resultados'} key={4}>
                {tab === 4 &&<ShowDre {...props} />}
            </Tabs.TabPane>
        </Tabs>
    )
}