import {PersonEntity} from "../../../../domain/entity/person.entity";
import {Col, Row, Typography} from "antd";
import React from "react";

export function LabelNamePerson(props: { person: PersonEntity }) {

    function getNamePerson() {
        return `${props.person?.name} - ${props.person?.document}`
    }

    return (
        <Row>
            <Col span={24}>
                <Typography.Title mark level={5}>
                    {getNamePerson()}
                </Typography.Title>
            </Col>
        </Row>
    )
}