import {useRouteMatch} from "react-router-dom";
import {ModuleResource, ModuleResourceTypeEnum, ModuleRoot} from "../../../core/component/module/module.root";
import React from "react";
import {ListClientProvider} from "./pages/list.client-provider";
import {CreateClientProvider} from "./pages/create.client-provider";
import {EditClientProvider} from "./pages/edit.client-provider";
import {ImportClientProvider} from "./pages/import.client-provider";


export function ClientProviderModule() {
    const routeMatch = useRouteMatch();
    const resources: ModuleResource[] = [
        {
            type: ModuleResourceTypeEnum.CREATE,
            render: (props) => <CreateClientProvider {...props} />
        },
        {
            type: ModuleResourceTypeEnum.EDIT,
            render: (props) => <EditClientProvider {...props} />
        },
        {
            type: ModuleResourceTypeEnum.CUSTOM,
            customPath: `import`,
            render: (props) => <ImportClientProvider {...props} />
        },
        {
            type: ModuleResourceTypeEnum.DEFAULT,
            render: (props) => <ListClientProvider {...props} />
        },
    ]

    return (
        <ModuleRoot
            resource={routeMatch.url}
            moduleResources={resources}
        />
    );
}
