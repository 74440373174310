import {BaseResponse, usePlaneduHttpService} from "../service/planedu.http.service";
import {FirmEntity} from "../entity/firm.entity";
import {DocumentType} from "../vo/document.type";
import {FirmDocumentEntity} from "../entity/firm.document.entity";
import {CostCenterEntity} from "../entity/cost-center.entity";
import {QueryParam} from "../../core/service/http.service";

export function useFirmRepository() {
    const httpService = usePlaneduHttpService<FirmEntity>('firm');

    async function getDocumentTypes() {
        return httpService.get('firm/document/types').then(
            res => res as BaseResponse<DocumentType[]>
        )
    }

    async function createDocument(firmDocument: FirmDocumentEntity) {
        return httpService.post('firm/document', JSON.stringify(firmDocument))
            .then(res => res as BaseResponse<FirmDocumentEntity>);
    }

    async function removeDocument(documentId: number) {
        return httpService.delete('firm/document/' + documentId)
            .then(res => res as BaseResponse<FirmDocumentEntity>);
    }

    async function listCostCenters(firmId: string, onlyAnalytical?: boolean) {
        const params: QueryParam[] = [];
        onlyAnalytical === true && params.push({ key: 'onlyAnalytical', value: 1});
        return httpService.get(`firm/${firmId}/cost-centers`, undefined, params)
            .then(res => res as BaseResponse<CostCenterEntity[]>)
    }

    return {
        ...httpService,
        getDocumentTypes,
        createDocument,
        removeDocument,
        listCostCenters
    }
}
