import {Tag} from "antd";
import React from "react";

export function StatusPaymentComponent(props: { status: string, isCanceled?: boolean }) {
  const { status, isCanceled } = props;
  if (isCanceled) {
    return <Tag color={'red'}>Cancelado</Tag>;
  }
  switch (status) {
    case 'pending':
      return <Tag color={'warning'}>Pendente</Tag>;
    case 'incomplete':
      return <Tag color={'cyan'}>Parcial</Tag>;
    case 'paid':
      return <Tag color={'success'}>Finalizado</Tag>;
    default:
      return null;
  }
}