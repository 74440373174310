import React, {useEffect, useState} from "react";
import {HeaderBudget} from "../component/header.budget";
import {Divider, Layout, message, Table,} from "antd";
import {BudgetEntity} from "../../../../domain/entity/budget.entity";
import {useBudgetRepository} from "../../../../domain/repository/budget.repository";
import {ListActions} from "../../../../core/component/list-actions";
import {useDateTimeUtil} from "../../../../core/util/date-time.util";
import {ListMonthsModal} from "./list.months.modal";
import { MonthsEnum } from "../../../../domain/vo/months.enum";

export function ListBudget(props: any) {
    const dateTimeUtil = useDateTimeUtil();
    const budgetRepository = useBudgetRepository();
    const [data, setData] = useState<BudgetEntity[]>([]);
    const [monthsModalVisible, setMonthsModalVisible] = useState(false);
    const [monthsModalCCId, setMonthsModalCCId] = useState<string>();

    function loadData() {
        budgetRepository.search()
            .then(res => {
                setData(res.data)
            }); 
                 
    }

    useEffect(() => {
        loadData()
    }, [1]);

    function removeBudget(id: string) {
        budgetRepository.remove(id)
            .then(res => {
                message.success(res.message);
                loadData();
            }).catch(err => {
                message.error(err.message);
        })
    }

    function closeMonthsModal() {
        setMonthsModalVisible(false);
        setMonthsModalCCId(undefined);
    }

    function renderMonthsCell(months: number[]) {
        if (months.length == 12) {
            return <strong>Todos</strong>;
        }
        const firstMonth = months.reduce((a,b) => a < b ? a : b);
        const lastMonth = months.reduce((a,b) => a > b ? a : b);
        return (
            <>
                de <strong>{MonthsEnum.parseName(firstMonth)}</strong> à <strong>{MonthsEnum.parseName(lastMonth)}</strong> 
            </>
        )
        return `${MonthsEnum.parseName(firstMonth)} até ${MonthsEnum.parseName(lastMonth)}`;
    }

    const columnsBudget = [
        {
            title: 'Competência',
            dataIndex: 'competence.year' ,
            key: 'competence.year',
            render: (val: any, row: BudgetEntity) => row.competence.year
        },
        {
            title: 'Versão',
            dataIndex: 'version',
            key: 'version',
        },
        {
            title: 'Meses',
            dataIndex: 'months',
            key: 'months',
            render: renderMonthsCell
        },
        {
            title: 'Data Final da Revisão',
            dataIndex: 'finalDateRevision',
            render: (val: any) => dateTimeUtil.convertToDatePtBr(val)
        },
        {
            title: 'Data Inicial da Edição',
            dataIndex: 'initialDateEdit',
            render: (val: any) => dateTimeUtil.convertToDatePtBr(val)
        },
        {
            title: 'Data Final da Edição',
            dataIndex: 'finalDateEdit',
            render: (val: any) => dateTimeUtil.convertToDatePtBr(val)
        },
        { 
            dataIndex: 'id',
            render: (val: any, budget: BudgetEntity) => ( 
                <>
                    <ListActions
                        onEditClick={() => props.navigator.goToEditPage(val) }
                        onRemoveClick={() => removeBudget(val) }
                    />
                </>
            )
        }
    ]
    
    return (
        <>
            <HeaderBudget />
            <Divider />
            <Layout.Content>
                <Table 
                    columns={columnsBudget} 
                    dataSource={data} 
                    loading={budgetRepository.loading} />
            </Layout.Content>
            {
                monthsModalVisible
                && monthsModalVisible
                && <ListMonthsModal
                        onClose={closeMonthsModal}
                        visible={monthsModalVisible}
                        budgetId={monthsModalCCId as string}
                    />
            }
        </>
    );
}