import React, {useEffect, useState} from "react";
import {HeaderFinancialClass} from "../component/header.financial-class";
import {Divider, Layout, message, Table, Tag,} from "antd";
import {ListActions} from "../../../../core/component/list-actions";
import {useFinancialClassRepository} from "../../../../domain/repository/financial-class.repository";
import {FinancialClassEntity} from "../../../../domain/entity/financial-class.entity";
import {FinancialGroupEntity} from "../../../../domain/entity/financial-group.entity";
import { FilterListFinancialClass } from "../component/filter-list.financial-class";

export function ListFinancialClass(props: any) {
    const financialGroupRepo = useFinancialClassRepository();
    const [data, setData] = useState<FinancialClassEntity[]>([]);
    const [groupFilter, setGroupFilter] = useState<number>();

    function loadData() {
        financialGroupRepo.search().then(res => {
            setData(res.data)
        });
    }

    useEffect(() => {
        loadData()
    }, [1]);

    function removeGroup(id: string) {
        financialGroupRepo.remove(id)
            .then(res => {
                message.success(res.message);
                loadData();
            }).catch(err => {
                message.error(err.message);
        })
    }

    function getData() {
        if (!groupFilter) {
            return data;
        }

        return data.filter(w => w.group.id == groupFilter)
    }

    const columnsBudget = [
        {
            title: 'Grupo',
            dataIndex: 'group' ,
            key: 'group',
            render: (val: FinancialGroupEntity) => `${val.code} - ${val.name}`,
        },
        {
            title: 'Classe',
            dataIndex: 'name' ,
            key: 'name',
        },
        {
            title: 'Código',
            dataIndex: 'code' ,
            key: 'code',
            render: (code: string, row: FinancialClassEntity) => `${row.group.code}.${code}`

        },
        {
            title: 'Status',
            dataIndex: 'active',
            key: 'active',
            render: (value: boolean) => (
                <Tag color={value ? 'green' : 'red'}>
                    {value ? 'Ativo' : 'Inativo'}
                </Tag>
            )
        },
        {
            dataIndex: 'id',
            render: (val: any) => (
                <>
                    <ListActions
                        onEditClick={() => props.navigator.goToEditPage(val) }
                        onRemoveClick={() => removeGroup(val) }
                    />
                </>
            )
        }
    ]
    return (
        <>
            <HeaderFinancialClass />
            <Divider />
            <FilterListFinancialClass
                onFilterGroup={filter => setGroupFilter(parseInt(filter))}
            />
            <Layout.Content>
                <Table 
                    columns={columnsBudget} 
                    dataSource={getData()}
                    loading={financialGroupRepo.loading} />
            </Layout.Content>
        </>
    );
}