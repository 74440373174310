import {SelectBase} from "./select.base";
import {BudgetCategoryEntity} from "../../../domain/entity/budget-category.entity";

export type SelectBudgetCategoryProps = {
  data: BudgetCategoryEntity[],
  label?: string,
  name?: string,
  required?: boolean;
  onChange?: (value: string | number) => void,
}

export function SelectBudgetCategory(props: SelectBudgetCategoryProps) {
  function getOpts() {
    return props.data.map(item => ({
      label: `${item.name}`,
      value: item.id,
    }))
  }
  return (
    <SelectBase
      label={props.label ?? 'Categoria Orçamentária'}
      name={props.name ?? 'budgetCategoryId'}
      data={getOpts()}
      onChange={props.onChange}
      required={props.required}
    />
  )
}