import {
    Button,
    Col,
    Descriptions,
    Divider,
    Form,
    Input,
    InputNumber,
    List,
    message,
    Row,
    Switch,
    Typography
} from "antd";
import React, { useEffect, useState } from "react";
import {HeaderFinancialGroup} from "../component/header.financial-group";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {ErrorMessageResource} from "../resource/error-message.resource";
import {useFinancialGroupRepository} from "../../../../domain/repository/financial-group.repository";
import { useClientStorage } from "../../../../core/service/client.storage";
import { useDocumentFormatter } from "../../../service/document.formatter.service";
import moment from "moment";
import { PlusOutlined } from "@ant-design/icons";
import { FirmEntity } from "../../../../domain/entity/firm.entity";
import { CostCenterEntity } from "../../../../domain/entity/cost-center.entity";
import { ManagerialAccountEntity } from "../../../../domain/entity/managerial-account.entity";
import { useCostCenterRepository } from "../../../../domain/repository/account.repository";
import { useManagerialRepository } from "../../../../domain/repository/managerial.repository";
import { useFirmRepository } from "../../../../domain/repository/firm.repository";
import { useHistory } from "react-router-dom";

export function ShowHome(props: ModuleRenderProps) {
    const client = useClientStorage().getClient();
    const [firms, setFirms] = useState<FirmEntity[]>();
    const [costCenters, setCostCenters] = useState<CostCenterEntity[]>();
    const [mas, setMas] = useState<ManagerialAccountEntity[]>();
    const history = useHistory();
    const { formatCNPJ } = useDocumentFormatter();
    const costCenterRepo = useCostCenterRepository();
    const maRepo = useManagerialRepository();
    const firmRepo = useFirmRepository();

    function loadData() {
      costCenterRepo.findAll().then(res => setCostCenters(res.data));
      firmRepo.findAll().then(res => setFirms(res.data));
      maRepo.findAll().then(res => setMas(res.data));
    }

    useEffect(() => {
      loadData();
    }, [1])

    function renderItemFilial(item: FirmEntity) {
        return (
          <List.Item>
              <List.Item.Meta
                title={item.name}
                description={formatCNPJ(item.document)}
              />
          </List.Item>
        )
    }

    function renderItemMA(item: ManagerialAccountEntity) {
        return (
          <List.Item>
              <List.Item.Meta
                title={item.description}
                description={item.code}
              />
          </List.Item>
        )
    }

    function renderItemCostCenter(item: CostCenterEntity) {
        return (
          <List.Item>
              <List.Item.Meta title={item.name} description={item.code}
              />
          </List.Item>
        )
    }

    return (
        <>
            <Descriptions title={'Dados do Cliente'} bordered layout={'vertical'}>
                <Descriptions.Item label={'Empresa'}>
                    {client?.name}
                </Descriptions.Item>
                <Descriptions.Item label={'CNPJ'}>
                    {formatCNPJ(client?.document ?? '')}
                </Descriptions.Item>
                <Descriptions.Item label={'Data de Ingresso'}>
                    {client && moment(client.createdAt).format('DD/MM/YYY')}
                </Descriptions.Item>
            </Descriptions>
            <Divider />
            <Row gutter={22}>
                <Col span={8}>
                    <List
                        header={ <Typography.Title level={5}>Filiais</Typography.Title> }
                        dataSource={firms}
                        renderItem={renderItemFilial}
                        pagination={{pageSize: 5}}
                        footer={
                            <Button
                              block
                              onClick={() => history.push('/admin/firm/create')}
                              icon={<PlusOutlined />}
                              shape={'round'}
                              size={'middle'}>
                                Adicionar Filial
                            </Button>
                        }
                    />
                </Col>
                <Col span={8}>
                    <List
                        header={ <Typography.Title level={5}>Centros de Custo</Typography.Title> }
                        dataSource={costCenters}
                        pagination={{ pageSize: 5}}
                        renderItem={renderItemCostCenter}
                        footer={
                            <Button
                              onClick={() => history.push('admin/cost-center/create')}
                              icon={<PlusOutlined />}
                              block
                              shape={'round'}
                              size={'middle'}
                            >
                                Adcionar Centro de Custo
                            </Button>
                        }
                    />
                </Col>
                <Col span={8}>
                    <List
                        header={ <Typography.Title level={5}>Contas Gerenciais</Typography.Title> }
                        dataSource={mas}
                        renderItem={renderItemMA}
                        pagination={{pageSize: 10}}
                        footer={
                          <Button
                            onClick={() => history.push('admin/managerial-account/create')}
                            icon={<PlusOutlined />}
                            block
                            shape={'round'}
                            size={'middle'}
                          >
                            Adcionar Conta Gerencial
                          </Button>
                        }
                    />
                </Col>
            </Row>
        </> 
    )
}
