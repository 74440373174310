import {useState} from "react";

export type HttpArgs = {
  server: string,
  headers?: any,
  onRequestError?: (req: any, res: any, err: any) => any,
  onRequestListeners?: (req: any, res: any, err: any) => any,
  onResponse?: (req: any, res: any, err: any) => any,
  onRequestHeader?: () => any,
}

export enum HttpMethods {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete'
}

export type QueryParam = {
  key: string,
  value: any
}

export function useHttpService(httpArgs: HttpArgs) {
  const {
    headers: globalHeaders,
    server,
    onRequestError,
    onResponse,
    onRequestHeader
  } = httpArgs;
  const [loading, setLoading] = useState(false);
  const [errorListeners, setErrorListener] = useState<Array<(req: any, res: any, error: any) => void>>([]);

  async function request(
    method: string,
    uri: string,
    body: any,
    queryParams: QueryParam[] = [],
    requestHeaders?: any,
  ) {
    const { send, request } = prepareRequest({
      uri,
      method,
      body,
      queryParams,
      requestHeaders,
    });
    setLoading(true);
    return send()
      .then(res => parseJson(request, res))
      .catch((err) => onError(request, err))
      .finally(() => setLoading(false));
  }

  async function requestFormData(
    method: string,
    uri: string,
    body: FormData,
    queryParams: QueryParam[] = [],
    requestHeaders?: any,
  ) {
    const { send, request } = prepareRequest({
      uri,
      method,
      body,
      queryParams,
      requestHeaders,
    });
    setLoading(true);
    return send()
      .then(res => parseJson(request, res))
      .catch((err) => onError(request, err))
      .finally(() => setLoading(false));
  }

  async function downloadRequest(
    method: string,
    uri: string,
    fileName: string,
    body?: any,
    queryParams?: QueryParam[],
    requestHeaders?: any,
  ) {
    const { send, request } = prepareRequest({
      uri,
      method,
      body,
      queryParams,
      requestHeaders,
    });
    setLoading(true);
    return send()
      .then(res => onDownloadResponse(res, fileName))
      .catch((err) => onError(request, err))
      .finally(() => setLoading(false));
  }

  function prepareRequest(args: Request) {
    const {
      requestHeaders,
      body,
      queryParams,
      downloadResponse,
      method,
      uri
    } = args;

    let queryParamsStr = '';
    if(queryParams && queryParams.length > 0) {
      queryParamsStr = mountQueryParams(queryParams);
    }
    const url = `${server}/${uri}?${queryParamsStr}`;
    const request: Request = {
      body,
      method,
      uri,
      queryParams,
      requestHeaders,
      downloadResponse
    };

    let allHeaders = {
      ...globalHeaders,
      ...(onRequestHeader ? onRequestHeader() : null),
      ...requestHeaders,
    }
    if (requestHeaders && requestHeaders['Content-Type'] === 'multipart/form-data') {
      delete allHeaders['Content-Type'];
    }

    function send() {
      return fetch(url, {
        body,
        method,
        headers: allHeaders,
      })
    }

    return {
      send,
      request
    }
  }

  async function parseJson(request: any, response: Response) {
    setLoading(false);
    const responseJson = await response.json();
    if(response.ok) {
      if (onResponse) {
        return onResponse(request, responseJson, null)
      }
      return responseJson;
    }
    return Promise.reject({
      response: responseJson,
      status: response.status
    });
  }

  async function onDownloadResponse(response: Response, fileName: string) {
    const url = window.URL.createObjectURL(new Blob([await response.blob()]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    return Promise.resolve();
  }

  async function onError(request: Request, error?: any) {
    let res = error.response
      ? { ...error.response, status: error.status }
      : { status: 500, message: 'default_error'}

    if(onRequestError) {
      return onRequestError(request, res, error);
    }

    setLoading(false);
    return Promise.reject({
      error,
      res,
      message: 'Servidor Indisponível'
    });
  }

  async function addErrorListener(listener: (req: any, res: any, error: any) => void) {
    setErrorListener([...errorListeners, listener]);
  }

  async function post(
    uri: string,
    body: any = undefined,
    queryParams?: QueryParam[],
    headers?: any
  ) {
    return request(HttpMethods.POST, uri, body, queryParams, headers);
  }

  async function get(
    uri: string,
    body: any = undefined,
    queryParams?: QueryParam[],
    headers?: any
  ) {
    return request(HttpMethods.GET, uri, body, queryParams, headers);
  }


  async function put(uri: string, body: any = undefined, queryParams?: QueryParam[]) {
    return request(HttpMethods.PUT, uri, body, queryParams);
  }

  async function _delete(uri: string, body: any = undefined, queryParams?: QueryParam[]) {
    return request(HttpMethods.DELETE, uri, body, queryParams);
  }

  function mountQueryParams(queryParameters: QueryParam[]): string {
    let queryParamsStr = '';
    for(let item of queryParameters) {
      queryParamsStr = `${queryParamsStr}${item.key}=${item.value}&`
    }
    return queryParamsStr;
  }

  return {
    loading,
    request,
    post,
    get,
    put,
    delete: _delete,
    addErrorListener,
    downloadRequest
  }
}

export type Request = {
  method: string,
  uri: string,
  body?: any,
  queryParams?: QueryParam[],
  requestHeaders?: any,
  downloadResponse?: boolean,
}
