import {Checkbox, Col, DatePicker, Divider, Form, message, Row} from "antd";
import React, {useEffect, useState} from "react";
import {HeaderBudget} from "../component/header.budget";
import {useBudgetRepository} from "../../../../domain/repository/budget.repository";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {MonthsEnum} from "../../../../domain/vo/months.enum";
import {useParams} from "react-router-dom";
import moment from "moment";

export function EditBudget(props: ModuleRenderProps) {
    const [form] = Form.useForm();
    const budgetRepo = useBudgetRepository();
    const [months, setMonths] = useState<Array<number>>([]);
    const { id: budgetId } = useParams<{ id: string }>()

    useEffect(() => {
        form.setFields([{
            name: 'months', value: months
        }])
    },[months])

    useEffect(() => {
        if (budgetId) {
            budgetRepo.find(budgetId).then(res => {
                form.setFields([
                    { name: 'finalDateRevision' , value: moment(res.data.finalDateRevision) },
                    { name: 'initialDateEdit' , value: moment(res.data.initialDateEdit) },
                    { name: 'finalDateEdit' , value: moment(res.data.finalDateEdit) }
                ])
                setMonths(res.data.months);
            })
        }
    }, [budgetId])


    function onFinish(data: any) {
        data = {
            months: months,
            finalDateRevision: data.finalDateRevision.format(),
            competence: data.competence,
            initialDateEdit: data.initialDateEdit.format(),
            finalDateEdit: data.finalDateEdit.format()
        }

        if (months.length === 0) {
            message.error("Selecione o(s) mes(es)");
            return;
        }

        budgetRepo.update(budgetId, data).then(res => {
            form.resetFields();
            message.success(res.message)
            props.navigator.goToListPage();
        }).catch(err => {
            message.error(err.message);
            if(err.messageKey === 'budget:duplicated_version_error') {
                form.setFields([{
                    name: 'code',
                    errors: [err.message]
                }])
            }
        })
    }

    function onChange(values: number[]) {
        const _months = [];
        if (values.length === 0) {
            return
        }
        const lastValue = values.reduce((a, b) => a < b ? a : b);
        for (let i = 12; i >= lastValue; i--) {
            _months.push(i);
        }
        setMonths(_months)
    }

    return (
        <>
            <HeaderBudget mode={'form'} />
            <Divider />
            <Form form={form} layout="vertical" name="nest-messages" onFinish={onFinish} >
                <Row gutter={12}>
                    <Col span={24}>
                        <Form.Item name={'months'} label="Meses" >
                            <Checkbox.Group
                                options={MonthsEnum.getValues()}    
                                style={{ width: '100%' }} 
                                onChange={_months => onChange(_months as number[])} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Data Inicial da Edição"
                            name={'initialDateEdit'}
                            style={{width: '100%'}}
                            rules={[{ required: true }]}
                        >
                            <DatePicker format='DD/MM/YYYY' style={{width: '100%'}} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Data Final da Edição"
                            name={'finalDateEdit'}
                            style={{width: '100%'}} rules={[{ required: true }]}
                        >
                            <DatePicker format='DD/MM/YYYY' style={{width: '100%'}} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Data Final de Revisão"
                            name={'finalDateRevision'}
                            style={{width: '100%'}}
                            rules={[{ required: true }]}
                        >
                            <DatePicker format='DD/MM/YYYY' style={{width: '100%'}} />
                        </Form.Item>
                    </Col>
                </Row>
                <FormActionsButtons
                    onCancel={props.navigator.goToDefaultPage}
                    isLoading={budgetRepo.loading}
                />
            </Form>
    
            
        </> 
    )
}
