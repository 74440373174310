import {Button, Col, Form, Input, InputNumber, message, Row, Select, Typography} from "antd";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import React, {useEffect, useState} from "react";
import {FinancialEntryEntity} from "../../../../domain/entity/financial-entry.entity";
import {FinancialEntryItemEntity} from "../../../../domain/entity/financial-entry-item.entity";
import {FinancialItemEntity} from "../../../../domain/entity/financial-item.entity";
import {BudgetCategoryEntity} from "../../../../domain/entity/budget-category.entity";
import {SupplyUnitEntity} from "../../../../domain/entity/supply-unit.entity";
import {useFinancialItemRepository} from "../../../../domain/repository/financial-item.repository";
import {useFinancialClassRepository} from "../../../../domain/repository/financial-class.repository";
import {ListFinancialEntryItem} from "./list.financial-entry-item";
import {useFinancialEntryItemRepository} from "../../../../domain/repository/financial-entry-item.repository";
import {useFinancialEntryRepository} from "../../../../domain/repository/financial-entry.repository";
import {useNumberFormatter} from "../../../service/number-formatter.service";
import {BrlInputNumber} from "../../../../core/component/brl-input-number";
import {useFinancialEntryItemService} from "../../../../domain/service/financial-entry-item.service";
import {ArrowRightOutlined} from "@ant-design/icons";
import {InputSearch} from "../../../../core/component/input-search";
import {SelectFinancialItem} from "../../../component/dropdown/select.financial-item";

export type FormFinancialEntryItemProps = {
  entry: FinancialEntryEntity,
  items?: FinancialEntryItemEntity[]
  setItems: (items: FinancialEntryItemEntity[]) => void,
  onFinish: (items: FinancialEntryItemEntity[]) => void,
  onBack: () => void
}

export function FormFinancialEntryItem(props: FormFinancialEntryItemProps) {
  const [form] = Form.useForm();
  const [financialItems, setFinancialItems] = useState<FinancialItemEntity[]>([]);
  const [categories, setCategories] = useState<BudgetCategoryEntity[]>([]);
  const [supplyUnits, setSupplyUnits] = useState<SupplyUnitEntity[]>([]);

  const { currencyFormatter } = useNumberFormatter();
  const { calcFinalValue } = useFinancialEntryItemService();
  const financialItemRepo = useFinancialItemRepository();
  const financialClassRepo = useFinancialClassRepository();
  const financialEntryItemRepo = useFinancialEntryItemRepository();
  const financialEntryRepo = useFinancialEntryRepository();

  function loadParams() {
    financialItemRepo.listByEntryType(props.entry.type).then(res => {
      setFinancialItems(res.data)
    });
  }

  function loadEntryItems() {
    financialEntryRepo.find(props.entry.id.toString()).then(res => {
      props.setItems(res.data.items ?? []);
    })
  }

  function loadCategoriesAndSupplyUnits(itemId: number) {
    console.log(itemId);
    const item = financialItems.find(w => w.id == itemId);
    form.resetFields(['budgetCategoryId', 'supplyUnitId']);
    if (!item) return;
    setSupplyUnits(item.supplyUnits);
    financialClassRepo.find(item.classId.toString()).then(res => {
      setCategories(res.data.budgetCategories);
    })
  }

  function loadDefaultValues() {
    console.log('defaul values loaded')
    form.setFields([
      // {name: 'singleValue', value: 0},
      {name: 'discount', value: 0},
      {name: 'quantity', value: 1},
      {name: 'taxWithholding', value: 0},
      {name: 'finalValue', value: 0},
    ])
  }

  useEffect(() => {
    loadParams();
    loadDefaultValues();
  }, [1])

  function onFinish(values: any) {
    values = {
      ...values,
      financialEntryId: props.entry?.id
    }
    financialEntryItemRepo.create(values).then(res => {
      message.success(res.message);
      loadEntryItems();
    }).catch(err => {
      message.error(err.message);
    })
  }

  function getItemOpts() {
    return financialItems.map(item => ({
      label: item.description,
      value: item.id
    }))
  }

  function getSupplyUnitOpts() {
    return supplyUnits.map(item => ({
      label: item.description,
      value: item.id
    }))
  }

  function getCategories() {
    return categories.map(item => ({
      label: item.name,
      value: item.id
    }))
  }

  function calcLiquidValue() {
    const singleValue = parseFloat(form.getFieldValue('singleValue') ?? 0)
    const quantity = parseFloat(form.getFieldValue('quantity') ?? 0)
    const discount = parseFloat(form.getFieldValue('discount') ?? 0)
    const result = calcFinalValue( singleValue, quantity, discount );

    if (result > 0) {
      form.setFields([{ name: 'finalValue' , value: currencyFormatter.format(result), errors: [] }])
    } else {
      form.setFields([{ name: 'finalValue' , value: currencyFormatter.format(result),
        errors: ['O valor deve ser maior que 0'] }])
    }
  }

  function onFinishStep() {
    if (props.items && props.items.length) {
      props.onFinish(props.items);
    } else {
      alert('Deve ser Cadastrado ao meno 1 item');
    }
  }

  return (
    <Form form={form} layout={'vertical'} onFinish={onFinish}>
      <Row gutter={12}>
        <Col span={8}>
          <SelectFinancialItem
            data={financialItems}
            name={'financialItemId'}
            onChange={value => loadCategoriesAndSupplyUnits(value as number)}
          />
        </Col>
        <Col span={8}>
          <Form.Item name={'budgetCategoryId'} label={'Categoria Orçamentária'} rules={[{ required: true }]}>
            <Select options={getCategories()} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name={'supplyUnitId'} label={'Unidade de Fornecimento'} rules={[{ required: true }]}>
            <Select options={getSupplyUnitOpts()} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={6}>
          <BrlInputNumber
            name={'singleValue'}
            label={'Valor Unitário'}
            required={true}
            onBlur={() => calcLiquidValue()}
          />
        </Col>
        <Col span={6}>
          <Form.Item label={'Quantidade'} name={'quantity'} rules={[{ required: true }]} >
            <InputNumber
              style={{width: '100%'}}
              onBlur={() => calcLiquidValue()}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <BrlInputNumber
            name={'discount'}
            label={'Descontos'}
            required={true}
            onBlur={() => calcLiquidValue()}
          />
        </Col>
        <Col span={6}>
          <Form.Item name={'finalValue'} label={'Valor Líquido'} rules={[{ required: true }]}>
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>
      <FormActionsButtons
        label={'Salvar'}
        labelCancel={'Voltar'}
        onCancel={() => props.onBack()}
        moreAction={[
          <Button
            type={'ghost'}
            icon={<ArrowRightOutlined />}
            onClick={onFinishStep}
            shape={'round'}
          >
            Continuar
          </Button>
        ]}
      />
      <ListFinancialEntryItem
        data={props.items}
        onRemoved={() => loadEntryItems()}
      />
    </Form>
  )
}