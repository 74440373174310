import React, {useEffect, useState} from "react";
import {HeaderUser} from "../component/header.user";
import {Alert, Button, Col, Divider, Form, Input, message, Row, Select} from "antd";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import {useUserRepository} from "../../../../domain/repository/user.repository";
import {useProfileRepository} from "../../../../domain/repository/profile.repository";
import {ProfileEntity} from "../../../../domain/entity/profile.entity";
import {ErrorMessageResource} from "../resource/error-message.resource";
import {ModuleRenderProps} from "../../../../core/component/module/module.root";


export function CreateUser(props: ModuleRenderProps) {
      const [form] = Form.useForm();
      const [profiles, setProfiles] = useState<ProfileEntity[]>([]);
      const [showAlertDuplicated, setShowAlertDuplicated] = useState(false);

      const userRepository = useUserRepository();
      const profileRepo = useProfileRepository();

      useEffect(() => {
          profileRepo.search().then(res => setProfiles(res.data));
      }, [1])

     function onFinish(data: any) {
     data = {
        ...data,
        profiles: profiles.filter(item => data.profiles.includes(item.id))
     }
     userRepository.create(data).then(
       res => {
         form.resetFields();
         message.success(res.message)
         props.navigator.goToListPage();
       }
     ).catch(err => {
       console.log(err.messageKey)
       switch(err.messageKey) {
         case ErrorMessageResource.DUPLICATED_USERNAME:
           form.setFields([{
               name: 'username', errors: [err.message]
           }]);
           break;
           case ErrorMessageResource.DUPLICATED_EMAIL:
             form.setFields([{
                 name: 'email', errors: [err.message]
             }]);
             setShowAlertDuplicated(true);
             break;
           default:
             message.error(err.message);
             break;
       }
    })
    }

    function getProfileOptions() {
      return profiles.map(item => ({
        label: item.name,
        value: item.id
      }))
    }

    function importUser() {
      const email = form.getFieldValue('email');
      userRepository.importUser(email, 'email').then(res => {
        message.success(res.message)
        res.data.id && props.navigator.goToEditPage(res.data.id.toString());
      }).catch(err => {
        message.error(err.message);
      })
    }

    function AlertDuplicateUser() {
      return (
        <Alert
          showIcon
          closable
          onClose={() => setShowAlertDuplicated(false)}
          message={'Dado Duplicado'}
          description={'Caso seja o mesmo usuário, clique no botão para habilitar o acesso do mesmo. Após isso,' +
          'será necessário fornecer os perfis de acesso ao mesmo.'}
          type={'warning'}
          action={<Button onClick={importUser}>Importar</Button>}
        />
      )
    }

    return (
      <>
        <HeaderUser mode={'form'} />
        <Divider />
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item name={['username']} label="Usuário" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={['email']} label="Email" rules={[{required: true, type: 'email'}]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          {
            showAlertDuplicated && (
              <Row>
                <Col span={24}>
                  <AlertDuplicateUser />
                </Col>
              </Row>
            )
          }
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item name={['name']} label="Nome" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={'profiles'} label={"Selecione os perfis do usuários"} rules={[{ required: true}]} >
                <Select options={getProfileOptions()} mode={"multiple"} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item name={['password']} label="Senha" rules={[{ required: true }]}>
                <Input.Password />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="confirm"
                label="Confirmação de senha"
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Por favor, confirme sua senha!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('As duas senhas que você digitou não coincidem!'));
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
          </Row>
          <FormActionsButtons
              onCancel={props.navigator.goToDefaultPage}
              isLoading={userRepository.loading}
          />
        </Form>
      </>
    )
  }
