import React, {useEffect} from 'react';
import './App.less';
import {AdminModule} from "./admin";
import {BrowserRouter, Route, Switch, Redirect} from "react-router-dom";
import {AuthModule} from "./admin/module/auth";
import {useAuthService} from "./admin/service/auth.service";
import ptBr from "antd/lib/locale/pt_BR";
import {ConfigProvider} from "antd";

function App() {
  const authService = useAuthService();

  useEffect(() => {
    const localEnv = process.env.REACT_ENV ?
      process.env.REACT_ENV == 'PROD' ? '' : `(${process.env.REACT_ENV})`
      : ''
    document.title = `${localEnv}${document.title}`
  },[1])

  function renderAdminModule() {
    if(authService.isAuthenticated()) {
      return <AdminModule />
    }
    return <Redirect to={'auth'} />;
  }

  const validateMessages = {
    required: '${label} é um dado obrigatório!',
    types: {
      email: '${label} não é um email valido!',
    },
  };

  return (
    <ConfigProvider locale={ptBr} componentSize={"large"} form={{ validateMessages }}>
      <BrowserRouter>
        <Switch>
          <Route path={'/auth'}>
            <AuthModule />
          </Route>
          <Route
            path={'/admin'}
            render={renderAdminModule}
          />
          <Route path={''} >
            <Redirect to={'/admin'} />
          </Route>
        </Switch>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
