import {isNull} from "util";

export function useCodeValidator() {

    function isCodeCompleted(mask: string, code: string) : boolean {
        const length = mask.length;
        let numbers = 0;
        for (const char of code) {
            if (char !== '.' && char.match('[0-9]') === null) {
                break;
            }
            numbers++;
        }

        return numbers  === length;
    }

    return {
        isCodeCompleted
    }
}