import {Divider, List, Tabs} from "antd";
import React, {useEffect, useState} from "react";
import {HeaderLedgerAccount} from "../component/header.ledger-account.";
import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {useParams} from "react-router-dom";
import {useLedgerAccountRepository} from "../../../../domain/repository/ledger-account.repository";
import {LedgerAccountEntity} from "../../../../domain/entity/ledger-account.entity";
import {FormLedgerAccount} from "../component/form.ledger-account";

export function EditLedgerAccount(props: ModuleRenderProps) {
  const [entity, setEntity] = useState<LedgerAccountEntity>();
  const ledgerAccountRepo = useLedgerAccountRepository();
  const { id } = useParams<{id: string}>();

  useEffect(() => {
    ledgerAccountRepo.find(id)
      .then(res => {
        setEntity(res.data);
      })
  }, []);

  return (
    <>
      <HeaderLedgerAccount
        title={`Editando conta ${entity?.name}`}
        mode={'form'}
      />
      <Tabs>
        <Tabs.TabPane tab={'Dados Básicos'} key={1}>
          {
            entity && (
              <FormLedgerAccount
                onSaved={props.navigator.goToListPage}
                onCancel={props.navigator.goToListPage}
                entity={entity}
              />
            )
          }
        </Tabs.TabPane>
        <Tabs.TabPane tab={'Contas Gerenciais'}>
          <List
            dataSource={entity?.managerialAccounts ?? []}
            renderItem={(ma) => (
              <List.Item>
                <List.Item.Meta
                  title={ma.description}
                  description={ma.code}
                />
              </List.Item>
            )}
          />
        </Tabs.TabPane>
      </Tabs>
    </>
  )
}
