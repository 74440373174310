import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {HeaderBankAccount} from "../component/header.bank-account";
import {Descriptions, Divider, message, Modal, Table, Tag} from "antd";
import React, {useEffect, useState} from "react";
import {BankAccountTransferenceEntity} from "../../../../domain/entity/bank-account-transference.entity";
import {useBankAccountRepository} from "../../../../domain/repository/bank-account.repository";
import {useParams} from "react-router-dom";
import {BankAccountEntity} from "../../../../domain/entity/bank-account.entity";
import {useDateTimeUtil} from "../../../../core/util/date-time.util";
import {useNumberFormatter} from "../../../service/number-formatter.service";
import {ListActions} from "../../../../core/component/list-actions";

export function ListTransference(props: ModuleRenderProps) {
  const [data, setData] = useState<BankAccountTransferenceEntity[]>([]);
  const [account, setAccount] = useState<BankAccountEntity>();
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [currentTransference, setCurrentTransference] = useState<number>();

  const { convertToDatePtBr } = useDateTimeUtil();
  const { decimalFormatter } = useNumberFormatter();
  const { id: bankAccountId } = useParams<any>();
  const bankAccountRepo = useBankAccountRepository();

  function loadData() {
    if (!bankAccountId) return;
    bankAccountRepo.listTransference(bankAccountId, limit,
      (page - 1) * limit
    ).then(res => {
      setTotal(res.total);
      setData(res.data)
    })
  }

  function cancelTransference(transferenceId: number) {
    return bankAccountRepo.cancelTransference(transferenceId)
      .then(res => {
        message.success(res.message);
        setCurrentTransference(undefined);
        loadData();
      }).catch(err => {
        setCurrentTransference(undefined);
        message.error(err.message);
      })
  }

  function loadAccountInfo() {
    if (!bankAccountId) return;
    bankAccountRepo.find(bankAccountId).then(res => setAccount(res.data))
  }

  useEffect(() => {
    loadData();
    loadAccountInfo();
  }, [bankAccountId])

  useEffect(() => {
    loadData();
  }, [page])

  function ConfirmCancel(props: { transferenceId: number }) {
    const transference = data.find(w => w.id === props.transferenceId);
    return (
      <Modal
        title={'Deseja cancelar essa transferência ?'}
        onCancel={() => setCurrentTransference(undefined)}
        width={600}
        visible={true}
        onOk={() => cancelTransference(props.transferenceId)}
        bodyStyle={{padding: 0}}
      >
        <Descriptions layout={'vertical'} bordered>
          <Descriptions.Item label={'Tipo'}>
            {transference?.accountFromId === bankAccountId ? 'Debito' : 'Credito'}
          </Descriptions.Item>
          <Descriptions.Item label={'Origem'}>
            {transference?.accountFrom?.alias}
          </Descriptions.Item>
          <Descriptions.Item label={'Destino'}>
            {transference?.accountTo?.alias}
          </Descriptions.Item>
          <Descriptions.Item label={'Valor'}>
            {transference?.amount && decimalFormatter.format(transference.amount)}
          </Descriptions.Item>
          <Descriptions.Item label={'Data'}>
            {transference?.dateTime && convertToDatePtBr(transference?.dateTime)}
          </Descriptions.Item>
          <Descriptions.Item label={'Usuário'}>
            {transference?.user?.username}
          </Descriptions.Item>
        </Descriptions>
      </Modal>
    )
  }

  const renderStatus = (isCanceled: boolean) => {
    if (isCanceled) {
      return <Tag color={'red'}>Cancelado</Tag>
    }
    return <Tag>Ativo</Tag>
  }

  return (
    <>
      <HeaderBankAccount
        title={'Transferências Realizadas'}
        subtitle={`${account?.alias ?? ''}`}
        mode={'form'}
      />
      <Divider />
      <Table
        dataSource={data}
        loading={bankAccountRepo.loading}
        pagination={{
          total,
          current: page,
          pageSize: limit,
          onShowSizeChange: (c,b) => setLimit(b),
          onChange: setPage,
        }}
        columns={[
          {
            title: 'Tipo',
            dataIndex: 'id',
            key: 'type',
            render: (item, row) => row.accountFromId === account?.id ? 'Débito' : 'Crédito',
          },
          {
            title: 'Conta Origem',
            dataIndex: 'accountFrom',
            render: item => item?.alias,
          },
          {
            title: 'Conta Destino',
            dataIndex: 'accountTo',
            render: item => item?.alias,
          },
          {
            title: 'Valor',
            dataIndex: 'amount',
            render: decimalFormatter.format,
          },
          {
            title: 'Data',
            dataIndex: 'dateTime',
            render: convertToDatePtBr,
          },
          {
            title: 'Usuário',
            dataIndex: 'user',
            render: item => item?.username
          },
          {
            title: 'Status',
            dataIndex: 'isCanceled',
            render: renderStatus
          },
          {
            dataIndex: 'id',
            render: (val: any, row: any) => (
              <>
                <ListActions
                  options={[
                    {
                      name: 'CANCELAR',
                      onClick: () => setCurrentTransference(val),
                      disabled: row.isCanceled,
                    },
                  ]}
                />
              </>
            )
          }
        ]}
      />
      {
        currentTransference && (
          <ConfirmCancel transferenceId={currentTransference} />
        )
      }
    </>
  )
}