import React, {useEffect, useState} from "react";
import {HeaderUser} from "../component/header.user";
import {Col, Divider, Form, Input, message, Row, Select} from "antd";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import {UserEntity} from "../../../../domain/entity/user.entity";
import {useUserRepository} from "../../../../domain/repository/user.repository";
import {useParams} from "react-router-dom";
import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {useProfileRepository} from "../../../../domain/repository/profile.repository";
import {ProfileEntity} from "../../../../domain/entity/profile.entity";
import {ErrorMessageResource} from "../resource/error-message.resource";

export function EditUser(props: ModuleRenderProps) {
  const [form] = Form.useForm();
  const userService = useUserRepository();
  const [profiles, setProfiles] = useState<ProfileEntity[]>([]);
  const [user, setUser] = useState<UserEntity>();
  const { id } = useParams<{id: string}>();
  const profileRepo = useProfileRepository();

  useEffect(() => {
    profileRepo.search().then(res => setProfiles(res.data));
  }, [])

  function loadFirm() {
    userService.find(id)
      .then(res => setUser(res.data));
  }

  function fillForm() {
    const profileIds = user?.profiles.map(item => item.id);
    form.setFields([
      { name: 'name' , value: user?.name },
      { name: 'email' , value: user?.email },
      { name: 'username' , value: user?.username },
      { name: 'password' , value: user?.password },
      { name: 'profiles' , value: profileIds },
    ])
  }

  useEffect(() => {
    loadFirm()
  }, [id]);
  useEffect(() => {
    fillForm()
  }, [user]);

  function onFinish(data: any) {
    data = {
      ...data,
      profiles: profiles.filter(item => data.profiles.includes(item.id))
    }
    userService.update(id, data).then(
      res => {
        form.resetFields();
        message.success(res.message);
        props.navigator.goToDefaultPage();
      }
    ).catch(err => {
      switch(err.mesageKey) {
        case ErrorMessageResource.DUPLICATED_USERNAME:
          form.setFields([{
            name: 'username', errors: [err.message]
          }]);
          break;
        case ErrorMessageResource.DUPLICATED_EMAIL:
          form.setFields([{
            name: 'email', errors: [err.message]
          }]);
          break;
        default:
          message.error(err.message);
          break;
      }

    })
  }

  function getProfileOptions() {
    return profiles?.map(item => ({
      label: item.name,
      value: item.id
    }))
  }

  return (
    <>
      <HeaderUser mode={'form'} title={' Usuário '} subtitle={'Edição de usuário'}/>
      <Divider />
      <Form form={form} layout="vertical" name="nest-messages" onFinish={onFinish}>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item name={['name']} label="Nome" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={['email']} label="Email" rules={[{required: true, type: 'email'}]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item name={['username']} label="Usuário" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={'profiles'} label={"Selecione os perfis do usuários"} rules={[{ required: true}]} >
              <Select options={getProfileOptions()} mode={"multiple"} />
            </Form.Item>
          </Col>
        </Row>
        <FormActionsButtons isLoading={userService.loading} />
      </Form>


    </>
  )
}
