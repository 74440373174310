import {useRouteMatch} from "react-router-dom";
import {ModuleResource, ModuleResourceTypeEnum, ModuleRoot} from "../../../core/component/module/module.root";
import React from "react";
import {ReportDreRealized} from "./pages/report.dre-realized";
import {ReportDreBudgeted} from "./pages/report.dre-budgeted";
import {ReportCashFlow} from "./pages/report.cash-flow";
import {ReportAnalyticCostCenter} from "./pages/report.analytic-cost-center";
import {ReportEntriesWithRetention} from "./pages/report.entries-with-retention";

export function ReportDreModule() {
    const routeMatch = useRouteMatch();
    const resources: ModuleResource[] = [
        {
            type: ModuleResourceTypeEnum.CUSTOM,
            customPath: 'budgeted',
            render: (props) => <ReportDreBudgeted {...props} />
        },
        {
            type: ModuleResourceTypeEnum.CUSTOM,
            customPath: 'cash-flow',
            render: (props) => <ReportCashFlow {...props} />
        },
        {
            type: ModuleResourceTypeEnum.CUSTOM,
            customPath: 'cash-flow-cost-center',
            render: (props) => <ReportAnalyticCostCenter {...props} />
        },
        {
            type: ModuleResourceTypeEnum.CUSTOM,
            customPath: 'entries-with-retention',
            render: (props) => <ReportEntriesWithRetention {...props} />
        },
        {
            type: ModuleResourceTypeEnum.DEFAULT,
            render: (props) => <ReportDreRealized {...props} />
        },
    ]

    return (
        <ModuleRoot
            resource={routeMatch.url}
            moduleResources={resources}
        />
    );
}
