import {Button} from "antd";
import {SisternodeOutlined} from "@ant-design/icons";
import {useProfileStorage} from "../../service/profile.storage";

export function RoleButton() {
    const profileStorage = useProfileStorage();


    return <Button type={'text'} icon={<SisternodeOutlined />}>{profileStorage.getProfile()?.name}</Button>;
}
