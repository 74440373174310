import {Col, Divider, Form, Input, InputNumber, message, Row} from "antd";
import {useFirmRepository} from "../../../../domain/repository/firm.repository";
import {FirmEntity} from "../../../../domain/entity/firm.entity";
import {HeaderFirm} from "../component/header.firm";
import MaskedInput from "antd-mask-input";
import {StateField} from "../component/state.field";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import React, {useEffect, useState} from "react";
import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {CNPJValidator} from "../service/cnpj.validator";
import {PostalCodeField} from "../component/postal-code.field";
import {useParams} from "react-router-dom";

export function EditFirm(props: ModuleRenderProps) {
    const [form] = Form.useForm();
    const firmRepository = useFirmRepository();
    const [firm, setFirm] = useState<FirmEntity>();
    const { id } = useParams<{id: string}>();

    function loadFirm() {
        firmRepository.find(id)
            .then(res => setFirm(res.data));
    }

    function fillForm() {
        form.setFields([
            { name: 'name' , value: firm?.name },
            { name: 'document' , value: firm?.document },
            { name: 'addressCity' , value: firm?.addressCity },
            { name: 'addressComplement' , value: firm?.addressComplement },
            { name: 'addressStreet' , value: firm?.addressStreet },
            { name: 'addressDistrict' , value: firm?.addressDistrict },
            { name: 'addressNumber' , value: firm?.addressNumber },
            { name: 'addressPostalCode' , value: firm?.addressPostalCode },
            { name: 'addressState' , value: firm?.addressState },
            { name: 'matrix' , value: firm?.matrix },
        ])

    }

    useEffect(() => loadFirm(), [id]);
    useEffect(() => fillForm(), [firm]);

    function onFinish(data: FirmEntity) {
        firmRepository.update(id, data).then(
            res => {
                form.resetFields();
                message.success('Empresa salva com sucesso');
                props.navigator.goToDefaultPage();
            }
        ).catch(err => {
            message.error(err.message);
        })
    }

    return (
        <>
            <HeaderFirm mode={'form'} title={'Editar Empresa '} />
            <Divider />
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Row gutter={12}>
                    <Col span={14}>
                        <Form.Item
                            label="Nome"
                            name={'name'}
                            style={{width: '100%'}}
                            rules={[{ required: true }]}
                        >
                            <Input style={{width: '100%'}} />
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item
                            label="Documento (CNPJ)"
                            name={'document'}
                            rules={[{ validator: CNPJValidator }, { required: true }]} >
                            <MaskedInput mask="##.###.###/####-##" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={6} >
                        <PostalCodeField setFields={form.setFields} />
                    </Col>
                    <Col offset={1} span={9} >
                        <Form.Item
                            label="Endereço"
                            name={`addressStreet`}
                            rules={[{ required: true }]}>
                            <Input placeholder="Rua 1° de janeiro" />
                        </Form.Item>
                    </Col>
                    <Col span={7} offset={1}>
                        <Form.Item
                            label="Número"
                            name={'addressNumber'}
                            rules={[{ required: true }]}>
                            <InputNumber placeholder="123" style={{width: '100%'}} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={12} >
                        <Form.Item
                            label="Bairro"
                            name={'addressDistrict'}
                            rules={[{ required: true }]}>
                            <Input placeholder="Oliveiras" />
                        </Form.Item>
                    </Col>
                    <Col offset={1} span={11}>
                        <Form.Item
                            label="Cidade"
                            name={'addressCity'}
                            rules={[{ required: true }]}>
                            <InputNumber placeholder="Santana" style={{width: '100%'}} />
                        </Form.Item>
                    </Col>
                </Row>

                <StateField name={'addressState'} label={'Estado'} />

                <FormActionsButtons isLoading={firmRepository.loading} />
            </Form>
        </>
    )
}