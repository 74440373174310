import {RecurrenceEntryPeriodEnum} from "../../../../domain/entity/recurrence-entry.entity";

export function recurrencePeriodTranslate(item: RecurrenceEntryPeriodEnum) {
  switch (item) {
    case RecurrenceEntryPeriodEnum.DAILY:
      return 'Diário';
    case RecurrenceEntryPeriodEnum.WEEKLY:
      return 'Semanal';
    case RecurrenceEntryPeriodEnum.MONTHLY:
      return 'Mensal';
    case RecurrenceEntryPeriodEnum.QUARTERLY:
      return 'Trimestral';
    case RecurrenceEntryPeriodEnum.SEMESTER:
      return 'Semestral';
    case RecurrenceEntryPeriodEnum.YEARLY:
      return 'Anual';
    default:
      return item;
  }
}