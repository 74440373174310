import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {useFinancialEntryRepository} from "../../../../domain/repository/financial-entry.repository";
import React, {useEffect, useState} from "react";
import {FinancialEntryInstallmentEntity} from "../../../../domain/entity/financial-entry-installment.entity";
import {ColumnsType} from "antd/es/table/Table";
import {Button, Divider, Drawer, message, Modal, Table} from "antd";
import {useNumberFormatter} from "../../../service/number-formatter.service";
import {useDateTimeUtil} from "../../../../core/util/date-time.util";
import {ArrowDownOutlined} from "@ant-design/icons";
import {FormWriteOff} from "../component/form.write-off";
import {FilterFinancialEntryInstallment} from "../component/filter.financial-entry-installment";
import {HeaderFinancialEntry} from "../component/header.financial-entry";
import {Route, Switch, useHistory, useRouteMatch} from "react-router-dom";
import {ShowFinancialEntry} from "./show.financial-entry";

export function ListFinancialEntryInstallment(props: ModuleRenderProps) {
  const [data, setData] = useState<FinancialEntryInstallmentEntity[]>([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState<any>();
  const [installment, setInstallment] = useState<FinancialEntryInstallmentEntity>();

  const { currencyFormatter } = useNumberFormatter();
  const { convertToDatePtBr } = useDateTimeUtil();

  const routeMatch = useRouteMatch();
  const history = useHistory();
  const entryRepo = useFinancialEntryRepository();

  function loadData() {
    entryRepo.listInstallments({
      filter,
      limit: limit,
      offset: limit * (page - 1)
    }).then(res => {
      setTotal(res.total);
      setData(res.data)
    });
  }

  function exportData() {
    console.log('export...')
    entryRepo.listInstallments({
      filter,
      limit: limit,
      offset: limit * (page - 1),
      export: true,
    }).then(() => {
      message.info('O sistema so ira exportar no máximo 5000 linhas por questões de desempenho.')
    });
  }

  useEffect(() => {
    loadData();
  },[page, filter])

  const columns: ColumnsType<any> = [
    {
      title: 'Movimento',
      key: 'financialEntryId',
      dataIndex: 'financialEntryId',
      render: val =>(
        <Button
          onClick={() => history.push(`entry/${val}`)}
          type={'text'}
        >
          {val}
        </Button>
      )
    },
    {
      title: 'Documento',
      key: 'financialEntry',
      dataIndex: 'financialEntry',
      render: financialEntry => financialEntry?.documentNumber
    },
    {
      title: 'Cli/For',
      key: 'person',
      dataIndex: 'financialEntry',
      render: entry => entry?.person?.name
    },
    {
      title: 'Data de Entrada',
      key: 'dateTimeEntry',
      dataIndex: 'financialEntry',
      render: entry => convertToDatePtBr(entry?.dateTimeEntry)
    },
    {
      title: 'Parcela',
      key: 'order',
      dataIndex: 'order',
      render: (value: number, row: FinancialEntryInstallmentEntity) => `${value} / ${row?.financialEntry?.totalInstallments}`
    },
    {
      title: 'Vencimento',
      dataIndex: 'dueDate',
      key: 'duaDate',
      render: (value) => convertToDatePtBr(value)
    },
    {
      title: 'Valor',
      dataIndex: 'value',
      key: 'value',
      render: (value) => currencyFormatter.format(value)
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      align: 'right',
      render: (value) => (
        <Button
          size={`small`}
          icon={<ArrowDownOutlined />}
          onClick={() => setInstallment(data.find(w => w.id === value) as FinancialEntryInstallmentEntity)}
        >
          Dar Baixa
        </Button>

      )
    },
  ]

  return (
    <>
      <HeaderFinancialEntry
        title={'Baixas Pendentes'}
        subtitle={'Parcelas criadas com baixas pendentes'}
        mode={'form'}
      />
      <Divider />
      <FilterFinancialEntryInstallment
        onPeriodFilter={(initialPaymentDate, finalPaymentDate) => setFilter({ initialPaymentDate, finalPaymentDate })}
        onNumMovFilter={(financialEntryId) => setFilter({ financialEntryId })}
        onEntryTypeFilter={(entryType) => setFilter({ entryType })}
        onPersonFilter={(personId) => setFilter({ personId })}
        onExport={exportData}
      />
      <Table
        dataSource={data}
        columns={columns}
        pagination={{
          total,
          pageSize: limit,
          onChange: setPage,
          current: page,
        }}
      />
      {
        installment && (
          <Modal
            title={`Baixar Parcela ${installment.order} - ${currencyFormatter.format(installment.value)}`}
            footer={null}
            width={1050}
            visible={true}
            bodyStyle={{padding: 0}}
            onCancel={() => setInstallment(undefined)}
          >
            <FormWriteOff
              entryId={installment.financialEntryId}
              installmentId={installment.id}
              onSaved={() => {
                setInstallment(undefined);
                loadData();
              }}
            />
          </Modal>
        )
      }
      <Switch>
        <Route path={`${routeMatch.path}/entry/:id`}>
          <Drawer
            placement={'bottom'}
            height={550}
            visible={true}
            bodyStyle={{padding: 0}}
            onClose={() => props.navigator.goToListPage()}
          >
            <ShowFinancialEntry {...props} />
          </Drawer>
        </Route>
      </Switch>
    </>
  )
}
