import {Button, Col, Divider, Form, Input, List, message, Row, Tabs} from "antd";
import React, {useEffect, useState} from "react";
import {HeaderFinancialDocumentType} from "../component/header.financial-document-type";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {useParams} from "react-router-dom";
import {FinancialDocumentTypeEntity} from "../../../../domain/entity/financial-document-type.entity";
import {useFinancialDocumentTypeRepository} from "../../../../domain/repository/financial-document-type.repository";
import {FinancialDocumentTypeTaxEntity} from "../../../../domain/entity/financial-document-type-tax.entity";
import {FormDocumentTypeTax} from "../component/form.document-type-tax";

export function EditFinancialDocumentType(props: ModuleRenderProps) {
    const [form] = Form.useForm();
    const [entity, setEntity] = useState<FinancialDocumentTypeEntity>();
    const financialDocumentTypeRepo = useFinancialDocumentTypeRepository();
    const { id } = useParams<{id: string}>();

    function loadParams() {
        financialDocumentTypeRepo
          .find(id)
          .then(res => setEntity(res.data));
    }

    useEffect(() => {
        loadParams()
    }, [1]);

    useEffect(() => {
        if(entity) {
            form.setFields([
                { name: 'name', value: entity.name },
                { name: 'alias', value: entity.alias },
            ])
        }
    }, [entity]);


    function onFinish(data: any) {
        financialDocumentTypeRepo.update(id, data).then(res => {
            message.success(res.message);
            props.navigator.goToListPage();
        }).catch(err => {
            message.error(err.message);
        })
    }


    return (
        <>
            <HeaderFinancialDocumentType mode={'form'} />
            <Tabs>
                <Tabs.TabPane tab={'Dados Básicos'} key={1}>
                    <Form form={form} layout="vertical" onFinish={onFinish}>
                        <Row gutter={12}>
                            <Col span={16}>
                                <Form.Item name={'name'} label="Nome" rules={[{ required: true }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name={'alias'} label="Sigla"  >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <FormActionsButtons
                          onCancel={props.navigator.goToDefaultPage}
                          isLoading={financialDocumentTypeRepo.loading}
                        />
                    </Form>
                </Tabs.TabPane>
                <Tabs.TabPane tab={'Impostos a Recolher'} key={2}>
                    <FormDocumentTypeTax {...props} />
                </Tabs.TabPane>
            </Tabs>
        </>
    )
}
