import {Button, Col, Form, Input, Row, Select} from "antd";
import React, { useEffect, useState } from "react";
import {SearchOutlined} from "@ant-design/icons";
import { FinancialGroupEntity } from "../../../../domain/entity/financial-group.entity";
import { getSorter } from "../../../../core/util/sort-array.util";
import { FinancialClassEntity } from "../../../../domain/entity/financial-class.entity";
import { useFinancialClassRepository } from "../../../../domain/repository/financial-class.repository";
import {useFinancialGroupRepository} from "../../../../domain/repository/financial-group.repository";

export type FilterListManagerialAccountProps = {
  onFilter: (values: { classId?: number; groupId?: number; description?: string}) => void,
}
export function FilterListFinancialClass(props: FilterListManagerialAccountProps) {
  const [form] = Form.useForm();
  const [classes, setClasses] = useState<FinancialClassEntity[]>([]);
  const [groups, setGroups] = useState<FinancialGroupEntity[]>([]);
  const [currentGroup, setCurrentGroup] = useState<number>();

  const classRepo = useFinancialClassRepository();
  const groupRepo = useFinancialGroupRepository();

  function onFilter(values: any) {
    let parsedValues: any = {};
    if (values.groupId) {
      parsedValues['groupId'] = values.groupId
    }
    if (values.classId) {
      parsedValues['classId'] = values.classId
    }
    if (values.description) {
      parsedValues['description'] = values.description
    }
    props.onFilter(parsedValues);
  }

  useEffect(() => {
    classRepo.findAll().then(res => setClasses(res.data));
    groupRepo.findAll().then(res => setGroups(res.data));
  }, [1]);

  function getClassesOpts() {
    return classes
      .filter(w => w.groupId === currentGroup)
      .sort(getSorter<FinancialClassEntity>('code'))
      .map(item => ({
        label: `${item.group.code}.${item.code} - ${item.name}`,
        value: item.id,
      }))
  }

  function getGroupOpts() {
    return groups
      .sort(getSorter<FinancialGroupEntity>('code'))
      .map(item => ({
        label: `${item.code} - ${item.name}`,
        value: item.id,
      }))
  }

  return (
    <Form
      form={form}
      layout="horizontal"
      size={'middle'}
      onFinish={onFilter}
      initialValues={{ field: 'classId' }}
    >
      <Row gutter={12} style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}} >
        <Col span={7} >
          <Form.Item name={'groupId'} label={'Grupo'}>
            <Select options={getGroupOpts()} onSelect={w => setCurrentGroup(w as number)} allowClear />
          </Form.Item>
        </Col>
        <Col span={7} >
          <Form.Item name={'classId'} label={'Classe'}>
            <Select options={getClassesOpts()} allowClear />
          </Form.Item>
        </Col>
        <Col span={6} >
          <Form.Item name={'description'} label={'Item'}>
            <Input />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item>
            <Button htmlType={'submit'} size={'middle'} icon={<SearchOutlined />}>
              Pesquisar
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}