import {useRouteMatch} from "react-router-dom";
import {ModuleResource, ModuleResourceTypeEnum, ModuleRoot} from "../../../core/component/module/module.root";
import React from "react";
import {ListBankAccount} from "./pages/list.bank-account";
import {CreateBankAccount} from "./pages/create.bank-account";
import {EditBankAccount} from "./pages/edit.bank-account";
import {ListBankAccountStatement} from "./pages/list.bank-account-statement";
import {TransferBankAccount} from "./pages/transfer.bank-account";
import { CreateInitialBalance } from "./pages/create.initial-balance";
import {ListTransference} from "./pages/list.transference";


export function BankAccountModule() {
    const routeMatch = useRouteMatch();
    const resources: ModuleResource[] = [
        {
            type: ModuleResourceTypeEnum.CREATE,
            render: (props) => <CreateBankAccount {...props} />
        },
        {
            type: ModuleResourceTypeEnum.CUSTOM,
            customPath: `statements/:id`,
            render: (props) => <ListBankAccountStatement {...props} />
        },
        {
            type: ModuleResourceTypeEnum.CUSTOM,
            customPath: `initial-balance/:id`,
            render: (props) => <CreateInitialBalance {...props} />
        },
        {
            type: ModuleResourceTypeEnum.CUSTOM,
            customPath: `transfer-account`,
            render: (props) => <TransferBankAccount {...props} />
        },
        {
            type: ModuleResourceTypeEnum.CUSTOM,
            customPath: `:id/transference`,
            render: (props) => <ListTransference {...props} />
        },
        {
            type: ModuleResourceTypeEnum.EDIT,
            render: (props) => <EditBankAccount {...props} />
        },
        {
            type: ModuleResourceTypeEnum.DEFAULT,
            render: (props) => <ListBankAccount {...props} />
        },
    ]

    return (
        <ModuleRoot
            resource={routeMatch.url}
            moduleResources={resources}
        />
    );
}
