import {useRouteMatch} from "react-router-dom";
import React from 'react';
import {ModuleResource, ModuleResourceTypeEnum, ModuleRoot} from "../../../core/component/module/module.root";
import {ListCashFlowRow} from "./pages/list.cash-flow";

export function CashFlowModule() {
    const routeMatch = useRouteMatch();
    const resources: ModuleResource[] = [
        {
            type: ModuleResourceTypeEnum.DEFAULT,
            render: (props) => <ListCashFlowRow {...props} />
        },
    ]

    return (
        <ModuleRoot
            resource={routeMatch.url}
            moduleResources={resources}
        />
    )
}
