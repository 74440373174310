import {Button, Result, Typography} from "antd";
import {FinancialEntryEntity} from "../../../../domain/entity/financial-entry.entity";
import {ArrowLeftOutlined} from "@ant-design/icons";

export type ResultFinancialEntryProps = {
  entry: FinancialEntryEntity,
  onFinish: () => void
}

export function ResultFinancialEntry(props: ResultFinancialEntryProps) {
  return (
    <>
      <Result title={'Lançamento Criado com Sucesso'} status={'success'}>
         <Typography.Title level={4}>
           Código do Lançamento: {props.entry.id.toString().padStart(9, '0')}
         </Typography.Title> <br />
        <Button size={'middle'} shape={'round'} icon={<ArrowLeftOutlined />} onClick={props.onFinish}>
          Voltar
        </Button>
      </Result>
    </>
  )
}