import {Descriptions, Modal} from "antd";
import {MonthsEnum} from "../../../../domain/vo/months.enum";

export type ModalReleases = {
    visible: boolean;
    setVisible: (val: boolean) => void,
    values: Array<{ month: number, value: number }>
}

export function ModalReleases(props: ModalReleases) {
    const moneyFormatter = Intl.NumberFormat('pt-BR', { style: 'currency' , currency: 'BRL'});
    return (
        <Modal
            visible={props.visible}
            onCancel={() => props.setVisible(false)}
            title={'Lançamentos'}
            footer={null}
        >
            <Descriptions bordered>
                {
                    props.values.map(month => (
                        <Descriptions.Item label={MonthsEnum.parseName(month.month)} span={2}>{moneyFormatter.format(month.value)}</Descriptions.Item>
                    ))
                }
            </Descriptions>
        </Modal>
    )
}
