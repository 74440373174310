import {Form, Select} from "antd";
import React from "react";
import {StatesEnum} from "../../../../domain/vo/states.enum";

export type StateFieldsProps = {
    name: string,
    label: string,
    defaultValue?: string,
    style?: React.CSSProperties

}

export function StateField(props: StateFieldsProps) {
    return (
        <Form.Item label={props.label} name={props.name} required tooltip="Campo Obrigatório">
            <Select defaultValue={props.defaultValue} style={props.style}>
                {
                    StatesEnum.STATES.map(state => (
                        <Select.Option key={state.sg} value={state.sg}>{state.name}</Select.Option>
                    ))
                }
            </Select>
        </Form.Item>
    )
}
