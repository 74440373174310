import {PersonEntity} from "../../../../domain/entity/person.entity";
import {PersonBankDataEntity} from "../../../../domain/entity/person-bank-data.entity";
import {Button, Col, Form, Input, InputNumber, message, Row, Select} from "antd";
import {usePersonRepository} from "../../../../domain/repository/person.repository";
import React, { useEffect, useState } from "react";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import { useBankRepository } from "../../../../domain/repository/bank.repository";
import { BankEntity } from "../../../../domain/entity/bank.entity";
import {ArrowRightOutlined} from "@ant-design/icons";

export type FormPersonBankData = {
    onFinish: (entity: PersonBankDataEntity | undefined) => void,
    onBack: () => void,
    personBankData?: PersonBankDataEntity,
    person: PersonEntity,
    hideSkipButton?: boolean,
}

export function FormPersonBankData(props: FormPersonBankData) {
    const [form] = Form.useForm();
    const [banks, setBanks] = useState<BankEntity[]>([]);

    const bankRepo = useBankRepository();
    const personRepo = usePersonRepository();

    useEffect(() => {
        if (props.personBankData) {
            const { personBankData } = props;
            form.setFields([
                { name: 'alias', value: personBankData.alias },
                { name: 'bankId', value: personBankData.bankId },
                { name: 'code', value: personBankData.code },
                { name: 'account', value: personBankData.account },
                { name: 'agency', value: personBankData.agency },
                { name: 'agencyCode', value: personBankData.agencyCode },
                { name: 'operation', value: personBankData.operation },
            ])
        } else {
            form.setFields([
                { name: 'alias' , value: 'Conta Principal' },
            ])
        }

        bankRepo.findAll().then(res => setBanks(res.data));
    }, [1])

    function onFinish(values: any) {
        values = {
            ...values,
            personId: props.person.id
        }
        function send() {
            if (props.personBankData?.id) {
                return personRepo.updateBankData(props.personBankData.id, values);
            }
            return personRepo.addBankData(values);
        }

        send().then((res) => {
            message.success(res.message);
            props.onFinish(res.data);
        }).catch(err => {
            message.error(err.message);
        })
    }

    function getBankOpts() {
        return banks.sort((a, b) => {
            if (a.code > b.code) return 1;
            if (a.code < b.code) return -1;
            return 0;
        }).map(item => ({
            label: `${item.code} - ${item.name}`,
            value: item.id
        }))
    }

    function skipStep() {
        props.onFinish(undefined);
    }

    function SkipButton() {
        if (props.hideSkipButton) {
            return null;
        }
        return (
          <Button
            onClick={skipStep}
            shape={'round'}
            icon={<ArrowRightOutlined />}
          >
              Pular
          </Button>
        )
    }

    return (
        <Form form={form} layout={'vertical'} onFinish={onFinish}>
            <Form.Item name={'alias'} label={'Descrição da Conta'} rules={[{required: true}]}>
                <Input  />
            </Form.Item>
            <Row gutter={12}>
                <Col span={14}>
                    <Form.Item name={'bankId'} label={'Banco'} rules={[{required: true}]}>
                        <Select options={getBankOpts()}  />
                    </Form.Item>
                </Col>
                <Col span={10}>
                    <Form.Item name={'operation'} label={'Operação'}>
                        <Input  />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={12}>
                <Col span={8}>
                    <Form.Item name={'agency'} label={'Agência'} rules={[{required: true}]}>
                        <InputNumber style={{width: '100%'}}  />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item name={'agencyCode'} label={'Dig. Agencia'}>
                        <InputNumber style={{width: '100%'}}  />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name={'account'} label={'Conta'} rules={[{required: true}]}>
                        <InputNumber style={{width: '100%'}}  />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item name={'code'} label={'Dig. Conta'} rules={[{required: true}]}>
                        <InputNumber style={{width: '100%'}}  />
                    </Form.Item>
                </Col>
            </Row>
            <FormActionsButtons
                label={'Continuar'}
                onCancel={() => props.onBack()}
                labelCancel={'Voltar'}
                moreAction={[<SkipButton />]}
            />
        </Form>
    )
}