import {Tree} from "antd";
import {DreRowEntity} from "../../../domain/entity/dre-row.entity";
import {FullscreenExitOutlined, MinusCircleOutlined, PlusCircleOutlined} from "@ant-design/icons";
import {DataNode} from "rc-tree/lib/interface";
import {dreRowSort} from "../../module/dre-row/service/dre-row.sort";
import {dreRowNameFormatter} from "../../module/dre-row/service/dre-row.name.formatter";

export type DreTreeViewProps = {
    rows: DreRowEntity[],
    onSelect: (row: DreRowEntity) => void
}

export function DreTreeView(props: DreTreeViewProps) {

    function getIconLine(type: string) {
        if(type == 'calc') {
            return <FullscreenExitOutlined style={{color: 'blue'}} />
        }
        return type === 'revenue' ?
            <PlusCircleOutlined style={{color: 'darkgreen'}} /> :
            <MinusCircleOutlined style={{color: 'darkred'}}  />
    }

    function parseData(data: DreRowEntity[]): DataNode[] {
        let result: DataNode[] = [];
        const parsedData = data.filter(w => w.isRowResult).sort(dreRowSort);
        for (let item of parsedData) {
            const node: DataNode = {
                key: item.id,
                icon: getIconLine(item.type),
                title: dreRowNameFormatter(item),
                children: data.filter(w => w.parentRowId === item.id && !w.isRowResult).sort(dreRowSort).map(ch => ({
                    title: dreRowNameFormatter(ch, item),
                    icon: getIconLine(ch.type),
                    isLeaf: true,
                    key: ch.id
                }))
            };
            result.push(node);
        }
        return result;
    }

    function onSelectRow(key: string | number) {
        const row = props.rows.find(w => w.id === key);
        if (row) {
            props.onSelect(row);
        }
    }

    function getExpandedKeys() {
        return props.rows.filter(w => w.isRowResult).map(item => item.id);
    }

    return (
        <Tree
            onSelect={(rows) => onSelectRow(rows[0])}
            defaultExpandAll
            showIcon={true}
            style={{fontSize: 'medium'}}
            treeData={parseData(props.rows)}
        />
    )
}