import {FinancialEntryItemEntity} from "../entity/financial-entry-item.entity";

export function useFinancialEntryItemService() {

  function calcFinalValue(singleValue: number, quantity: number, discount: number): number {
    return singleValue * quantity - discount;
  }

  function calcTotalValue(data: FinancialEntryItemEntity[]) {
    let result = 0;
    for (let item of data) {
      result +=  calcFinalValue(
        item.singleValue,
        item.quantity,
        item.discount,
      )
    }
    return result;
  }

  return {
    calcTotalValue,
    calcFinalValue
  }
}