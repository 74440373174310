import {Checkbox, Col, Divider, Form, Input, InputNumber, message, Radio, Row, Select} from "antd";
import React, {useEffect, useState} from "react";
import {HeaderBankAccount} from "../component/header.bank-account";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {useParams} from "react-router-dom";
import {BankAccountTypeEnum} from "../../../../domain/vo/bank-account-type.enum";
import {getSorter} from "../../../../core/util/sort-array.util";
import {BankEntity} from "../../../../domain/entity/bank.entity";
import {FirmEntity} from "../../../../domain/entity/firm.entity";
import {ManagerialAccountEntity} from "../../../../domain/entity/managerial-account.entity";
import {useFirmRepository} from "../../../../domain/repository/firm.repository";
import {useManagerialRepository} from "../../../../domain/repository/managerial.repository";
import {useBankRepository} from "../../../../domain/repository/bank.repository";
import {useBankAccountRepository} from "../../../../domain/repository/bank-account.repository";
import {BankAccountEntity} from "../../../../domain/entity/bank-account.entity";
import {InputSearch} from "../../../../core/component/input-search";
import {BrlInputNumber} from "../../../../core/component/brl-input-number";

export function EditBankAccount(props: ModuleRenderProps) {
  const [form] = Form.useForm();
  const [showNegativeBalance, setShowNegativeBalance] = useState(false);
  const [entity, setEntity] = useState<BankAccountEntity>();
  const [banks, setBanks] = useState<BankEntity[]>([]);
  const [managerialAccounts, setManagerialAccounts] = useState<ManagerialAccountEntity[]>([]);
  const [firms, setFirms] = useState<FirmEntity[]>([]);
  const [disableAccountFields, setDisableAccountFields] = useState(false);

  const firmRepo = useFirmRepository();
  const managerialAccountRepo = useManagerialRepository();
  const bankRepo = useBankRepository();
  const bankAccountRepo = useBankAccountRepository();
  const { id } = useParams<{id: string}>();

  useEffect(() => {
    loadParams();
    bankAccountRepo.find(id)
      .then(res => {
        setEntity(res.data);
      })
  }, [1]);


  function loadParams() {
    firmRepo.findAll().then(res => setFirms(res.data));
    bankRepo.findAll().then(res => setBanks(res.data));
    managerialAccountRepo.findAll().then(res => setManagerialAccounts(res.data));
  }

  useEffect(() => {
    if(entity) {
      form.setFields([
        { name: 'alias', value: entity.alias },
        { name: 'type', value: entity.type },
        { name: 'bankId', value: entity.bankId },
        { name: 'agency', value: entity.agency },
        { name: 'agencyCode', value: entity.agencyCode },
        { name: 'account', value: entity.account },
        { name: 'code', value: entity.code },
        { name: 'negativeBalanceLimit', value: entity.negativeBalanceLimit },
        { name: 'canHasNegativeBalance', value: entity.canHasNegativeBalance },
        { name: 'code', value: entity.code },
        { name: 'firmId', value: entity.firmId },
        { name: 'managerialAccountId', value: entity.managerialAccountId },
        { name: 'externalKey', value: entity.meta?.externalKey },
        { name: 'active', value: entity.active },
      ])
      checkDisabledAccountFields();
      updateShowNegativeBalance();
    }
  }, [entity]);


  function onFinish(data: any) {
    bankAccountRepo.update(id, data).then(res => {
      message.success(res.message);
      props.navigator.goToListPage();
    }).catch(err => {
      message.error(err.message);
    })
  }


  function getTypeOpts() {
    return [
      { label: 'Conta Corrernte', value: BankAccountTypeEnum.CHECKING_ACCOUNT },
      { label: 'Conta Poupança', value: BankAccountTypeEnum.SAVINGS_ACCOUNT },
      { label: 'Conta Aplicação', value: BankAccountTypeEnum.INVESTMENT_ACCOUNT },
      { label: 'Conta Caixa', value: BankAccountTypeEnum.CASH_ACCOUNT },
    ]
  }

  function getBankOpts() {
    return banks.sort(getSorter<BankEntity>('name'))
      .map(item => ({
        label: item.name,
        value: item.id
      }))
  }

  function getFirmOpts() {
    return firms.sort(getSorter<FirmEntity>('name'))
      .map(item => ({
        label: `${item.name} - ${item.document}`,
        value: item.id as number
      }))
  }

  function getMAOpts() {
    return managerialAccounts.sort(getSorter<ManagerialAccountEntity>('code'))
      .map(item => ({
        label: `${item.code} - ${item.description}`,
        value: item.id
      }))
  }

  function checkDisabledAccountFields() {
    if (form.getFieldValue('type') === BankAccountTypeEnum.CASH_ACCOUNT) {
      setDisableAccountFields(true)
    } else {
      setDisableAccountFields(false);
    }
  }

  function updateShowNegativeBalance() {
    setShowNegativeBalance(form.getFieldValue('canHasNegativeBalance'))
  }

  return (
    <>
      <HeaderBankAccount mode={'form'} />
      <Divider />
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item name={'type'} label="Tipo da Conta" rules={[{ required: true }]}>
          <Radio.Group options={getTypeOpts()} disabled={true} />
        </Form.Item>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item name={'alias'} label="Nome da Conta" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <InputSearch
              disabled={disableAccountFields}
              label={'Banco'}
              data={getBankOpts()}
              name={'bankId'}
              required={!disableAccountFields}
            />
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={9}>
            <Form.Item name={'agency'} label="Agencia" rules={[{ required: !disableAccountFields }]} >
              <Input disabled={disableAccountFields} />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item  name={'agencyCode'} label={'Dig.'}>
              <Input disabled={disableAccountFields} />
            </Form.Item>
          </Col>
          <Col span={2} />
          <Col span={9}>
            <Form.Item  name={'account'} label="Conta" rules={[{ required: !disableAccountFields }]}>
              <Input disabled={disableAccountFields} />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name={'code'} label={'Dig.'} rules={[{ required: !disableAccountFields }]}>
              <Input disabled={disableAccountFields} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item name={'firmId'} label="Filial">
              <Select options={getFirmOpts()} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={'managerialAccountId'} label="Conta Gerencial">
              <Select options={getMAOpts()} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={4}>
            <Form.Item
              name={'active'}
              label={'Conta Ativa'}
              valuePropName={'checked'}
            >
              <Checkbox onChange={() => updateShowNegativeBalance()} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name={'canHasNegativeBalance'}
              label={'Habilitar Saldo Negativo'}
              valuePropName={'checked'}
            >
              <Checkbox onChange={() => updateShowNegativeBalance()} />
            </Form.Item>
          </Col>
          {
            showNegativeBalance && (
              <Col span={8}>
                <BrlInputNumber label={'Limite Saldo Negativo'} name={'negativeBalanceLimit'} required={true} />
              </Col>
            )
          }
        </Row>
        <FormActionsButtons
          onCancel={props.navigator.goToDefaultPage}
          isLoading={bankAccountRepo.loading}
        />
      </Form>
    </>
  )
}
