import {FinancialEntryEntity} from "../../../../../domain/entity/financial-entry.entity";
import {useNumberFormatter} from "../../../../service/number-formatter.service";
import {List} from "antd";
import {FinancialEntryTaxEntity} from "../../../../../domain/entity/financial-entry-tax.entity";

export function TaxesEntry(props: { entry: FinancialEntryEntity }) {
  const { currencyFormatter } = useNumberFormatter();
  return (
    <div style={{padding: 10}}>
      <List
        dataSource={props.entry.taxes ?? []}
        renderItem={(item: FinancialEntryTaxEntity) => (
          <List.Item>
            <List.Item.Meta
              title={item.tax?.name}
              description={currencyFormatter.format(item.value)}
            />
          </List.Item>
        )}
      />
    </div>
  )
}