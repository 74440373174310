import {useEffect, useState} from "react";
import {NextToParseRecurrenceDto} from "../../../../domain/dto/next-to-parse.recurrence.dto";
import {useRecurrenceEntryRepository} from "../../../../domain/repository/recurrence-entry.repository";
import {Button, Table} from "antd";
import {recurrencePeriodTranslate} from "../service/recurrence-period.translate";
import {useDateTimeUtil} from "../../../../core/util/date-time.util";
import Text from "antd/es/typography/Text";
import {getSorter} from "../../../../core/util/sort-array.util";

export type DataListNextToParseComponentProps = {
  navigator: any,
}
export function DataListNextToParseComponent(props: DataListNextToParseComponentProps) {
  const [data, setDate] = useState<NextToParseRecurrenceDto[]>([]);

  const recurrenceEntryRepo = useRecurrenceEntryRepository();
  const { convertToDatePtBr } = useDateTimeUtil();

  function loadData() {
    recurrenceEntryRepo.listNextToParse()
      .then(res => setDate(res.data.sort(getSorter<NextToParseRecurrenceDto>('daysToExpire'))));
  }

  useEffect(() => {
    loadData();
  }, [1]);

  function renderRecurrenceName(row: NextToParseRecurrenceDto) {
    if (row.recurrenceDescription) {
      return `${row.recurrenceName} - ${row.recurrenceDescription}`
    }
    return `${row.recurrenceName}`
  }

  function renderStatus(daysToExpire: number) {
    if (daysToExpire < 0) {
      return <Text type={'danger'}>Vencido a {Math.abs(daysToExpire)} dia(s)</Text>
    }
    if (daysToExpire === 0) {
      return <Text type={'warning'}>Vence hoje</Text>
    }
    if (daysToExpire > 0) {
      return <Text>Vence em {Math.abs(daysToExpire)} dias</Text>
    }
  }

  return (
    <>
      <Table
        dataSource={data}
        columns={[
          {
            title: 'Recorrència',
            dataIndex: 'recurrenceName',
            render: (val, row) => renderRecurrenceName(row),
          },
          {
            title: 'Período',
            dataIndex: 'recurrencePeriod',
            render: recurrencePeriodTranslate,
          },
          {
            title: 'Próximo Vencimento',
            dataIndex: 'nextDueDate',
            render: convertToDatePtBr
          },
          {
            title: 'Status',
            dataIndex: 'daysToExpire',
            render: renderStatus
          },
          {
            title: '',
            dataIndex: 'recurrenceId',
            render: (id) => (
              <Button
                size={'small'}
                shape={'round'}
                onClick={() => props.navigator.goToCustomPage(`parse/${id}`)}
              >
                Gerar Lançamento
              </Button>
            )
          },
        ]}
        />
    </>
  )
}