import {usePlaneduHttpService} from "../service/planedu.http.service";
import {SupplyUnitEntity} from "../entity/supply-unit.entity";

export function useSupplyUnitRepository() {
    const httpService = usePlaneduHttpService<SupplyUnitEntity>('supply-unit');

    function downloadSupplyUnitsCsv() {
        return httpService.downloadRequest(
          'get',
          `${httpService.resourceName}/export`,
          'unidade-fornecimento.xlsx',
        )
    }

    return {
        ...httpService,
        downloadSupplyUnitsCsv
    }

}