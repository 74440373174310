import {useRouteMatch} from "react-router-dom";
import {ModuleResource, ModuleResourceTypeEnum, ModuleRoot} from "../../../core/component/module/module.root";
import React from "react";
import {ListCostCenter} from "./pages/list.cost-center";
import {CreateCostCenter} from "./pages/create.cost-center";
import {EditCostCenter} from "./pages/edit.cost-center";

export function CostCenterModule() {
    const routeMatch = useRouteMatch();
    const resources: ModuleResource[] = [
        {
            type: ModuleResourceTypeEnum.CREATE,
            render: (props) => <CreateCostCenter {...props} />
        },
        {
            type: ModuleResourceTypeEnum.EDIT,
            render: (props) => <EditCostCenter {...props} />
        },
        {
            type: ModuleResourceTypeEnum.DEFAULT,
            render: (props) => <ListCostCenter {...props} />
        },
    ]

    return (
        <ModuleRoot
            resource={routeMatch.url}
            moduleResources={resources}
        />
    );
}
