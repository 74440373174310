import React, {useState} from "react";
import {Dropdown, Menu, Modal} from "antd";
import {MoreOutlined} from "@ant-design/icons";

export type ListActionsProps = {
  onEditClick?: () => void,
  onRemoveClick?: () => void,
  options?: Array<{ name: string, disabled?: boolean, onClick: () => void }>,
  loading?: boolean
}

export function ListActions(props: ListActionsProps) {
  let { options } = props;
  const [modalConfirmVisible, setModalConfirmVisible] = useState(false)
  options = options ?? [];

  function Options() {
    return (
      <Menu>
        {
          props.onEditClick && (
            <Menu.Item key={1} onClick={props.onEditClick}>
              EDITAR
            </Menu.Item>
          )
        }
        {
          props.onRemoveClick && (
            <Menu.Item key={2} onClick={() => setModalConfirmVisible(true)} >
              REMOVER
            </Menu.Item>
          )
        }
        {
          options?.map((item, key) => (
            <Menu.Item key={key+3} onClick={item.onClick} disabled={item.disabled}>
              {item.name}
            </Menu.Item>
          ))
        }
      </Menu>
    )
  }

  async function onRemove() {
    await (props.onRemoveClick && props.onRemoveClick());
    setModalConfirmVisible(false);
  }

  function ModalConfirmRemove() {
    return (
      <Modal
        title={'Confirmar Operação'}
        visible={modalConfirmVisible}
        onOk={() => onRemove()}
        onCancel={() => setModalConfirmVisible(false)}
        okText={'Confirmar'}
        cancelText={'Cancelar'}
        confirmLoading={props.loading}
      >
        Deseja prosseguir com a remoção desse registro ?
      </Modal>
    )
  }

  return (
    <div style={{ textAlign: 'right'}}>
      {modalConfirmVisible && <ModalConfirmRemove />}
      <Dropdown overlay={<Options />} placement={'bottomLeft'}>
        <MoreOutlined style={{cursor: 'pointer'}} />
      </Dropdown>
    </div>
  )
}