import {Col, Form, Input} from "antd";
import React from "react";
import {useViaCepAddressRepository} from "../../../service/via-cep.address.repository";

export function PostalCodeField(props: any) {
    const viaCepRepo = useViaCepAddressRepository();

    function onPostalCodeChange(postalCode: string) {
        viaCepRepo.findByPostalCode(postalCode)
            .then(result => {
                props.setFields([
                    {name: 'addressStreet', value: result.logradouro},
                    {name: 'addressDistrict', value: result.bairro},
                    {name: 'addressCity', value: result.localidade},
                    {name: 'addressState', value: result.uf},
                ])
            });
    }

    return (
        <Form.Item
            label="CEP"
            rules={[{ required: true }]}
            name={`addressPostalCode`}>
            <Input placeholder="35171332"
                   onBlur={event => onPostalCodeChange(event.target.value)} />
        </Form.Item>
    )
}