import React, {useEffect, useState} from "react";
import {HeaderManagerialAccounts} from "../component/header.managerial-accounts";
import {Divider, Layout, message, Table, Tag} from "antd";
import {ManagerialAccountEntity, ManagerialAccountRowType} from "../../../../domain/entity/managerial-account.entity";
import {useManagerialRepository} from "../../../../domain/repository/managerial.repository";
import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {ListActions} from "../../../../core/component/list-actions";
import {FilterListManagerialAccount} from "../component/filter-list.managerial-account";
import {ExportExcelButton} from "../../../../core/component/export-button";

export function ListManagerial(props: ModuleRenderProps) {
    const maRepository = useManagerialRepository();
    const [data, setData] = useState<ManagerialAccountEntity[]>([]);
    const [filteredData, setFilteredData] = useState<ManagerialAccountEntity[]>([]);

    function loadData() {
        maRepository.search().then(res => {
            setData(res.data)
            setFilteredData(res.data);
        });
    }

    useEffect(() => {
        loadData()
    }, [1]);

    function removeManagerial(id: string) {
        maRepository.remove(id)
            .then(res => {
                message.success(res.message);
                loadData();
            }).catch(err => {
                message.error(err.message);
        })
    }

    function parseType(type: string) {
        switch (type) {
            case ManagerialAccountRowType.CREDIT:
                return 'Crédito';
            case ManagerialAccountRowType.DEBIT:
                return 'Débito';
            default:
                return type;
        }
    }

    function renderType(value: string) {
        return <Tag>{parseType(value)}</Tag>
    }

    function renderStatus(active: boolean) {
        return <Tag color={active ? 'green' : 'red'}>{active ? 'Ativo' : 'Inativo' }</Tag>
    }

    const columnManagerial = [
        {
            title: 'Tipo',
            dataIndex: 'type',
            key: 'type',
            render: (val: any) => renderType(val)
        },
        {
            title: 'Código',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: 'Descrição',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Status',
            dataIndex: 'active',
            render: (val: boolean) => renderStatus(val)
        },
        {
            dataIndex: 'id',
            render: (val: any, managerial: ManagerialAccountEntity) => (
                <>
                    <ListActions
                        onEditClick={() => props.navigator.goToEditPage(val) }
                        onRemoveClick={() => removeManagerial(val) }
                    />
                </>
            )
        }
    ]

    function onFilterCode(value: string) {
        let temp: ManagerialAccountEntity[] = []
        if (value) {
            temp = data.filter(w => w.code.includes(value));
        } else {
            temp = data;
        }
        setFilteredData(temp);
    }

    function onFilterDesc(value: string) {
        let temp: ManagerialAccountEntity[] = []
        if (value) {
            temp = data.filter(w => w.description.includes(value));
        } else {
            temp = data;
        }
        setFilteredData(temp);
    }

    return (
        <>
            <HeaderManagerialAccounts
              extra={[
                  <ExportExcelButton onClick={() => maRepository.downloadListMA()} />
              ]}
            />
            <Divider />
            <Layout.Content>
                <FilterListManagerialAccount onFilterDesc={onFilterDesc} onFilterCode={onFilterCode}  />
                <Table 
                    columns={columnManagerial} 
                    dataSource={filteredData}
                    loading={maRepository.loading}
                />
            </Layout.Content>

        </>
    );
}
