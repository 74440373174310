import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {HeaderCashFlow} from "../component/header.cash-flow";
import {Button, Col, Descriptions, Divider, Form, Popconfirm, Radio, Row, Space, Table} from "antd";
import {Content} from "antd/es/layout/layout";
import {useEffect, useMemo, useRef, useState} from "react";
import {useCashFlowRowRepository} from "../../../../domain/repository/cash-flow-row.repository";
import moment, {Moment} from "moment";
import {DatePickerPtBr} from "../../../../core/component/date-picker-ptbr";
import {ExportOutlined, SearchOutlined} from "@ant-design/icons";
import {ColumnsType} from "antd/lib/table";
import {useNumberFormatter} from "../../../service/number-formatter.service";
import {CashFlowCostCenterDto} from "../../../../domain/dto/cash-flow-cost-center.dto";
import Text from "antd/es/typography/Text";

export function ReportAnalyticCostCenter(props: ModuleRenderProps) {
  const [data, setData] = useState<CashFlowCostCenterDto[]>([]);
  const [dateFormat, setDateFormat] = useState<'d' | 'm' | 'y'>('d');
  const [dateRef, setDateRef] = useState<Moment>(moment());
  const [formatReport, setFormatReport] = useState<'s' | 'a'>('s');

  const { decimalFormatter } = useNumberFormatter();
  const cashFlowRepo = useCashFlowRowRepository();

  useEffect(() => {
    search(dateRef, dateFormat);
  }, [formatReport]);

  function search(
    startDate: Moment,
    dateFormat: 'd' | 'm' | 'y',
  ) {
    const [startDateFilter, endDateFilter] = getFilterDates(startDate, dateFormat);
    switch (formatReport) {
      case "a":
        searchAnalyticData(startDateFilter, endDateFilter);
        break;
      case "s":
        searchSyntheticData(startDateFilter, endDateFilter);
        break;
      default:
        break;
    }
  }

  function exportReport(
    startDate: Moment,
    dateFormat: 'd' | 'm' | 'y',
    removeAnalyticCostCenters?: boolean
  ) {
    const [startDateFilter, endDateFilter] = getFilterDates(startDate, dateFormat);
    switch (formatReport) {
      case "a":
        exportAnalyticData(
          startDateFilter,
          endDateFilter,
          dateFormat
        );
        break;
      case "s":
        exportSyntheticData(
          startDateFilter,
          endDateFilter,
          dateFormat,
          removeAnalyticCostCenters
        );
        break;
      default:
        break;
    }
  }

  function getFilterDates(
    dt: Moment,
    dateFormat: 'm' | 'd' | 'y'
  ): [string, string] {
    let startDateFilter = dt.toISOString().substr(0, 10);
    let endDateFilter = dt.toISOString().substr(0, 10);
    switch (dateFormat) {
      case "m":
        startDateFilter = `${dt.toISOString().substr(0, 8)}01`;
        endDateFilter = `${moment(dt).add(1, 'month').toISOString().substr(0, 8)}01`;
        break;
      case "y":
        startDateFilter = `${dt.toISOString().substr(0, 5)}01-01`;
        endDateFilter = `${moment(dt).add(1, 'year').toISOString().substr(0, 5)}01-01`;
        break
    }
    return [startDateFilter, endDateFilter];
  }

  function searchAnalyticData(startDateFilter: string, endDateFilter: string) {
    cashFlowRepo.generateCCAReport({
      startDate: startDateFilter,
      endDate: endDateFilter,
      dateFormat,
    }).then(res => {
      setData(res.data)
    });
  }

  function searchSyntheticData(startDateFilter: string, endDateFilter: string) {
    cashFlowRepo.generateCCReport({
      startDate: startDateFilter,
      endDate: endDateFilter,
      dateFormat: dateFormat
    }).then(res => {
      setData(res.data)
    });
  }

  function exportSyntheticData(
    startDateFilter: string,
    endDateFilter: string,
    dateFormat: string,
    removeAnalyticCostCenters?: boolean,
  ) {
    return cashFlowRepo.exportCCReport({
      startDate: startDateFilter,
      endDate: endDateFilter,
      removeAnalyticCostCenters: removeAnalyticCostCenters ?? false,
      dateFormat
    });
  }

  function exportAnalyticData(startDateFilter: string, endDateFilter: string, dateFormat: string) {
    return cashFlowRepo.exportCCAReport({
      startDate: startDateFilter,
      endDate: endDateFilter,
      dateFormat
    });
  }

  function getColumns() {
    const columns: ColumnsType<CashFlowCostCenterDto> = [
      {
        title: 'Centro de Custo',
        dataIndex: 'costCenterName',
        width: '25%',
        ellipsis: true,
      },
    ]

    if (formatReport === 'a') {
      columns.push(
        {
          title: 'Fornecedor',
          dataIndex: 'personName',
          width: '25%',
          ellipsis: true
        },
        {
          title: 'Conta Bancaria',
          dataIndex: 'bankAccountName',
          width: '25%',
          ellipsis: true
        }
      );
    }
    columns.push(
      {
        title: 'Receita',
        dataIndex: 'writeOffRevenueAmount',
        render: decimalFormatter.format
      },
      {
        title: 'Despesa',
        dataIndex: 'writeOffDeductionAmount',
        render: (val) => <Text>{decimalFormatter.format(val)}</Text>
      },
      {
        title: 'Resultado',
        dataIndex: 'id',
        render: (val: any, row) => decimalFormatter.format(
          row.writeOffRevenueAmount - row.writeOffDeductionAmount
        )
      }
    )
    return columns;
  }

  function onSearch(values: any) {
    const { startDate, dateFormat } = values;
    return search(startDate, dateFormat);
  }

  function onExport(values: any) {
    const { startDate, dateFormat, removeAnalyticCostCenters } = values;
    console.log(startDate, dateFormat, removeAnalyticCostCenters);
    return exportReport(startDate, dateFormat, removeAnalyticCostCenters);
  }

  const maxCostCenterLength = useMemo<number>(() => {
    let maxLength = 0;
    for (const item of data) {
      if (item.costCenterCode.length > maxLength) {
        maxLength = item.costCenterCode.length;
      }
    }
    return maxLength;
  }, [data]);

  const totalDeduction = useMemo<number>(() => {
    return data
      .filter(w => w.costCenterCode.length === maxCostCenterLength)
      .map(w => w.writeOffDeductionAmount)
      .reduce((p, c) => p + c, 0);
  }, [data]);

  const totalRevenue = useMemo<number>(() => {
    return data
      .filter(w => w.costCenterCode.length === maxCostCenterLength)
      .map(w => w.writeOffRevenueAmount)
      .reduce((p, c) => p + c, 0);
  }, [data]);

  function ShowResults() {
    return (
      <Descriptions>
        <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={'Total Receita'}>
          {decimalFormatter.format(totalRevenue)}
        </Descriptions.Item>
        <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={'Total Despesa'}>
          {decimalFormatter.format(totalDeduction)}
        </Descriptions.Item>
        <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={'Resultado'}>
          {decimalFormatter.format(totalRevenue - totalDeduction)}
        </Descriptions.Item>
      </Descriptions>
    )
  }

  return (
    <>
      <HeaderCashFlow
        title={'Resultado Por Centro de Custo'}
        subtitle={'Resultado financeiro agrupado por centro de custo.'}
        extra={[
          <Radio.Group value={formatReport} onChange={e => setFormatReport(e.target.value)}>
            <Radio.Button value={'a'}>Analítico</Radio.Button>
            <Radio.Button value={'s'}>Sintético</Radio.Button>
          </Radio.Group>
        ]}
      />
      <Divider />
      <Content>
        <ReportAnalyticCostCenterFilter
          dateFormat={dateFormat}
          onExport={onExport}
          onSearch={onSearch}
          setDateFormat={setDateFormat}
          setDateRef={setDateRef}
          formatReport={formatReport}
        />
        <Table
          loading={cashFlowRepo.loading}
          columns={getColumns()}
          dataSource={data}
          footer={() => <ShowResults />}
        />
      </Content>
    </>
  )
}


function ReportAnalyticCostCenterFilter(props: {
  onExport: (data: any) => void,
  onSearch: (values: any) => void,
  dateFormat: 'd' | 'm' | 'y',
  setDateFormat: (val: 'd' | 'm' | 'y') => void,
  setDateRef: (dt: Moment) => void,
  formatReport: 'a' | 's',
}) {
  const { dateFormat, onSearch, onExport, setDateFormat } = props;
  const [form] = Form.useForm();
  const exportButtonRef = useRef<any>();

  useEffect(() => {
    const startDate = moment();
    form.setFields([
      { name: 'startDate', value: startDate },
      { name: 'dateFormat', value: dateFormat }
    ])
  }, [1])

  function  getDateFormatOpts() {
    return [
      { label: 'Diário', value: 'd'},
      { label: 'Mensal', value: 'm'},
      { label: 'Anual', value: 'y'},
    ]
  }

  function getPicker() {
    switch (dateFormat) {
      case "m":
        return 'month'
      case "y":
        return 'year';
      default:
        return 'date';
    }
  }

  function _onExport(args: { removeAnalyticCostCenters: boolean }) {
    const { removeAnalyticCostCenters } = args;
    return onExport({ ...form.getFieldsValue(), download: true, removeAnalyticCostCenters });
  }


  return (
    <Form form={form} onFinish={onSearch} layout={'horizontal'} size={'middle'}>
      <Row>
        <Col span={12}>
          <Form.Item name={'dateFormat'} label={'Formato do Filtro'} rules={[{ required: true }]}>
            <Radio.Group
              onChange={(format) => setDateFormat(format.target.value)}
              options={getDateFormatOpts()} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <DatePickerPtBr
            label={'Referência'}
            name={'startDate'}
            required={true}
            picker={getPicker()}
            onChange={dt => dt && props.setDateRef(dt)}
          />
        </Col>
      </Row>
      <Divider />
      <Row style={{display: 'flex', justifyContent: 'flex-end'}}>
        <Space>
          <Form.Item>
            <Button
              type={'primary'}
              icon={<SearchOutlined />}
              htmlType={'submit'}
              shape={'round'}
            >
              Pesquisar
            </Button>
          </Form.Item>
          <Form.Item>
            {
              props.formatReport === 's' ?
                (
                  <Popconfirm
                    title={'Zerar Centros de Custo Analíticos'}
                    onPopupAlign={() => exportButtonRef.current.preventDefault()}
                    onConfirm={() => _onExport({ removeAnalyticCostCenters: true })}
                    onCancel={() => _onExport({ removeAnalyticCostCenters: false })}
                    okText={'Sim'}
                    cancelText={'Não'}
                  >
                    <Button
                      icon={<ExportOutlined />}
                      htmlType={'button'}
                      shape={'round'}
                    >
                      Exportar
                    </Button>
                  </Popconfirm>
                ) : (
                  <Button
                    icon={<ExportOutlined />}
                    htmlType={'button'}
                    shape={'round'}
                    onClick={() => _onExport({ removeAnalyticCostCenters: false  })}
                  >
                    Exportar
                  </Button>
                )
            }
          </Form.Item>
        </Space>
      </Row>
    </Form>
  )
}