import React, {useEffect, useState} from "react";
import {RecurrenceEntryEntity} from "../../../../domain/entity/recurrence-entry.entity";
import {useDateTimeUtil} from "../../../../core/util/date-time.util";
import {useRecurrenceEntryRepository} from "../../../../domain/repository/recurrence-entry.repository";
import {message, Modal, Table, Tag} from "antd";
import Text from "antd/es/typography/Text";
import {recurrencePeriodTranslate} from "../service/recurrence-period.translate";
import {recurrenceContractTypeTranslateService} from "../service/reference-contract-type.translate.service";
import {ListActions} from "../../../../core/component/list-actions";

export type DataListRecurrenceComponentProps = {
  navigator: any,
}
export function DataListRecurrenceComponent(props: DataListRecurrenceComponentProps) {
  const [data, setData] = useState<RecurrenceEntryEntity[]>([]);
  const [recurrenceToCancel, setRecurrenceToCancel] = useState<number>();

  const { convertToDatePtBr } = useDateTimeUtil();
  const recurrenceEntryRepo = useRecurrenceEntryRepository();

  function loadData() {
    recurrenceEntryRepo.findAll()
      .then(res => setData(res.data));
  }

  function remove(id: string) {
    recurrenceEntryRepo.remove(id)
      .then(res => {
        message.success(res.message);
        loadData();
      }).catch(err => {
      message.error(err.message);
    })
  }

  useEffect(() => loadData(), []);


  function ModalConfirmCancel() {
    return (
      <Modal
        title={'Confirmar Cancelamento de Recorrência ?'}
        visible={!!recurrenceToCancel}
        onOk={() => onCancel()}
        onCancel={() => setRecurrenceToCancel(undefined)}
        okText={'Confirmar'}
        cancelText={'Cancelar'}
        confirmLoading={recurrenceEntryRepo.loading}
      >
        Após o cancelamento, não será possível gerar nem uma recorrência a mais, porém, os lançamentos que já foram gerados permanescem intactos.
      </Modal>
    )
  }

  function onCancel() {
    return recurrenceEntryRepo.cancelRecurrence(recurrenceToCancel ?? 0)
      .then((res) => {
        message.success(res.message)
        setRecurrenceToCancel(undefined);
        loadData()
      })
  }

  return (
    <>
      {
        recurrenceToCancel && <ModalConfirmCancel />
      }
      <Table
        columns={[
          {
            title: 'Nome',
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: 'Inicio',
            dataIndex: 'dateTimeBeginContract',
            render: convertToDatePtBr
          },
          {
            title: 'Fim',
            dataIndex: 'dateTimeEndContract',
            render: (val) => val ? convertToDatePtBr(val) : <Text type={'secondary'}>Não Informado</Text>
          },
          {
            title: 'Primeiro Vencimento',
            dataIndex: 'firstDueDate',
            render: convertToDatePtBr,
          },
          {
            title: 'Frequência',
            dataIndex: 'periodRecurrence',
            render: recurrencePeriodTranslate,
          },
          {
            title: 'Tipo Contrato',
            dataIndex: 'contractType',
            render: recurrenceContractTypeTranslateService
          },
          {
            title: 'Status',
            dataIndex: 'canceled',
            render: (canceled) => canceled ? <Tag color={'red'}>Cancelado</Tag> : <Tag>Ativo</Tag>
          },
          {
            dataIndex: 'id',
            render: (val) => (
              <ListActions
                onEditClick={() => props.navigator.goToEditPage(val)}
                onRemoveClick={() => remove(val)}
                options={[
                  { name: 'ITENS', onClick: () => props.navigator.goToCustomPage(`items/${val}`) },
                  { name: 'LANÇAMENTOS', onClick: () => props.navigator.goToCustomPage(`parse/${val}`) },
                  { name: 'CANCELAR', onClick: () => setRecurrenceToCancel(val) },
                ]}
              />
            )
          },
        ]}
        dataSource={data}
      />
    </>
  )
}