import {BaseResponse, BaseSearchResponse, usePlaneduHttpService} from "../service/planedu.http.service";
import {BankAccountEntity} from "../entity/bank-account.entity";
import {QueryParam} from "../../core/service/http.service";
import {BankAccountTransferenceEntity} from "../entity/bank-account-transference.entity";

export function useBankAccountRepository() {
  const httpService = usePlaneduHttpService<BankAccountEntity>('bank-account');

  function getBankAccountStatusBalance(bankAccountId: string): Promise<BaseResponse<{ shouldAddFirstBalance: boolean }>> {
    return httpService.get(`${httpService.resourceName}/${bankAccountId}/status-balance`);
  }

  function transferBalance(
    accountFromId: number,
    accountToId: number,
    value: number,
    dateTime: any
  ): Promise<BaseResponse<any>> {
    return httpService.post(`${httpService.resourceName}/transfer-balance`, JSON.stringify({
      accountFromId,
      accountToId,
      value,
      dateTime,
    }))
  }

  function exportToExcel() {
    return httpService.downloadRequest(
      'get',
      `${httpService.resourceName}/export`,
      'contas-bancarias.xlsx',
    )
  }

  function findAll(onlyActive?: boolean): Promise<BaseResponse<BankAccountEntity[]>> {
    const params: QueryParam[] = [];
    if (typeof onlyActive !== 'undefined') {
      params.push({ key: 'active', value: onlyActive ? 1 : 0})
    }
    return httpService.get(
      `${httpService.resourceName}`,
      undefined,
      params
    );
  }

  function getBalanceAmount(id: string): Promise<BaseResponse<number>> {
    return httpService.get(
      `${httpService.resourceName}/${id}/balance-amount`
    )
  }

  function addInitialBalance(args: {
    dateTime: any,
    creditValue: number,
    bankAccountId: number}
  ): Promise<BaseResponse<BankAccountEntity>> {
    return httpService.post(
      `${httpService.resourceName}/first-balance`,
      JSON.stringify(args),
    )
  }

  function listTransference(
    accountId: string | number,
    limit: number,
    offset: number
  ): Promise<BaseSearchResponse<BankAccountTransferenceEntity>> {
    return httpService.get(
      `${httpService.resourceName}/${accountId}/transference`,
      undefined,
      [
        { key: 'limit', value: limit },
        { key: 'offset', value: offset },
      ]
    )
  }

  function cancelTransference(id: number): Promise<BaseResponse<BankAccountTransferenceEntity>> {
    return httpService.post(
      `${httpService.resourceName}/${id}/cancel-transference`,
    )
  }

  return {
    ...httpService,
    getBankAccountStatusBalance,
    transferBalance,
    exportToExcel,
    findAll,
    getBalanceAmount,
    addInitialBalance,
    listTransference,
    cancelTransference
  }

}