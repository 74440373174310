import {useRouteMatch} from "react-router-dom";
import {ModuleResource, ModuleResourceTypeEnum, ModuleRoot} from "../../../core/component/module/module.root";
import React from "react";
import {ListUser} from "./pages/list.user";
import {CreateUser} from "./pages/create.user";
import {EditUser} from "./pages/edit.user";
import {EditPasswordUser} from "./pages/edit.password.user";

export function UserModule() {
    const routeMatch = useRouteMatch();
    const resources: ModuleResource[] = [
        {
            type: ModuleResourceTypeEnum.CREATE,
            render: (props) => <CreateUser {...props} />
        },
        {
            type: ModuleResourceTypeEnum.EDIT,
            render: (props) => <EditUser {...props} />
        },
        {
            type: ModuleResourceTypeEnum.CUSTOM,
            customPath: 'password/:id',
            render: (props) => <EditPasswordUser {...props} />
        },
        {
            type: ModuleResourceTypeEnum.DEFAULT,
            render: (props) => <ListUser {...props} />
        },
    ]

    return (
        <ModuleRoot
            resource={routeMatch.url}
            moduleResources={resources}
        />
    );
}
