import {Button, Form, Input, message, Typography} from "antd";
import React from "react";
import {useAuthService} from "../../../../service/auth.service";
import {useHistory} from "react-router-dom";

export function FormRequestChange(props: any) {
  const [form] = Form.useForm();

  const history = useHistory();
  const authService = useAuthService();

  function onFinish(values: any) {
    const { emailOrUsername } = values;
    return authService.requestChange({ emailOrUsername }).then(res => {
      message.success(res.message)
      history.push('/auth/login');
    }).catch(err => {
      message.error(err.message);
    })
  }

  function onCancel() {
    history.push('/auth/login')
  }

  return (
    <Form
      form={form}
      layout={'vertical'}
      style={{width: '100%', backgroundColor: 'white', padding: 50, borderRadius: 10}}
      initialValues={{ remember: true }}
      onFinish={onFinish}
    >
      <Typography.Title level={5} style={{textAlign: 'center'}}>Recuperação de Senha</Typography.Title>
      <Form.Item name="emailOrUsername" label={'Informe seu email ou usuário.'} rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item>
        <Button loading={authService.loading} type="primary" htmlType="submit" style={{width: '100%', }}>
          Enviar
        </Button>
      </Form.Item>
      <Form.Item>
        <Button  onClick={() => onCancel()} style={{width: '100%', backgroundColor: '#1b1b1b', color: 'white'}}>
          Cancelar
        </Button>
      </Form.Item>
    </Form>
  )
}