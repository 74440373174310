import {FinancialEntryEntity} from "../../../../domain/entity/financial-entry.entity";
import {Button, Card, Col, Descriptions, Drawer, Row, Statistic} from "antd";
import React, {useState} from "react";
import {useDateTimeUtil} from "../../../../core/util/date-time.util";

export function DetailsEntry(props: { entry: FinancialEntryEntity }) {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const { entry } = props
  const { convertToDatePtBr } = useDateTimeUtil();
  return (
    <Row gutter={12}>
      <Col span={4}>
        <Card>
          <Statistic
            title="Valor Bruto"
            value={parseFloat(entry.documentGrossValue.toString())}
            precision={2}
            decimalSeparator={','}
            prefix="R$"
          />
        </Card>
      </Col>
      <Col span={4}>
        <Card>
          <Statistic
            title="Valor Líquido"
            value={parseFloat(entry.documentNetValue.toString())}
            precision={2}
            decimalSeparator={','}
            prefix="R$"
          />
        </Card>
      </Col>
      <Col span={4}>
        <Card>
          <Statistic
            title="Total de Itens"
            value={entry.items?.length}
          />
        </Card>
      </Col>
      <Col span={12}>
        <Descriptions bordered>
          <Descriptions.Item label={'Cliente / Fornecedor'} span={24}>
            {entry.person?.name}
          </Descriptions.Item>
          <Descriptions.Item label={'Movimento'}>
            {entry.id} (<Button onClick={() => setDrawerVisible(true)} type={'link'} size={'small'}>+ Info</Button>)
          </Descriptions.Item>
        </Descriptions>
      </Col>
      <Drawer
        width={350}
        visible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        bodyStyle={{padding: 0}}
      >
        <Descriptions bordered layout={'vertical'}>
          <Descriptions.Item label={'Filial'} span={12}>
            {entry?.firm?.name}
          </Descriptions.Item>
          <Descriptions.Item label={'Centro de Custo'} span={12}>
            {entry?.costCenter?.code} - {entry?.costCenter?.name}
          </Descriptions.Item>
          <Descriptions.Item label={'Cliente / Fornecedor'} span={12}>
            {entry?.person?.name}
          </Descriptions.Item>
          <Descriptions.Item label={'Documento'} span={12}>
            {entry?.documentNumber}
          </Descriptions.Item>
          <Descriptions.Item label={'Emissao'} span={12}>
            {entry?.dateTimeEmission ? convertToDatePtBr(entry.dateTimeEmission) : ''}
          </Descriptions.Item>
          <Descriptions.Item label={'Entrada'} span={12}>
            {entry?.dateTimeEntry ? convertToDatePtBr(entry.dateTimeEntry) : ''}
          </Descriptions.Item>
        </Descriptions>
      </Drawer>
    </Row>
  )
}