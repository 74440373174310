import React, {useEffect, useState} from "react";
import {Divider, Drawer, Layout, message, Modal, Table, TablePaginationConfig,} from "antd";
import {ListActions} from "../../../../core/component/list-actions";
import {useFinancialEntryRepository} from "../../../../domain/repository/financial-entry.repository";
import {FinancialEntryEntity} from "../../../../domain/entity/financial-entry.entity";
import {PersonEntity} from "../../../../domain/entity/person.entity";
import {useDateTimeUtil} from "../../../../core/util/date-time.util";
import moment, {Moment} from "moment";
import {FilterFinancialEntry} from "../component/filter.financial-entry";
import {Route, Switch as SwitchRouter, useRouteMatch} from "react-router-dom";
import {ShowFinancialEntry} from "./show.financial-entry";
import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {WriteOffEntryInstallment} from "./write-off.entry-installment";
import {useNumberFormatter} from "../../../service/number-formatter.service";
import {HeaderFinancialEntry} from "../component/header.financial-entry";
import {CancelFinancialEntry} from "./cancel.financial-entry";
import {StatusPaymentComponent} from "../component/status-payment.component";

export function ListFinancialEntry(props: ModuleRenderProps) {
  const financialEntryRepo = useFinancialEntryRepository();
  const [data, setData] = useState<FinancialEntryEntity[]>([]);
  const [page, setPage] = useState<number>(1);
  const [totalData, setTotalData] = useState(0);
  const [filter, setFilter] = useState<any>({});

  const routeMatch = useRouteMatch();
  const dtFormat = useDateTimeUtil();
  const { decimalFormatter } = useNumberFormatter();

  function loadData() {
    const limit = 10;
    const offset = (page - 1) * limit;
    financialEntryRepo.search({ limit, offset, filter }).then(res => {
      setTotalData(res.total);
      setData(res.data)
    });
  }

  useEffect(() => {
    loadData();
  }, [filter, page, routeMatch.path])

  function removeItem(id: string) {
    financialEntryRepo.remove(id)
      .then(res => {
        message.success(res.message);
        loadData();
      }).catch(err => {
      message.error(err.message);
    })
  }

  const columns = [
    {
      title: 'Movimento',
      dataIndex: 'id' ,
      key: 'id',
    },
    {
      title: 'Cliente/Fornecedor',
      dataIndex: 'person' ,
      key: 'person',
      render: (val: PersonEntity) => val.name
    },
    {
      title: 'Tipo Documento',
      dataIndex: 'documentType' ,
      key: 'documentType',
      render: (val: DocumentType) => val?.name
    },
    {
      title: 'Documento',
      dataIndex: 'documentNumber' ,
      key: 'description',
    },
    {
      title: 'Valor',
      dataIndex: 'documentGrossValue' ,
      key: 'documentGrossValue',
      render: (value: number) => decimalFormatter.format(value)
    },
    {
      title: 'Entrada',
      dataIndex: 'dateTimeEntry' ,
      key: 'dateTimeEntry',
      render: (val: string) => dtFormat.convertToDatePtBr(moment(val))
    },
    {
      title: 'Criação',
      dataIndex: 'createdAt' ,
      key: 'createdAt',
      render: (val: string) => dtFormat.convertToDatePtBr(moment(val))
    },
    {
      title: 'Status',
      dataIndex: 'statusPayment' ,
      key: 'statusPayment',
      render: (val: string, row: any) => (
        <StatusPaymentComponent
          status={val}
          isCanceled={row.isCanceled}
        />
      )
    },
    {
      dataIndex: 'id',
      render: (val: any) => (
        <>
          <ListActions
            onEditClick={() => props.navigator.goToEditPage(val) }
            onRemoveClick={() => removeItem(val) }
            options={[
              { name: 'VISUALIZAR' , onClick: () => props.navigator.goToShowPage(val) },
              { name: 'BAIXA' , onClick: () => props.navigator.goToCustomPage(`write-off/${val}`) },
              { name: 'CANCELAR' , onClick: () => props.navigator.goToCustomPage(`cancel/${val}`) },
              { name: 'RATEIO' , onClick: () => props.navigator.goToCustomPage(`apportionment/${val}`) },
              { name: 'RENEGOCIAR' , onClick: () => props.navigator.goToCustomPage(`renegotiate/${val}`) },
            ]}
          />
        </>
      )
    }
  ]

  function filterType(type?: string) {
    if (type) {
      setFilter({ type });
    } else {
      setFilter(undefined);
    }
  }

  function filterDocument(document?: string) {
    if (document) {
      setFilter({ document })
    } else {
      setFilter(undefined);
    }
  }

  function filterNumMov(id: number) {
    if (id) {
      setFilter({ id })
    } else {
      setFilter(undefined);
    }
  }

  function filterInterval(initialDate: Moment, finalDate: Moment) {
    if (initialDate) {
      setFilter({
        period: `${initialDate.format('YYYY-MM-DD')},${finalDate.format('YYYY-MM-DD')}`
      });
    } else {
      setFilter(undefined);
    }
  }

  function filterPerson(personId: number, initialDate: Moment, finalDate: Moment) {
    if (initialDate) {
      setFilter({
        period: `${initialDate.format('YYYY-MM-DD')},${finalDate.format('YYYY-MM-DD')}`,
        personId
      });
    } else {
      setFilter(undefined);
    }
  }

  function exportEntries(initialDate: Moment, finalDate: Moment) {
    financialEntryRepo.exportEntries(
      initialDate.format('YYYY-MM-DD'),
      finalDate.format('YYYY-MM-DD'),
    )
  }

  const paginate: TablePaginationConfig = {
    total: totalData,
    pageSize: 10,
    current: page,
    onChange: page => setPage(page),
    showSizeChanger: false,
  }

  return (
    <>
      <HeaderFinancialEntry />
      <Divider />
      <Layout.Content>
        <FilterFinancialEntry
          onTypeFilter={filterType}
          onDocumentFilter={filterDocument}
          onIntervalFilter={filterInterval}
          onNumMovFilter={filterNumMov}
          onExport={exportEntries}
          onPersonFilter={filterPerson}
        />
        <Table
          columns={columns}
          dataSource={data}
          loading={financialEntryRepo.loading}
          pagination={paginate}
        />
      </Layout.Content>
      <SwitchRouter>
        <Route path={`${routeMatch.path}/show/:id`}>
          <Drawer
            placement={'bottom'}
            height={550}
            visible={true}
            bodyStyle={{padding: 0}}
            onClose={() => {
              loadData();
              props.navigator.goToListPage()
            }}
          >
            <ShowFinancialEntry {...props} />
          </Drawer>
        </Route>
        <Route path={`${routeMatch.path}/write-off/:id`}>
          <Modal
            visible={true}
            footer={null}
            width={1050}
            bodyStyle={{padding: 0}}
            onCancel={() => props.navigator.goToListPage()}
          >
            <WriteOffEntryInstallment {...props} />
          </Modal>
        </Route>
        <Route path={`${routeMatch.path}/cancel/:id`}>
          <Modal
            visible={true}
            footer={null}
            width={800}
            bodyStyle={{padding: 0}}
            onCancel={() => props.navigator.goToListPage()}
          >
            <CancelFinancialEntry {...props} onSaved={loadData}  />
          </Modal>
        </Route>
      </SwitchRouter>
    </>
  );
}