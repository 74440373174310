import {useRouteMatch} from "react-router-dom";
import {ModuleResource, ModuleResourceTypeEnum, ModuleRoot} from "../../../core/component/module/module.root";
import React from "react";
import {ListFirm} from "./pages/list.firm";
import {CreateFirm} from "./pages/create.firm";
import {EditFirm} from "./pages/edit.firm";
import {BindFilialFirm} from "./pages/bind-filial.firm";

export function FirmModule() {
    const routeMatch = useRouteMatch();
    const resources: ModuleResource[] = [
        {
            type: ModuleResourceTypeEnum.CREATE,
            render: (props) => <CreateFirm {...props} />
        },
        {
            type: ModuleResourceTypeEnum.EDIT,
            render: (props) => <EditFirm {...props} />
        },
        {
            type: ModuleResourceTypeEnum.CUSTOM,
            customPath: 'filial/:id',
            render: (props) => <BindFilialFirm {...props} />
        },
        {
            type: ModuleResourceTypeEnum.DEFAULT,
            render: (props) => <ListFirm {...props} />
        },
    ]

    return (
        <ModuleRoot
            resource={routeMatch.url}
            moduleResources={resources}
        />
    );
}
