import React, {useCallback, useEffect, useState} from "react";
import MaskedInput from "antd-mask-input";
import {HeaderManagerialAccounts} from "../component/header.managerial-accounts";
import {Col, Divider, Form, Input, message, Radio, Row, Switch} from "antd";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import {useManagerialRepository} from "../../../../domain/repository/managerial.repository";
import {useClientRepository} from "../../../../domain/repository/client.repository";
import {ManagerialAccountTypeEnum} from "../../../../domain/vo/managerial-account.type.enum";
import {useManagerialCodeService} from "../service/managerial-code.service";
import TextArea from "antd/es/input/TextArea";
import {SelectLedgerAccount} from "../../../component/dropdown/select.ledger-account";
import {LedgerAccountEntity} from "../../../../domain/entity/ledger-account.entity";
import {useLedgerAccountRepository} from "../../../../domain/repository/ledger-account.repository";

export function CreateManagerial(props: any) {
  const [form] = Form.useForm();
  const managerialService = useManagerialRepository();
  const [managerialAccountMask, setManagerialAccountMask] = useState<string>('#.#');
  const [ledgerAccounts, setLedgerAccounts] = useState<LedgerAccountEntity[]>([]);
  const [isAnalytical, setIsAnalytical] = useState(false);

  const clientRepository = useClientRepository();
  const ledgerAccountRepo = useLedgerAccountRepository();

  const loadParams = useCallback(() => {
    ledgerAccountRepo.listAnalyticalAccounts().then(res => setLedgerAccounts(res.data));
    clientRepository.getSettings()
      .then(res => {
        setManagerialAccountMask(res.managerialAccountMask)
      });
  }, [ledgerAccountRepo, clientRepository])

  useEffect(() => {
    loadParams()
    form.setFields([
      { name: 'active', value: true }
    ])
  }, [])

  useEffect(() => {
    if (!isAnalytical) {
      form.resetFields(['ledgerAccountId'])
    }
  }, [isAnalytical])

  function checkIsAnalytical() {
    const code = form.getFieldValue('code');
    if (!code) {
      return;
    }
    setIsAnalytical(code.toString().trim().length === managerialAccountMask?.length)
  }

  function onFinish(data: any) {
    data.code = data.code.split('.')
      .map((item: string) => item.trim())
      .filter((w: string) =>  w && !isNaN(Number(w.trim())))
      .join('.');
    managerialService.create(data).then(
      res => {
        form.resetFields();
        message.success(res.message)
        props.navigator.goToListPage();
      }
    ).catch(err => {
      message.error(err.message);
    })
  }

  return (
    <>
      <HeaderManagerialAccounts mode={'form'} />
      <Divider />
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        <Row gutter={12}>
          <Col>
            <Form.Item name={['type']} label="Tipo" rules={[{ required: true }]}>
              <Radio.Group>
                {
                  ManagerialAccountTypeEnum.getValues().map(item => (
                    <Radio value={item.value} children={item.label} />
                  ))
                }
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item name={['description']} label="Descrição" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={['code']} label="Código" rules={[{required: true}]}>
              <MaskedInput
                mask={managerialAccountMask}
                onBlur={checkIsAnalytical}
                placeholderChar={' '}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <SelectLedgerAccount
              data={ledgerAccounts}
              required={false}
              disabled={!isAnalytical}
            />
          </Col>
        </Row>
        <Form.Item name={'help'} label={'Dica de Ajuda'}>
          <TextArea />
        </Form.Item>
        <Form.Item name={'active'} valuePropName={'checked'}>
          <Switch
            checkedChildren={'Ativo'}
            unCheckedChildren={'Inativo'}
          />
        </Form.Item>

        <FormActionsButtons
          onCancel={props.navigator.goToDefaultPage}
          isLoading={managerialService.loading}
        />
      </Form>
    </>
  )
}
