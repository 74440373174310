import {Col, Form, Input, InputNumber, message, Row} from "antd";
import React, {useEffect, useState} from "react";
import {FinancialItemEntity} from "../../../../domain/entity/financial-item.entity";
import {SupplyUnitEntity} from "../../../../domain/entity/supply-unit.entity";
import {BudgetCategoryEntity} from "../../../../domain/entity/budget-category.entity";
import {useRecurrenceEntryRepository} from "../../../../domain/repository/recurrence-entry.repository";
import {useFinancialItemRepository} from "../../../../domain/repository/financial-item.repository";
import {useFinancialClassRepository} from "../../../../domain/repository/financial-class.repository";
import {SelectFinancialItem} from "../../../component/dropdown/select.financial-item";
import {SelectBudgetCategory} from "../../../component/dropdown/select.budget-category";
import {SelectSupplyUnit} from "../../../component/dropdown/select.supply-unit";
import {BrlInputNumber} from "../../../../core/component/brl-input-number";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import {RecurrenceEntryEntity} from "../../../../domain/entity/recurrence-entry.entity";

export function FormRecurrenceEntryItem(props: { recurrenceEntry: RecurrenceEntryEntity, onSaved: () => void }) {
  const [form] = Form.useForm();
  const [items, setItems] = useState<FinancialItemEntity[]>([]);
  const [supplyUnities, setSupplyUnities] = useState<SupplyUnitEntity[]>([]);
  const [categories, setCategories] = useState<BudgetCategoryEntity[]>([]);
  const { id: recurrenceEntryId } = props.recurrenceEntry

  const recurrenceEntryRepo = useRecurrenceEntryRepository();
  const itemRepo = useFinancialItemRepository();
  const financialClassRepo = useFinancialClassRepository();

  useEffect(() => {
    form.setFields([
      { name: 'discount', value: 0 },
      { name: 'quantity', value: 1 },
      { name: 'singleValue', value: 0 },
    ])
  }, [1]);

  useEffect(() => {
    itemRepo
      .listByEntryType(props.recurrenceEntry.typeEntry)
      .then(res => setItems(res.data));
  }, [props.recurrenceEntry])

  function loadItems() {
    const item = items.find(w => w.id === form.getFieldValue('financialItemId'));
    if (item && item.supplyUnits) {
      setSupplyUnities(item.supplyUnits);
    }
    if (item && item.classId) {
      financialClassRepo.find(item.classId.toString())
        .then(res => setCategories(res.data.budgetCategories))
    }
  }

  function calcTotalAmount() {
    const { singleValue, quantity, discount } = form.getFieldsValue();
    form.setFields([
      { name: 'amountTotal' , value: singleValue * quantity - discount }
    ])
  }

  function onFinish(values: any) {
    const { quantity } = values;
    if (!quantity || quantity == 0) {
      form.setFields([
        { name: 'quantity', errors: ['Quantidade deve ser maior que 0']}
      ]);
      return;
    }
    return recurrenceEntryRepo.addItem(recurrenceEntryId, values)
      .then(res => {
        message.success(res.message);
        props.onSaved();
        form.resetFields();
        form.setFields([{ name: 'quantity', value: 1 }])
      }).catch(err => {
        message.error(err.message);
      })
  }

  return (
    <Form form={form} layout={'vertical'} onFinish={onFinish} >
      <Row gutter={12}>
        <Col span={8}>
          <SelectFinancialItem data={items} onChange={loadItems} />
        </Col>
        <Col span={8}>
          <SelectBudgetCategory data={categories} />
        </Col>
        <Col span={8}>
          <SelectSupplyUnit data={supplyUnities} />
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={6}>
          <BrlInputNumber
            label={'Valor Unit.'}
            name={'singleValue'}
            required={true}
            onBlur={calcTotalAmount}
          />
        </Col>
        <Col span={6}>
          <Form.Item name={'quantity'} label={'Quantidade'} rules={[{ required: true }]}>
            <InputNumber style={{width: '100%'}} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <BrlInputNumber
            label={'Desconto'}
            name={'discount'}
            required={true}
            onBlur={calcTotalAmount}
          />
        </Col>
        <Col span={6}>
          <Form.Item name={'amountTotal'} label={'Valor Liq.'}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <FormActionsButtons />
    </Form>
  )
}