import React, {useEffect, useState} from "react";
import {HeaderBudgetCategory} from "../component/header.budget-category";
import {Divider, Layout, message, Table, Tag,} from "antd";
import {BudgetEntity} from "../../../../domain/entity/budget.entity";
import {ListActions} from "../../../../core/component/list-actions";
import {useDateTimeUtil} from "../../../../core/util/date-time.util";
import {useBudgetCategoryRepository} from "../../../../domain/repository/budget-category.repository";
import {BudgetCategoryEntity} from "../../../../domain/entity/budget-category.entity";

export function ListBudgetCategory(props: any) {
    const dateTimeUtil = useDateTimeUtil();
    const budgetRepository = useBudgetCategoryRepository();
    const [data, setData] = useState<BudgetCategoryEntity[]>([]);

    function loadData() {
        budgetRepository.search().then(res => {
            setData(res.data)
        });
    }

    useEffect(() => {
        loadData()
    }, [1]);

    function removeBudget(id: string) {
        budgetRepository.remove(id)
            .then(res => {
                message.success(res.message);
                loadData();
            }).catch(err => {
                message.error(err.message);
        })
    }

    const columnsBudget = [
        {
            title: 'Nome',
            dataIndex: 'name' ,
            key: 'name',
        },
        {
            title: 'Status',
            dataIndex: 'active',
            key: 'active',
            render: (value: boolean) => (
                <Tag color={value ? 'green' : 'red'}>
                    {value ? 'Ativo' : 'Inativo'}
                </Tag>
            )
        },
        {
            dataIndex: 'id',
            render: (val: any, budget: any) => (
                <>
                    <ListActions
                        onEditClick={() => props.navigator.goToEditPage(val) }
                        onRemoveClick={() => removeBudget(val) }
                    />
                </>
            )
        }
    ]
    return (
        <>
            <HeaderBudgetCategory />
            <Divider />
            <Layout.Content>
                <Table 
                    columns={columnsBudget} 
                    dataSource={data} 
                    loading={budgetRepository.loading} />
            </Layout.Content>
        </>
    );
}