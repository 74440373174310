import {Checkbox, Modal,Row, Col, Button, Space,Divider} from "antd";
import {useEffect, useState} from "react";
import {BudgetEntity} from "../../../../domain/entity/budget.entity";
import {useBudgetRepository} from "../../../../domain/repository/budget.repository";
import {RollbackOutlined} from '@ant-design/icons';

export type ListMonthsModalProps = {
    visible: boolean,
    budgetId: string,
    onClose: () => void
}
export function ListMonthsModal(props: ListMonthsModalProps) {
    const [budget, setBudget] = useState<BudgetEntity>();
    const budgetRepo = useBudgetRepository();
    const months: number[] = [];

    useEffect(() => {
        budgetRepo.find(props.budgetId )
        .then(res => setBudget(res.data));
        if(budget){
            for(let i = 0 ; i < budget.months.length; i++ ){
                months.push(budget.months[i])
            }
        }
        else{
            console.log("aqui")
        }
        {console.log("teste:", months)}

    }, [1]);

    function parseMonths(month: number) {
     
        switch (month) {
            case 1:
                return 'Janeiro';
            case 2:
                return 'Fevereiro';
            case 3:
                return 'Março';
            case 4:
                return 'Abril';
            case 5:
                return 'Maio';
            case 6:
                return 'Junho';
            case 7:
                return 'Julho';
            case 8:
                return 'Agosto';
            case 9:
                return 'Setembro';
            case 10:
                return 'Outubro';
            case 11:
                return 'Novembro';
            case 12:
                return 'Dezembro';
            default:
                return month;
        }
    }
    return (
        <Modal
            title={'Meses do Orçamento'}
            width={750}
            visible={props.visible}
            footer={null}
        >
            {
                budget ? budget.months.map(month => (
                    <p>{parseMonths(month)}</p>
                )) : ""
            }
            <Checkbox.Group style={{ width: '100%' }} 
                defaultValue={budget ? budget.months : undefined}
                >
                   {console.log("oi:", months)}
                <Row>
                    <Col span={6}>
                        <Checkbox value={1}>Janeiro</Checkbox>
                    </Col>
                    <Col span={6}>
                        <Checkbox value={2}>Fevereiro</Checkbox>
                    </Col>
                    <Col span={6}>
                        <Checkbox value={3}>Março</Checkbox>
                    </Col>
                    <Col span={6}>
                        <Checkbox value={4}>Abril</Checkbox>
                    </Col>
                    <Col span={6}>
                        <Checkbox value={5}>Maio</Checkbox>
                    </Col>
                    <Col span={6}>
                        <Checkbox value={6}>Junho</Checkbox>
                    </Col>
                    <Col span={6}>
                        <Checkbox value={7}>Julho</Checkbox>
                    </Col>
                    <Col span={6}>
                        <Checkbox value={8}>Agosto</Checkbox>
                    </Col>
                    <Col span={6}>
                        <Checkbox value={9}>Setembro</Checkbox>
                    </Col>
                    <Col span={6}>
                        <Checkbox value={10}>Outubro</Checkbox>
                    </Col>
                    <Col span={6}>
                        <Checkbox value={11}>Novembro</Checkbox>
                    </Col>
                    <Col span={6}>
                        <Checkbox value={12}>Dezembro</Checkbox>
                    </Col>
                </Row>
            </Checkbox.Group>
            <Divider />
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Space>
                    <Button
                        onClick={props.onClose}
                        htmlType={'button'}
                        icon={<RollbackOutlined />}
                        shape={'round'}
                        type={'ghost'}>
                        Fechar
                    </Button>
                </Space>
            </div>
        </Modal>
    )
}