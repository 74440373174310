import {AppPageHeader} from "../../../../core/component/page-header";
import {useHistory, useRouteMatch} from 'react-router-dom';
import React, {ReactNode} from "react";

export type HeaderProfileProps = {
    createRoute?: string;
    mode?: 'list' | 'form';
    title?: string,
    subtitle?:string,
    children?: ReactNode
}

export function HeaderRecurrenceEntry(props: HeaderProfileProps) {
    const urlMatch = useRouteMatch();
    const history = useHistory();

    function getCreateLink(): string {
        return `${urlMatch.url}/create`;
    }

    function getListLink(): string {
        return `list`;
    }
    return (
        <>
            <AppPageHeader
                title={props.title ?? 'Recorrência'}
                mode={props.mode}
                subtitle={props.subtitle ?? 'Gestão dos contratos de recorrência'}
                onCreateLink={getCreateLink()}
                onListLink={getListLink()}
                onBack={() => history.goBack()}
                onCreateLabel={'Adicionar'}
                children={props.children}
            />
        </>
    );

}
