import React, {useEffect, useState} from "react";
import {RecurrenceEntryContractType, RecurrenceEntryEntity} from "../../../../domain/entity/recurrence-entry.entity";
import {useRecurrenceEntryRepository} from "../../../../domain/repository/recurrence-entry.repository";
import {Content} from "antd/es/layout/layout";
import {FormFinancialEntry} from "../../financial-entry/component/form.financial-entry";
import {FinancialEntryEntity} from "../../../../domain/entity/financial-entry.entity";
import moment from "moment";
import {FormFinancialEntryItem} from "../../financial-entry/component/form.financial-entry-item";
import {FinancialEntryItemEntity} from "../../../../domain/entity/financial-entry-item.entity";
import {message} from "antd";
import {useFinancialEntryRepository} from "../../../../domain/repository/financial-entry.repository";
import {FormEntryCheckItem} from "./form.check-entry-item.component";
import {FormFinancialEntryTax} from "../../financial-entry/component/form.financial-entry-tax";
import {FinancialEntryTaxEntity} from "../../../../domain/entity/financial-entry-tax.entity";
import {FinancialEntryInstallmentEntity} from "../../../../domain/entity/financial-entry-installment.entity";
import {FormFinancialInstallment} from "../../financial-entry/component/form.financial-installment";
import {ResultFinancialEntry} from "../../financial-entry/component/result.financial-entry";
import {FormCheckInstallmentComponent} from "./form.check-installment.component";
import {FormApportionmentEntryComponent} from "../../financial-entry/component/form.apportionment-entry.component";
import {FinancialEntryApportionmentEntity} from "../../../../domain/entity/financial-entry-apportionment.entity";
import {RecurrenceEntryParsedEntity} from "../../../../domain/entity/recurrence-entry-parsed.entity";

export type FormGenerateEntryProps = {
  recurrenceEntryId: number,
  dueDateTime: Date,
  setStepTitle: (title: string) => any,
  onFinish: () => any,
  onBindEntry: (entity: RecurrenceEntryParsedEntity) => any,
}

export function FormGenerateEntry(props: FormGenerateEntryProps) {
  const [recurrenceEntry, setRecurrenceEntry] = useState<RecurrenceEntryEntity>();
  const [entry, setEntry] = useState<FinancialEntryEntity>();
  const [entryItems, setEntryItems] = useState<FinancialEntryItemEntity[]>([])
  const [entryTaxes, setEntryTaxes] = useState<FinancialEntryTaxEntity[]>([]);
  const [installments, setInstallments] = useState<FinancialEntryInstallmentEntity[]>([]);
  const [step, setStep] = useState(1);
  const { recurrenceEntryId } = props;

  const financialEntryRepo = useFinancialEntryRepository();
  const recurrenceRepo = useRecurrenceEntryRepository();

  useEffect(() => {
    recurrenceEntryId && (
      recurrenceRepo.find(recurrenceEntryId.toString())
        .then(res => setRecurrenceEntry(res.data))
    );
  }, [recurrenceEntryId])

  useEffect(() => {
    if (entry) {
      entry.items?.length && setEntryItems(entry.items);
      entry.taxes?.length && setEntryTaxes(entry.taxes);
      entry.installments?.length && setInstallments(entry.installments);
    }
  }, [entry])

  useEffect(() => {
    switch (step) {
      case 1:
        props.setStepTitle('Dados Básicos');
        break
      case 2:
        props.setStepTitle('Itens do Lançamento');
        break
      case 3:
        props.setStepTitle('Retenção Tributária');
        break
      case 4:
        props.setStepTitle('Forma de Pagamento');
        break
      case 5:
        props.setStepTitle('Rateio entre Centros de Custo');
        break
      case 6:
        props.setStepTitle('Lançamento Gerado');
        break
    }
    if (step === 5) {
      if (!recurrenceEntry?.apportionment || !recurrenceEntry.apportionment.length) {
        setStep(step + 1);
      }
    }
  }, [step])

  async function reloadEntry(): Promise<FinancialEntryEntity | null> {
    return entry ? financialEntryRepo
      .find(entry.id.toString())
      .then(res => {
        setEntry(res.data)
        return res.data;
      }) : Promise.resolve(null);
  }

  function onFinishEntry(entry: FinancialEntryEntity) {
    setEntry(entry);
    reloadEntry();
    recurrenceRepo.bindEntry(recurrenceEntryId, {
      dueDate: props.dueDateTime.toISOString(),
      recurrenceEntryId,
      financialEntryId: entry.id,
    }).then(res => {
      props.onBindEntry(res.data);
      message.success(res.message);
      setStep(step + 1);
    }).catch(err => {
      message.error(err.message);
    });
  }

  function StepEntry() {
    function getObs() {
      return `Movimento gerado a partir da recorrência ` +
              `'${recurrenceEntry?.name}' referente ao dia ` +
              `${props.dueDateTime.toLocaleDateString()}`
    }
    return (
      <FormFinancialEntry
        onFinish={onFinishEntry}
        initialValues={{
          type: recurrenceEntry?.typeEntry,
          firmId: recurrenceEntry?.firmId,
          costCenterId: recurrenceEntry?.costCenterId,
          personId: recurrenceEntry?.personId,
          dateTimeEmission: moment(props.dueDateTime),
          dateTimeEntry: moment(props.dueDateTime),
          dueDateTime: moment(props.dueDateTime),
          observation: getObs(),
        }}
      />
    )
  }

  function StepCheckEntryItem() {
    function onItemCreated() {
        reloadEntry().then((entry) => {
          if (entry?.items?.length === recurrenceEntry?.items.length) {
            setStep(step + 1);
          }
        })
    }

    return (
      <>
        {recurrenceEntry?.items
          .map(item => (
            <FormEntryCheckItem
              item={item}
              financialEntry={entry as FinancialEntryEntity}
              recurrenceFixed={recurrenceEntry.contractType === RecurrenceEntryContractType.FIXED}
              onItemCreated={onItemCreated}
              onCancel={() => setStep(step - 1)}
            />
          ))}
      </>
    )
  }

  function StepTaxes() {
    function onFinishTaxForm() {
      reloadEntry().then(() => setStep(step + 1));
    }

    return (
      <FormFinancialEntryTax
        entry={entry as FinancialEntryEntity}
        onFinish={onFinishTaxForm}
        items={entryItems ?? []}
        taxes={entryTaxes}
        onBack={() => setStep(1)}
      />
    )
  }

  function StepApportionment() {
    function onFinishApportionment() {
      reloadEntry().then(() => setStep(step + 1));
    }

    function getPresetApportionment(): FinancialEntryApportionmentEntity[] {
      if (entry?.apportionment && entry.apportionment.length) {
        return entry.apportionment;
      }
      if (recurrenceEntry?.apportionment?.length) {
        return recurrenceEntry.apportionment.map(item => ({
          id: 0,
          client: null,
          clientId: 0,
          apportionment: item.percentAmount * (entry?.documentGrossValue ?? 0) / 100,
          entry: entry as FinancialEntryEntity,
          costCenter: item.costCenter ?? null,
          costCenterId: item.costCenterId ?? 0,
          entryId: 0,
        }))
      }
      return [];
    }

    return (
      <FormApportionmentEntryComponent
        entry={{
          ...entry as FinancialEntryEntity,
          apportionment: getPresetApportionment(),
        }}
        onFinish={onFinishApportionment}
      />
    )
  }

  function StepCheckInstallment() {
    function onFinishInstallmentsForm() {
      reloadEntry().then(() => setStep(step + 1));
    }
    return (
      <FormCheckInstallmentComponent
        entry={entry as FinancialEntryEntity}
        onFinish={onFinishInstallmentsForm}
        installment={entry?.installments?.length ? entry.installments[0] : undefined}
        recurrenceEntry={recurrenceEntry as RecurrenceEntryEntity}
        currentDueData={props.dueDateTime}
      />
    )
  }

  function FinishStep() {
    return (
      <ResultFinancialEntry
        entry={entry as FinancialEntryEntity}
        onFinish={() => props.onFinish()}
      />
    )
  }

  return (
    <>
      <Content>
        {
          step === 1 && <StepEntry />
        }
        {
          step === 2 && <StepCheckEntryItem />
        }
        {
          step === 3 && <StepTaxes />
        }
        {
          step === 4 && <StepCheckInstallment />
        }
        {
          step === 5 && <StepApportionment />
        }
        {
          step === 6 && <FinishStep />
        }
      </Content>
    </>
  )
}

