import {Divider, Tabs} from "antd";
import React, {useEffect, useState} from "react";
import {HeaderClientProvider} from "../component/header.client-provider";
import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {PersonEntity, PersonTypeEnum} from "../../../../domain/entity/person.entity";
import {usePersonRepository} from "../../../../domain/repository/person.repository";
import {useParams} from "react-router-dom";
import {FormPersonAddress} from "../component/form.person-address";
import {FormPersonBankData} from "../component/form.person-bank-data";
import {FormConfirm} from "../component/form.confirm";
import {FormPerson} from "../component/form.person";
import {FormPersonCompany} from "../component/form.person-company";
import {FormPersonIndividual} from "../component/form.person-individual";

export function EditClientProvider(props: ModuleRenderProps) {
  const [person, setPerson] = useState<PersonEntity>();

  const { id } = useParams<{ id: string }>();
  const personRepo = usePersonRepository();

  useEffect(() => {
    id && loadPerson();
  }, [id]);

  function loadPerson() {
    personRepo.find(id).then(res => {
      setPerson(res.data)
    });
  }

  function getTypePerson() {
    switch (person?.type) {
      case PersonTypeEnum.COMPANY:
        return 'Pessoa Jurídica';
      case PersonTypeEnum.INDIVIDUAL:
        return 'Pessoa Física';
      default:
        return null;
    }
  }

  function PersonTypeForm() {
    switch (person?.type) {
      case PersonTypeEnum.COMPANY:
        return (
          <FormPersonCompany
            person={person}
            onFinish={() => null}
            onBack={() => props.navigator.goToListPage()}
            hideSkipButton
          />
        )

      case PersonTypeEnum.INDIVIDUAL:
        return (
          <FormPersonIndividual
            person={person}
            onFinish={() => null}
            onBack={() => props.navigator.goToListPage()}
            hideSkipButton
          />
        )
      default:
        return null;
    }
  }

  return (
    <>
      <HeaderClientProvider mode={'form'} title={'Editar Registro'} />
      <Divider />
      <Tabs tabPosition={'left'}>
        <Tabs.TabPane tab={"Dados Básicos"} key={'1'} >
          { person && (
            <FormPerson
              person={person}
              onFinish={() => null}
            />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane key={5} tab={getTypePerson()}>
          <PersonTypeForm />
        </Tabs.TabPane>
        <Tabs.TabPane tab={"Endereços"} key={'2'} >
          {
            person && person.personAddresses && (
              <FormPersonAddress
                onFinish={() => props.navigator.goToListPage()}
                onBack={() => props.navigator.goToListPage()}
                person={person}
                personAddress={person.personAddresses[0]}
                hideSkipStep
              />
            )
          }
        </Tabs.TabPane>
        <Tabs.TabPane tab={"Dados Bancários"} key={'3'} >
          {
            person && person.personBankData && (
              <FormPersonBankData
                onFinish={() => props.navigator.goToListPage()}
                onBack={() => props.navigator.goToListPage()}
                person={person}
                personBankData={person.personBankData[0]}
                hideSkipButton
              />
            )
          }
        </Tabs.TabPane>
        <Tabs.TabPane tab={"Funções"} key={'4'} >
          {
            person && (
              <FormConfirm
                person={person}
                onFunctionCreated={loadPerson}
                onFunctionRemoved={loadPerson}
                onFinish={() => props.navigator.goToListPage()}
              />
            )
          }
        </Tabs.TabPane>
      </Tabs>
    </>
  )
}
