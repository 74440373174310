import {Button, Col, Descriptions, Form, Input, message, Row, Select, Typography} from "antd";
import {useEffect, useState} from "react";
import {FinancialEntryEntity} from "../../../../domain/entity/financial-entry.entity";
import {CostCenterEntity} from "../../../../domain/entity/cost-center.entity";
import {useFinancialEntryRepository} from "../../../../domain/repository/financial-entry.repository";
import {useFirmRepository} from "../../../../domain/repository/firm.repository";
import {BrlInputNumber} from "../../../../core/component/brl-input-number";
import {PlusOutlined} from "@ant-design/icons";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import {useNumberFormatter} from "../../../service/number-formatter.service";

export type FormApportionmentEntryComponentProps = {
  entry: FinancialEntryEntity,
  onFinish: () => any,
}

export function FormApportionmentEntryComponent(props: FormApportionmentEntryComponentProps) {
  const [form] = Form.useForm();
  const [amountWithoutApportionment, setAmountWithoutApportionment] = useState(0);
  const [costCenters, setCostCenters] = useState<CostCenterEntity[]>([]);

  const { entry } = props;
  const entryRepo = useFinancialEntryRepository();
  const firmRepo = useFirmRepository()
  const { decimalFormatter } = useNumberFormatter();

  function loadCostCenters() {
    if (entry?.firmId) {
      firmRepo.listCostCenters(entry.firmId.toString(), true)
        .then(res => setCostCenters(res.data));
    }
  }


  function calcPercent(x: number): string {
    if (!entry) return `0`;
    return `${(x * 100 / entry.documentGrossValue).toFixed(0)}%`;
  }

  useEffect(() => {
    loadCostCenters();
    if (entry?.apportionment && entry.apportionment.length) {
      for (let x = 0; x < entry.apportionment.length; x++) {
        const item = entry.apportionment[x];
        form.setFields([
          { name: ['apportionment', x ,'costCenterId'], value: item.costCenterId },
          { name: ['apportionment', x ,'amount'], value: item.apportionment },
          { name: ['apportionment', x ,'percent'], value: calcPercent(item.apportionment) },
        ])
      }
    } else if (entry) {

      form.setFields([
        { name: ['apportionment', 0 ,'costCenterId'], value: entry.costCenterId },
        { name: ['apportionment', 0 ,'amount'], value: entry.documentGrossValue },
        { name: ['apportionment', 0 ,'percent'], value: calcPercent(entry.documentGrossValue) },
      ])
    }
    updatePercent()
  }, [entry])

  function getCostCentersOpts() {
    return costCenters
      .map(item => ({
        label: `${item.code} - ${item.name}`,
        value: item.id as number,
      }))
  }

  function updateAmountWithoutApportionment() {
    const apportionment = form.getFieldValue('apportionment');
    let temp = 0;
    for (const item of apportionment) {
      temp += parseFloat(item.amount);
    }
    console.log(temp, entry.documentNetValue,)
    setAmountWithoutApportionment(
      entry.documentNetValue - temp
    );
  }

  function checkDupCostCenter() {
    const apportionment = form.getFieldValue('apportionment');
    let x = 0;
    for (const item of apportionment) {
      const quantity = apportionment
        .filter((w: any) => w?.costCenterId === item?.costCenterId)?.length;
      if (quantity > 1) {
        form.setFields([
          { name: ['apportionment', x, 'costCenterId'], errors: ['Centro de Custo Duplicado']}
        ])
      }
      x++
    }
  }

  function onFinish(values: any) {
    const data = values.apportionment.map((item: any) => ({
      ...item,
      amount: parseFloat(item.amount)
    }))
    if (!data || !data.length) {
      return entryRepo.removeApportionment(entry.id).then(res => {
        message.success(res.message);
      }).catch(err => {
        message.error(err.message);
      })
    }
    entryRepo.saveApportionment(entry.id, data).then(res => {
      message.success(res.message);
      props.onFinish();
    }).catch(err => {
      message.error(err.message);
    })
  }

  function updatePercent() {
    let idx = 0;
    for (const item of form.getFieldValue('apportionment') ?? []) {
      form.setFields([
        { name: ['apportionment', idx , 'percent'], value: calcPercent(item.amount)}
      ])
      idx++;
    }
    updateAmountWithoutApportionment();
  }

  return (
    <>
      <Form form={form} layout={'horizontal'} onFinish={onFinish} style={{marginTop: 15}}>
        <Descriptions column={6} bordered layout={'vertical'} size={'small'}>
          <Descriptions.Item label={'Valor Total a Ser Rateado'}>
            {decimalFormatter.format(entry.documentGrossValue)}
          </Descriptions.Item>
          <Descriptions.Item label={'Valor Sem Rateio'}>
            {decimalFormatter.format(amountWithoutApportionment)}
          </Descriptions.Item>
          <Descriptions.Item span={2} label={'Observacao'}>
            É obrigatório que o rateio tenha a participação do centro de custo original {entry?.costCenter?.name}
          </Descriptions.Item>
        </Descriptions>
        <br />
        <Row gutter={12}>
          <Col span={12}>
            <Typography.Title level={5}>Centro de Custo</Typography.Title>
          </Col>
          <Col span={5}>
            <Typography.Title level={5}>Quant. Rateio</Typography.Title>
          </Col>
        </Row>
        <Form.List name="apportionment">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <Row gutter={12} key={key}>
                  <Col span={12}>
                    <Form.Item
                      {...restField}
                      name={[name, 'costCenterId']}
                      fieldKey={[fieldKey, 'costCenter']}
                      rules={[{ required: true }]}
                    >
                      <Select options={getCostCentersOpts()} onSelect={checkDupCostCenter} />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <BrlInputNumber
                      label={''}
                      name={[name,'amount']}
                      required={true}
                      onKeyUp={updatePercent}
                    />
                  </Col>
                  <Col span={2}>
                    <Form.Item name={[name, 'percent']}>
                      <Input bordered={false} disabled />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Button
                      shape={'round'}
                      onClick={() => remove(name)}
                    >
                      Remover
                    </Button>
                  </Col>
                </Row>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Centro de Custo
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <FormActionsButtons />
      </Form>
    </>
  )
}