import {Col, Divider, Form, Input, Row, message} from "antd";
import {HeaderProfile} from "../component/header.profile";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import React, {useEffect, useState} from "react";
import {useProfileRepository} from "../../../../domain/repository/profile.repository";
import {DisabledResource, ProfileEntity} from "../../../../domain/entity/profile.entity";
import {ProfileFormTable} from "../component/profile.form.table";

export function CreateProfile(props: any) {
    const [form] = Form.useForm();
    const profileRepository = useProfileRepository();
    const [resources, setResources] = useState<string[]>([]);
    const [disabledResources, setDisabledResources] = useState<DisabledResource[]>([]);

    function onFinish(data: any) {
        const values: ProfileEntity = {
            ...data,
            disabledResources: disabledResources
        }
        profileRepository.create(values)
            .then(res => {
                message.success(res.message);
                props.navigator.goToListPage();
            }).catch(err => {
                message.error(err.message);
                if(err.messageKey === 'profile:duplicated_profile_alias') {
                    form.setFields([{
                        name: 'alias',
                        errors: [err.message]
                    }])
                }
        })
    }

    useEffect(() => {
        profileRepository.getResources()
            .then(res => setResources(res.data));
    }, [1]);

    return (
        <>
            <HeaderProfile mode={'form'} title={' Perfil '} subtitle={'Cadastro de perfil'}/>
            <Divider />
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Row gutter={12}>
                    <Col xs={24} sm={24} md={24} lg={20} xl={12}>
                        <Form.Item label="Nome" name={'name'} style={{width: '100%'}} rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={20} xl={12}>
                        <Form.Item label="Apelido" name={'alias'} style={{width: '100%'}} rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={20} xl={24}>
                        <Form.Item label="Descrição" name={'description'} style={{width: '100%'}} rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <ProfileFormTable
                    disabledResources={disabledResources}
                    setDisabledResources={setDisabledResources}
                    resources={resources}
                />

                <FormActionsButtons
                    onCancel={props.navigator.goToDefaultPage}
                    isLoading={profileRepository.loading}
                />
            </Form>
        </>
    )
}