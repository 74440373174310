import React, {useEffect, useState} from "react";
import {HeaderFinancialDocumentType} from "../component/header.financial-document-type";
import {Divider, Layout, message, Table,} from "antd";
import {ListActions} from "../../../../core/component/list-actions";
import {useFinancialDocumentTypeRepository} from "../../../../domain/repository/financial-document-type.repository";
import {FinancialDocumentTypeEntity} from "../../../../domain/entity/financial-document-type.entity";
import {ExportExcelButton} from "../../../../core/component/export-button";

export function ListFinancialDocumentType(props: any) {
    const financialDocumentType = useFinancialDocumentTypeRepository();
    const [data, setData] = useState<FinancialDocumentTypeEntity[]>([]);

    function loadData() {
        financialDocumentType.findAll().then(res => {
            setData(res.data)
        });
    }

    useEffect(() => {
        loadData()
    }, [1]);

    function removeDoc(id: string) {
        financialDocumentType.remove(id)
            .then(res => {
                message.success(res.message);
                loadData();
            }).catch(err => {
                message.error(err.message);
        })
    }

    const columnsBudget = [
        {
            title: 'Nome',
            dataIndex: 'name' ,
            key: 'name',
        },
        {
            title: 'Sigla',
            dataIndex: 'alias' ,
            key: 'alias',
        },
        {
            dataIndex: 'id',
            render: (val: any) => (
                <>
                    <ListActions
                        onEditClick={() => props.navigator.goToEditPage(val) }
                        onRemoveClick={() => removeDoc(val) }
                    />
                </>
            )
        }
    ]
    return (
        <>
            <HeaderFinancialDocumentType extra={[<ExportExcelButton onClick={() => financialDocumentType.downloadDocumentTypeCSV()} />]} />
            <Divider />
            <Layout.Content>
                <Table 
                    columns={columnsBudget} 
                    dataSource={data} 
                    loading={financialDocumentType.loading}
                />
            </Layout.Content>
        </>
    );
}