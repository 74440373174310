import {SelectBase} from "./select.base";
import {PersonEntity} from "../../../domain/entity/person.entity";
import {SupplyUnitEntity} from "../../../domain/entity/supply-unit.entity";

export type SelectSupplyUnitProps = {
  data: SupplyUnitEntity[],
  label?: string,
  name?: string,
  required?: boolean;
  onChange?: (value: string | number) => void,
}

export function SelectSupplyUnit(props: SelectSupplyUnitProps) {
  function getOpts() {
    return props.data.map(item => ({
      label: `${item.description} ${item.alias}`,
      value: item.id,
    }))
  }
  return (
    <SelectBase
      label={props.label ?? 'Unidade'}
      name={props.name ?? 'supplyUnitId'}
      data={getOpts()}
      onChange={props.onChange}
      required={props.required}
    />
  )
}