import {usePlaneduHttpService} from "../service/planedu.http.service";
import {FinancialDocumentTypeEntity} from "../entity/financial-document-type.entity";

export function useFinancialDocumentTypeRepository() {
    const httpService = usePlaneduHttpService<FinancialDocumentTypeEntity>('financial-document-type');


    function downloadDocumentTypeCSV(): Promise<void> {
        return httpService.downloadRequest(
          'get',
          `${httpService.resourceName}/export`,
          'tipo-documento.xlsx',
        )
    }


    return {
        ...httpService,
        downloadDocumentTypeCSV
    }
}