import {FinancialEntryInstallmentEntity} from "../../../../../domain/entity/financial-entry-installment.entity";
import {useNumberFormatter} from "../../../../service/number-formatter.service";
import {useDateTimeUtil} from "../../../../../core/util/date-time.util";
import {Button, Checkbox, Table, Tag} from "antd";
import {FormActionsButtons} from "../../../../../core/component/form-actions-buttons";


export type SelectInstallmentsComponentProps = {
  installments: FinancialEntryInstallmentEntity[],
  onFinish: () => any,
  selectedInstallments: FinancialEntryInstallmentEntity[],
  onInstallmentSelected: (installment: FinancialEntryInstallmentEntity) => any,
  onInstallmentRemoved: (installment: FinancialEntryInstallmentEntity) => any,
}
export function SelectInstallmentsComponent(props: SelectInstallmentsComponentProps) {
  const { decimalFormatter } = useNumberFormatter();
  const { convertToDatePtBr } = useDateTimeUtil();
  return (
    <>
      <Table
        dataSource={props.installments}
        columns={[
          {
            title: '',
            dataIndex: 'id',
            render: (val: null, row: any) => {
              if (row.paid) {
                return '';
              }
              return (
                <Checkbox
                  onChange={e => e.target.checked ? props.onInstallmentSelected(row) : props.onInstallmentRemoved(row)}
                  checked={!!props.selectedInstallments.find(w => w.id === val)}
                />
              )
            }
          },
          {
            title: 'Parcela',
            dataIndex: 'order'
          },
          {
            title: 'Pago',
            dataIndex: 'paid',
            render: paid => paid ? <Tag color={'success'}>Sim</Tag> : <Tag>Não</Tag>
          },
          {
            title: 'Vencimento',
            dataIndex: 'dueDate',
            render: convertToDatePtBr,
          },
          {
            title: 'Data de Baixa',
            dataIndex: 'writeOffDate',
            render: dt => dt ? convertToDatePtBr(dt) : '',
          },
          {
            title: 'Valor',
            dataIndex: 'value',
            render: decimalFormatter.format
          }
        ]}
      />
      <FormActionsButtons
        hideCancel
        hideSubmit
        moreAction={[
          props.selectedInstallments.length ?
            <Button
              type={'primary'}
              shape={'round'}
              onClick={props.onFinish}
            >
              Continuar
            </Button> : null
        ]}
      />
    </>
  )
}