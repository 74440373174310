import React, {useCallback, useEffect, useState} from "react";
import {HeaderLedgerAccount} from "../component/header.ledger-account.";
import {Divider, Layout, message, Table,} from "antd";
import {ListActions} from "../../../../core/component/list-actions";
import {useLedgerAccountRepository} from "../../../../domain/repository/ledger-account.repository";
import {LedgerAccountEntity} from "../../../../domain/entity/ledger-account.entity";
import {FilterListLedgerAccount} from "../component/filter-list.ledger-account";

export function ListLedgerAccount(props: any) {
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState<any>();
  const [data, setData] = useState<LedgerAccountEntity[]>([]);

  const ledgerAccountRepo = useLedgerAccountRepository();

  const loadData = useCallback(() => {
    ledgerAccountRepo.search({
      limit,
      offset: (page - 1) * limit,
      filter,
    }).then(res => {
      setData(res.data)
      setTotal(res.total);
    });
  }, [ledgerAccountRepo])

  useEffect(() => {
    loadData()
  }, [page, filter]);

  function onRemove(id: string) {
    ledgerAccountRepo.remove(id)
      .then(res => {
        message.success(res.message);
        loadData();
      }).catch(err => {
      message.error(err.message);
    })
  }

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name' ,
      key: 'name',
    },
    {
      title: 'Código',
      dataIndex: 'code' ,
      key: 'code',
    },
    {
      title: 'Descrição',
      dataIndex: 'description' ,
      key: 'description',
    },
    {
      dataIndex: 'id',
      render: (val: any) => (
        <>
          <ListActions
            onEditClick={() => props.navigator.goToEditPage(val) }
            onRemoveClick={() => onRemove(val) }
          />
        </>
      )
    }
  ]

  return (
    <>
      <HeaderLedgerAccount  />
      <Divider />
      <Layout.Content>
        <FilterListLedgerAccount onFilter={setFilter} />
        <Table
          columns={columns}
          dataSource={data}
          pagination={{
            total,
            onChange: setPage,
            pageSize: limit,
            onShowSizeChange: (a,b) => setLimit(b)
          }}
          loading={ledgerAccountRepo.loading}
        />
      </Layout.Content>
    </>
  );
}