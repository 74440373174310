import React from 'react'
import {Button, Form, Input} from "antd";
import {useUserStorage} from "../../../../../core/service/user.storage";

export function PersonalDataPage(props: any) {

    const userStorage = useUserStorage();
    const initialValues = userStorage.getUser();

    const layout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 16 },
    };

    const onFinish = (values: any) => {
        console.log(values);
    };

    const validateMessages = {
        required: 'Campo obrigatório',
    };

    return (
        <Form {...layout} name="nest-messages" initialValues={initialValues} onFinish={onFinish} validateMessages={validateMessages}>
            <Form.Item name={'name'} label="Nome" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item name={'email'} label="Email" rules={[{ type: 'email', required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item name={'username'} rules={[{required: true}]} label="Usuário de Acesso">
                <Input />
            </Form.Item>
            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 4 }}>
                <Button type="primary" htmlType="submit">
                    Atualizar Informações
                </Button>
            </Form.Item>
        </Form>
    )
}
