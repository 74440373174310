import moment from 'moment';

export function useDateTimeUtil() {

  function convertToDatePtBr(date: string | Date | moment.Moment) {
    let dt = moment(date);
    return dt.format('DD/MM/YYYY')
  }

  function convertToDateTimePtBr(date: string | Date | moment.Moment) {
    let dt = moment(date);
    return dt.format('DD/MM/YYYY HH:mm:ss')
  }

  function forceCurrentOffsetDate(dt: Date) {
    dt.setMinutes(dt.getMinutes() - dt.getTimezoneOffset());
    return dt;
  }

  function convertToMonthAndYear(date: Date | string): string {
    let dt = moment(date);
    return dt.format('MM/YYYY')
  }

  return {
    convertToDatePtBr,
    convertToDateTimePtBr,
    forceCurrentOffsetDate,
    convertToMonthAndYear
  }
}
