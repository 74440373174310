import {SaveOutlined, RollbackOutlined} from '@ant-design/icons';
import {Button, Divider, Form, Space} from "antd";
import React from "react";
import {useHistory} from "react-router-dom";

export type FormActionsButtons = {
    label?: string,
    isLoading?: boolean,
    onCancel?: () => void,
    hideCancel?: boolean,
    hideSubmit?: boolean,
    labelCancel?: string,
    moreAction?: Array<any>
}

export function FormActionsButtons(props: FormActionsButtons) {
    const history = useHistory();

    const onCancel = () => props.onCancel ? props.onCancel() : history.goBack();

    return (
        <Form.Item>
            <Divider />
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Space>
                    {
                        !props.hideCancel && (
                            <Button
                                onClick={onCancel}
                                htmlType={'button'}
                                icon={<RollbackOutlined />}
                                shape={'round'}
                                type={'ghost'}>
                                {
                                    props.labelCancel ? props.labelCancel : 'Cancelar'
                                }
                            </Button>
                        )
                    }
                    {
                        !props.hideSubmit && (
                          <Button
                            loading={props.isLoading}
                            icon={<SaveOutlined />}
                            htmlType={'submit'}
                            shape={'round'}
                            type="primary">
                              {props.label ?? 'Salvar'}
                          </Button>
                        )
                    }
                    {
                        props.moreAction
                    }
                </Space>
            </div>

        </Form.Item>
    )
}