import {BaseResponse, usePlaneduHttpService} from "../service/planedu.http.service";
import {FinancialItemEntity} from "../entity/financial-item.entity";

export function useFinancialItemRepository() {
  const httpService = usePlaneduHttpService<FinancialItemEntity>('financial-item');

  function downloadListItems() {
    return httpService.downloadRequest(
      'get',
      `${httpService.resourceName}/export`,
      'items.xlsx',
    )
  }

  function listByEntryType(entryType: string): Promise<BaseResponse<FinancialItemEntity[]>> {
    return httpService.get(
      `${httpService.resourceName}/entry-type/${entryType}`,
    )
  }

  return {
    ...httpService,
    downloadListItems,
    listByEntryType
  }

}