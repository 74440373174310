import {useUserRepository} from "../../../../domain/repository/user.repository";
import {UserEntity} from "../../../../domain/entity/user.entity";

export function useUserCrud() {
    const userRepo = useUserRepository();

    function create(user: UserEntity) {
        return userRepo.create(user);
    }


    function update(id: string, user: UserEntity) {
        return userRepo.update(id, user);
    }

    return {
        ...userRepo,
        create,
        update
    }
}