import {Col, Form, Input, message, Radio, Row} from "antd";
import React, {useEffect, useState} from "react";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import MaskedInput from "antd-mask-input";
import {PersonDocumentEnum, PersonEntity} from "../../../../domain/entity/person.entity";
import {usePersonRepository} from "../../../../domain/repository/person.repository";
import {useDocumentValidator} from "../../../../core/service/document.validator";
import {ErrorMessageResource} from "../resource/error-message.resource";

const CPFMask = '###.###.###-##';
const CNPJMask = '##.###.###/####-##';

export type FormPerson = {
    person?: PersonEntity,
    onFinish: (values: PersonEntity) => void
}

export function FormPerson(props: FormPerson) {
    const [form] = Form.useForm();
    const [documentMask, setDocumentMask] = useState<string | undefined>();
    const personRepo = usePersonRepository();

    useEffect(() => {
        if (props.person) {
            const { person } = props;
            form.setFields([
                { name: 'type' , value: person.type },
                { name: 'name' , value: person.name },
                { name: 'document' , value: person.document },
                { name: 'documentType' , value: person.documentType },
                { name: 'phoneNumber' , value: person.phoneNumber },
                { name: 'email' , value: person.email },
                { name: 'id' , value: person.id },
            ])
        } else {
            form.setFields([
                { name: 'type', value: 'J'}
            ])
            setDocumentMask(CNPJMask);
        }
        if (form.getFieldValue('type') === 'J') {
            setDocumentMask(CNPJMask);
        } else {
            setDocumentMask(CPFMask);
        }
    }, [1])

    function changeDocMask() {
        switch (form.getFieldValue('type')) {
            case 'F':
                setDocumentMask(CPFMask);
                break;
            case 'J':
                setDocumentMask(CNPJMask);
                break;
            default:
                setDocumentMask(undefined);
        }
        form.resetFields(['document'])
    }

    async function onFinish(values: any) {
        values = {
            ...values,
            documentType: values.type == 'F' ?
                PersonDocumentEnum.CPF :
                PersonDocumentEnum.CNPJ
        }
        function send() {
            if (props?.person?.id) {
                return personRepo.update(props.person.id.toString(), values);
            }
            return personRepo.create(values);
        }
        send().then(res => {
            message.success(res.message);
            props.onFinish(res.data);
        }).catch(err => {
            switch (err.messageKey) {
                case ErrorMessageResource.DUPLICATED_DOCUMENT:
                    form.setFields([{ name: 'document', errors: ['Documento Duplicado'] }])
                    break;
                case ErrorMessageResource.INVALID_DOCUMENT:
                    form.setFields([{ name: 'document', errors: [`Documento Inválido`] }])
                    break;
                default:
                    message.error(err.message);
                    break;
            }
        })
    }

    return (
        <Form form={form} layout={'vertical'} onFinish={onFinish} >
            <Form.Item name={'type'} rules={[{ required: true }]}>
                <Radio.Group onChange={() => changeDocMask()} disabled={!!props.person?.id}>
                    <Radio value={'F'}>Física</Radio>
                    <Radio value={'J'}>Jurídica</Radio>
                </Radio.Group>
            </Form.Item>
            <Row gutter={12}>
                <Col span={16}>
                    <Form.Item
                      name={'name'}
                      label={form.getFieldValue('type') === 'F' ? 'Nome' : 'Nome Fantasia'}
                      rules={[{ required: true }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                      name={'document'}
                      label={form.getFieldValue('type') === 'F' ? 'CPF' : 'CNPJ'}
                      rules={[{ required: true }]}
                    >
                        { documentMask ?
                            <MaskedInput mask={documentMask} /> :
                            <Input />
                        }
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={12}>
                <Col span={12}>
                    <Form.Item name={'email'} label={'Email'} rules={[{ type: 'email' }]}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name={'phoneNumber'} label={'Telefone'}>
                        <MaskedInput mask={'(##) #####-####'} />
                    </Form.Item>
                </Col>
            </Row>
            <FormActionsButtons label={'Salvar'} hideCancel={true} />
        </Form>
    )
}