import {Divider, message, Switch, Table} from "antd";
import React, {useEffect, useState} from "react";
import {useDateTimeUtil} from "../../../../core/util/date-time.util";
import {CompetenceHeader} from "../components/header.competence";
import {useCompetenceRepository} from "../../../../domain/repository/competence.repository";
import {CompetenceEntity} from "../../../../domain/entity/competence.entity";
import {ListActions} from "../../../../core/component/list-actions";

export function ListCompetence(props: any) {
    const dateTimeUtil = useDateTimeUtil();
    const competenceRepository = useCompetenceRepository();
    const [data, setData] = useState<CompetenceEntity[]>([]);

    function loadData() {
        competenceRepository.search()
            .then(res => setData(res.data));
    }

    function removeCompetence(id: string) {
        competenceRepository.remove(id)
            .then(res => {
                message.success(res.message);
                loadData();
            }).catch(err => {
                message.error(err.message);
        })
    }

    useEffect(() => loadData(), []);

    return (
        <>
            <CompetenceHeader />
            <Divider />
            <Table
                columns={[
                    {
                        title: 'Ano',
                        dataIndex: 'year',
                    },
                    {
                        title: 'Data Inicial',
                        dataIndex: 'initialDate',
                        render: (val) => dateTimeUtil.convertToDatePtBr(val)
                    },
                    {
                        title: 'Data de Encerramento',
                        dataIndex: 'endDate',
                        render: (val) => dateTimeUtil.convertToDatePtBr(val)

                    },
                    {
                        title: 'Aceita Atualizações',
                        dataIndex: 'canUpdate',
                        render: (val) => <Switch checked={val} />
                    },
                    {
                        title: 'Ativo',
                        dataIndex: 'active',
                        render: (val) => <Switch checked={val} />
                    },
                    {
                        dataIndex: 'id',
                        render: (val) => (
                            <ListActions
                                onEditClick={() => props.navigator.goToEditPage(val) }
                                onRemoveClick={() => removeCompetence(val) }
                            />
                        )
                    },
                ]}
                dataSource={data}
            />
        </>
    )
}
