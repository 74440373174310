import {Button, Col, Form, Input, Row, Select} from "antd";
import React, {useState} from "react";
import {SearchOutlined} from "@ant-design/icons";
import MaskedInput from "antd-mask-input";

export type FilterListManagerialAccountProps = {
    onFilterDocument: (filter: string) => void,
    onFilterName: (filter: string) => void,
}

export function FilterListClientProvider(props: FilterListManagerialAccountProps) {
    const [form] = Form.useForm();
    const [currentFilterType, setCurrenFilterType] = useState<string>();

    const fields: Array<{value: string, label: string}> = [
        { value: 'name', label: 'Nome' },
        { value: 'documentCpf', label: 'CPF' },
        { value: 'documentCnpj', label: 'CNPJ' },
    ];



    function onFilter(values: any) {
        console.log(values);
        if (values.field == 'name') {
            props.onFilterName(values.filter);
        }
        if (values.field == 'documentCpf') {
            props.onFilterDocument(values.filter.replace(/[^\d]+/g, ''));
        }
        if (values.field == 'documentCnpj') {
            props.onFilterDocument(values.filter.replace(/[^\d]+/g, ''));
        }
    }

    function InputFilter() {
        let field = <Input size={'middle'} allowClear />;
        switch (currentFilterType) {
            case 'documentCpf':
                field = <MaskedInput mask={'###.###.###-##'} size={'middle'} allowClear />;
                break;
            case 'documentCnpj':
                field =  <MaskedInput mask={'##.###.###/####-##'} size={'middle'} allowClear />;
                break;
            default: break;
        }

        return <Form.Item name={'filter'}>{field}</Form.Item>;
    }

    function changeValues(values: any) {
        if (!values.filter) {
            form.submit();
        }
    }

    return (
        <Form
            form={form}
            layout="horizontal"
            onFinish={onFilter}
            initialValues={{ field: 'name' }}
            onValuesChange={changeValues}
        >
            <Row style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}} >
                <Col>
                    <Form.Item name={'field'}>
                        <Select
                            size={'middle'}
                            options={fields} defaultValue={'name'}
                            onSelect={value => setCurrenFilterType(value)}
                        />
                    </Form.Item>
                </Col>
                <Col span={6} >
                    <InputFilter />
                </Col>
                <Col>
                    <Form.Item>
                        <Button htmlType={'submit'} size={'middle'} icon={<SearchOutlined />}>
                            Pesquisar
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}