import {useRouteMatch} from "react-router-dom";
import {ModuleResource, ModuleResourceTypeEnum, ModuleRoot} from "../../../core/component/module/module.root";
import React from "react";
import {ShowClient} from "./pages/show.client";

export function ClientModule() {
    const routeMatch = useRouteMatch();
    const resources: ModuleResource[] = [
        {
            type: ModuleResourceTypeEnum.CUSTOM,
            customPath: 'settings',
            render: (props) => <ShowClient {...props} />
        },

    ]

    return (
        <ModuleRoot
            resource={routeMatch.url}
            moduleResources={resources}
        />
    );
}
