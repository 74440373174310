import React, {useEffect, useState} from "react";
import {HeaderSupplyUnit} from "../component/header.supply-unit.";
import {Divider, Layout, message, Table, Tag,} from "antd";
import {ListActions} from "../../../../core/component/list-actions";
import {SupplyUnitEntity} from "../../../../domain/entity/supply-unit.entity";
import {useSupplyUnitRepository} from "../../../../domain/repository/supply-unit.repository";
import {ExportExcelButton} from "../../../../core/component/export-button";

export function ListSupplyUnit(props: any) {
    const supplyUnitRepo = useSupplyUnitRepository();
    const [data, setData] = useState<SupplyUnitEntity[]>([]);

    function loadData() {
        supplyUnitRepo.search().then(res => {
            setData(res.data)
        });
    }

    useEffect(() => {
        loadData()
    }, [1]);

    function removeGroup(id: string) {
        supplyUnitRepo.remove(id)
            .then(res => {
                message.success(res.message);
                loadData();
            }).catch(err => {
                message.error(err.message);
        })
    }

    const columnsBudget = [
        {
            title: 'Descrição',
            dataIndex: 'description' ,
            key: 'name',
        },
        {
            title: 'Sigla',
            dataIndex: 'alias' ,
            key: 'alias',
        },
        {
            title: 'Status',
            dataIndex: 'active',
            key: 'active',
            render: (value: boolean) => (
                <Tag color={value ? 'green' : 'red'}>
                    {value ? 'Ativo' : 'Inativo'}
                </Tag>
            )
        },
        {
            dataIndex: 'id',
            render: (val: any) => (
                <>
                    <ListActions
                        onEditClick={() => props.navigator.goToEditPage(val) }
                        onRemoveClick={() => removeGroup(val) }
                    />
                </>
            )
        }
    ]
    return (
        <>
            <HeaderSupplyUnit extra={[ <ExportExcelButton onClick={() => supplyUnitRepo.downloadSupplyUnitsCsv()} /> ]} />
            <Divider />
            <Layout.Content>
                <Table 
                    columns={columnsBudget} 
                    dataSource={data} 
                    loading={supplyUnitRepo.loading} />
            </Layout.Content>
        </>
    );
}