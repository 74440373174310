import {SelectBase} from "./select.base";
import {CostCenterEntity} from "../../../domain/entity/cost-center.entity";
import {PaymentMethodEntity} from "../../../domain/entity/payment-method.entity";

export type SelectPaymentMethodProps = {
  data: PaymentMethodEntity[],
  label?: string,
  name?: string | string[],
  required?: boolean;
  onChange?: (value: string | number) => void,
}

export function SelectPaymentMethod(props: SelectPaymentMethodProps) {
  function getOpts() {
    return props.data.map(item => ({
      label: `${item.description}`,
      value: item.id as number,
    }))
  }

  return (
    <SelectBase
      label={props.label ?? 'Método de Pagemtno'}
      name={props.name ?? 'paymentMethodId'}
      data={getOpts()}
      onChange={props.onChange}
      required={props.required}
    />
  )
}