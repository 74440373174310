import {Button} from "antd";
import React from "react";
import {DeleteOutlined} from '@ant-design/icons';

export type DeleteButtonProps = {
    onClick: () => void,
    label?: string
}

export function DeleteButton(props: DeleteButtonProps) {
    let { label } = props;
    label = label ?? 'Deletar';
    return (
        <Button
            icon={<DeleteOutlined />}
            onClick={() => props.onClick()}
            size={'small'}
            color={'warning'}
            shape={"round"} >
            {label}
        </Button>
    )
}