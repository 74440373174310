import {AppPageHeader} from "../../../../core/component/page-header";
import {useHistory, useRouteMatch} from 'react-router-dom';
import React from "react";

export type HeaderFilialProps = {
    createRoute?: string;
    mode?: 'list' | 'form',
    title?: string,
}

export function HeaderClient(props: HeaderFilialProps) {
    const urlMatch = useRouteMatch();
    const history = useHistory();

    return (
        <>
            <AppPageHeader
                title={props.title ?? 'Cliente'}
                mode={props.mode}
                subtitle={'Definições do Cliente'}
                onBack={() => history.goBack()}
            />
        </>
    );

}
