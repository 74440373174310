export type FinancialEntryEventEntity = {
  id: number;
  entryId: number;
  installmentId?: number;
  dateTime: Date;
  event: FinancialEntryEventEventsEnum;
  entity: FinancialEntryEventEntityEnum;
  description: string;
  user: string;
}

export enum FinancialEntryEventEventsEnum {
  CREATE = 'created',
  REMOVED = 'removed',
  UPDATED = 'updated',
  WRITE_OFF = 'written_off',
  CANCELED = 'canceled',
}

export enum FinancialEntryEventEntityEnum {
  ITEM = 'item',
  ENTRY = 'entry',
  INSTALLMENT = 'installment',
  APPORTIONMENT = 'apportionment',
  TAX = 'TAX',
}
