import React, {useEffect, useState} from "react";
import {HeaderFirm} from "../component/header.firm";
import {Divider, Layout, Switch, Table} from "antd";
import {useFirmRepository} from "../../../../domain/repository/firm.repository";
import {FirmEntity} from "../../../../domain/entity/firm.entity";
import {useDocumentFormatter} from "../../../service/document.formatter.service";
import {useAddressFormat} from "../service/address.format";
import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {ListActions} from "../../../../core/component/list-actions";
import {ListDocumentFirm} from "./list.document.firm";

export function ListFirm(props: ModuleRenderProps) {
    const documentFormat = useDocumentFormatter();
    const addressFormat = useAddressFormat();
    const firmRepository = useFirmRepository();
    const [data, setData] = useState<FirmEntity[]>([]);
    const [docsVisible, setDocsVisible] = useState<boolean>(false);
    const [firm, setCurrentFirm] = useState<FirmEntity>();

    function loadData() {
        firmRepository.search().then(res => {
            let data: FirmEntity[] = [];
            data.push(...res.data.filter(w => w.matrix))
            data.push(...res.data.filter(w => !w.matrix));
            setData(data)
        });
    }

    function goToFilialPage(id: string) {
        props.navigator.goToCustomPage(`filial/${id}`);
    }

    function closeViewFirmDocument() {
        loadData();
        setCurrentFirm(undefined);
        setDocsVisible(false);
    }

    useEffect(() => {
        loadData()
    }, []);

    const columns = [
        {
            title: 'Nome',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Matriz',
            dataIndex: 'matrix',
            key: 'matrix',
            render: (val: boolean) => <Switch checked={val} /> ,
        },
        {
            title: 'Documento (CNPJ)',
            dataIndex: 'document',
            key: 'document',
            render: (val: string) => documentFormat.formatCNPJ(val),
        },
        {
            title: 'Endereço',
            dataIndex: 'address',
            key: 'address',
            render: (val: string, row: FirmEntity) => addressFormat.showAddressComplete(row)
        },
        {
            title: 'Ativo',
            dataIndex: 'active',
            key: 'active',
            render: (val: boolean) => <Switch checked={val} />
        },
        {
            title: '',
            dataIndex: 'id',
            render: (val: string, firm: FirmEntity) => (
                <ListActions
                    onEditClick={() => props.navigator.goToEditPage(val) }
                    onRemoveClick={() => null }
                    options={[
                        { name: 'CENTROS DE CUSTO', onClick: () => goToFilialPage(val)}
                    ]}
                />
            )
        }
    ]

    return (
        <>
            <HeaderFirm />
            <Divider />
            <Layout.Content>
                <Table
                    columns={columns}
                    dataSource={data}
                    loading={firmRepository.loading}
                />
            </Layout.Content>
            {
                firm && (
                    <ListDocumentFirm
                        visible={docsVisible}
                        onDocumentCreated={closeViewFirmDocument}
                        onDocumentRemoved={closeViewFirmDocument}
                        firm={firm}
                        onClose={() => setDocsVisible(false)}
                    />
                )
            }
        </>
    );
}
