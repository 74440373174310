import React, {useCallback, useEffect, useState} from "react";
import {FinancialEntryEntity} from "../../../../domain/entity/financial-entry.entity";
import {useIntegrationRepository} from "../../../../domain/repository/integration.repository";
import {Button, Drawer, Modal, Table} from "antd";
import {useNumberFormatter} from "../../../service/number-formatter.service";
import {StatusPaymentComponent} from "../../financial-entry/component/status-payment.component";
import {EyeOutlined, RollbackOutlined} from "@ant-design/icons";
import {Switch as SwitchRouter} from "react-router";
import {Route, useHistory, useParams, useRouteMatch} from "react-router-dom";
import {ShowFullFinancialEntry} from "../../financial-entry/component/show-full-financial-entry";
import {FormCancelEntryComponent} from "../../financial-entry/component/form.cancel-entry.component";

export type ListBatchEntriesComponentProps = {
  batchId: number,
}

export function ListBatchEntriesComponent(props: ListBatchEntriesComponentProps) {
  const [data, setData] = useState<FinancialEntryEntity[]>([]);
  const { decimalFormatter } = useNumberFormatter();

  const { entryId } = useParams<any>();
  const history = useHistory();
  const routeMatch = useRouteMatch();
  const integrationRepo = useIntegrationRepository();
  
  const findData = useCallback(() => {
    integrationRepo.listBatchEntries(props.batchId).then(res => {
      setData(res.data)
    })
  }, [integrationRepo, props.batchId])

  console.log({ entryId });

  useEffect(() => {
    findData()
  }, [])

  return (
    <>
      <Table
        dataSource={data}
        loading={integrationRepo.loading}
        columns={[
          {
            title: 'Lançamento',
            dataIndex: 'id'
          },
          {
            title: 'Valor Bruto',
            dataIndex: 'documentGrossValue',
            render: decimalFormatter.format
          },
          {
            title: 'Valor Baixado',
            dataIndex: 'documentPaidAmount',
            render: decimalFormatter.format
          },
          {
            title: 'Status',
            dataIndex: 'statusPayment',
            render: (val: string, row: any) => (
              <StatusPaymentComponent
                status={val}
                isCanceled={row.isCanceled}
              />
            )
          },
          {
            title: '',
            dataIndex: 'id',
            render: (val: string, row: any) => (
              <Button.Group>
                <Button
                  size={'middle'}
                  onClick={() => history.push(`${routeMatch.url}/show-entry/${val}`)}
                  title={'Visualizar Lançamento'}
                  icon={<EyeOutlined />}
                />
                <Button
                  size={'middle'}
                  title={'Cancelar Lançamento'}
                  onClick={() => history.push(`${routeMatch.url}/cancel-entry/${val}`)}
                  icon={<RollbackOutlined />}
                />
              </Button.Group>
            )
          }
        ]}
      />
      <SwitchRouter>
        <Route path={`${routeMatch.path}/show-entry/:entryId`}>
          <ShowEntry />
        </Route>
        <Route path={`${routeMatch.path}/cancel-entry/:entryId`}>
          <CancelEntry onCanceled={findData} />
        </Route>
      </SwitchRouter>
    </>

  )
}

function ShowEntry() {
  const history = useHistory();
  const { entryId } = useParams<any>();
  return (
    <Drawer
      placement={'bottom'}
      height={550}
      visible={true}
      bodyStyle={{padding: 0}}
      onClose={() => history.goBack()}
    >
      <ShowFullFinancialEntry entryId={entryId}/>
    </Drawer>
  )
}

function CancelEntry(props: { onCanceled?: () => any }) {
  const history = useHistory();
  return (
    <Modal
      visible={true}
      footer={null}
      title={'Cancelar Lançamento'}
      width={800}
      onCancel={() => history.goBack()}
    >
      <FormCancelEntryComponent
        entryId={useParams<any>().entryId}
        onSaved={() => {
          history.goBack();
          props.onCanceled && props.onCanceled();
        }}
      />
    </Modal>
  )
}