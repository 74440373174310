import {SelectBase} from "./select.base";
import {FinancialItemEntity} from "../../../domain/entity/financial-item.entity";
import {OptionsValue} from "../../../core/types/option.type";
import {useState} from "react";

export type SelectFinancialItemProps = {
  data: FinancialItemEntity[],
  label?: string,
  name?: string,
  required?: boolean;
  onChange?: (value: string | number) => void,
}

export function SelectFinancialItem(props: SelectFinancialItemProps) {
  const [help, setHelp] = useState('');
  function getOpts(): OptionsValue {
    return props.data.map(item => ({
      label: `${item.description} (${item.class?.group?.code}.${item.class?.code}.${item.code})`,
      value: item.id,
    }))
  }

  function onChange(val: string | number) {
    if (props.onChange) {
      props.onChange(val);
    }
    const item = props.data.find(w => w.id == val);
    if (item) {
      if (item.class && item.class.group) {
        setHelp(`Grupo: ${item.class.group.name}; Classe: ${item.class.name}`)
      } else {
        setHelp('');
      }
    } else {
      setHelp('');
    }
  }

  return (
    <SelectBase
      label={props.label ?? 'Item'}
      name={props.name ?? 'financialItemId'}
      data={getOpts()}
      onChange={onChange}
      required={props.required}
      help={help}
    />
  )
}