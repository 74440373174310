import {Form, Select} from "antd";
import {OptionsValue} from "../../../core/types/option.type";

export type SelectFirmType = {
  data: OptionsValue,
  label: string,
  name: string | string[],
  required?: boolean;
  placeholder?: string;
  onChange?: (value: string | number) => void,
  help?: string,
  disabled?: boolean;
}

export function SelectBase(props: SelectFirmType) {
  const { data, label, name, required, help } = props;

  const rules = [];
  if (typeof required !== 'undefined') {
    rules.push({ required })
  } else {
    rules.push({ required: true })
  }

  function onChange(value: string | number) {
    if(props.onChange) {
      props.onChange(value);
    }
  }

  function onFilter(input: string, option: string) {
    return option.toLowerCase()?.indexOf(input.toLowerCase()) >= 0;
  }

  return (
    <Form.Item label={label} name={name} rules={rules} help={help} >
      <Select
        options={data}
        onSelect={onChange}
        placeholder={props.placeholder}
        allowClear={true}
        disabled={props.disabled}
        showSearch={true}
        filterOption={(input: string, option) =>
          onFilter(input, option?.label ? option.label.toString() : '')}
      />
    </Form.Item>
  )
}