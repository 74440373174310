import {Button, Col, Form, message, Row, Select} from "antd";
import {SelectCostCenter} from "../../../component/dropdown/select.cost-center";
import {useEffect, useState} from "react";
import {CostCenterEntity} from "../../../../domain/entity/cost-center.entity";
import {useCostCenterRepository} from "../../../../domain/repository/account.repository";
import {DeleteOutlined} from "@ant-design/icons";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import {useRecurrenceEntryRepository} from "../../../../domain/repository/recurrence-entry.repository";
import {RecurrenceEntryEntity} from "../../../../domain/entity/recurrence-entry.entity";
import {BrlInputNumber} from "../../../../core/component/brl-input-number";
import {useFirmRepository} from "../../../../domain/repository/firm.repository";

export type FormRecurrenceEntryApportionmentProps = {
  recurrence: RecurrenceEntryEntity,
  onFinish: () => any,
  onCancel: () => any,
}
export function FormRecurrenceEntryApportionment(props: FormRecurrenceEntryApportionmentProps) {
  const [form] = Form.useForm();
  const [costCenters, setCostCenters] = useState<CostCenterEntity[]>([]);
  const [costCenterApportionment, setCostCenterApportionment] = useState<CostCenterEntity[]>([]);

  const { recurrence } = props;
  const recurrenceEntryRepo = useRecurrenceEntryRepository();
  const firmRepo = useFirmRepository();

  useEffect(() => {
    firmRepo.listCostCenters(recurrence.firmId.toString(), true).then(res => {
      setCostCenters(res.data)
      if (recurrence.apportionment?.length) {
        const currentCostCenters: CostCenterEntity[] = [];
        for (const item of recurrence.apportionment) {
          const temp = res.data.find(w => w.id === item.costCenterId);
          if (temp) {
            currentCostCenters.push(temp);
          }
        }
        setCostCenterApportionment(currentCostCenters);
      }
    });
  }, []);

  useEffect(() => {
    if (recurrence.apportionment?.length) {
      for (const item of recurrence.apportionment) {
        form.setFields([
          { name: ['apportionment', `${item.costCenterId}`, 'costCenterId'], value: item.costCenterId },
          { name: ['apportionment', `${item.costCenterId}`, 'percentAmount'], value: item.percentAmount },
        ])

      }
    }
  }, [recurrence?.apportionment])

  function addCostCenterToApportionment() {
    const costCenterId = form.getFieldValue('costCenterToApportionmentId');
    if (!costCenterId) {
      return;
    }
    const costCenter = costCenters.find(w => w.id === costCenterId);
    if (costCenter) {
      setCostCenterApportionment([
        ...costCenterApportionment,
        costCenter
      ]);
      form.resetFields(['costCenterToApportionmentId']);
    }
  }

  function getCostCentersAvailable() {
    return costCenters
      .filter(w => !costCenterApportionment.map(a => a.id).includes(w.id))
  }

  function onFinish(values: any) {
    const { apportionment } = values;
    recurrenceEntryRepo.saveApportionment(
      recurrence.id,
      Object.values(apportionment),
    ).then(res => {
      message.success(res.message);
      props.onFinish();
    }).catch(err => {
      message.error(err.message);
    })
  }

  function onRemove(costCenter: CostCenterEntity) {
    setCostCenterApportionment(
      costCenterApportionment.filter(w => w.id !== costCenter.id)
    )
  }

  return (
    <Form form={form} onFinish={onFinish} size={'middle'} >
      <Row gutter={12}>
        <Col span={8}>
          <SelectCostCenter
            name={'costCenterToApportionmentId'}
            data={getCostCentersAvailable()}
            placeholder={'Centro de Custo'}
            required={false}
            label={''}
          />
        </Col>
        <Col>
          <Button onClick={addCostCenterToApportionment}>Adicionar ao Rateio</Button>
        </Col>
        <Col span={8}>
          {
            !costCenterApportionment.find(w => w.id === recurrence.costCenterId) && (
              <Button onClick={() => setCostCenterApportionment([...costCenterApportionment, recurrence.costCenter])}>
                Adicionar Centro de Custo Original `{recurrence.costCenter?.code} - {recurrence.costCenter?.name}`
              </Button>
            )
          }
        </Col>
      </Row>
      {
        costCenterApportionment.map(item => {
          return (
            <ApportionmentItem
              setFormItem={(key,val) => form.setFields([{ name: key, value: val }])}
              costCenter={item}
              onRemove={onRemove}
            />
          )

        })
      }
      <FormActionsButtons onCancel={props.onCancel} />
    </Form>
  )
}

export type ApportionmentItemProps = {
  costCenter: CostCenterEntity,
  onRemove: (costCenter: CostCenterEntity) => any,
  setFormItem: (key: any, value: any) => any,
}
function ApportionmentItem(props: ApportionmentItemProps) {
  const { costCenter, onRemove, setFormItem } = props;

  function getCostCenter() {
    const { id, code, name } = costCenter;
    return [{
      label: `${code} - ${name}`,
      value: id as number,
      defaultValue: true,
    }];
  }

  useEffect(() => {
    setFormItem(['apportionment', `${props.costCenter.id}` ,'costCenterId'], costCenter.id);
  }, [costCenter])

  return (
    <Row gutter={12}>
      <Col>
        <Form.Item>
          <Button size={'middle'} onClick={() => onRemove(costCenter)} icon={<DeleteOutlined />} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name={['apportionment', `${props.costCenter.id}` ,'costCenterId']} required >
          <Select size={'middle'} options={getCostCenter()} defaultValue={costCenter.id}  />
        </Form.Item>
      </Col>
      <Col span={10}>
          <BrlInputNumber
            required
            label={'Percentual (%)'}
            name={['apportionment', `${props.costCenter.id}` ,'percentAmount']}
          />
      </Col>
    </Row>
  )
}