import {RecurrenceEntryItemEntity} from "../../../../domain/entity/recurrence-entry-item.entity";
import {FinancialEntryEntity} from "../../../../domain/entity/financial-entry.entity";
import {FinancialEntryItemEntity} from "../../../../domain/entity/financial-entry-item.entity";
import {Button, Descriptions, Divider, Form, Input, InputNumber, message} from "antd";
import React, {useEffect, useState} from "react";
import {useFinancialEntryItemService} from "../../../../domain/service/financial-entry-item.service";
import {useNumberFormatter} from "../../../service/number-formatter.service";
import {useFinancialEntryItemRepository} from "../../../../domain/repository/financial-entry-item.repository";
import {SaveOutlined} from "@ant-design/icons";
import {BrlInputNumber} from "../../../../core/component/brl-input-number";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";

export type FormEntryCheckItemProps = {
  item: RecurrenceEntryItemEntity,
  recurrenceFixed?: boolean,
  financialEntry: FinancialEntryEntity,
  onItemCreated: (item: FinancialEntryItemEntity) => any,
  onCancel: () => any,
}
export function FormEntryCheckItem(props: FormEntryCheckItemProps) {
  const [form] = Form.useForm();
  const [financialEntryItem, setFinancialEntryItem] = useState<FinancialEntryItemEntity>();

  const { calcFinalValue } = useFinancialEntryItemService();
  const { item } = props;
  const financialEntryItemRepo = useFinancialEntryItemRepository();
  const { currencyFormatter } = useNumberFormatter();

  useEffect(() => {
    form.setFields([
      { name: 'singleValue' , value: item.singleValue },
      { name: 'quantity' , value: item.quantity },
      { name: 'discount' , value: item.discount },
    ])
    amountTotal();
  }, [item])

  function amountTotal() {
    const singleValue = parseFloat(form.getFieldValue('singleValue') ?? 0)
    const quantity = parseFloat(form.getFieldValue('quantity') ?? 0)
    const discount = parseFloat(form.getFieldValue('discount') ?? 0)
    const result = calcFinalValue( singleValue, quantity, discount );

    if (result > 0) {
      form.setFields([{ name: 'finalValue' , value: currencyFormatter.format(result), errors: [] }])
    } else {
      form.setFields([{ name: 'finalValue' , value: currencyFormatter.format(result),
        errors: ['O valor deve ser maior que 0'] }])
    }
  }

  function SaveButton() {
    return (
      <Button
        icon={<SaveOutlined />}
        htmlType={'submit'}
        type={'primary'}
        shape={'round'} size={'middle'}>
        Confirmar
      </Button>
    )
  }

  function onFinish(values: any) {
    financialEntryItemRepo.create(
      {
        ...props.item,
        ...values,
        financialEntryId: props.financialEntry.id,
      }
    ).then(res => {
      message.success(res.message);
      setFinancialEntryItem(res.data);
      props.onItemCreated(res.data);
    }).catch(err => {
      console.log(err)
      message.error(err.message);
    })
  }


  return (
    <Form form={form} layout={'vertical'} size={'small'} onFinish={onFinish}>
      {
        !financialEntryItem && (
          <Descriptions
            column={4}
            size={'small'}
            title={item.financialItem.description}
            extra={<SaveButton />}
            layout={'vertical'}
          >
            <Descriptions.Item label={'Valor Unitário'}>
              {
                props.recurrenceFixed ? (
                  currencyFormatter.format(item.singleValue)
                ) : (
                  <BrlInputNumber
                    name={'singleValue'}
                    label={''}
                    required={true}
                    onBlur={() => amountTotal()}
                  />
                )
              }
            </Descriptions.Item>
            <Descriptions.Item label={'Quantidade'}>
              <Form.Item name={'quantity'} rules={[{ required: true }]} >
                {
                  props.recurrenceFixed ? (
                    item.quantity
                  ) : (
                    <InputNumber
                      style={{width: '100%'}}
                      onBlur={() => amountTotal()}
                    />
                  )
                }
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label={'Desconto'}>
              {
                props.recurrenceFixed ? (
                  currencyFormatter.format(item.discount)
                ) : (
                  <BrlInputNumber
                    name={'discount'}
                    label={''}
                    required={true}
                    onBlur={() => amountTotal()}
                  />
                )
              }
            </Descriptions.Item>
            <Descriptions.Item label={'Valor Final'}>
              <Form.Item
                name={'finalValue'}
                label={''}
                rules={[{ required: true }]}
              >
                <Input disabled />
              </Form.Item>
            </Descriptions.Item>
          </Descriptions>
        )
      }
    </Form>
  )
}