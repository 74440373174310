import React, {useEffect, useState} from "react";
import {HeaderUser} from "../component/header.user";
import {Divider, Form, Input, message} from "antd";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import {ResetPasswordEntity} from "../../../../domain/entity/reset-password.entity";
import {useResetPasswordService} from "../../../../domain/repository/reset-password.repository";
import {useParams} from "react-router-dom";
import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {useUserStorage} from "../../../../core/service/user.storage";

export function EditPasswordUser(props: ModuleRenderProps) {
    const [form] = Form.useForm();
    const resetPasswordService = useResetPasswordService();
    const [resetPassword, setResetPassword] = useState<ResetPasswordEntity>();
    const { id } = useParams<any>();
    const userStorage = useUserStorage();
    useEffect(() => { resetPasswordService.find(id).then(res => setResetPassword(res.data)) }, [1]);
    useEffect(() => { resetPassword && form.setFields([
        { name: 'password', value: resetPassword.password },
    ])}, [resetPassword])

    function onFinish(data: any) {
        const resetPasswordEntity: ResetPasswordEntity = {
            password: data.password,
        }

        resetPasswordService.reset(id, resetPasswordEntity)
            .then(res => {
                message.success(res.message);
                props.navigator.goToListPage();
            }).catch(err => {
                message.error(err.message);
        })
    }
    return (
        <>
            <HeaderUser mode={'form'} title={' Usuário '} subtitle={'Edição de senha do usuário'}/>
            <Divider />
            <h1>Nome: {userStorage.getUser()?.name}</h1>
        
            <Form form={form} layout="vertical" name="nest-messages" onFinish={onFinish}>
                <Form.Item
                    name="password"
                    label="Nova senha"
                    rules={[
                    {
                        required: true,
                        message: 'Digite sua nova senha!',
                    },
                    ]}
                    hasFeedback
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    name="confirm"
                    label="Confirma a nova senha"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                    {
                        required: true,
                        message: 'Digite sua nova senha novamente!',
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('As duas senhas que você digitou não coincidem!'));
                        },
                    }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <FormActionsButtons isLoading={resetPasswordService.loading} />
            </Form>
    
            
        </>
    )
}

