import {Col, Divider, Form, Input, InputNumber, message, Row, Select, Switch} from "antd";
import React, {useEffect, useState} from "react";
import {HeaderFinancialClass} from "../component/header.financial-class";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {ErrorMessageResource} from "../resource/error-message.resource";
import {useBudgetCategoryRepository} from "../../../../domain/repository/budget-category.repository";
import {useManagerialRepository} from "../../../../domain/repository/managerial.repository";
import {BudgetCategoryEntity} from "../../../../domain/entity/budget-category.entity";
import {ManagerialAccountEntity} from "../../../../domain/entity/managerial-account.entity";
import {FinancialGroupEntity} from "../../../../domain/entity/financial-group.entity";
import {useFinancialGroupRepository} from "../../../../domain/repository/financial-group.repository";
import {useFinancialClassRepository} from "../../../../domain/repository/financial-class.repository";

export function CreateFinancialClass(props: ModuleRenderProps) {
    const [form] = Form.useForm();
    const [categories, setCategories] = useState<BudgetCategoryEntity[]>([]);
    const [managerialAccounts, setManagerialAccounts] = useState<ManagerialAccountEntity[]>([]);
    const [group, setGroups] = useState<FinancialGroupEntity[]>([]);

    const budgetCategoryRepo = useBudgetCategoryRepository();
    const financialClassRepo = useFinancialClassRepository();
    const financialGroupRepository = useFinancialGroupRepository();
    const managerialAccountRepo = useManagerialRepository();

    useEffect(() => {
        form.setFields([{
            name: 'active', value: true
        }])
        budgetCategoryRepo.search().then(res => { setCategories(res.data) });
        managerialAccountRepo.search().then(res => { setManagerialAccounts(res.data) });
        financialGroupRepository.search().then(res => setGroups(res.data));
    }, [1]);

    function onFinish(data: any) {
        financialClassRepo.create(data).then(res => {
            message.success(res.message);
            props.navigator.goToListPage();
        }).catch(err => {
            switch (err.messageKey) {
                case ErrorMessageResource.DUPLICATED_CODE:
                    form.setFields([{
                        name: 'code', errors: [err.message]
                    }])
                    break;
                default:
                    message.error(err.message)
                    break;
            }
        })
    }

    function getCategoriesOptions() {
        return categories
            .sort((a,b)  => {
                if (a.name > b.name) return 1;
                if (a.name < b.name) return -1;
                return 0
            })
            .map(item => ({
                label: item.name,
                value: item.id
            }))
    }

    function getManagerialAccountsOpts() {
        return managerialAccounts
            .sort((a,b)  => {
                if (a.code > b.code) return 1;
                if (a.code < b.code) return -1;
                return 0
            })
            .map(item => ({
            label: `${item.code} - ${item.description}`,
            value: item.id
        }))
    }

    function getGroupsOpts() {
        return group
            .sort((a,b) => {
                if (a.name > b.name) return 1;
                if (a.name < b.name) return -1;
                return 0
            }).map(item => ({
                label: item.name,
                value: item.id
            }))
    }

    return (
        <>
            <HeaderFinancialClass mode={'form'} />
            <Divider />
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Row gutter={12}>
                    <Col span={10}>
                        <Form.Item name={'groupId'} label="Grupo" rules={[{ required: true }]}>
                            <Select options={getGroupsOpts()} />
                        </Form.Item>
                    </Col>
                    <Col span={14}>
                        <Form.Item name={'name'} label="Nome" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={12}>
                    <Col span={12}>
                        <Form.Item name={'managerialAccountId'} label="Conta Gerencial" rules={[{ required: true }]}>
                            <Select options={getManagerialAccountsOpts()} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'budgetCategoriesId'} label="Categorias Orçamentárias" >
                            <Select options={getCategoriesOptions()} mode={'multiple'} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={4}>
                        <Form.Item name={'active'} label="Status" valuePropName={'checked'} >
                            <Switch
                                checkedChildren={'Ativo'}
                                unCheckedChildren={'Inativo'}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <FormActionsButtons
                    onCancel={props.navigator.goToDefaultPage}
                    isLoading={financialGroupRepository.loading}
                />
            </Form>
        </> 
    )
}
