import {useCallback, useMemo} from "react";
import {useDateTimeUtil} from "../../../../core/util/date-time.util";
import {Table} from "antd";
import {ColumnsType} from "antd/lib/table";
import {useNumberUtil} from "../../../../core/util/numer.util";
import {useNumberFormatter} from "../../../service/number-formatter.service";
import {RenderStatementDescriptionComponent} from "./render.statement-description.component";

export type BankAccountStatementViewModel = {
  id?: number,
  key?: string | number,
  dateTime: string;
  description: string;
  creditValue: number;
  debitValue: number;
  balance: number;
  beforeBalance?: number;
  meta?: any;
  accumulatedBalance?: number;
  _children?: BankAccountStatementViewModel[],
}

export type ListSyntheticStatementComponentProps = {
  data: BankAccountStatementViewModel[],
  previousBalance: number,
  onEntryClick: (entryId: number) => any,
  groupStrategy: 'd' | 'm' | 'y',
  loading?: boolean,
}
export function ListSyntheticStatementComponent(props: ListSyntheticStatementComponentProps) {
  const { convertToDatePtBr, convertToMonthAndYear } = useDateTimeUtil();
  const { decimalFormatter } = useNumberFormatter();
  const { sumNumbers } = useNumberUtil();
  
  const getDateByGroupStrategy = useCallback<(dt: string | Date) => string>((dt: string | Date) => {
    switch (props.groupStrategy) {
      case "y": 
        return '';
      case "d":
        return convertToDatePtBr(dt);
      case "m":
        return convertToMonthAndYear(dt);
      default:
        return dt.toString();
    }
  }, [convertToDatePtBr, convertToMonthAndYear, props.groupStrategy])

  const generateData = useCallback<() => BankAccountStatementViewModel[]>(() => {
    const values = new Map<string, BankAccountStatementViewModel>();
    for (const item of props.data) {
      const date = getDateByGroupStrategy(item.dateTime);
      let current = values.get(date);
      if (current) {
        current = {
          ...current,
          dateTime: date,
          creditValue: sumNumbers(item.creditValue, current?.creditValue),
          debitValue: sumNumbers(item.debitValue, current?.debitValue),
          _children: [...(current?._children ?? []), item],
          balance: item.balance,
        }
      } else {
        current = {
          ...item,
          key: item.id,
          _children: [item],
          dateTime: date,
          description: `Acumulado dia ${date}`,
        }
      }
      values.set(date, current);
    }
    let lastBalance = props.previousBalance;
    for (const [key, data] of Array.from(values.entries())) {
      data.beforeBalance = lastBalance;
      data.accumulatedBalance = data.beforeBalance + data.balance;
      lastBalance = data.balance;
      values.set(key, data)
    }
    return Array.from(values.values());
  }, [props.previousBalance, props.data, getDateByGroupStrategy, sumNumbers])


  const parentColumns: ColumnsType<BankAccountStatementViewModel> = [
    {
      title: 'Data',
      dataIndex: 'dateTime',
    },
    {
      title: 'Saldo Anterior',
      dataIndex: 'beforeBalance',
      render: decimalFormatter.format
    },
    {
      title: 'Crédito',
      dataIndex: 'creditValue',
      render: decimalFormatter.format
    },
    {
      title: 'Débito',
      dataIndex: 'debitValue',
      render: decimalFormatter.format
    },
    {
      title: 'Saldo',
      dataIndex: 'balance',
      render: decimalFormatter.format
    },
  ]

  return (
    <>
      <Table
        loading={props.loading}
        columns={parentColumns}
        dataSource={generateData()}
        expandable={
          { expandedRowRender: (row) => <ChildrenTable onEntryClick={props.onEntryClick} item={row} /> }
        }
      />
    </>
  )
}

function ChildrenTable(props: { item: BankAccountStatementViewModel, onEntryClick: (entryId: number) => any }) {
  const { convertToDatePtBr } = useDateTimeUtil();
  const { decimalFormatter } = useNumberFormatter();

  const sortedDate = useMemo<BankAccountStatementViewModel[]>(() => {
    return props.item._children ?? [];
  }, [props.item])

  const columns: ColumnsType<BankAccountStatementViewModel> = [
    {
      title: 'Cod.',
      dataIndex: 'id',
    },
    {
      title: 'Data',
      dataIndex: 'dateTime',
      render: convertToDatePtBr
    },
    {
      title: 'Usuário',
      dataIndex: 'createdBy',
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      render: (val, row) => (
        <RenderStatementDescriptionComponent
          description={val}
          meta={row.meta}
          onEntryClick={props.onEntryClick}
        />
      )
    },
    {
      title: 'Crédito',
      dataIndex: 'creditValue',
      render: decimalFormatter.format
    },
    {
      title: 'Débito',
      dataIndex: 'debitValue',
      render: decimalFormatter.format
    },
    {
      title: 'Saldo',
      dataIndex: 'balance',
      render: decimalFormatter.format
    },
  ]
  return (
    <div style={{paddingBottom: 10}}>
      <Table
        columns={columns}
        dataSource={sortedDate}
        pagination={false}
      />
    </div>
  )
}