import {Col, Divider, Form, message, Row, Typography} from "antd";
import {BrlInputNumber} from "../../../../core/component/brl-input-number";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import React, {useEffect, useState} from "react";
import {FinancialEntryEntity} from "../../../../domain/entity/financial-entry.entity";
import {FinancialEntryItemEntity} from "../../../../domain/entity/financial-entry-item.entity";
import {FinancialEntryTaxEntity} from "../../../../domain/entity/financial-entry-tax.entity";
import {FinancialDocumentTypeEntity} from "../../../../domain/entity/financial-document-type.entity";
import {useFinancialDocumentTypeRepository} from "../../../../domain/repository/financial-document-type.repository";
import {useFinancialEntryRepository} from "../../../../domain/repository/financial-entry.repository";
import {DetailsEntry} from "./details.entry";

export type FormFinancialEntryTaxProps = {
  entry: FinancialEntryEntity,
  items: FinancialEntryItemEntity[],
  taxes?: FinancialEntryTaxEntity[],
  onFinish: (taxes: FinancialEntryTaxEntity[]) => void,
  onBack: () => void
}

export function FormFinancialEntryTax(props: FormFinancialEntryTaxProps) {
  const [form] = Form.useForm();
  const [documentType, setDocumentType] = useState<FinancialDocumentTypeEntity>();

  const documentTypeRepo = useFinancialDocumentTypeRepository();
  const financialEntryRepo = useFinancialEntryRepository();
  const { entry, taxes } = props;

  useEffect(() => {
    entry?.documentTypeId && documentTypeRepo
      .find(entry.documentTypeId.toString())
      .then(res => setDocumentType(res.data));
    console.log(entry);
  }, [entry]);

  useEffect(() => {
    if (taxes) {
      for (const tax of taxes) {
        form.setFields([{ name: ['values', tax.taxId], value: tax.value }])
      }
    }
  }, [taxes])

  function onFinish(data: any) {
    if (!data.values) {
      props.onFinish([]);
      return;
    }
    const values: Array<{ taxId: number, value: number }> = [];
    for(const taxId of Object.keys(data.values)) {
      values.push({
        taxId: parseInt(taxId),
        value: parseFloat(data.values[taxId])
      })
    }
    financialEntryRepo.saveFinancialEntryTax(values, entry.id).then(res => {
      message.success(res.message);
      props.onFinish(res.data);
    }).catch(err => {
      message.error(err.message);
    })
  }

  return (
    <Form form={form} layout={'vertical'} onFinish={onFinish}>
      <DetailsEntry entry={props.entry} />
      <Divider />
      <Row gutter={12}>
        {
          documentType?.taxes?.map(item => (
              <Col span={6}>
                <BrlInputNumber
                  name={['values',item.tax?.id.toString() ?? '']}
                  label={item.tax?.alias ?? ''}
                  help={item.tax?.name}
                  required={true}
                />
              </Col>
            )
          )
        }
        {
          !documentType?.taxes?.length && (
            <Typography.Text style={{textAlign: `center`, display: 'block', width: '100%'}} >
              Não há retenção tributária para esse documento. Clique em continuar para prosseguir.
            </Typography.Text>
          )
        }
      </Row>
      <FormActionsButtons
        label={!documentType?.taxes?.length ? 'Continuar' : 'Salvar'}
        labelCancel={'Voltar'}
        onCancel={() => props.onBack()}
      />
    </Form>
  )
}