import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {HeaderBankAccount} from "../component/header.bank-account";
import { Col, Divider, Form, message, Row, Select } from "antd";
import {useCallback, useEffect, useState} from "react";
import { BankAccountEntity } from "../../../../domain/entity/bank-account.entity";
import { useBankAccountRepository } from "../../../../domain/repository/bank-account.repository";
import { BrlInputNumber } from "../../../../core/component/brl-input-number";
import { FormActionsButtons } from "../../../../core/component/form-actions-buttons";
import { getSorter } from "../../../../core/util/sort-array.util";
import {useNumberFormatter} from "../../../service/number-formatter.service";
import {ErrorMessageResource} from "../resource/error-message.resource";
import {DatePickerPtBr} from "../../../../core/component/date-picker-ptbr";

export function TransferBankAccount(props: ModuleRenderProps) {
  const [form] = Form.useForm();
  const [accounts, setAccounts] = useState<BankAccountEntity[]>([]);
  const [accountFromId, setAccountFromId] = useState<number>();
  const [currentBalance, setCurrentBalance] = useState<number>();

  const { currencyFormatter } = useNumberFormatter();
  const bankAccountRepo = useBankAccountRepository();

  const getBalanceAmount = useCallback((accountId: number) => {
    bankAccountRepo
      .getBalanceAmount(accountId.toString())
      .then(res => setCurrentBalance(res.data))
  }, [bankAccountRepo])

  useEffect(() => {
    bankAccountRepo.findAll(true).then(res => setAccounts(res.data));
  }, []);


  function getAccountsOpts(isAccountToId = false) {
    return accounts.sort(getSorter<BankAccountEntity>('alias'))
      .map(item => ({
        label: `${item.alias}` + (item.account ? ` (${item.agency} - ${item.account})` : ''),
        value: item.id,
        disabled: isAccountToId && item.id === accountFromId
      }))
  }

  function onFinish(data: any) {
    return bankAccountRepo.transferBalance(
      data.accountFromId,
      data.accountToId,
      data.value,
      data.dateTime
    ).then(res => {
      message.success(res.message);
      props.navigator.goToListPage();
    }).catch(err => {
      switch (err.messageKey) {
        case ErrorMessageResource.INSUFFICIENT_BALANCE:
          form.setFields([{
            name: 'value',
            errors: ['O saldo da conta de origem é insuficiente pra esse valor']
          }]);
          break;
        default:
          message.error(err.message);
          break;
      }
    })
  }

  function _setAccountFromId(val: number) {
    setAccountFromId(val);
    getBalanceAmount(val);
  }

  function getCurrentBalance() {
    return `Saldo disponível ${currencyFormatter.format(currentBalance ?? 0)}`;
  }

  return (
    <>
      <HeaderBankAccount title={'Trasferência entre contas'} />
      <Divider />
      <Form form={form} layout={'vertical'} onFinish={onFinish}>
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item
              label={'Conta Origem'}
              name={'accountFromId'}
              rules={[{required: true}]}
              extra={getCurrentBalance()}
            >
              <Select
                options={getAccountsOpts()}
                onSelect={(val: number) => _setAccountFromId(val)}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={'Conta Destino'} name={'accountToId'} rules={[{required: true}]}>
              <Select options={getAccountsOpts(true)} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <BrlInputNumber label={'Valor'} name={'value'} required={true} />
          </Col>
          <Col span={4}>
            <DatePickerPtBr label={'Data Operação'} name={'dateTime'} required={true} />
          </Col>
        </Row>
        <FormActionsButtons />
      </Form>
    </>
  )
}