import React, {useEffect, useState} from "react";
import {HeaderRecurrenceEntry} from "../component/header.recurrence-entry";
import {message, Tabs} from "antd";
import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {useRecurrenceEntryRepository} from "../../../../domain/repository/recurrence-entry.repository";
import {useParams} from "react-router-dom";
import {RecurrenceEntryEntity} from "../../../../domain/entity/recurrence-entry.entity";
import {FormRecurrenceEntryItem} from "../component/form.recurrence-entry-item";
import {ListRecurrenceEntryItem} from "../component/list.recurrence-entry-item";
import {FormRecurrenceEntry} from "../component/form.recurrence-entry";
import {FormRecurrenceEntryApportionment} from "../component/form.recurrence-entry-apportionment";

export function EditRecurrenceEntry(props: ModuleRenderProps) {
  const [tab, setTab] = useState<string>('1');
  const [recurrenceEntry, setRecurrenceEntry] = useState<RecurrenceEntryEntity>();
  const { id: recurrenceEntryId } = useParams<{ id: string }>();
  const queryTab = new URLSearchParams(window.location.search).get('tab');

  useEffect(() => {
    console.log(tab);
    queryTab && setTab(queryTab);
  }, [queryTab])
  const recurrenceEntryRepo = useRecurrenceEntryRepository();

  function loadRecurrenceEntry() {
    return recurrenceEntryRepo
      .find(recurrenceEntryId)
      .then(res => setRecurrenceEntry(res.data));
  }

  useEffect(() => {
    loadRecurrenceEntry()
  }, [recurrenceEntryId]);


  function removeRecurrenceEntryId(id: string) {
    recurrenceEntryRepo.removeItem(id).then(res => {
      message.success(res.message);
      loadRecurrenceEntry();
    }).catch(err => {
      message.error(err.message);
    })
  }

  return (
    <>
      <HeaderRecurrenceEntry
        title={`Editando ${recurrenceEntry?.name}`}
        mode={'form'}
      />
      <Tabs activeKey={tab}  onChange={setTab}>
        <Tabs.TabPane tab={'Dados Básicos'} key={1}>
          {
            recurrenceEntry && (
              <FormRecurrenceEntry
                recurrenceEntry={recurrenceEntry}
                mode={'edit'}
                onFinish={() => {
                  loadRecurrenceEntry().then(() => setTab('2'))
                }}
                onCancel={() => props.navigator.goToListPage()}
              />
            )
          }
        </Tabs.TabPane>
        <Tabs.TabPane tab={'Item Lançamento'} key={2}>
          {
            recurrenceEntry && (
              <>
                <FormRecurrenceEntryItem
                  recurrenceEntry={recurrenceEntry}
                  onSaved={() => loadRecurrenceEntry()}
                />
                <ListRecurrenceEntryItem
                  loading={recurrenceEntryRepo.loading}
                  removeItem={removeRecurrenceEntryId}
                  items={recurrenceEntry?.items ?? []}
                />
              </>
            )
          }
        </Tabs.TabPane>
        <Tabs.TabPane tab={'Rateio'} key={3}>
          {
            recurrenceEntry && (
              <>
                <FormRecurrenceEntryApportionment
                  recurrence={recurrenceEntry}
                  onCancel={() => props.navigator.goToListPage()}
                  onFinish={() => props.navigator.goToListPage()}
                />
              </>
            )
          }
        </Tabs.TabPane>
      </Tabs>
    </>
  )
}
