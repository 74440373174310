import React, {useEffect, useState} from "react";
import {HeaderFinancialGroup} from "../component/header.financial-group";
import {Divider, Layout, message, Table, Tag,} from "antd";
import {ListActions} from "../../../../core/component/list-actions";
import {FinancialGroupEntity} from "../../../../domain/entity/financial-group.entity";
import {useFinancialGroupRepository} from "../../../../domain/repository/financial-group.repository";

export function ListFinancialGroup(props: any) {
    const financialGroupRepo = useFinancialGroupRepository();
    const [data, setData] = useState<FinancialGroupEntity[]>([]);

    function loadData() {
        financialGroupRepo.search().then(res => {
            setData(res.data)
        });
    }

    useEffect(() => {
        loadData()
    }, [1]);

    function removeGroup(id: string) {
        financialGroupRepo.remove(id)
            .then(res => {
                message.success(res.message);
                loadData();
            }).catch(err => {
                message.error(err.message);
        })
    }

    const columnsBudget = [
        {
            title: 'Nome',
            dataIndex: 'name' ,
            key: 'name',
        },
        {
            title: 'Código',
            dataIndex: 'code' ,
            key: 'code',
        },
        {
            title: 'Status',
            dataIndex: 'active',
            key: 'active',
            render: (value: boolean) => (
                <Tag color={value ? 'green' : 'red'}>
                    {value ? 'Ativo' : 'Inativo'}
                </Tag>
            )
        },
        {
            dataIndex: 'id',
            render: (val: any) => (
                <>
                    <ListActions
                        onEditClick={() => props.navigator.goToEditPage(val) }
                        onRemoveClick={() => removeGroup(val) }
                    />
                </>
            )
        }
    ]
    return (
        <>
            <HeaderFinancialGroup />
            <Divider />
            <Layout.Content>
                <Table 
                    columns={columnsBudget} 
                    dataSource={data} 
                    loading={financialGroupRepo.loading} />
            </Layout.Content>
        </>
    );
}