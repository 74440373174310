import {RoleEntity} from "../../domain/entity/role.entity";
import {ProfileEntity} from "../../domain/entity/profile.entity";

export function useProfileStorage() {
    const PROFILE_KEY_STORAGE = '__a8sd';

    function setProfile(role: ProfileEntity) {
        localStorage.setItem(PROFILE_KEY_STORAGE, btoa(JSON.stringify(role)));
    }

    function getProfile(): ProfileEntity | undefined {
        const item = localStorage.getItem(PROFILE_KEY_STORAGE);
        if(item) {
            return JSON.parse(atob(item));
        }
        return undefined;
    }

    function clear() {
        localStorage.removeItem(PROFILE_KEY_STORAGE);
    }

    return {
        setProfile,
        getProfile,
        clear
    }
}