import {Button, Form, Input, message, Typography} from "antd";
import React, {useEffect, useState} from "react";
import {useAuthService} from "../../../../service/auth.service";
import {UserEntity} from "../../../../../domain/entity/user.entity";
import {useHistory} from "react-router-dom";

export function FormRecoverPassword(props: any) {
  const [form] = Form.useForm();
  const [user, setUser] = useState<UserEntity>();
  const [currentToken, setCurrentToken] = useState<string>();

  const history = useHistory();
  const authService = useAuthService();

  useEffect(() => {
    const token = new URLSearchParams(window.location.search).get('token');
    if (token) {
      setCurrentToken(token);
    }
  }, [])

  useEffect(() => {
    if (currentToken) {
      authService.getUserReqChange(currentToken).then(res => {
        setUser(res.data);
      }).catch(err => {
        message.error(err.message);
      })
    }
  }, [currentToken]);

  function onFinish(values: any) {
    const { password, confirmPassword } = values;
    if (!currentToken) {
      return;
    }
    if (password !== confirmPassword) {
      return form.setFields([
        { name: 'confirmPassword', errors: ['As senhas não são correspondentes.'] }
      ])
    }
    return authService.recoverPassword({
      newPassword: values.password,
      tokenId: currentToken as string,
    }).then(res => {
      message.success(res.message)
      history.push('/auth/login');
    }).catch(err => {
      message.error(err.message);
    })
  }

  function onCancel() {
    history.push('/auth/login')
  }

  return (
    <Form
      form={form}
      layout={'vertical'}
      style={{width: '100%', backgroundColor: 'white', padding: 50, borderRadius: 10}}
      initialValues={{ remember: true }}
      onFinish={onFinish}
    >
      <Typography.Title level={5} style={{textAlign: 'center'}}>
        {
          user ? `Alterar senha do usuario '${user?.username}'` : 'Token Inválido'
        }
      </Typography.Title>
      <Form.Item name="password" label={'Informe uma nova senha'} rules={[{ required: true }]}>
        <Input.Password />
      </Form.Item>
      <Form.Item label={'Confirme a nova senha'} name="confirmPassword" rules={[{ required: true }]}>
        <Input.Password />
      </Form.Item>
      <Form.Item>
        <Button loading={authService.loading} type="primary" htmlType="submit" style={{width: '100%', }}>
          Confirmar
        </Button>
      </Form.Item>
      <Form.Item>
        <Button htmlType={'button'} onClick={() => onCancel()} style={{width: '100%', backgroundColor: '#1b1b1b', color: 'white'}}>
          Cancelar
        </Button>
      </Form.Item>
    </Form>
  )
}