import {FinancialEntryItemEntity} from "./financial-entry-item.entity";
import {PersonEntity} from "./person.entity";
import {FirmEntity} from "./firm.entity";
import {CostCenterEntity} from "./cost-center.entity";
import {FinancialDocumentTypeEntity} from "./financial-document-type.entity";
import {FinancialEntryTaxEntity} from "./financial-entry-tax.entity";
import {FinancialEntryInstallmentEntity} from "./financial-entry-installment.entity";
import { FinancialEntryApportionmentEntity } from "./financial-entry-apportionment.entity";

export type FinancialEntryEntity = {
  id: number,
  type: string,
  firmId: number,
  costCenterId: number,
  clientId: number,
  documentTypeId: number,
  documentNumber: string,
  documentSeries?: string,
  dateTimeEmission: Date,
  dateTimeEntry: Date,
  createdAt: Date,
  dateTimeWriteOff?: Date,
  dateTimeReceipt?: Date,
  personId: number,
  items?: FinancialEntryItemEntity[],
  taxes?: FinancialEntryTaxEntity[],
  installments?: FinancialEntryInstallmentEntity[],
  apportionment?: FinancialEntryApportionmentEntity[],
  person?: PersonEntity,
  firm?: FirmEntity,
  isCanceled?: boolean,
  costCenter?: CostCenterEntity,
  documentType: FinancialDocumentTypeEntity,
  observation?: string,
  documentNetValue: number,
  documentGrossValue: number,
  paymentCondition: string,
  statusPayment: string,
  totalInstallments: number,
  paidInstallments: number,
}

export function parseTypeEntryName(type: string) {
  switch (type) {
    case 'D':
      return 'Dedução';
    case 'R':
      return 'Receita';
    case 'P':
      return 'Provisão';
    default:
      return type;
  }
}