import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {HeaderCashFlow} from "../component/header.cash-flow";
import {Button, Col, Divider, Drawer, Form, message, Radio, Row, Select, Space, Table} from "antd";
import {Content} from "antd/es/layout/layout";
import React, {useEffect, useState} from "react";
import {ColumnsType} from "antd/lib/table";
import {useNumberFormatter} from "../../../service/number-formatter.service";
import {parseTypeEntryName} from "../../../../domain/entity/financial-entry.entity";
import {useFinancialEntryRepository} from "../../../../domain/repository/financial-entry.repository";
import {ShowFullFinancialEntry} from "../../financial-entry/component/show-full-financial-entry";
import {useDateTimeUtil} from "../../../../core/util/date-time.util";
import {FinancialEntryWithRetentionDto} from "../../../../domain/dto/financial-entry-with-retention.dto";
import {DatePickerPtBr} from "../../../../core/component/date-picker-ptbr";
import {ExportOutlined, SearchOutlined} from "@ant-design/icons";
import moment from "moment";

export function ReportEntriesWithRetention(props: ModuleRenderProps) {
  const [data, setData] = useState<FinancialEntryWithRetentionDto[]>([]);
  const [entryDetailsId, setEntryDetailsId] = useState<number>();
  const [initialDate, setInitialDate] = useState(moment().add('-1', 'month'))
  const [typeDate, setTypeDate] = useState('entry');
  const [typeEntry, setTypeEntry] = useState<string>();
  const [endDate, setEndDate] = useState(moment());
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [form] = Form.useForm();

  const { decimalFormatter } = useNumberFormatter();
  const dateTimeUtil = useDateTimeUtil();
  const financialEntryRepo = useFinancialEntryRepository();

  function loadData(args: {
    startDate: Date,
    endDate: Date,
    typeEntry?: string;
    typeDate: string,
    limit: number,
    offset: number,
  }) {
    financialEntryRepo.listEntriesWithRetention(args)
      .then(res => {
        setData(res.data)
        setTotal(res.total);
      });
  }

  function exportDate(args: {
    startDate: Date,
    endDate: Date,
    typeEntry?: string;
    typeDate: string,
    limit: number,
    offset: number,
  }) {
    financialEntryRepo.exportEntriesWithRetention(args).then(() => {
      message.success('Lançamentos exportados com sucesso !');
    });
  }

  useEffect(() => {
    loadData({
      startDate: initialDate.toDate(),
      endDate: endDate.toDate(),
      limit,
      offset: (page - 1) * limit,
      typeDate,
      typeEntry
    });
  }, [page]);


  const columns: ColumnsType<FinancialEntryWithRetentionDto> = [
    {
      title: 'Número Lançamento',
      dataIndex: 'entryId',
      key: 'id'
    },
    {
      title: 'Tipo',
      dataIndex: 'typeEntry',
      key: 'typeEntry',
      render: parseTypeEntryName
    },
    {
      title: 'Filial',
      dataIndex: 'firmName',
    },
    {
      title: 'Centro de Custo',
      dataIndex: 'costCenterName',
    },
    {
      title: 'Cli/For',
      dataIndex: 'personName',
    },
    {
      title: 'Valor Bruto',
      dataIndex: 'documentGrossValue',
      render: decimalFormatter.format,
    },
    {
      title: 'Data de Entrada',
      dataIndex: 'dateTimeEntry',
      render: dateTimeUtil.convertToDatePtBr,
    },
    {
      title: 'Primeira Baixa',
      dataIndex: 'firstWriteOffDateTime',
      render: (dt: any) => dt ? dateTimeUtil.convertToDatePtBr(dt) : '',
    },
    {
      title: '',
      key: 'action',
      dataIndex: 'entryId',
      render: (id) => (
        <Button
          type={'ghost'}
          shape={'round'}
          size={'small'}
          onClick={() => setEntryDetailsId(id)}
        >
          Detalhes
        </Button>
      )
    }
  ]

  function onFilter(values: any) {
    setInitialDate(values.initialDate);
    setEndDate(values.endDate);
    setTypeDate(values.typeDate);
    setTypeEntry(values.typeEntry);

    const args = {
      startDate: values.initialDate.toDate(),
      endDate: values.endDate.toDate(),
      limit,
      typeEntry: values.typeEntry,
      typeDate: values.typeDate,
      offset: (page - 1) * limit,
    }
    if (values.exportAction) {
      return exportDate(args);
    }
    return loadData(args);
  }


  return (
    <>
      <HeaderCashFlow
        title={'Lançamentos com Retenção Tributária'}
        subtitle={'Lançamentos com Retenção Tributária Listados Por Período.'}
      />
      <Divider />

      <Form
        form={form}
        onFinish={onFilter}
        layout={'vertical'}
        size={'middle'}
        initialValues={{ endDate, initialDate, typeDate }}
      >
        <Row gutter={12}>
          <Col>
            <Form.Item name={'typeDate'}>
              <Radio.Group style={{paddingTop: 5}}>
                <Radio value={'entry'}>Data de Entrada</Radio>
                <Radio value={'writeOff'}>Data da Primeira Baixa</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={4}>
            <DatePickerPtBr
              label={''}
              name={'initialDate'}
              required={true}
              placeholder={'Data de Entrada Incial'}
            />
          </Col>
          <Col span={4}>
            <DatePickerPtBr
              label={''}
              name={'endDate'}
              required={true}
              placeholder={'Data de Entrada Final'}
            />
          </Col>
          <Col span={4}>
            <Form.Item name={'typeEntry'} label={''}>
              <Select placeholder={'Tipo Lançamento'} options={[
                { label: 'Receita', value: 'R' },
                { label: 'Despesa', value: 'D' },
              ]} />
            </Form.Item>
          </Col>
          <Col>
            <Space align={'center'} >
              <Button icon={<SearchOutlined />} shape={'round'} htmlType={'submit'}>
                Filtrar
              </Button>
              <Button
                icon={<ExportOutlined />}
                shape={'round'}
                htmlType={'button'}
                onClick={() => onFilter({
                  ...form.getFieldsValue(),
                  exportAction: true,
                })}
              >
                Exportar
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
      <Content>
        <Table
          loading={financialEntryRepo.loading}
          columns={columns}
          dataSource={data}
          pagination={{
            onChange: setPage,
            defaultPageSize: limit,
            onShowSizeChange: setLimit,
            total,
          }}
        />
      </Content>
      <Drawer
        placement={'bottom'}
        height={550}
        visible={!!entryDetailsId}
        bodyStyle={{padding: 0}}
        onClose={() => setEntryDetailsId(undefined)}
      >
        {
          entryDetailsId && <ShowFullFinancialEntry entryId={entryDetailsId} />
        }
      </Drawer>
    </>
  )
}


