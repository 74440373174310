import {useEffect, useState} from "react";
import {FinancialEntryEntity} from "../../../../../domain/entity/financial-entry.entity";
import {useFinancialEntryRepository} from "../../../../../domain/repository/financial-entry.repository";
import {Tabs} from "antd";
import {BasicDataEntry} from "./basic-data.entry";
import {ItemsEntry} from "./items.entry";
import {TaxesEntry} from "./taxes.entry";
import {InstallmentsEntry} from "./installments.entry";
import {HistoryEntry} from "./history.entry";
import {ApportionmentEntry} from "./apportionment.entry";
import {RenegotiationHistory} from "./renegotiation-history";
import {FinancialEntryRenegotiationEntity} from "../../../../../domain/entity/financial-entry-renegotiation.entity";

export function ShowFullFinancialEntry(props: { entryId: number }) {
  const [entry, setEntry] = useState<FinancialEntryEntity>();
  const [renegotiation, setRenegotiation] = useState<FinancialEntryRenegotiationEntity[]>([]);
  const { entryId } = props;
  const financialEntryRepo = useFinancialEntryRepository();

  useEffect(() => {
    loadData();
  }, [entryId])

  function loadData() {
    financialEntryRepo.find(entryId.toString()).then(res => setEntry(res.data));
    financialEntryRepo.listRenegotiation(entryId).then(res => setRenegotiation(res.data));
  }

  return (
    <Tabs type={'card'} >
      <Tabs.TabPane tab={`Dados Básicos`} key={1} >
        { entry && <BasicDataEntry entry={entry} /> }
      </Tabs.TabPane>
      <Tabs.TabPane tab={'Items'} key={2}>
        { entry && <ItemsEntry entry={entry} />}
      </Tabs.TabPane>
      <Tabs.TabPane tab={'Tributos'} key={3}>
        { entry && <TaxesEntry entry={entry} />}
      </Tabs.TabPane>
      <Tabs.TabPane tab={'Parcelas'} key={4}>
        { entry && <InstallmentsEntry entry={entry} onInstallmentCanceled={loadData} />}
      </Tabs.TabPane>
      <Tabs.TabPane tab={'Rateio'} key={5}>
        { entry && <ApportionmentEntry entry={entry} />}
      </Tabs.TabPane>
      <Tabs.TabPane tab={'Histórico'} key={6}>
        { entry && <HistoryEntry entry={entry} />}
      </Tabs.TabPane>
      <Tabs.TabPane tab={'Histórico de Renegociação'} key={7}>
        { renegotiation.length && <RenegotiationHistory renegotiation={renegotiation} />}
      </Tabs.TabPane>
    </Tabs>
  )

}