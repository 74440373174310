import {CSVLink} from "react-csv";
import {useRef} from "react";
import {Button} from "antd";
import {BaseButtonProps} from "antd/lib/button/button";

export interface ExportableButtonComponentProps extends BaseButtonProps {
  label?: string,
  fileName?: string;
  data: Array<object>,
}
export function ExportableButtonComponent(props: ExportableButtonComponentProps) {
  const csvLink = useRef<any>();
  const headers = props.data.length ? Object.keys(props.data[0]) : [];
  const { label, data, fileName, ...ButtonProps } = props;
  return (
    <Button
      onClick={() => csvLink.current?.click()}
      {...ButtonProps}
    >
      <CSVLink
        ref={csvLink.current}
        data={props.data}
        separator={';'}
        filename={fileName}
        headers={headers}
      >
        {label ?? 'Exportar para CSV'}
      </CSVLink>
    </Button>
  )
}