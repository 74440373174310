import {BaseResponse, usePlaneduHttpService} from "../service/planedu.http.service";
import {RecurrenceEntryEntity} from "../entity/recurrence-entry.entity";
import {RecurrenceEntryItemEntity} from "../entity/recurrence-entry-item.entity";
import {RecurrenceEntryParsedEntity} from "../entity/recurrence-entry-parsed.entity";
import {RecurrenceToParseDto} from "../dto/recurrence-to-parse.dto";
import {RecurrenceEntryApportionmentEntity} from "../entity/recurrence-entry-apportionment.entity";
import {NextToParseRecurrenceDto} from "../dto/next-to-parse.recurrence.dto";

export function useRecurrenceEntryRepository() {
  const httpService = usePlaneduHttpService<RecurrenceEntryEntity>('recurrence-entry');

  function addItem(
    recurrenceEntryId: number,
    item: RecurrenceEntryItemEntity
  ): Promise<BaseResponse<RecurrenceEntryItemEntity>> {
    return httpService.post(
      `${httpService.resourceName}/${recurrenceEntryId}/item`,
      JSON.stringify(item)
    )
  }

  function removeItem(itemId: string): Promise<BaseResponse<RecurrenceEntryItemEntity>> {
    return httpService.delete(
      `${httpService.resourceName}/item/${itemId}`
    )
  }

  async function getNextToParse(recurrenceEntryId: number): Promise<BaseResponse<RecurrenceEntryParsedEntity>> {
    return httpService.get(
      `${httpService.resourceName}/${recurrenceEntryId}/next-parsed`
    );
  }

  async function listRecToParse(
    recurrenceEntryId: number
  ): Promise<BaseResponse<RecurrenceToParseDto[]>> {
    return httpService.get(
      `${httpService.resourceName}/${recurrenceEntryId}/list-to-parse`
    );
  }

  async function bindEntry(recurrenceEntryId: number, args: {
    dueDate: string,
    recurrenceEntryId: number,
    financialEntryId: number,
  }): Promise<BaseResponse<RecurrenceEntryParsedEntity>> {
    return httpService.put(
      `${httpService.resourceName}/${recurrenceEntryId}/bind-entry`,
      JSON.stringify(args),
    )
  }

  function saveApportionment(
    recurrenceId: number,
    apportionment: RecurrenceEntryApportionmentEntity[],
  ): Promise<BaseResponse<RecurrenceEntryApportionmentEntity[]>> {
    return httpService.put(
      `${httpService.resourceName}/${recurrenceId}/apportionment`,
      JSON.stringify({ apportionment })
    )
  }

  function listNextToParse(): Promise<BaseResponse<NextToParseRecurrenceDto[]>> {
    return httpService.get(
      `${httpService.resourceName}/next-to-parse`
    )
  }

  function cancelParsed(recurrenceEntryParsedId: number): Promise<BaseResponse<RecurrenceEntryParsedEntity>> {
    return httpService.post(
      `${httpService.resourceName}/${recurrenceEntryParsedId}/cancel`,
    )
  }

  function cancelRecurrence(id: number) {
    return httpService.post(
      `${httpService.resourceName}/cancel/${id}`
    )
  }

  return {
    ...httpService,
    addItem,
    removeItem,
    getNextToParse,
    listRecToParse,
    bindEntry,
    saveApportionment,
    listNextToParse,
    cancelParsed,
    cancelRecurrence
  }
}