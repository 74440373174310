import {RecurrenceEntryContractType} from "../../../../domain/entity/recurrence-entry.entity";

export function recurrenceContractTypeTranslateService(item: RecurrenceEntryContractType): string {
  switch (item) {
    case RecurrenceEntryContractType.FIXED:
      return 'Fixo';
    case RecurrenceEntryContractType.VARIABLE:
      return 'Variável';
    default:
      return item;
  }
}