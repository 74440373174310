import { ModuleRenderProps } from "../../../../core/component/module/module.root";
import { useEffect, useState } from "react";
import { BankAccountEntity } from "../../../../domain/entity/bank-account.entity";
import { useBankAccountRepository } from "../../../../domain/repository/bank-account.repository";
import { useParams } from "react-router-dom";
import { FormInitialBalance } from "../component/form.initial-balance.component";

export function CreateInitialBalance(props: ModuleRenderProps) {
  const [bankAccount, setBankAccount] = useState<BankAccountEntity>();
  const { id: bankAccountId } = useParams<any>();

  const bankAccountRepo = useBankAccountRepository();

  useEffect(() => {
    bankAccountId && bankAccountRepo
      .find(bankAccountId).then(({ data }) => {
        setBankAccount(data);
      });
  }, [bankAccountId])


  return (
    <>
      {
        bankAccount && (
          <FormInitialBalance
            account={bankAccount}
            onFinish={() => props.navigator.goToListPage()}
          />
        )
      }
    </>
  )
}