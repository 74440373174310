import {useTokenStorage} from "../../core/service/token.storage";
import {useHttpService} from "../../core/service/http.service";
import {UserEntity} from "../../domain/entity/user.entity";
import {ClientEntity} from "../../domain/entity/client.entity";
import {useUserStorage} from "../../core/service/user.storage";
import {useClientStorage} from "../../core/service/client.storage";
import {ProfileEntity} from "../../domain/entity/profile.entity";
import {useProfileStorage} from "../../core/service/profile.storage";
import {useMessageTranslateService} from "../../core/service/message.tranlate";
import {BaseResponse} from "../../domain/service/planedu.http.service";

export function useAuthService() {
  const tokenStorage = useTokenStorage();
  const userStorage = useUserStorage();
  const clientStorage = useClientStorage();
  const profileStorage = useProfileStorage();
  const translateService = useMessageTranslateService();

  const httpService = useHttpService({
    server: process.env.REACT_APP_SERVER_HOST as string,
    headers: { "Content-Type": "application/json" }
  });


  function getTransformerMessage(success = true) {
    return function (data: any) {
      if (success) {
        const res = {
          ...data,
          message: translateService.translate(data.message)
        }
        return Promise.resolve(res);
      }
      const res = {
        ...data,
        message: translateService.translate(data?.error?.response?.message)
      }
      return Promise.reject(res);
    }
  }

  async function auth(username: string, password: string, clientKey: string, roleAlias: string) : Promise<AuthResponse> {
    const body = { username, password, clientKey, roleAlias };
    return httpService.post('auth/login', JSON.stringify(body))
      .then( res => {
        return res as AuthResponse
      }).catch(err => {
        throw ({
          ...err,
          message: translateService.translate(err.res.message) ?? err.res.messsage
        })
      });
  }
  async function changeToken(profileId: number, clientId: number, bearerToken: string) : Promise<AuthResponse> {
    const body = { profileId, clientId };
    return httpService.post('auth/change-token', JSON.stringify(body), undefined, {
      Authorization: `Bearer ${bearerToken}`
    })
      .then( res => {
        return res.data as AuthResponse
      }).catch(err => {
        throw ({
          ...err,
          message: translateService.translate(err.res.message) ?? err.res.messsage
        })
      });
  }

  async function authUser(
    username: string,
    password: string
  ): Promise<BaseResponse<{ user: UserEntity, profiles: ProfileEntity[], clients: ClientEntity[] }>> {
    return httpService.post('auth/user', JSON.stringify({
      username,
      password,
    }))
  }

  async function me(bearerToken: string):
    Promise<BaseResponse<{ user: UserEntity, profiles: ProfileEntity[], clients: ClientEntity[] }>> {
    return httpService.get('auth/me', undefined, undefined, {
      Authorization: `Bearer ${bearerToken}`,
    })
  }

  async function listClients(bearerToken: string): Promise<ClientEntity[]> {
    return httpService.get(
      'auth/clients',
      undefined,
      undefined,
      { Authorization: `Bearer ${bearerToken}`}
    ).then(res => {
      return res.data as ClientEntity[]
    })
  }

  async function listProfiles(bearerToken: string): Promise<ProfileEntity[]> {
    return httpService.get(
      'auth/profiles',
      undefined,
      undefined,
      { Authorization: `Bearer ${bearerToken}`}
    ).then(res => {
      return res.data as ProfileEntity[]
    })
  }

  async function changeClient(
    bearerToken: string,
    targetClientId: number
  ): Promise<{
    bearerToken: string,
    refreshToken: string,
    client: ClientEntity,
    profile: ProfileEntity
  }> {
    return httpService.post(
      'auth/change-client',
      JSON.stringify({ targetClientId }),
      undefined,
      { Authorization: `Bearer ${bearerToken}`}
    )
  }

  async function changeProfile(
    bearerToken: string,
    targetProfileId: number
  ): Promise<{ bearerToken: string, refreshToken: string, profile: ProfileEntity }> {
    return httpService.post(
      'auth/change-profile',
      JSON.stringify({ targetProfileId }),
      undefined,
      { Authorization: `Bearer ${bearerToken}`}
    )
  }

  function getAuthResponseFromStorage(): AuthResponse | null {
    const bearerToken = tokenStorage.getBearerToken();
    if (!bearerToken) {
      return null;
    }
    const refreshToken = tokenStorage.getRefreshToken();
    if (!refreshToken) {
      return null
    }
    const user = userStorage.getUser();
    if (!user) {
      return null;
    }
    const client = clientStorage.getClient();
    if (!client) {
      return null;
    }
    const profile = profileStorage.getProfile();
    if (!profile) {
      return null;
    }

    return {
      user,
      refreshToken,
      profile,
      client,
      bearerToken
    }
  }

  function setStorage(login: AuthResponse) {
    tokenStorage.setBearerToken(login.bearerToken);
    tokenStorage.setRefreshToken(login.refreshToken);
    userStorage.setUser(login.user);
    clientStorage.setClient(login.client);
    profileStorage.setProfile(login.profile);
  }

  function clearStorage() {
    tokenStorage.clearToken();
    userStorage.clear();
    clientStorage.clear();
    profileStorage.clear();
  }

  function logout() {
    clearStorage();
    window.location.href = '/auth/login';
  }

  function isAuthenticated() {
    return tokenStorage.getBearerToken() !== null;
  }

  function getUserReqChange(token: string): Promise<BaseResponse<UserEntity>> {
    return httpService.get(
      `user/external/request-change/${token}`
    )
  }

  function recoverPassword(args: {tokenId: string, newPassword: string}): Promise<BaseResponse<any>> {
    return httpService.post(
      `user/external/recover-password`,
      JSON.stringify(args)
    )
  }

  function requestChange(args: {emailOrUsername: string}): Promise<BaseResponse<any>> {
    return httpService.post(
      `user/external/request-change`,
      JSON.stringify(args)
    ).then(getTransformerMessage())
    .catch(getTransformerMessage(false))
  }

  return {
    loading: httpService.loading,
    auth,
    setStorage,
    clearStorage,
    logout,
    isAuthenticated,
    listClients,
    listProfiles,
    changeClient,
    changeProfile,
    getAuthResponseFromStorage,
    authUser,
    me,
    changeToken,
    getUserReqChange,
    recoverPassword,
    requestChange
  }
}


export type AuthResponse = {
  user: UserEntity;
  client: ClientEntity;
  profile: ProfileEntity;
  bearerToken: string;
  refreshToken: string;
}
