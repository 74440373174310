import {Col, Divider, Form, Input, message, Row} from "antd";
import React from "react";
import {HeaderFinancialDocumentType} from "../component/header.financial-document-type";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {useFinancialDocumentTypeRepository} from "../../../../domain/repository/financial-document-type.repository";

export function CreateFinancialDocumentType(props: ModuleRenderProps) {
    const [form] = Form.useForm();
    const financialDocumentTypeRepo = useFinancialDocumentTypeRepository();

    function onFinish(data: any) {
        financialDocumentTypeRepo.create(data).then(res => {
            message.success(res.message);
            props.navigator.goToEditPage(res.data.id.toString());
        }).catch(err => {
            message.error(err.message)
        })
    }

    return (
        <>
            <HeaderFinancialDocumentType mode={'form'} />
            <Divider />
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Row gutter={12}>
                    <Col span={16}>
                        <Form.Item name={'name'} label="Nome" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name={'alias'} label="Sigla"  >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <FormActionsButtons
                    onCancel={props.navigator.goToDefaultPage}
                    isLoading={financialDocumentTypeRepo.loading}
                />
            </Form>
        </> 
    )
}
