import React, {useCallback, useEffect, useState} from "react";
import {HeaderManagerialAccounts} from "../component/header.managerial-accounts";
import {Col, Divider, Form, Input, message, Radio, Row, Switch} from "antd";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import {ManagerialAccountEntity} from "../../../../domain/entity/managerial-account.entity";
import {useManagerialRepository} from "../../../../domain/repository/managerial.repository";
import {useParams} from "react-router-dom";
import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import MaskedInput from "antd-mask-input";
import {useClientRepository} from "../../../../domain/repository/client.repository";
import {ManagerialAccountTypeEnum} from "../../../../domain/vo/managerial-account.type.enum";
import {ErrorMessagesEnum} from "../resources/error-messages.enum";
import TextArea from "antd/es/input/TextArea";
import {LedgerAccountEntity} from "../../../../domain/entity/ledger-account.entity";
import {useLedgerAccountRepository} from "../../../../domain/repository/ledger-account.repository";
import {SelectLedgerAccount} from "../../../component/dropdown/select.ledger-account";

export function EditManagerial(props: ModuleRenderProps) {
  const [form] = Form.useForm();
  const managerialService = useManagerialRepository();
  const [managerialAccount, setManagerialAccount] = useState<ManagerialAccountEntity>();
  const [managerialAccountMask, setManagerialAccountMask] = useState<string>('#.#');
  const [ledgerAccounts, setLedgerAccounts] = useState<LedgerAccountEntity[]>([]);
  const [isAnalytical, setIsAnalytical] = useState(false);

  const clientRepository = useClientRepository();
  const { id } = useParams<{id: string}>();
  const ledgerAccountRepo = useLedgerAccountRepository();


  const loadParams = useCallback(() => {
    ledgerAccountRepo.listAnalyticalAccounts().then(res => setLedgerAccounts(res.data));
    clientRepository.getSettings()
      .then(res => {
        setManagerialAccountMask(res.managerialAccountMask)
      });
  }, [ledgerAccountRepo, clientRepository])

  function loadFirm() {
    managerialService.find(id)
      .then(res => setManagerialAccount(res.data));
 }

  useEffect(() => {
    if (!isAnalytical) {
      form.resetFields(['ledgerAccountId'])
    }
  }, [isAnalytical])

  function checkIsAnalytical() {
    const code = form.getFieldValue('code');
    if (!code) {
      return;
    }
    setIsAnalytical(code.toString().trim().length === managerialAccountMask?.length)
  }

  function fillForm() {
    form.setFields([
      { name: 'description' , value: managerialAccount?.description },
      { name: 'code' , value: managerialAccount?.code },
      { name: 'active' , value: managerialAccount?.active },
      { name: 'ledgerAccountId' , value: managerialAccount?.ledgerAccountId },
      { name: 'type' , value: managerialAccount?.type },
      { name: 'help' , value: managerialAccount?.help },
    ])
    checkIsAnalytical();
  }

  useEffect(() => {
    loadParams()
  }, [])

  useEffect(() => {
    loadFirm()
  }, [id]);

  useEffect(() => {
    fillForm()
  }, [managerialAccount]);

  function onFinish(data: ManagerialAccountEntity) {
    data.code = data.code.split('.')
      .map(item => item.trim())
      .filter(w =>  w && !isNaN(Number(w.trim())))
      .join('.');
    managerialService.update(id, data).then(
      res => {
        form.resetFields();
        message.success(res.message);
        props.navigator.goToDefaultPage();
      }
    ).catch(err => {
      message.error(err.message);
      if(err.messageKey === ErrorMessagesEnum.DuplicatedCodeError) {
        form.setFields([{
          name: 'code',
          errors: [err.message]
        }])
      }
    })
  }

  return (
    <>
      <HeaderManagerialAccounts mode={'form'} title={' Conta Gerencial '} subtitle={'Edição de conta gerencial'}/>
      <Divider />
      <Form form={form} layout="vertical" name="nest-messages" onFinish={onFinish}>
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item name={['type']} label="Tipo" rules={[{ required: true }]}>
              <Radio.Group>
                {
                  ManagerialAccountTypeEnum.getValues().map(item => (
                    <Radio value={item.value} children={item.label} />
                  ))
                }
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item name={['description']} label="Descrição" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={['code']} label="Código" rules={[{required: true}]}>
              <MaskedInput
                mask={managerialAccountMask}
                onBlur={checkIsAnalytical}
                placeholderChar={' '}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <SelectLedgerAccount disabled={!isAnalytical} data={ledgerAccounts} required={false} />
          </Col>
        </Row>
        <Form.Item name={'help'} label={'Dica de Ajuda'}>
          <TextArea />
        </Form.Item>
        <Form.Item name={'active'} valuePropName={'checked'} >
          <Switch
            unCheckedChildren={'Inativo'}
            checkedChildren={'Ativo'}
            defaultChecked={true}
          />
        </Form.Item>
        <FormActionsButtons isLoading={managerialService.loading} />
      </Form>


    </>
  )
}
