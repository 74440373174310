import {Button, Col, Form, Input, Row, Select} from "antd";
import React, { useEffect, useState } from "react";
import {SearchOutlined} from "@ant-design/icons";
import { FinancialGroupEntity } from "../../../../domain/entity/financial-group.entity";
import { getSorter } from "../../../../core/util/sort-array.util";
import { useFinancialGroupRepository } from "../../../../domain/repository/financial-group.repository";

export type FilterListManagerialAccountProps = {
    onFilterGroup: (filter: string) => void,
}

export function FilterListFinancialClass(props: FilterListManagerialAccountProps) {
    const [form] = Form.useForm();
    const [groups, setGroup] = useState<FinancialGroupEntity[]>([]);
    const fields: Array<{value: string, label: string}> = [
        { value: 'groupId', label: 'Grupo' },
    ];

    const groupRepo = useFinancialGroupRepository();

    function onFilter(values: any) {
        if (values.field === 'groupId') {
            props.onFilterGroup(values.filter);
        }
    }

    useEffect(() => {
      groupRepo.findAll().then(res => setGroup(res.data));
    }, [1])

    function getGroupOpts() {
        return groups.sort(getSorter<FinancialGroupEntity>('code'))
          .map(item => ({
              label: `${item.code} - ${item.name}`,
              value: item.id,
          }))
    }

    return (
        <Form
            form={form}
            layout="horizontal"
            size={'middle'}
            onFinish={onFilter}
            initialValues={{ field: 'groupId' }}
        >
            <Row style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}} >
                <Col>
                    <Form.Item name={'field'}>
                        <Select size={'middle'} options={fields} />
                    </Form.Item>
                </Col>
                <Col span={6} >
                    <Form.Item name={'filter'}>
                        <Select
                          options={getGroupOpts()}
                          allowClear
                          onChange={(event) => props.onFilterGroup(event?.toString())}
                        />
                    </Form.Item>
                </Col>
                <Col>
                    <Form.Item>
                        <Button htmlType={'submit'} size={'middle'} icon={<SearchOutlined />}>
                            Pesquisar
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}