export class MonthsEnum {
    public static JANUARY = 1;
    public static FEBRUARY = 2;
    public static MARCH = 3;
    public static APRIL = 4;
    public static MAY = 5;
    public static JUNE = 6;
    public static JULY = 7;
    public static AUGUST = 8;
    public static SEPTEMBER = 9;
    public static OCTOBER = 10;
    public static NOVEMBER = 11;
    public static DECEMBER = 12;

    public static parseName(month: number) {
        switch (month) {
            case 1:
                return 'Janeiro';
            case 2:
                return 'Fevereiro';
            case 3:
                return 'Março';
            case 4:
                return 'Abril';
            case 5:
                return 'Maio';
            case 6:
                return 'Junho';
            case 7:
                return 'Julho';
            case 8:
                return 'Agosto';
            case 9:
                return 'Setembro';
            case 10:
                return 'Outubro';
            case 11:
                return 'Novembro';
            case 12:
                return 'Dezembro';
            default:
                return month;
        }
    }

    public static getValues() {
        return [
            { label: this.parseName(this.JANUARY), value: this.JANUARY },
            { label: this.parseName(this.FEBRUARY), value: this.FEBRUARY },
            { label: this.parseName(this.MARCH), value: this.MARCH },
            { label: this.parseName(this.APRIL), value: this.APRIL },
            { label: this.parseName(this.MAY), value: this.MAY },
            { label: this.parseName(this.JUNE), value: this.JUNE },
            { label: this.parseName(this.JULY), value: this.JULY },
            { label: this.parseName(this.AUGUST), value: this.AUGUST },
            { label: this.parseName(this.SEPTEMBER), value: this.SEPTEMBER },
            { label: this.parseName(this.OCTOBER), value: this.OCTOBER },
            { label: this.parseName(this.NOVEMBER), value: this.NOVEMBER },
            { label: this.parseName(this.DECEMBER), value: this.DECEMBER },
        ]
    }
}