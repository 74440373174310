import {MenuItem} from "../core/component/skeleton/skeleton.menu";
import {
    BankOutlined,
    CalculatorOutlined,
    ControlOutlined,
    DashboardFilled,
    DashboardOutlined,
    DatabaseOutlined,
    DeploymentUnitOutlined,
    EnterOutlined,
    FieldTimeOutlined,
    GroupOutlined,
    InsertRowRightOutlined,
    MergeCellsOutlined,
    MoneyCollectFilled,
    MoneyCollectOutlined,
    OrderedListOutlined, PicCenterOutlined,
    ProfileOutlined,
    ReadFilled,
    RiseOutlined,
    SecurityScanFilled,
    SettingOutlined,
    SplitCellsOutlined,
    TagsFilled,
    UserOutlined
} from "@ant-design/icons";

export function getAppMenu(prefix: string): MenuItem[] {
    return [
        {
            label: 'Parametrizações',
            icon: <SettingOutlined />,
            uri: '',
            resource: 'parameters',
            children: [
                {
                    label: 'Competência',
                    icon: <FieldTimeOutlined />,
                    resource: 'competence',
                    uri: 'competence',
                },
                {
                    label: 'Filiais',
                    icon: <InsertRowRightOutlined />,
                    resource: 'firm',
                    uri: 'firm',
                },
                {
                    label: 'Contas Gerenciais',
                    icon: <RiseOutlined />,
                    resource: 'managerial_account',
                    uri: 'managerial-account',
                },
                {
                    label: 'Contas Contábeis',
                    icon: <PicCenterOutlined />,
                    resource: 'accounting_account',
                    uri: 'ledger-account',
                },
                {
                    label: 'Centro de Custo',
                    icon: <ControlOutlined />,
                    resource: 'cost_center',
                    uri: 'cost-center',
                },
                {
                    label: 'Cat. Orçamentária',
                    icon: <TagsFilled />,
                    resource: 'budget_category',
                    uri: 'budget-category',
                },
                {
                    label: 'Unid. Fornecimento',
                    icon: <DeploymentUnitOutlined />,
                    resource: 'supply_unit',
                    uri: 'supply-unit',
                },
                {
                    label: 'Espec. Documento',
                    icon: <SplitCellsOutlined />,
                    resource: 'financial_document_type',
                    uri: 'financial-document-type',
                },
                {
                    label: 'Forma Pagameno',
                    icon: <MoneyCollectFilled />,
                    resource: 'payment_method',
                    uri: 'payment-method',
                },
                {
                    label: 'Grupo',
                    icon: <GroupOutlined />,
                    resource: 'financial_group',
                    uri: 'financial-group',
                },
                {
                    label: 'Classe',
                    icon: <GroupOutlined />,
                    resource: 'financial_class',
                    uri: 'financial-class',
                },
                {
                    label: 'Item',
                    icon: <GroupOutlined />,
                    resource: 'financial_item',
                    uri: 'financial-item',
                },
            ]
        },
        {
            label: 'Cadastros',
            icon: <DatabaseOutlined />,
            resource: 'operational',
            children: [
                {
                    label: 'Cliente / Fornecedor',
                    icon: <UserOutlined />,
                    resource: 'person',
                    uri: 'client-provider',
                },
                {
                    label: 'Contas Bancárias',
                    icon: <BankOutlined />,
                    resource: 'bank_account',
                    uri: 'bank-account',
                },
                {
                    label: 'Recorrências',
                    icon: <ControlOutlined />,
                    resource: 'recurrence',
                    uri: 'recurrence',
                },
            ]
        },
        {
            label: 'Operacional',
            icon: <MergeCellsOutlined />,
            resource: 'operational',
            children: [
                {
                    label: 'DRE',
                    icon: <OrderedListOutlined />,
                    resource: 'dre',
                    uri: 'dre-row',
                },
                {
                    label: 'Fluxo de Caixa',
                    icon: <MoneyCollectOutlined />,
                    resource: 'cash_flow',
                    uri: 'cash-flow',
                },
                {
                    label: 'Orçamento',
                    icon: <CalculatorOutlined />,
                    resource: 'budget',
                    uri: 'budget',
                },
                {
                    label: 'Lançamentos',
                    icon: <MoneyCollectFilled />,
                    resource: 'financial_entry',
                    uri: 'financial-entry',
                },
            ]
        },
        {
            label: 'Relatórios',
            icon: <DashboardFilled />,
            uri: 'report',
            resource: 'report',
            children: [
                {
                    label: 'DRE - Realizado',
                    icon: <DashboardOutlined />,
                    resource: 'report_dre_realized',
                    uri: 'report',
                },
                {
                    label: 'DRE - Orçada',
                    icon: <DashboardOutlined />,
                    resource: 'report_dre_budgeted',
                    uri: 'report/budgeted',
                },
                {
                    label: 'Fluxo de Caixa',
                    icon: <DashboardOutlined />,
                    resource: 'report_cash_flow',
                    uri: 'report/cash-flow',
                },
                {
                    label: 'Lançamentos com Retenção',
                    icon: <DashboardOutlined />,
                    resource: 'report_entries_with_retention',
                    uri: 'report/entries-with-retention',
                },
                {
                    label: 'Resultado / Centro de Custo',
                    icon: <DashboardOutlined />,
                    resource: 'report_result_by_cost_center',
                    uri: 'report/cash-flow-cost-center',
                },
            ]
        },
        {
            label: 'Adicional',
            icon: <ReadFilled />,
            resource: 'additional',
            children: [
                {
                    label: 'Integrações',
                    icon: <EnterOutlined />,
                    resource: 'integration',
                    uri: 'integration',
                },
            ]
        },
        {
            label: 'Segurança',
            icon: <SecurityScanFilled />,
            uri: 'profile',
            resource: 'operational',
            children: [
                {
                    label: 'Usuários',
                    icon: <UserOutlined />,
                    resource: 'user',
                    uri: 'users',
                },
                {
                    label: 'Perfil',
                    icon: <ProfileOutlined />,
                    resource: 'profile',
                    uri: 'profile',
                },
            ]
        },
    ]
}

