export type ImportEntity = {
  id: number;
  createdAt: string;
  statusImport: ImportStatusEnum;
  entity: string;
  messages: any
}

export enum ImportStatusEnum {
  PENDING = 'pending',
  PROGRESS = 'progress',
  FINISHED = 'finished',
}