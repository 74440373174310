import {Col, Divider, Form, Input, InputNumber, message, Row, Switch} from "antd";
import React, {useEffect, useState} from "react";
import {HeaderFinancialGroup} from "../component/header.financial-group";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {useParams} from "react-router-dom";
import {FinancialGroupEntity} from "../../../../domain/entity/financial-group.entity";
import {useFinancialGroupRepository} from "../../../../domain/repository/financial-group.repository";

export function EditFinancialGroup(props: ModuleRenderProps) {
    const [form] = Form.useForm();
    const [entity, setEntity] = useState<FinancialGroupEntity>();
    const financialGroupRepository = useFinancialGroupRepository();
    const { id } = useParams<{id: string}>();

    useEffect(() => {
        financialGroupRepository.find(id)
            .then(res => {
                setEntity(res.data);
            })
    }, [1]);

    useEffect(() => {
        if(entity) {
            form.setFields([
                { name: 'name', value: entity.name },
                { name: 'active', value: entity.active },
                { name: 'code', value: entity.code },
            ])
        }
    }, [entity]);


    function onFinish(data: any) {
        financialGroupRepository.update(id, data).then(res => {
            message.success(res.message);
            props.navigator.goToListPage();
        }).catch(err => {
            message.error(err.message);
        })
    }

    return (
        <>
            <HeaderFinancialGroup mode={'form'} />
            <Divider />
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Row gutter={12}>
                    <Col span={24}>
                        <Form.Item name={'name'} label="Nome" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={4}>
                        <Form.Item name={'active'} label="Status" valuePropName={'checked'} >
                            <Switch
                                checkedChildren={'Ativo'}
                                unCheckedChildren={'Inativo'}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <FormActionsButtons
                    onCancel={props.navigator.goToDefaultPage}
                    isLoading={financialGroupRepository.loading}
                />
            </Form>
        </>
    )
}
