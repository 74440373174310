export enum IntegrationParamEntityEnum {
  COST_CENTER = 'cost-center',
  PAYMENT_METHOD = 'payment-method',
  FINANCIAL_ITEM = 'financial-item',
  BUDGET_CATEGORY = 'budget-category',
  SUPPLY_UNITY = 'supply-unity',
  FIRM = 'firm',
  FINANCIAL_DOCUMENT_TYPE = 'document-type',
  PERSON = 'person',
  BANK_ACCOUNT = 'bank-account',
}
