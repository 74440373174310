import {Col, Form, Input, InputNumber, message, Radio, Row, Select, Switch} from "antd";
import React, {useEffect, useState} from "react";
import {DreRowEntity, DreRowType} from "../../../../domain/entity/dre-row.entity";
import {useDRERowRepository} from "../../../../domain/repository/dre-row.repository";
import {useHistory} from "react-router-dom";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";

export function EditDreRow(props: { dreRowId: number, navigator: any, onEdited: () => void }) {
    const [form] = Form.useForm();
    const [isRowResult, setRowResult] = useState(false);
    const [type, setType] = useState<string>();
    const [dreRows, setDreRows] = useState<DreRowEntity[]>([]);
    const dreRowRepository = useDRERowRepository();
    const history = useHistory();
    const { dreRowId } = props;

    useEffect(() => {
        loadRows();
    }, [1])

    useEffect(() => { loadEditData() }, [dreRowId]);

    function loadEditData() {
        if (!dreRowId) {
            return;
        }
        dreRowRepository.find(dreRowId.toString())
            .then(res => {
                form.setFields([
                    { name: 'name' , value: res.data.name },
                    { name: 'isRowResult' , value: res.data.isRowResult },
                    { name: 'type' , value: res.data.type },
                    { name: 'order' , value: res.data.order },
                    { name: 'parentRowId' , value: res.data.parentRowId },
                    { name: 'columnsToCalc' , value: res.data.columnsToCalc },
                ])
                setRowResult(res.data.isRowResult);
            })
    }

    function loadRows() {
        dreRowRepository.search().then(res => setDreRows(res.data));
    }


    function onFinish(values: any) {
        values = {
            ...values,
            isRowResult
        }
        dreRowRepository.update(dreRowId.toString(), values)
            .then(res => {
                message.success(res.message);
                history.goBack();
                props.onEdited();
            })
    }

    function getParentRowsOptions() {
        return dreRows.filter(w => w.isRowResult).map(item => ({
            value: item.id,
            label: item.name
        }));
    }

    function getTypeOptions() {
        return [
            { value: DreRowType.DEDUCTION , label: "Dedução" },
            { value: DreRowType.REVENUE , label: "Receita" },
            { value: DreRowType.CALC , label: "Cálculada", disabled: true },
        ]
    }

    function getResultRowOpts() {
        return [
            { value: true, label: 'Linha de Resultado' },
            { value: false, label: 'Linha de Lançamento' },
        ]
    }

    return (
        <Form form={form} layout="vertical" onFinish={onFinish}>
            <Row gutter={12}>
                <Col span={24}>
                    <Form.Item label="Nome" name={'name'} style={{width: '100%'}} rules={[{ required: true }]}>
                        <Input style={{width: '100%'}} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item label="Tipo da Linha" required name={'type'}>
                        <Radio.Group
                            options={getTypeOptions()}
                            onChange={(event) => setType(event.target.value)} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                {
                    (form.getFieldValue('type') == DreRowType.CALC) && (
                        <Col span={24}>
                            <Form.Item label="Selecione as Linhas para cálculo" required name={'columnsToCalc'}>
                                <Select mode={'multiple'} options={getParentRowsOptions()}  />
                            </Form.Item>
                        </Col>
                    )
                }
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item name={'isRowResult'} label={'Linha de Resultado'} rules={[{required: true}]}>
                        <Radio.Group disabled options={getResultRowOpts()} onChange={event => setRowResult(event.target.value)} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                {
                    (!isRowResult) && (
                        <Col span={24}>
                            <Form.Item label="Selecione a Linha Pai" required name={'parentRowId'}>
                                <Select disabled options={getParentRowsOptions()}  />
                            </Form.Item>
                        </Col>
                    )
                }
            </Row>
            <FormActionsButtons
                onCancel={props.navigator.goToDefaultPage}
                isLoading={dreRowRepository.loading}
            />
        </Form>
    )
}