import {Divider, message, Select, Table} from "antd";
import {HeaderProfile} from "../component/header.profile";
import React, {useEffect, useState} from "react";
import {useDateTimeUtil} from "../../../../core/util/date-time.util";
import {useProfileRepository} from "../../../../domain/repository/profile.repository";
import {ProfileEntity} from "../../../../domain/entity/profile.entity";
import {ListActions} from "../../../../core/component/list-actions";
import { deserialize } from "node:v8";

export function ListProfiles(props: any) {
    const dateTimeUtil = useDateTimeUtil();
    const profileRepository = useProfileRepository();
    const [data, setData] = useState<ProfileEntity[]>([]);

    function loadData() {
        profileRepository.search()
            .then(res => setData(res.data));
    }

    function removeProfile(id: string) {
        profileRepository.remove(id)
            .then(res => {
                message.success(res.message);
                loadData();
            }).catch(err => {
                message.error(err.message);
        })
    }

    useEffect(() => loadData(), []);

    return (
        <>
            <HeaderProfile/>
            <Divider />
            <Table
                columns={[
                    {
                        title: 'Nome',
                        dataIndex: 'name',
                        key: 'name',
                    },
                    {
                        title: 'Descrição',
                        dataIndex: 'description',
                    },
                    {
                        title: 'Apelido',
                        dataIndex: 'alias',

                    },
                    {
                        dataIndex: 'id',
                        render: (val) => (
                            <ListActions
                                onEditClick={() => props.navigator.goToEditPage(val) }
                                onRemoveClick={() => removeProfile(val) }
                            />
                        )
                    },
                ]}
                dataSource={data}
            />
        </>
    )
}
