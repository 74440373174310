import {Checkbox, Col, Form, Input, message, Radio, Row, Select} from "antd";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import React, {useEffect, useState} from "react";
import {DreRowEntity, DreRowType} from "../../../../domain/entity/dre-row.entity";
import {useDRERowRepository} from "../../../../domain/repository/dre-row.repository";
import {useHistory} from "react-router-dom";
import {dreRowSort} from "../service/dre-row.sort";

export type CreateDreRow = {
    onCreated: () => any,
    navigator: any,
}

export function CreateDrePage(props: CreateDreRow) {
    const [form] = Form.useForm();
    const [type, setType] = useState<any>();
    const [isRowResult, setRowResult] = useState(false);
    const [continueEditing, setContinueEditing] = useState(false);
    const [dreRows, setDreRows] = useState<DreRowEntity[]>([]);
    const dreRowRepository = useDRERowRepository();
    const history = useHistory();

    useEffect(() => {
        loadRows();
    }, [1])

    useEffect(() => {
        form.setFields([ { name: 'isRowResult' , value: true } ])
        setRowResult(true);
    }, [type])

    function loadRows() {
        dreRowRepository.search().then(res => setDreRows(res.data));
    }

    function onFinish(values: any) {
        values = {
            ...values,
            isRowResult
        }
        if (!isRowResult) {
            if (!values.parentRowId) {
                form.setFields([{ name: 'parentRowId', errors: ['Campo Obrigatório']}])
                return;
            }
        }
        dreRowRepository.create(values)
            .then(res => {
                props.onCreated();
                message.success(res.message);
                loadRows();
                form.resetFields();
                if(!continueEditing) {
                    history.goBack();
                }
            }).catch(err => {
                message.error(err.message)
            })
    }

    function getParentRowsOptions() {
        return dreRows.filter(w => w.isRowResult).sort(dreRowSort).map(item => ({
                value: item.id,
                label: `${item.order} - ${item.name}`
            }));
    }

    function getTypeOptions() {
        return [
            { value: DreRowType.DEDUCTION , label: "Dedução" },
            { value: DreRowType.REVENUE , label: "Receita" },
            { value: DreRowType.CALC , label: "Calculada" },
        ]
    }

    function getResultRowOpts() {
        return [
            { value: true, label: 'Linha de Resultado' },
            { value: false, label: 'Linha de Lançamento', disabled: type === DreRowType.CALC || getParentRowsOptions().length === 0 },
        ]
    }

    return (
        <Form form={form} layout="vertical" onFinish={onFinish}>
            <Row gutter={12}>
                <Col span={24}>
                    <Form.Item label="Nome" name={'name'} style={{width: '100%'}} rules={[{ required: true }]}>
                        <Input style={{width: '100%'}} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item label="Tipo da Linha" required name={'type'}>
                        <Radio.Group
                            options={getTypeOptions()}
                            onChange={(event) => setType(event.target.value)}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                {
                    (form.getFieldValue('type') == DreRowType.CALC) && (
                        <Col span={24}>
                            <Form.Item label="Selecione as Linhas para cálculo" required name={'columnsToCalc'}>
                                <Select mode={'multiple'} options={getParentRowsOptions()}  />
                            </Form.Item>
                        </Col>
                    )
                }
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item name={'isRowResult'} label={'Linha de Resultado'} rules={[{required: true}]}>
                        <Radio.Group options={getResultRowOpts()} onChange={event => setRowResult(event.target.value)} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                {
                    (!isRowResult) && (
                        <Col span={24}>
                            <Form.Item label="Selecione a Linha Pai" required name={'parentRowId'}>
                                <Select options={getParentRowsOptions()}  />
                            </Form.Item>
                        </Col>
                    )
                }
            </Row>
            <Form.Item>
                <Checkbox
                    onChange={(event) => setContinueEditing(event.target.checked)}
                    checked={continueEditing}
                /> Continuar Cadastrando.
            </Form.Item>

            <FormActionsButtons
                onCancel={props.navigator.goToDefaultPage}
                isLoading={dreRowRepository.loading}
            />
        </Form>
    )
}