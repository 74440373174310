import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {HeaderFinancialEntry} from "../component/header.financial-entry";
import {Steps} from "antd";
import {Content} from "antd/es/layout/layout";
import React, {CSSProperties, useEffect, useState} from "react";
import {FormProvisionEntry} from "../component/form.provision-entry";
import {FinancialEntryEntity} from "../../../../domain/entity/financial-entry.entity";
import {FormFinancialEntryItem} from "../component/form.financial-entry-item";
import {FinancialEntryItemEntity} from "../../../../domain/entity/financial-entry-item.entity";
import {ResultFinancialEntry} from "../component/result.financial-entry";
import {useParams} from "react-router-dom";
import {useFinancialEntryRepository} from "../../../../domain/repository/financial-entry.repository";

export function SaveFinancialProvision(props: ModuleRenderProps) {
  const [step, setStep] = useState<number>(0);
  const [entry, setEntry] = useState<FinancialEntryEntity>();
  const [items, setItems] = useState<FinancialEntryItemEntity[]>([]);
  const { id } = useParams<{ id: any }>();

  const financialEntryRepo = useFinancialEntryRepository();

  useEffect(() => {
    console.log({ id })
    if (id) {
      financialEntryRepo
        .find(id)
        .then(res => {
          setEntry(res.data)
          setItems(res.data?.items ?? [])
        });
    }
  }, [id])

  function ShowContent() {
    switch (step) {
      case 0:
        return (
          <FormProvisionEntry
            entry={entry}
            setEntry={setEntry}
            onFinish={() => setStep(1)}
          />
        )
      case 1:
        return !entry ? null : (
          <FormFinancialEntryItem
            entry={entry}
            items={items}
            onBack={() => setStep(0)}
            setItems={setItems}
            onFinish={() => setStep(2)}
          />
        )
      case 2:
        return !entry ? null : (
          <ResultFinancialEntry
            entry={entry}
            onFinish={() => props.navigator.goToCustomPage('?step=2')}
          />
        )
    }
    return null;
  }

  return (
    <>
      <HeaderFinancialEntry title={'Nova Provisão'} subtitle={''} mode={'form'} />
      <br />
      <Steps current={step} onChange={step => setStep(step)}>
        <Steps.Step title={'Dados Iniciais'} />
        <Steps.Step title={'Itens do Lançamento'} disabled={step < 1} />
        <Steps.Step title={'Finalizar'}  disabled={step < 4} />
      </Steps>
      <Content style={stepContent}>
        <ShowContent/>
      </Content>
    </>
  )
}


const stepContent: CSSProperties = {
  minHeight: '200',
  marginTop: '16px',
  paddingTop: '15px',
  borderRadius: '2px'
}