import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {HeaderRecurrenceEntry} from "../component/header.recurrence-entry";
import React, {useEffect, useState} from "react";
import {RecurrenceEntryEntity} from "../../../../domain/entity/recurrence-entry.entity";
import {useRecurrenceEntryRepository} from "../../../../domain/repository/recurrence-entry.repository";
import {useParams} from "react-router-dom";
import {Divider, message} from "antd";
import {ListRecurrenceEntryItem} from "../component/list.recurrence-entry-item";
import {FormRecurrenceEntryItem} from "../component/form.recurrence-entry-item";

export function FormRecurrenceItem(props: ModuleRenderProps) {
  const [recurrence, setRecurrence] = useState<RecurrenceEntryEntity>();
  const { id: recurrenceEntryId } = useParams<{ id: any }>();

  const recurrenceEntryRepo = useRecurrenceEntryRepository();

  function loadRecurrenceEntry() {
    recurrenceEntryRepo.find(recurrenceEntryId).then(res => setRecurrence(res.data));
  }

  function removeRecurrenceEntryId(id: string) {
    recurrenceEntryRepo.removeItem(id).then(res => {
      message.success(res.message);
      loadRecurrenceEntry();
    }).catch(err => {
      message.error(err.message);
    })
  }

  useEffect(() => {
    loadRecurrenceEntry();
  }, [recurrenceEntryId])

  return (
    <>
      <HeaderRecurrenceEntry
        mode={'form'}
        title={recurrence?.name}
        subtitle={'Gestâo de Items da Recorrencia'}
      />
      <Divider />
      {
        recurrence && (
          <FormRecurrenceEntryItem
            onSaved={loadRecurrenceEntry}
            recurrenceEntry={recurrence}
          />
        )
      }
      <ListRecurrenceEntryItem
        loading={recurrenceEntryRepo.loading}
        removeItem={removeRecurrenceEntryId}
        items={recurrence?.items ?? []}
      />
    </>
  )
}

