import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {useEffect, useState} from "react";
import {IntegrationEntity} from "../../../../domain/entity/integration.entity";
import {useIntegrationRepository} from "../../../../domain/repository/integration.repository";
import {useParams} from "react-router-dom";
import {HeaderIntegration} from "../component/header-integration";
import {Button, Col, Divider, List, Row} from "antd";
import {Content} from "antd/es/layout/layout";
import {IntegrationParamEntity} from "../../../../domain/entity/integration-param.entity";
import {BoxPlotOutlined} from "@ant-design/icons";
import {FormConfigProxyComponent} from "../component/form-config-proxy.component";
import {FormConfigDefaultValueComponent} from "../component/form-config-default-value.component";

export function ConfigIntegration(props: ModuleRenderProps) {
  const [integration, setIntegration] = useState<IntegrationEntity>();
  const [param, setParam] = useState<IntegrationParamEntity>();

  const { id: integrationId } = useParams<{ id: string }>();
  const integrationRepo = useIntegrationRepository();

  function loadIntegration(onLoad?: (integration: IntegrationEntity) => void) {
    integrationRepo
      .find(integrationId)
      .then(res => {
        onLoad && onLoad(res.data);
        setIntegration(res.data)
      });
  }

  useEffect(() => {
    loadIntegration();
  }, [integrationId]);

  function ConfigForm() {
    if (!param) {
      return null;
    }

    const loadParam = (integration: IntegrationEntity) => {
      setParam(integration.params?.find(i => i.id == param?.id))
    }

    if (param.hasDefaultValue) {
      return <FormConfigDefaultValueComponent param={param} onSaved={() => loadIntegration(loadParam)} />
    }

    return <FormConfigProxyComponent param={param} onSaved={() => loadIntegration(loadParam)} />
  }

  return (
    <>
      <HeaderIntegration />
      <Divider />
      <Content>
        <Row gutter={12}>
          <Col span={8}>
            <List
              dataSource={integration?.params ?? []}
              renderItem={(item: IntegrationParamEntity) => (
                <List.Item
                  actions={[param?.id != item.id ? <Button size={'small'} onClick={() => setParam(item)}>Configurar</Button> : null]}
                >
                  <List.Item.Meta
                    avatar={<BoxPlotOutlined />}
                    title={item.name}
                    description={item.hasDefaultValue ? 'Valor Padrão' : 'Valor Configurado'}
                  />
                </List.Item>
              )}
            />
          </Col>
          <Col span={16}>
            <ConfigForm />
          </Col>
        </Row>
      </Content>
    </>
  )
}