import {AppPageHeader} from "../../../../core/component/page-header";
import {useHistory, useRouteMatch} from 'react-router-dom';
import React, {ReactNode} from "react";

export type HeaderSupplyUnitsProps = {
    createRoute?: string;
    mode?: 'list' | 'form';
    title?: string,
    subtitle?:string,
    extra?: ReactNode[]
}

export function HeaderSupplyUnit(props: HeaderSupplyUnitsProps) {
    const urlMatch = useRouteMatch();
    const history = useHistory();


    function getCreateLink(): string {
        return `${urlMatch.url}/create`;
    }

    function getListLink(): string {
        return `list`;
    }
    return (
        <>
            <AppPageHeader
                title={props.title ?? 'Unidades de Fornecimento'}
                mode={props.mode}
                subtitle={props.subtitle ?? 'Gestão das unidades de fornecimento do Sistema'}
                onCreateLink={getCreateLink()}
                onListLink={getListLink()}
                onBack={() => history.goBack()}
                onCreateLabel={'Adicionar'}
                extra={props.extra}
            />
        </>
    );

}