import {Button, Col, Form, List, message, Row, Select} from "antd";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import React, {useEffect, useState} from "react";
import {FinancialDocumentTypeTaxEntity} from "../../../../domain/entity/financial-document-type-tax.entity";
import {TaxEntity} from "../../../../domain/entity/tax.entity";
import {useTaxRepository} from "../../../../domain/repository/tax.repository";
import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {useParams} from "react-router-dom";
import {useFinancialDocumentTypeTaxRepository} from "../../../../domain/repository/financial-document-type-tax.repository";
import {useFinancialDocumentTypeRepository} from "../../../../domain/repository/financial-document-type.repository";
import {DeleteOutlined} from "@ant-design/icons";

export function FormDocumentTypeTax(props: ModuleRenderProps) {
  const [form] = Form.useForm();
  const [documentTypeTaxes, setDocumentTypeTaxes] = useState<FinancialDocumentTypeTaxEntity[]>([]);
  const [taxes, setTaxes] = useState<TaxEntity[]>([]);
  const { id: financialDocumentTypeId } = useParams<{ id: string }>();

  const taxRepo = useTaxRepository();
  const documentTypeTaxRepo = useFinancialDocumentTypeTaxRepository();
  const documentTypeRepo = useFinancialDocumentTypeRepository();

  function loadDocTaxes() {
    if (financialDocumentTypeId) {
      documentTypeRepo.find(financialDocumentTypeId).then(res => {
        setDocumentTypeTaxes(res.data?.taxes ?? []);
      });
    }
  }

  useEffect(() => {
    taxRepo.findAll().then(res => setTaxes(res.data));
    loadDocTaxes();
  }, [financialDocumentTypeId])

  function getTaxesOpts() {
    const idsDocTaxes = documentTypeTaxes.map(item => item.tax?.id);
    return taxes
      .filter(item => !idsDocTaxes.includes(item.id))
      .sort((a,b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      }).map(item => ({
        label: `${item.name} - ${item.alias}`,
        value: item.id
      }))
  }

  function onFinish(values: any) {
    values = {
      ...values,
      financialDocumentTypeId,
    }
    documentTypeTaxRepo.create(values).then(res => {
      message.success(res.message);
      loadDocTaxes();
      form.resetFields();
    }).catch(err => {
      message.error(err.message);
    })
  }

  function onRemove(id: number) {
    documentTypeTaxRepo.remove(id.toString()).then(res => {
      message.success(res.message);
      loadDocTaxes();
    }).catch(err => {
      message.error(err.message);
    })
  }

  return (
    <Form form={form} layout={'vertical'} onFinish={onFinish}>
      <Row gutter={42}>
        <Col span={12}>
          <Form.Item name={'taxId'} label={'Imposto'}>
            <Select options={getTaxesOpts()} />
          </Form.Item>
          <FormActionsButtons
            onCancel={props.navigator.goToDefaultPage}
            isLoading={documentTypeTaxRepo.loading}
            label={'Adicionar'}
          />
        </Col>
        <Col span={12}>
          <List
            loading={documentTypeTaxRepo.loading}
            dataSource={documentTypeTaxes}
            renderItem={(item: FinancialDocumentTypeTaxEntity) => {
              return (
                <List.Item actions={[<Button size={'small'} icon={<DeleteOutlined />} onClick={() => onRemove(item.id)} />]}>
                  <List.Item.Meta title={`${item.tax?.name} - ${item.tax?.alias}`} />
                </List.Item>
              )
            }}
          />
        </Col>
      </Row>
    </Form>
  )
}