import {useRouteMatch} from "react-router-dom";
import {ModuleResource, ModuleResourceTypeEnum, ModuleRoot} from "../../../core/component/module/module.root";
import React from "react";
import {ListIntegration} from "./pages/list.integration";
import {ConfigIntegration} from "./pages/config.integration";
import {ImportIntegration} from "./pages/import.integration";

export function IntegrationModule() {
    const routeMatch = useRouteMatch();
    const resources: ModuleResource[] = [
        {
            type: ModuleResourceTypeEnum.CUSTOM,
            customPath: 'config/:id',
            render: (props) => <ConfigIntegration {...props} />
        },
        {
            type: ModuleResourceTypeEnum.CUSTOM,
            customPath: 'import/:id',
            render: (props) => <ImportIntegration {...props} />
        },
        {
            type: ModuleResourceTypeEnum.DEFAULT,
            render: (props) => <ListIntegration {...props} />
        },
    ]

    return (
        <ModuleRoot
            resource={routeMatch.url}
            moduleResources={resources}
        />
    );
}
