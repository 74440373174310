import {AppPageHeader} from "../../../../core/component/page-header";
import {useHistory, useRouteMatch} from 'react-router-dom';
import React, {ReactNode} from "react";

export type HeaderBudgetProps = {
  createRoute?: string;
  mode?: 'list' | 'form';
  title?: string,
  subtitle?:string,
  extra?: ReactNode[]
}

export function HeaderFinancialEntry(props: HeaderBudgetProps) {
  const urlMatch = useRouteMatch();
  const history = useHistory();


  function getCreateLink(): string {
    return `${urlMatch.url}/create`;
  }

  function getListLink(): string {
    return `list`;
  }
  return (
    <>
      <AppPageHeader
        title={props.title ?? 'Lançamentos'}
        mode={props.mode}
        subtitle={props.subtitle ?? 'Gestão dos fluxo de caixa'}
        onCreateLink={getCreateLink()}
        onListLink={getListLink()}
        onBack={() => history.goBack()}
        onCreateLabel={'Adicionar'}
        extra={props.extra}
      />
    </>
  );

}