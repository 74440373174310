import { Form, InputNumber } from "antd";
import React, { CSSProperties } from "react";

export type BrlInputNumberProps = {
  label: string,
  name: string | string[] | Array<number | string>,
  required: boolean,
  help?: string,
  onBlur?: (value: number) => void,
  onChange?: (value: number) => void,
  onKeyUp?: (value: number) => void,
  style?: CSSProperties,
  size?: 'small' | 'middle' | 'large',
  addOnAfter?: string
  readOnly?: boolean,
  disabled?: boolean,
}

export function BrlInputNumber(props: BrlInputNumberProps) {
  const { label, name, required, help} = props;
  const decimalFormatter = Intl.NumberFormat('pt-BR', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })

  const style = {
    ...props.style,
  }

  const onFormatter = (value?: number) => {
    if (!value) {
      return '';
    }
    return decimalFormatter.format(value);
  }

  const onParser = (value?: string) => {
    if (!value) {
      return 0;
    }
    const isNegative = parseFloat(value) < 0;
    const onlyDigits = value
      .split("")
      .filter((s: string) => /\d/.test(s))
      .join("")
      .padStart(3, "0");
    const digitsFloat = onlyDigits.slice(0, -2) + "." + onlyDigits.slice(-2);
    return parseFloat(`${isNegative ? '-' : ''}${digitsFloat}`);
  }

  const onBlur = (value: any) => props.onBlur ? props.onBlur(value) : null;
  const onKeyUp = (value: any) => props.onKeyUp ? props.onKeyUp(value) : null;

  return (
    <Form.Item
      label={label}
      name={name}
      style={style}
      rules={[{ required }]}
      help={help}
    >
      <InputNumber
        style={{width: '100%'}}
        size={props.size}
        maxLength={13}
        stringMode
        onChange={props.onChange}
        formatter={onFormatter}
        parser={onParser}
        readOnly={props.readOnly}
        lang={'pt_BR'}
        disabled={props.disabled}
        onBlur={onBlur}
        onKeyUp={onKeyUp}
      />
    </Form.Item>
  )
}