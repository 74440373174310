import {useHistory, useRouteMatch} from "react-router-dom";
import {AppPageHeader} from "../../../../core/component/page-header";
import React from "react";
import {Button} from "antd";
import {DownloadOutlined} from "@ant-design/icons";
import {useDRERowRepository} from "../../../../domain/repository/dre-row.repository";

export type HeaderDreRowProps = {
    onCreateLink: string,
}

export function HeaderDreRow(props: any) {
    const urlMatch = useRouteMatch();
    const history = useHistory();
    const dreRowRepo = useDRERowRepository();

    function getCreateLink(): string {
        return `${urlMatch.url}/create`;
    }

    function getListLink(): string {
        return `${urlMatch.url}/list`;
    }
    return (
        <>
            <AppPageHeader
                title={'DRE'}
                subtitle={'Configuração da DRE'}
                onCreateLink={getCreateLink()}
                onListLink={getListLink()}
                onBack={() => history.goBack()}
                onCreateLabel={'Adicionar Linha'}
                extra={[
                  <Button
                    shape={'round'}
                    icon={<DownloadOutlined />}
                    onClick={() => dreRowRepo.downloadDRECSV()}
                  >
                      Exportar DRE
                  </Button>,
                  <Button
                    shape={'round'}
                    icon={<DownloadOutlined />}
                    onClick={() => dreRowRepo.downloadDReleaseParams()}
                  >
                      Exportar Parâmetrização
                  </Button>
                ]}
            />
        </>
    );
}