import { BaseResponse, BaseSearchResponse, SearchArgs, usePlaneduHttpService } from "../service/planedu.http.service";
import {FinancialEntryEntity} from "../entity/financial-entry.entity";
import {FinancialEntryTaxEntity} from "../entity/financial-entry-tax.entity";
import {SaveEntryInstallmentsDto} from "../dto/save-entry-installments.dto";
import {FinancialEntryInstallmentEntity} from "../entity/financial-entry-installment.entity";
import {DreRowDto} from "../dto/dre-row.dto";
import {QueryParam} from "../../core/service/http.service";
import { FinancialEntryApportionmentEntity } from "../entity/financial-entry-apportionment.entity";
import {FinancialEntryEventEntity} from "../entity/financial-entry-event.entity";
import {FinancialEntryRenegotiationEntity} from "../entity/financial-entry-renegotiation.entity";
import {FinancialEntryWithRetentionDto} from "../dto/financial-entry-with-retention.dto";

export function useFinancialEntryRepository() {
  const httpService = usePlaneduHttpService<FinancialEntryEntity>('financial-entry');

  function saveFinancialEntryTax(values: { taxId: number, value: number}[], financialEntryId: number) : Promise<BaseResponse<FinancialEntryTaxEntity[]>> {
    return httpService.put(`${httpService.resourceName}/taxes`, JSON.stringify({ values, financialEntryId }));
  }

  function saveFinancialEntryInstallments(
    financialEntryId: number,
    paymentCondition: string,
    installments: SaveEntryInstallmentsDto[]
  ) : Promise<BaseResponse<FinancialEntryInstallmentEntity[]>> {
    return httpService.put(`${httpService.resourceName}/installments`, JSON.stringify({
      financialEntryId, paymentCondition, installments
    }));
  }

  function writeOffEntryInstallment(
    installmentId: number,
    data: {
      paymentData: string,
      paymentMethodId: number,
      effectiveDate: string
    }
  ): Promise<BaseResponse<FinancialEntryInstallmentEntity>> {
    return httpService.put(
      `${httpService.resourceName}/installment/${installmentId}/write-off`,
      JSON.stringify(data)
    );
  }

  function revertWriteOffInstallment(
    installmentId: number,
    args: {
      reason: string,
      dateTimeRevertPayment: Date,
    }
  ): Promise<BaseResponse<FinancialEntryInstallmentEntity>> {
    return httpService.post(
      `${httpService.resourceName}/installment/${installmentId}/revert-write-off`,
      JSON.stringify(args)
    )
  }

  function listInstallments(
    args: {
      filter?: {
        financialEntryId?: number;
        initialPaymentDate?: any;
        finalPaymentDate?: any;
        personId?: any;
        entryType: string;
      },
      limit: number,
      offset: number,
      export?: boolean,
    }
  ): Promise<BaseSearchResponse<FinancialEntryInstallmentEntity>> {
    const queryParams: QueryParam[] = [];
    for (const key of Object.keys(args.filter ?? {})) {
      // @ts-ignore
      queryParams.push({key, value: args.filter[key]});
    }
    queryParams.push( ...[
      { key: `limit`, value: args.limit },
      { key: `offset`, value: args.offset },
    ])
    if (args.export) {
      queryParams.push({ key: 'export', value: 1 });
      console.log({ queryParams });
      return httpService.downloadRequest(
        'get',
        `${httpService.resourceName}/installments`,
        'baixas-pendentes.xlsx',
        undefined,
        queryParams
      );
    }
    return httpService.get(
      `${httpService.resourceName}/installments`,
      undefined,
      queryParams
    );
  }

  function getDREReport(
    competenceId: number,
    firmId?: number,
    costCenterId?: number,
    downloadCsv?: boolean
  ): Promise<BaseResponse<DreRowDto[]>> {
    const queryParams: QueryParam[] = [];
    if (firmId) queryParams.push({ key: 'firmId' , value: firmId });
    if (costCenterId) queryParams.push({ key: 'costCenterId' , value: costCenterId });
    if (downloadCsv) queryParams.push({ key: 'transform', value: 'xlsx'});

    if (downloadCsv) {
      return httpService.downloadRequest(
        'get',
        `${httpService.resourceName}/dre/competence/${competenceId}`,
        'dre-report.xlsx',
        undefined,
        queryParams,
      );
    }
    return httpService
      .request(
        'get',
        `${httpService.resourceName}/dre/competence/${competenceId}`,
        undefined,
        queryParams,
        undefined,
      );
  }

  function exportEntries(initialDate: string, finalDate: string) {
    const params: QueryParam[] = [];
    params.push({ key: 'startDateTimeEntry', value: initialDate });
    params.push({ key: 'endDateTimeEntry', value: finalDate });
    return httpService.downloadRequest(
      'get',
      `${httpService.resourceName}/export`,
      'entries.xlsx',
      undefined,
      params,
      undefined
    )
  }

  function addProvisionEntry(
    values: { firmId: number, costCenterId: number, dateTimeEntry: string }
  ): Promise<BaseResponse<FinancialEntryEntity>> {
    return httpService.post(
      `${httpService.resourceName}/provision`,
      JSON.stringify(values),
      undefined,
      undefined
    )
  }

  function listProvision(args?: SearchArgs): Promise<BaseSearchResponse<FinancialEntryEntity>> {
    const queryParams = httpService.getUriWithSearchArgs(args ?? {});
    return httpService.get(
      `${httpService.resourceName}/provision?${queryParams}`,
      undefined,
      undefined,
      undefined
    )
  }


  function saveApportionment(
    entryId: number,
    apportionment: Array<{ costCenterId: number, amount: number }>
  ): Promise<BaseResponse<FinancialEntryApportionmentEntity[]>> {
    return httpService.put(
      `${httpService.resourceName}/apportionment`,
      JSON.stringify({ entryId, apportionment }),
    );
  }

  function removeApportionment(entryId: number): Promise<BaseResponse<FinancialEntryApportionmentEntity>> {
    return httpService.delete(
      `${httpService.resourceName}/${entryId}/apportionment`,
    )
  }

  async function cancelEntry(
      entryId: number,
      reason: string
  ): Promise<BaseResponse<FinancialEntryEntity>> {
    return httpService.post(
      `${httpService.resourceName}/${entryId}/cancel`,
      JSON.stringify({reason}),
    );
  }

  function listEvents(
    args: {
      limit: number,
      offset: number,
      orderByDirection: string,
      orderByField: string,
      dateTime?: string,
      entryId?: number,
      events?: string[],
      entities?: string[]
    }
  ): Promise<BaseSearchResponse<FinancialEntryEventEntity>> {
    const params: QueryParam[] = [];
    const { limit, events, entryId, entities, dateTime, offset, orderByField, orderByDirection } = args;
    params.push({ key: 'offset', value: offset });
    params.push({ key: 'limit', value: limit });
    dateTime && params.push({ key: 'dateTime', value: dateTime });
    entryId && params.push({ key: 'entryId', value: entryId });
    params.push({ key: 'orderByDirection', value: orderByDirection });
    params.push({ key: 'orderByField', value: orderByField });
    events?.length && params.push({ key: 'events', value: events ? events.join(',') : undefined });
    entities?.length && params.push({ key: 'entities', value: entities ? entities.join(',') : undefined });
    return httpService.get(
      `financial-entry-event`,
      undefined,
      params
    )
  }

  function renegotiateInstallments(
    entryId: number,
    installmentsToCancelIds: number[],
    installmentsRenegotiated: Array<{
      order: number;
      value: number;
      paymentMethodId: number;
      dueDate: any;
      interestAmount: number;
      fineAmount: number;
    }>
  ): Promise<BaseResponse<any>> {
    return httpService.post(
      `${httpService.resourceName}/${entryId}/renegotiate-installments`,
      JSON.stringify({
        installmentsToCancelIds,
        installmentsRenegotiated
      })
    )
  }

  function listRenegotiation(
    entryId: number
  ): Promise<BaseResponse<FinancialEntryRenegotiationEntity[]>> {
    return httpService.get(`${httpService.resourceName}/${entryId}/renegotiation`);
  }

  function listEntriesWithRetention(args: {
    startDate: Date,
    endDate: Date,
    limit: number,
    offset: number,
    typeDate: string,
    typeEntry?: string,
  }): Promise<BaseSearchResponse<FinancialEntryWithRetentionDto>> {
    return httpService.get(
      `${httpService.resourceName}/with-retention`,
      null,
      [
        { key: 'startDate', value: args.startDate.toISOString() },
        { key: 'endDate', value: args.endDate.toISOString() },
        { key: 'limit', value: args.limit },
        { key: 'offset', value: args.offset },
        { key: 'typeDate', value: args.typeDate },
        { key: 'typeEntry', value: args.typeEntry ?? '' },
      ]
    )
  }

  function exportEntriesWithRetention(args: {
    startDate: Date,
    endDate: Date,
    limit: number,
    offset: number,
    typeDate: string,
    typeEntry?: string,
  }): Promise<BaseSearchResponse<FinancialEntryWithRetentionDto>> {
    return httpService.downloadRequest(
      'get',
      `${httpService.resourceName}/with-retention`,
      'entries-with-retention.xlsx',
      null,
      [
        { key: 'export', value: 1 },
        { key: 'startDate', value: args.startDate.toISOString() },
        { key: 'endDate', value: args.endDate.toISOString() },
        { key: 'limit', value: args.limit },
        { key: 'offset', value: args.offset },
        { key: 'typeDate', value: args.typeDate },
        { key: 'typeEntry', value: args.typeEntry ?? '' },
      ]
    )
  }

  return {
    ...httpService,
    saveFinancialEntryTax,
    saveFinancialEntryInstallments,
    writeOffEntryInstallment,
    listInstallments,
    getDREReport,
    exportEntries,
    addProvisionEntry,
    listProvision,
    saveApportionment,
    removeApportionment,
    cancelEntry,
    listEvents,
    renegotiateInstallments,
    listRenegotiation,
    revertWriteOffInstallment,
    listEntriesWithRetention,
    exportEntriesWithRetention
  }
}