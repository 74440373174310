import React from 'react';
import {Table} from "antd";

export function ProfilesPage(props: any) {
    return (
        <Table
            columns={[
                {
                    title: 'Nome',
                    dataIndex: 'alias',
                },
                {
                    title: 'Descrição',
                    dataIndex: 'description',
                },
            ]}

            dataSource={[
                { alias: 'ROLE_ADMIN' , description: 'Administrador de Sisema' },
                { alias: 'ROLE_USER' , description: 'Operador do Sistema' },
            ]}
        />
    )
}
