import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {useParams} from "react-router-dom";
import {useFinancialEntryRepository} from "../../../../domain/repository/financial-entry.repository";
import {useEffect, useState} from "react";
import {FinancialEntryEntity} from "../../../../domain/entity/financial-entry.entity";
import {FormWriteOff} from "../component/form.write-off";
import {InstallmentsEntry} from "../component/show-full-financial-entry/installments.entry";

export function WriteOffEntryInstallment(props: ModuleRenderProps) {
  const [entry, setEntry] = useState<FinancialEntryEntity>();
  const { id: entryId } = useParams<{ id: string }>();

  const entryRepo = useFinancialEntryRepository();

  function loadParams() {
    entryId && entryRepo.find(entryId).then(res => setEntry(res.data));
  }

  useEffect(() => {
    loadParams();
  }, [1]);

  return (
    <>
      { entryId && <FormWriteOff entryId={entryId} onSaved={() => loadParams()} /> }
      { entry && <InstallmentsEntry entry={entry} onInstallmentCanceled={loadParams} /> }
    </>
  )
}