import {Col, Divider, Form, Input, Row, Switch, message} from "antd";
import React, {useEffect, useState} from "react";
import {HeaderBudgetCategory} from "../component/header.budget-category";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {useBudgetCategoryRepository} from "../../../../domain/repository/budget-category.repository";
import {useParams} from "react-router-dom";
import {BudgetCategoryEntity} from "../../../../domain/entity/budget-category.entity";

export function EditBudgetCategory(props: ModuleRenderProps) {
    const [form] = Form.useForm();
    const [entity, setEntity] = useState<BudgetCategoryEntity>();
    const budgetCategoryRepository = useBudgetCategoryRepository();
    const { id } = useParams<{id: string}>();

    useEffect(() => {
        budgetCategoryRepository.find(id)
            .then(res => {
                setEntity(res.data);
            })
    }, [1]);

    useEffect(() => {
        if(entity) {
            form.setFields([
                { name: 'name', value: entity.name },
                { name: 'active', value: entity.active },
            ])
        }
    }, [entity]);


    function onFinish(data: any) {
        budgetCategoryRepository.update(id, data).then(res => {
            message.success(res.message);
            props.navigator.goToListPage();
        }).catch(err => {
            message.error(err.message);
        })
    }

    return (
        <>
            <HeaderBudgetCategory mode={'form'} />
            <Divider />
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Row gutter={12}>
                    <Col span={12}>
                        <Form.Item name={'name'} label="Nome" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'active'} label="Status" valuePropName={'checked'} >
                            <Switch
                                checkedChildren={'Ativo'}
                                unCheckedChildren={'Inativo'}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <FormActionsButtons
                    onCancel={props.navigator.goToDefaultPage}
                    isLoading={budgetCategoryRepository.loading}
                />
            </Form>
        </>
    )
}
