import {useRouteMatch} from "react-router-dom";
import {ModuleResource, ModuleResourceTypeEnum, ModuleRoot} from "../../../core/component/module/module.root";
import React from "react";
import {ListCompetence} from "./pages/list.competence";
import {CreateCompetence} from "./pages/create.competence";
import {EditCompetence} from "./pages/edit.competence";

export function CompetenceModule() {
    const routeMatch = useRouteMatch();
    const resources: ModuleResource[] = [
        {
            type: ModuleResourceTypeEnum.LIST,
            render: (props) => <ListCompetence {...props} />
        },
        {
            type: ModuleResourceTypeEnum.CREATE,
            render: (props) => <CreateCompetence {...props} />
        },
        {
            type: ModuleResourceTypeEnum.EDIT,
            render: (props) => <EditCompetence {...props} />
        },
        {
            type: ModuleResourceTypeEnum.DEFAULT,
            render: (props) => <ListCompetence {...props} />
        },
    ]

    return (
        <ModuleRoot
            resource={routeMatch.url}
            moduleResources={resources}
        />
    );
}
