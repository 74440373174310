import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {HeaderFinancialEntry} from "../component/header.financial-entry";
import {useParams} from "react-router-dom";
import {useCallback, useEffect, useState} from "react";
import {FinancialEntryEntity} from "../../../../domain/entity/financial-entry.entity";
import {useFinancialEntryRepository} from "../../../../domain/repository/financial-entry.repository";
import {RenegotiateFormComponent} from "../component/renegotiate-form";

export function RenegotiateInstallments(props: ModuleRenderProps) {
  const [entry, setEntry] = useState<FinancialEntryEntity>();
  const { id: entryId } = useParams<any>();
  const entryRepo = useFinancialEntryRepository();

  const loadEntry = useCallback((entryId: string | number) => {
    entryRepo.find(entryId.toString())
      .then(res => setEntry(res.data));
  }, [entryRepo]);

  useEffect(() => {
    entryId && loadEntry(entryId);
  }, [entryId])

  return (
    <>
      <HeaderFinancialEntry
        title={`Renegociar Parcelas Lançamento ${entryId}`}
        subtitle={'Selecione apenas as parcelas em aberto para a renegociação'}
        mode={'form'}
      />
      <br />
      {
        entry && (
          <RenegotiateFormComponent
            entry={entry}
            onFinish={props.navigator.goToListPage}
          />
        )
      }
    </>
  )
}