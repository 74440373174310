import React from "react";
import {Route, Switch} from "react-router-dom";
import {Layout} from "antd";
const { Content } = Layout;

export type AppModule = {
    path: string,
    exact?: boolean,
    render: () => any
}

export type SkeletonContentProps = {
    modules: AppModule[];
}

export function SkeletonContent(props: SkeletonContentProps) {
    const { modules } = props;

    return (
        <Content
            className="site-layout-background"
            style={{
                margin: '24px 16px',
                padding: 24,
                minHeight: 280,
            }}
        >
            <Switch>
                {
                    modules.map((module, key) => (
                        <Route {...module} key={key}  />
                    ))
                }
            </Switch>

        </Content>
    )
}
