import {Col, Form, Row, Select} from "antd";
import {useBudgetRepository} from "../../../../domain/repository/budget.repository";
import React, {useEffect, useState} from "react";
import {BudgetEntity} from "../../../../domain/entity/budget.entity";
import {useCompetenceRepository} from "../../../../domain/repository/competence.repository";
import {CompetenceEntity} from "../../../../domain/entity/competence.entity";
import {DreRowDto} from "../../../../domain/dto/dre-row.dto";
import {DreList} from "../../../component/dre-list";

export function ShowDre(props: any) {
    const [budgets, setBudgets] = useState<BudgetEntity[]>([]);
    const [budgetId, setBudgetId] = useState<number>();
    const [dreRowResult, setDreRowResults] = useState<Array<DreRowDto>>([]);
    const [competencies, setCompetencies] = useState<CompetenceEntity[]>([]);
    const [competence, setCompetence] = useState<number>()

    const budgetRepo = useBudgetRepository();
    const competenceRepo = useCompetenceRepository();

    useEffect(() => {
        competenceRepo.search().then(res =>  setCompetencies(res.data));
    }, [1]);

    useEffect(() => {
        if (!competence) return;
        budgetRepo.getActiveVersions(competence).then(res => {
            setBudgets(res.data);
        }) }, [competence]);

    useEffect(() => {
        if (budgetId) {
            budgetRepo.getDreResult(budgetId).then(res => {
                setDreRowResults(res.data);
            })
        }
    }, [budgetId])


    function getBudgetsOpts() {
        return budgets.map(item => ({
            label: `${item.version}`,
            value: item.id as number
        }))
    }

    function getCompetencesOpts() {
        return competencies.map(item => ({
            label: item.year,
            value: item.id as number
        }));
    }

    return (
        <>
            <Row gutter={12}>
                <Col span={12}>
                    <Form.Item name={'competenceId'} label={'Competência'} rules={[{ required: true }]}>
                        <Select size={'middle'} onSelect={val => setCompetence(val as number)} options={getCompetencesOpts()}  />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name={'budgetId'} label={'Versão'} rules={[{ required: true }]}>
                        <Select size={'middle'} onSelect={val => setBudgetId(val as number)} options={getBudgetsOpts()} />
                    </Form.Item>
                </Col>
            </Row>
            {
                budgetId && <DreList data={dreRowResult} />
            }
        </>
    )
}