import {Badge, Button, message, Modal, Table, Tag, Typography} from "antd";
import {ColumnsType} from "antd/es/table";
import {FinancialItemEntity} from "../../../../domain/entity/financial-item.entity";
import {FinancialEntryItemEntity} from "../../../../domain/entity/financial-entry-item.entity";
import {useNumberFormatter} from "../../../service/number-formatter.service";
import {useFinancialEntryItemService} from "../../../../domain/service/financial-entry-item.service";
import {DeleteOutlined} from "@ant-design/icons";
import { useFinancialEntryItemRepository } from "../../../../domain/repository/financial-entry-item.repository";
import {useState} from "react";
import {ShowFullFinancialItem} from "../../financial-item/pages/show-full.financial-item";


export type ListFinancialEntryItemProps = {
    data?: FinancialEntryItemEntity[],
    onRemoved?: () => void
}


export function ListFinancialEntryItem(props: ListFinancialEntryItemProps) {
    const numberFormatter = useNumberFormatter();
    const [itemModel, setItemModel] = useState<number>();
    const { calcFinalValue, calcTotalValue } = useFinancialEntryItemService();
    const financialEntryItemRepo = useFinancialEntryItemRepository();

    function removeItem(id: string) {
        financialEntryItemRepo.remove(id).then(res => {
            message.success(res.message);
            props.onRemoved && props.onRemoved()
        }).catch(err => {
            message.error(err.message);
        })
    }

    function renderFinalValue(item: FinancialEntryItemEntity) {
        const value = calcFinalValue(
          item.singleValue,
          item.quantity,
          item.discount,
        );
        return numberFormatter.currencyFormatter.format(value);
    }

    function calcTotalValues() {
        let { data } = props;
        if (!data || !data.length) {
            return 0;
        }
        let result = calcTotalValue(data);
        return numberFormatter.currencyFormatter.format(result);
    }

    const columns: ColumnsType<FinancialEntryItemEntity> = [
        {
            title: 'Item',
            dataIndex: 'financialItem',
            key: 'financialItem',
            render: (item: FinancialItemEntity) => (
              <Button
                size={'small'}
                shape={'round'}
                onClick={() => setItemModel(item.id)}
              >
                  {item?.description}
              </Button>
            )
        },
        {
            title: 'Valor Unitário',
            dataIndex: 'singleValue',
            key: 'singleValue',
            render: val => numberFormatter.currencyFormatter.format(val)
        },
        {
            title: 'Quantidade',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Desconto',
            dataIndex: 'discount',
            key: 'discount',
            render: val => numberFormatter.currencyFormatter.format(val)
        },
        {
            title: 'Valor Líquido',
            dataIndex: 'id',
            key: 'id',
            render: (val: number, entity) => renderFinalValue(entity)
        },
    ]

    if (props.onRemoved) {
        columns.push(
          {
              dataIndex: 'id',
              key: 'id',
              render: (val: string) => (
                <Button onClick={() => removeItem(val)} size={'small'} icon={<DeleteOutlined />} />
              )
          }
        )
    }

    return (
      <>
          <Table
            columns={columns}
            dataSource={props.data ?? []}
            pagination={false}
            footer={() => (
              <Typography.Text
                style={{textAlign: 'right', display: 'block', fontWeight: 'bold'}}
              >
                  Total: {calcTotalValues()}
              </Typography.Text>
            )}
          />
          {
            itemModel && (
              <Modal
                width={800}
                visible={true}
                onCancel={() => setItemModel(undefined)}
                footer={null}
                bodyStyle={{padding: 0}}
              >
                  <ShowFullFinancialItem itemId={itemModel} />
              </Modal>
            )
          }
      </>

    )
}