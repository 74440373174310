import messagesPtBr from './../../resource/message/pt_br.json';

export function useMessageTranslateService(locale: string = 'pt_br') {

    function translate(message: string) {
        if (messagesPtBr.hasOwnProperty(message)) {
            // @ts-ignore
            return messagesPtBr[message];
        }

        return message;
    }

    return {
        translate
    }
}