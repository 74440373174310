import {RecurrenceEntryEntity} from "../../../../domain/entity/recurrence-entry.entity";
import {Col, Divider, Form, Input, message, Radio, Row, Typography} from "antd";
import React, {useEffect, useState} from "react";
import {CostCenterEntity} from "../../../../domain/entity/cost-center.entity";
import {FirmEntity} from "../../../../domain/entity/firm.entity";
import {PersonEntity} from "../../../../domain/entity/person.entity";
import {useRecurrenceEntryRepository} from "../../../../domain/repository/recurrence-entry.repository";
import {usePersonRepository} from "../../../../domain/repository/person.repository";
import {useFirmRepository} from "../../../../domain/repository/firm.repository";
import {SelectFirm} from "../../../component/dropdown/select.firm";
import {SelectCostCenter} from "../../../component/dropdown/select.cost-center";
import {SelectPerson} from "../../../component/dropdown/select.person";
import {DatePickerPtBr} from "../../../../core/component/date-picker-ptbr";
import {SelectRecurrencePeriod} from "./select.recurrence-period";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import moment from "moment";

export type FormRecurrenceEntryProps = {
  recurrenceEntry?: RecurrenceEntryEntity,
  mode: 'create' | 'edit',
  onFinish: (recurrence: RecurrenceEntryEntity) => any,
  onCancel: () => any,
}

export function FormRecurrenceEntry(props: FormRecurrenceEntryProps) {
  const [form] = Form.useForm();
  const [costCenters, setCostCenters] = useState<CostCenterEntity[]>([]);
  const [firms, setFirms] = useState<FirmEntity[]>([]);
  const [persons, setPersons] = useState<PersonEntity[]>([]);

  const { recurrenceEntry } = props;
  const recurrenceEntryRepo = useRecurrenceEntryRepository();
  const personRep = usePersonRepository();
  const firmRepo = useFirmRepository();


  useEffect(() => {
    if (recurrenceEntry) {
      form.setFields([
        { name: 'name', value: recurrenceEntry.name },
        { name: 'description', value: recurrenceEntry.description },
        { name: 'typeEntry', value: recurrenceEntry.typeEntry },
        { name: 'periodRecurrence', value: recurrenceEntry.periodRecurrence },
        { name: 'contractType', value: recurrenceEntry.contractType },
        { name: 'personId', value: recurrenceEntry.personId },
        { name: 'costCenterId', value: recurrenceEntry.costCenterId },
        { name: 'firmId', value: recurrenceEntry.firmId },
        {
          name: 'dateTimeBeginContract',
          value: moment(recurrenceEntry.dateTimeBeginContract)
        },
        {
          name: 'dateTimeBeginContract',
          value: recurrenceEntry.dateTimeBeginContract ?
            moment(recurrenceEntry.dateTimeBeginContract) : undefined
        },
        {
          name: 'dateTimeEndContract',
          value: recurrenceEntry.dateTimeEndContract ?
            moment(recurrenceEntry.dateTimeEndContract) : undefined
        },
        {
          name: 'firstDueDate',
          value: moment(recurrenceEntry.firstDueDate)
        },
      ])
      loadCostCenters();
    }
  }, [recurrenceEntry])

  function onFinish(data: any) {
    function send() {
      if (props.mode == 'create') {
        return recurrenceEntryRepo.create(data);
      }
      return recurrenceEntryRepo
        .update(props.recurrenceEntry?.id.toString() ?? '', data);
    }

    send().then(res => {
        message.success(res.message);
        props.onFinish(res.data);
      }).catch(err => {
      message.error(err.message);
    })
  }

  function loadCostCenters() {
    const firmId = form.getFieldValue('firmId');
    if (!firmId) return;
    firmRepo.listCostCenters(firmId, true).then(res => setCostCenters(res.data));
  }

  useEffect(() => {
    firmRepo.findAll().then(res => setFirms(res.data));
    personRep.findAll().then(res => setPersons(res.data));
  }, [1]);

  return (
    <>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Row gutter={12}>
          <Col span={6}>
            <Form.Item name={'typeEntry'} label={'Tipo Lançamento'} rules={[ { required: true }]}>
              <Radio.Group>
                <Radio value={'D'}>Despesa</Radio>
                <Radio value={'R'}>Receita</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={6}>
            <SelectFirm data={firms} onChange={loadCostCenters} />
          </Col>
          <Col span={6}>
            <SelectCostCenter data={costCenters} />
          </Col>
          <Col span={6}>
            <SelectPerson data={persons} />
          </Col>
        </Row>
        <Divider />
        <Row gutter={12}>
          <Col span={6}>
            <Form.Item name={'name'} label={'Nome'} rules={[{ required: true }]} help={'Identificação da Recorrência'}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name={'description'} label={'Descrição'} >
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <SelectRecurrencePeriod />
          </Col>
          <Col span={4}>
            <Form.Item name={'contractType'} label={'Tipo Contrato'} rules={[ { required: true }]}>
              <Radio.Group>
                <Radio.Button value={'v'}>Variável</Radio.Button>
                <Radio.Button value={'f'}>Fixo</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={8}>
            <DatePickerPtBr
              label={'Inicio da Recorrència'}
              name={'dateTimeBeginContract'}
              required={true}
            />
          </Col>
          <Col span={8}>
            <DatePickerPtBr
              label={'Fim da Recorrència'}
              name={'dateTimeEndContract'}
              required={false}
            />
          </Col>
          <Col span={8}>
            <DatePickerPtBr
              label={'Primeiro Vencimento'}
              name={'firstDueDate'}
              required={false}
            />
          </Col>
        </Row>
        <FormActionsButtons
          onCancel={props.onCancel}
          isLoading={recurrenceEntryRepo.loading}
        />
      </Form>
    </>
  )
}