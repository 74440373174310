import {useDocumentValidator} from "../../../../core/service/document.validator";

export function useDocumentPrefix(document: string) {

    const docValidator = useDocumentValidator();

    function getPrefix() {
        const rawPrefix: string = document.substr(0, 8) as string;
        let formattedPrefix = '';
        for (let c = 0; c < 8; c++) {
            if (c === 2 || c === 5) {
                formattedPrefix += '.';
            }
            formattedPrefix += rawPrefix[c];
        }
        return formattedPrefix;
    }


    function validateDocument(value: string, onError: (error: string) => void) {
        value = `${getPrefix()}${value}`;
        if (!docValidator.isValidCNPJ(value)) {
            onError('Documento inválido');
        }
    }

    return {
        getPrefix,
        validateDocument
    }
}