import {MenuFoldOutlined, MenuUnfoldOutlined} from "@ant-design/icons";
import {Layout} from "antd";
import React, {CSSProperties} from "react";
import {ProfileButton} from "../user-button";
import {ClientButton} from "../client-button";
import {RoleButton} from "../role-button";
import {NotificationButton} from "../notification-button";

const { Header } = Layout;

export type SkeletonHeaderProps = {
    setCollapsed: (val: boolean) => void,
    collapsed: boolean,
}

export function SkeletonHeader(props: SkeletonHeaderProps) {
    const { collapsed, setCollapsed } = props;

    return (
        <Header className="site-layout-background" style={{ padding: 0 }}>
            <div style={navBar}>
                <div style={{flex: 1, paddingLeft: 20}}>
                    <div style={{justifySelf: 'start'}}>
                        {
                            collapsed ? <MenuUnfoldOutlined className={'trigger'} onClick={() => setCollapsed(false)} /> :
                                <MenuFoldOutlined className={'trigger'} onClick={() => setCollapsed(true)} />
                        }
                    </div>
                </div>
                <div style={navActions}>
                    <NotificationButton />
                    <RoleButton />
                    <ClientButton />
                    <ProfileButton />
                </div>

            </div>

        </Header>
    )
}

const navBar: CSSProperties = {
    width: '100%',
    height: '100%' ,
    display: 'flex',
    alignItems: 'center',
}

const navActions: CSSProperties = {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 25
}
