import {Col, Divider, Form, Input, message, Row, Switch} from "antd";
import {useCostCenterCrud} from "../service/cost-center.crud";
import {useClientRepository} from "../../../../domain/repository/client.repository";
import React, {useEffect, useState} from "react";
import {CostCenterEntity} from "../../../../domain/entity/cost-center.entity";
import {HeaderAccount} from "../component/header.account";
import MaskedInput from "antd-mask-input";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import {useParams} from "react-router-dom";
import {useCodeValidator} from "../service/code.validator";
import {ErrorMessages} from "../resources/error-messages";

export function EditCostCenter(props: any) {
    const [form] = Form.useForm();
    const costCenterCrud = useCostCenterCrud();
    const clientRepository = useClientRepository();
    const [costCenterMask, setCostCenterMask] = useState<string>('');
    const { id } = useParams<{id: string}>();
    const validator = useCodeValidator();

    useEffect(() => {
        clientRepository.getSettings()
            .then(res => setCostCenterMask(res.costCenterMask));
    }, [1])

    useEffect(() => { loadData() }, [id]);

    function loadData() {
        costCenterCrud.find(id).then(res => {
            form.setFields([
                { name: 'name', value: res.data.name },
                { name: 'code', value: res.data.code },
                { name: 'costCenterOfResult', value: res.data.costCenterOfResult },
                { name: 'active', value: res.data.active },
                { name: 'acceptUpdate', value: res.data.acceptUpdate },
                { name: 'externalKey', value: res.data.meta?.externalKey },
            ])
        })
    }

    function checkCode(value: string) {
        if(validator.isCodeCompleted(costCenterMask, value)) {
            form.setFields([{
                name: 'acceptUpdate', value: true
            }])
        } else  {
            form.setFields([{
                name: 'acceptUpdate', value: false
            }])
        }
    }

    function onFinish(data: CostCenterEntity) {
        costCenterCrud.update(id, data).then(
            res => {
                form.resetFields();
                message.success(res.message)
                props.navigator.goToListPage();
            }
        ).catch(err => {
            switch (err.messageKey) {
                case ErrorMessages.DUPLICATED_COST_CENTER:
                case ErrorMessages.INVALID_COD_FORMAT:
                    form.setFields([{
                        name: 'code',
                        errors: [err.message]
                    }])
                    break;
                default:
                    message.error(err.message);
                    break;
            }
        })
    }


    return (
        <>
            <HeaderAccount mode={'form'} />
            <Divider />
            <Form form={form} layout="vertical" name="nest-messages" onFinish={onFinish}>
                <Row gutter={12}>
                    <Col span={12}>
                        <Form.Item name={['name']} label="Nome" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name={'code'} label="Código" rules={[{required: true}]}>
                            {
                                costCenterMask
                                    ? <MaskedInput mask={costCenterMask} onBlur={event => checkCode(event.target.value)} />
                                    : <Input />
                            }
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name={'externalKey'} label="Chave de Integração" >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={42}>
                    <Col>
                        <Form.Item label={'Lançamentos'} name={'acceptUpdate'} valuePropName={'checked'} >
                            <Switch
                              checkedChildren={'Permitir lançamentos neste Centro de Custo'}
                              unCheckedChildren={'Não Permitir lançamentos neste Centro de Custo'}
                            />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label={'Centro de Custo de Resultado'} name={'costCenterOfResult'} valuePropName={'checked'}>
                            <Switch
                                unCheckedChildren={'Não'}
                                checkedChildren={'Sim'}
                            />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item name={'active'} valuePropName={'checked'} label={'Situação'}>
                            <Switch
                                checkedChildren={'Ativo'}
                                unCheckedChildren={'Inativo'}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <FormActionsButtons
                    onCancel={props.navigator.goToDefaultPage}
                    isLoading={costCenterCrud.loading}
                />
            </Form>
        </>
    )
}