import {Button, Checkbox, Col, Descriptions, Divider, Drawer, List, message, Modal, Row, Tag, Typography} from "antd";
import {HeaderRecurrenceEntry} from "../component/header.recurrence-entry";
import React, {ReactNode, useEffect, useState} from "react";
import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {RecurrenceEntryEntity} from "../../../../domain/entity/recurrence-entry.entity";
import {useRecurrenceEntryRepository} from "../../../../domain/repository/recurrence-entry.repository";
import {Route, Switch, useHistory, useParams, useRouteMatch} from "react-router-dom";
import {useDateTimeUtil} from "../../../../core/util/date-time.util";
import {RecurrenceToParseDto} from "../../../../domain/dto/recurrence-to-parse.dto";
import {recurrencePeriodTranslate} from "../service/recurrence-period.translate";
import {FormGenerateEntry} from "../component/form.generate-entry";
import {ShowFullFinancialEntry} from "../../financial-entry/component/show-full-financial-entry";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {RecurrenceEntryParsedEntity} from "../../../../domain/entity/recurrence-entry-parsed.entity";

export function ParseRecurrenceEntry(props: ModuleRenderProps) {
  const [entryId, setEntryId] = useState<number>()
  const [recurrenceEntryParsed, setRecurrenceEntryParsed] = useState<RecurrenceEntryParsedEntity>();
  const [showFullHistory, setShowFullHistory] = useState(false);
  const [recurrence, setRecurrence] = useState<RecurrenceEntryEntity>();
  const [titleModal, setTitleModal] = useState('Gerar Movimento')
  const [recsToParse, setRecsToParse] = useState<RecurrenceToParseDto[]>([]);
  const { id: recurrenceEntryId } = useParams<any>();

  const { convertToDatePtBr } = useDateTimeUtil();
  const recurrenceRepo = useRecurrenceEntryRepository();
  const routeMatch = useRouteMatch();
  const history = useHistory();
  const queryParams = new URLSearchParams(window.location.search);
  const dueDateParam = queryParams.get('dueDate');
  const currentDateQuery = dueDateParam ? new Date(dueDateParam) : null

  function loadData() {
    recurrenceEntryId && recurrenceRepo
      .find(recurrenceEntryId)
      .then(res => setRecurrence(res.data));
    recurrenceEntryId && recurrenceRepo
      .listRecToParse(recurrenceEntryId)
      .then(res => setRecsToParse(res.data))
  }

  useEffect(() => {
    loadData();
  }, [recurrenceEntryId])

  function getDiffInDays(_dt: Date) {
    const dt = new Date(_dt)
    const diffTime: any = Math.abs(dt.getTime() - Date.now());
    return (diffTime / (1000 * 60 * 60 * 24)).toFixed(0);
  }

  function GenerateEntryButton(props: { recurrenceEntryId: number, dueDate: Date }) {
    const onClick = () => history.push(
      `${routeMatch.url}/generate-entry?dueDate=${props.dueDate.toString()}&recurrenceEntryId=${recurrenceEntryId}`
    )
    return (
      <Button
        size={'middle'}
        shape={'round'}
        onClick={onClick}
      >
        Gerar Movimento
      </Button>
    )
  }

  function ShowEntryButton(props: { financialEntryId: number }) {
    return (
      <Button
        size={'middle'}
        shape={'round'}
        onClick={() => setEntryId(props.financialEntryId)}
      >
        Ver Movimento
      </Button>
    )
  }

  function renderRecItem(item: RecurrenceToParseDto) {
    const action: ReactNode[] = [];
    let subTitle = 'asd';
    let title: ReactNode = '';
    const setSubtitleExpired = () => {
      const diff = getDiffInDays(item.duaDate);
      if (diff === '0') {
        subTitle = `Vencendo Hoje`;
      } else {
        subTitle = `Vencido a ${diff} dia(s)`;
      }
    }
    const setSubtitleGenerated = () => {
      subTitle = `Referente à ${convertToDatePtBr(item.duaDate)}`;
    }
    const setSubtitleWillExpire = () => {
      subTitle = `Vence em ${getDiffInDays(item.duaDate)} dias`;
    }
    const setTitleGenerated = () => {
      title = `Movimento ${item.financialEntryId} Gerado`;
    }
    const setTitleExpired = () => title = (
      <Typography.Text type={'danger'}>
        Recorrência vencida em {convertToDatePtBr(item.duaDate)}
      </Typography.Text>
    )
    const setTitleWillExpire = () => title = (
      title = (
        <Typography.Text >
          Gerar Movimento Referente à {convertToDatePtBr(item.duaDate)}
        </Typography.Text>
      )
    )

    function processInfo(){
      if (item.financialEntryId) {
        setSubtitleGenerated();
        setTitleGenerated();
        action.push(
          <ShowEntryButton financialEntryId={item.financialEntryId} />
        )
      } else {
        action.push(
          <GenerateEntryButton
            recurrenceEntryId={item.recurrenceEntryId}
            dueDate={item.duaDate}
          />
        )
        if (new Date(item.duaDate).getTime() < Date.now()) {
          setSubtitleExpired();
          setTitleExpired();
        } else {
          setSubtitleWillExpire();
          setTitleWillExpire();
        }
      }
    }

    processInfo();
    return (
      <List.Item actions={action}>
        <List.Item.Meta
          title={title}
          description={subTitle}
        />
      </List.Item>
    )
  }

  function getData() {
    if (showFullHistory) {
      return recsToParse;
    } else {
      return recsToParse.filter(w => !w.financialEntryId)
    }
  }

  function HeaderList() {
    return (
      <div>
          <Typography.Title level={4}>
            Historico de Lançamentos
          </Typography.Title>
          <Checkbox
            checked={showFullHistory}
            onChange={e => setShowFullHistory(e.target.checked)}
          >
            Exibir Histórico Completo
          </Checkbox>
      </div>
    )
  }

  function onParseCancel() {
    if (!recurrenceEntryParsed) {
      return history.goBack();
    }
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      content: 'Caso voce prossiga, todas as alterações feitas serão revertidas',
      onOk() {
        recurrenceEntryParsed && recurrenceRepo
          .cancelParsed(recurrenceEntryParsed?.id)
          .then((res) => {
            message.success(res.message)
            loadData();
            history.goBack()
          });
      },
      onCancel() {

      },
    });
  }

  return (
    <>
      <HeaderRecurrenceEntry
        title={`Lançar Recorrência: ${recurrence?.name}`}
        subtitle={`Conversão de Recorrências`}
        mode={'form'}
      />
      <Divider/>
      <Descriptions bordered layout={'vertical'} column={5}>
        <Descriptions.Item label={'Inicio da Recorrência'}>
          {recurrence && convertToDatePtBr(recurrence.dateTimeBeginContract)}
        </Descriptions.Item>
        <Descriptions.Item label={'Fim da Recorrência'}>
          {recurrence?.dateTimeEndContract && convertToDatePtBr(recurrence.dateTimeEndContract)}
        </Descriptions.Item>
        <Descriptions.Item label={'Primeiro Vencimento'}>
          {recurrence && convertToDatePtBr(recurrence.firstDueDate)}
        </Descriptions.Item>
        <Descriptions.Item label={'Periodo Recorrencia'}>
          {recurrence && recurrencePeriodTranslate(recurrence.periodRecurrence)}
        </Descriptions.Item>
        <Descriptions.Item label={'Status'}>
          {
            recurrence?.canceled ? (
              <Tag color={'red'}>Cancelado</Tag>
            ) : (
              <Tag>Ativo</Tag>
            )
          }
        </Descriptions.Item>
      </Descriptions>
      <br />
      <Row gutter={24}>
        <Col span={24}>
          <List
            header={<HeaderList />}
            extra={[<Button>Ocultar Convertidas</Button>]}
            loading={recurrenceRepo.loading}
            dataSource={getData()}
            renderItem={renderRecItem}
            pagination={{pageSize: 5}}
          />
        </Col>
      </Row>
      <Switch>
        <Route path={`${routeMatch.path}/generate-entry`}>
          <Modal
            title={titleModal}
            visible={true}
            width={'80%'}
            centered={true}
            footer={null}
            onCancel={onParseCancel}
          >
            {
              currentDateQuery && (
                <FormGenerateEntry
                  recurrenceEntryId={recurrenceEntryId}
                  setStepTitle={setTitleModal}
                  dueDateTime={currentDateQuery}
                  onBindEntry={(entity) => {
                    setRecurrenceEntryParsed(entity);
                    loadData()
                  }}
                  onFinish={() => {
                    loadData();
                    history.goBack();
                  }}
                />
              )
            }
          </Modal>
        </Route>
      </Switch>

      {
        entryId && (
          <Drawer
            placement={'bottom'}
            height={550}
            visible={true}
            bodyStyle={{padding: 0}}
            onClose={() => setEntryId(undefined)}
          >
            <ShowFullFinancialEntry entryId={entryId} />
          </Drawer>
        )
      }
    </>
  )
}