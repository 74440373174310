import {Steps} from "antd";
import React, {CSSProperties, useState} from "react";
import {HeaderClientProvider} from "../component/header.client-provider";
import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {Content} from "antd/es/layout/layout";
import {FormPerson} from "../component/form.person";
import {PersonEntity} from "../../../../domain/entity/person.entity";
import {FormPersonIndividual} from "../component/form.person-individual";
import {FormPersonAddress} from "../component/form.person-address";
import {PersonAddressEntity} from "../../../../domain/entity/person-address.entity";
import {PersonBankDataEntity} from "../../../../domain/entity/person-bank-data.entity";
import {FormPersonBankData} from "../component/form.person-bank-data";
import {usePersonRepository} from "../../../../domain/repository/person.repository";
import {FormPersonCompany} from "../component/form.person-company";
import {StepConfirm} from "../component/step.confirm";
import {PersonCompanyEntity} from "../../../../domain/entity/person-company.entity";
import {PersonIndividualEntity} from "../../../../domain/entity/person-individual.entity";

export function CreateClientProvider(props: ModuleRenderProps) {
    const [step, setStep] = useState(0);
    const [person, setPerson] = useState<PersonEntity>();
    const [companyPerson, setCompanyPerson] = useState<PersonCompanyEntity>();
    const [individualPerson, setIndividualPerson] = useState<PersonIndividualEntity>();
    const [personAddress, setPersonAddress] = useState<PersonAddressEntity>();
    const [personBankData, setPersonBankData] = useState<PersonBankDataEntity>();

    const personRepo = usePersonRepository();

    function loadPerson() {
        person && personRepo.find(person.id.toString()).then(res => {
            setPerson(res.data);
        })
    }

    function onFinishFormPerson(_person: PersonEntity) {
        setPerson({...person, ..._person});
        setStep(1);
    }

    function onFinishFormCompany(personCompany: PersonCompanyEntity | undefined) {
        setCompanyPerson(personCompany);
        setStep(step + 1);
    }

    function onFinishFormPersonIndividual(individualPerson: PersonIndividualEntity | undefined) {
        setIndividualPerson(individualPerson)
        setStep(step + 1);
    }

    function onFinishFormPersonAddress(_personAddress: PersonAddressEntity | undefined) {
        _personAddress && setPersonAddress({...personAddress, ..._personAddress});
        setStep(3);
    }

    function onFinishFormPersonBankData(_personBankData: PersonBankDataEntity | undefined) {
        _personBankData && setPersonBankData({...personBankData, ..._personBankData});
        setStep(4);
    }

    function CurrentStepPage() {
        switch (step) {
            case 0:
                return <FormPerson person={person} onFinish={onFinishFormPerson} />
            case 1:
                return person?.type == 'F' ?
                    <FormPersonIndividual
                        person={person}
                        onFinish={onFinishFormPersonIndividual}
                        onBack={() => setStep(0)}
                    />
                    :
                    !person ? null :
                        <FormPersonCompany
                            person={person}
                            onFinish={onFinishFormCompany}
                            onBack={() => setStep(0)}
                        />
            case 2:
                return !person ? null :
                    <FormPersonAddress
                        person={person}
                        onFinish={onFinishFormPersonAddress}
                        onBack={() => setStep(1)}
                        personAddress={personAddress}
                    />;
            case 3:
                return !person ? null :
                    <FormPersonBankData
                        person={person}
                        onFinish={onFinishFormPersonBankData}
                        onBack={() => setStep(2)}
                        personBankData={personBankData}
                    />
            case 4:
                return !person ? null :
                    <StepConfirm
                        person={person}
                        onFunctionCreated={loadPerson}
                        onFunctionRemoved={loadPerson}
                        onFinish={() => props.navigator.goToListPage()}
                    />
            default: return null;
        }
    }
    return (
        <>
            <HeaderClientProvider mode={'form'} title={'Novo Cliente/Fornecedor'} />
            <br />
            <Steps current={step} onChange={setStep}>
                <Steps.Step title={'Dado Básicos'} />
                <Steps.Step title={'Dados Adicionais'} disabled={step < 1} />
                <Steps.Step title={'Endereço'} disabled={step < 2} />
                <Steps.Step title={'Dados Bancários'}  disabled={step < 3}/>
                <Steps.Step title={'Confirmar'}  disabled={step < 4} />
            </Steps>
            <Content style={stepContent}>
                <CurrentStepPage />
            </Content>
        </> 
    )
}

const stepContent: CSSProperties = {
    minHeight: '200',
    marginTop: '16px',
    paddingTop: '15px',
    borderRadius: '2px'
}