import {Col, Modal, Row, Tree} from "antd";
import {Content} from "antd/es/layout/layout";
import {useDRERowRepository} from "../../../../domain/repository/dre-row.repository";
import {Key, useEffect, useState} from "react";
import {DreRowEntity} from "../../../../domain/entity/dre-row.entity";
import {DataNode} from "rc-tree/lib/interface";
import {Route, Switch, useHistory, useRouteMatch} from "react-router-dom";
import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {FullscreenExitOutlined, MinusCircleOutlined, PlusCircleOutlined} from "@ant-design/icons";
import {ShowDreRow} from "./show.dre-row";
import {EditDreRow} from "./edit.dre-row";
import {dreRowSort} from "../service/dre-row.sort";
import {dreRowNameFormatter} from "../service/dre-row.name.formatter";

export function ListDreRow(props: ModuleRenderProps) {
    const [rows, setRows] = useState<DreRowEntity[]>([]);
    const [selectedRow, selectRow] = useState<Key>();
    const dreRowRepository = useDRERowRepository();
    const routeMatch = useRouteMatch();
    const history = useHistory();

    useEffect(() => {
        loadData()
    }, [1]);

    function loadData() {
        dreRowRepository.search().then(res => setRows(res.data));
    }

    function getIconLine(type: string) {
        if(type == 'calc') {
            return <FullscreenExitOutlined style={{color: 'blue'}} />
        }
        return type === 'revenue' ?
            <PlusCircleOutlined style={{color: 'darkgreen'}} /> :
            <MinusCircleOutlined style={{color: 'darkred'}}  />
    }

    function parseData(data: DreRowEntity[]): DataNode[] {
        let result: DataNode[] = [];
        const parsedData = data.filter(w => w.isRowResult).sort(dreRowSort);
        for (let item of parsedData) {
            const node: DataNode = {
                key: item.id,
                icon: getIconLine(item.type),
                title: dreRowNameFormatter(item),
                children: data.filter(w => w.parentRowId === item.id && !w.isRowResult)
                    .sort(dreRowSort)
                    .map(ch => ({
                        title: dreRowNameFormatter(ch, item),
                        icon: getIconLine(ch.type),
                        isLeaf: true,
                        key: ch.id
                    }))
            };
            result.push(node);
        }
        return result;
    }

    function onRemoved() {
        selectRow(undefined);
        loadData();
    }

    function takeBeforeId(currentId: Key) {
        const dreRow: DreRowEntity | undefined = rows
            .find(w => w.id === currentId);
        if (!dreRow) {
            return;
        }
        return rows.find(w => w.order === dreRow.order - 1 && w.parentRowId == dreRow.parentRowId)?.id;
    }

    function takeAfterId(currentId: Key) {
        const dreRow: DreRowEntity | undefined = rows
            .find(w => w.id === currentId);
        if (!dreRow) {
            return;
        }
        if (dreRow.parentRowId) {
            return rows.find(w => w.parentRowId === dreRow.parentRowId && w.order === dreRow.order + 1)?.id;
        }
        return rows.find(w => w.order === dreRow.order + 1)?.id;
    }

    return (
        <>
            <Content>
                <Row>
                    <Col span={14}>
                        <Tree
                            onSelect={(rows) => selectRow(rows[0])}
                            selectedKeys={selectedRow ? [selectedRow] : []}
                            defaultExpandAll
                            showIcon={true}
                            treeData={parseData(rows)}
                        />
                    </Col>
                    <Col span={10}>
                        {
                            selectedRow && (
                                <ShowDreRow
                                    dreRowId={selectedRow}
                                    onRemoved={() => onRemoved()}
                                    beforeRowId={takeBeforeId(selectedRow)}
                                    afterRowId={takeAfterId(selectedRow)}
                                    navigator={props.navigator}
                                />
                            )
                        }
                    </Col>
                </Row>
                <Switch>
                    <Route path={`${routeMatch.path}/edit`}>
                        <Modal
                            visible={true}
                            title={'Editar Nova Linha'}
                            footer={null}
                            onCancel={() => history.goBack()}
                        >
                            <EditDreRow
                                navigator={props.navigator}
                                dreRowId={selectedRow as number}
                                onEdited={() => loadData()}
                            />
                        </Modal>
                    </Route>
                </Switch>
            </Content>
        </>
    )
}