import {Form, Select} from "antd";
import React from "react";

export type InputSearchProps = {
  name: string,
  label: string,
  required?: boolean,
  onSelected?: (value: string | number) => void,
  data: { label: string, value: number | string }[],
  disabled?: boolean,
  loading?: boolean,
}
export function InputSearch(props: InputSearchProps) {
  const { data, label, name, required } = props;

  function onFilter(input: string, option: string) {
    return option.toLowerCase()?.indexOf(input.toLowerCase()) >= 0;
  }

  function onSelect(value: string | number) {
    if (props.onSelected) {
      props.onSelected(value);
    }
  }

  return (
    <Form.Item name={name} label={label} rules={[{ required: required }]}>
      <Select
        options={data}
        disabled={props.disabled}
        showSearch
        onSelect={onSelect}
        loading={props.loading}
        filterOption={(input: string, option) =>
          onFilter(input, option?.label ? option.label.toString() : '')}
      />
    </Form.Item>
  )
}