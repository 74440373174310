import {Button, Col, Divider, Form, InputNumber, message, Radio, Row} from "antd";
import React, {useCallback, useEffect, useState} from "react";
import {DatePickerPtBr} from "../../../../core/component/date-picker-ptbr";
import {BrlInputNumber} from "../../../../core/component/brl-input-number";
import {PaymentMethodEntity} from "../../../../domain/entity/payment-method.entity";
import {usePaymentMethodRepository} from "../../../../domain/repository/payment-method.repository";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import moment, {Moment} from "moment";
import {FinancialEntryEntity} from "../../../../domain/entity/financial-entry.entity";
import {FinancialEntryItemEntity} from "../../../../domain/entity/financial-entry-item.entity";
import {FinancialEntryTaxEntity} from "../../../../domain/entity/financial-entry-tax.entity";
import {useFinancialEntryRepository} from "../../../../domain/repository/financial-entry.repository";
import {DetailsEntry} from "./details.entry";
import {FinancialEntryInstallmentEntity} from "../../../../domain/entity/financial-entry-installment.entity";
import {SelectPaymentMethod} from "../../../component/dropdown/select.payment-method";
import {SaveEntryInstallmentsDto} from "../../../../domain/dto/save-entry-installments.dto";

export type InstallmentItem = {
  order: number,
  dueDate: Moment,
  value: number,
  paymentMethodId?: number,
}

export type  FormFinancialInstallmentProps = {
  entry: FinancialEntryEntity,
  items: FinancialEntryItemEntity[],
  taxes: FinancialEntryTaxEntity[],
  installments: FinancialEntryInstallmentEntity[],
  onFinish: () => void,
}

export function FormFinancialInstallment(props: FormFinancialInstallmentProps) {
  const [form] = Form.useForm();
  const [currentCondition, setCurrentCondition] = useState<string>();
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethodEntity[]>([])
  const [installments, setInstallments] = useState<InstallmentItem[]>([]);

  const financialEntryRepository = useFinancialEntryRepository();
  const paymentMethodRepo = usePaymentMethodRepository();

  const loadPaymentMethod = useCallback(() => {
    paymentMethodRepo?.findAll()?.then(res => setPaymentMethods(res.data))
  }, [paymentMethodRepo])

  useEffect(() => {
    loadPaymentMethod()
  }, [])

  useEffect(() => {
    if (props.entry.paymentCondition) {
      form.setFields([{ name: 'condition', value: props.entry.paymentCondition}])
      setCurrentCondition(props.entry.paymentCondition)
    }
    if (props.installments?.length) {
      setInstallments(
        props.installments.map(w => ({
          value: w.value,
          order: w.order,
          dueDate: moment(w.dueDate),
          paymentMethodId: w.paymentMethodId
        }))
      )
    }
  }, [form, props.entry, props.installments])

  useEffect(() => {
    const formatName = (order: string, name: string) => ['installments', order, name]
    for(const installment of installments) {
      const { order, dueDate, value, paymentMethodId } = installment;
      form.setFields([
        { name: formatName(order.toString(), 'order'), value: order },
        { name: formatName(order.toString(), 'value'), value: value },
        { name: formatName(order.toString(), 'dueDate'), value: dueDate },
        { name: formatName(order.toString(), 'paymentMethodId'), value: paymentMethodId },
      ])
    }
  }, [installments, form])


  function onFinish(values: any) {
    const { condition } = values;
    const installments = Object.values(values.installments) as SaveEntryInstallmentsDto[];
    return financialEntryRepository.saveFinancialEntryInstallments(
      props.entry.id,
      condition,
      installments
    ).then(res => {
      message.success(res.message);
      props.onFinish();
    }).catch(err => {
      message.error(err.message);
    })
  }

  function onConditionChange() {
    const temp = form.getFieldValue('condition');
    if (temp === 'cash') {
      generateInstallments(1);
    } else {
      generateInstallments(0);
    }
    setCurrentCondition(temp);
  }

  function calcInstallmentValue(
    order: number,
    totalInstallments: number,
    totalValue: number
  ): number {
    let result = totalValue;
    if (totalInstallments === 1) {
      return result;
    }
    result = parseFloat((totalValue / totalInstallments).toFixed(2));
    if (totalInstallments === order) {
      result += totalValue - result * totalInstallments;
    }
    return result;
  }

  function generateInstallments(quantity: number) {
    const tempInstallments: InstallmentItem[] = [];
    const defaultPaymentMethod = form.getFieldValue('defaultPaymentMethodId');
    for (let order = 1; order <= quantity; order++) {
      tempInstallments.push({
        order,
        value: calcInstallmentValue(order, quantity, props.entry.documentNetValue),
        paymentMethodId: defaultPaymentMethod,
        dueDate: moment().add({ month: (order - 1) }),
      })
    }
    setInstallments(tempInstallments);
  }


  return (
    <Form layout={'vertical'} form={form} onFinish={onFinish}>
      <DetailsEntry entry={props.entry} />
      <Divider />
      <Row gutter={12}>
        <Col>
          <Form.Item
            name={'condition'}
            label={'Condição'}
            rules={[{ required: true }]}
          >
            <Radio.Group onChange={onConditionChange}>
              <Radio value={'cash'}>À Vista</Radio>
              <Radio value={'installment'}>Parcelado</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        {
          currentCondition !== 'cash' && (
            <>
              <Col span={6}>
                <Form.Item name={'installmentsQuantity'} label={'Número de Parcelas'}>
                  <InputNumber
                    style={{width: '100%'}}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <SelectPaymentMethod data={paymentMethods} name={'defaultPaymentMethodId'} required={false} />
              </Col>
              <Col span={4}>
                <Button
                  style={{position: 'absolute',  bottom: 24}}
                  onClick={() => generateInstallments(form.getFieldValue('installmentsQuantity'))}
                >
                  Gerar Parcelas
                </Button>
              </Col>
            </>
          )
        }

      </Row>
      {
        installments.map(w => (
            <FormFinancialInstallmentItem
              changeValue={(name, value) => form.setFields([{ name: ['installments', w.order, name], value }])}
              paymentMethods={paymentMethods}
              item={w}
            />
          )
        )
      }
      <FormActionsButtons />
    </Form>
  );
}

export type FormFinancialInstallmentItemProps = {
  item: InstallmentItem,
  paymentMethods: PaymentMethodEntity[],
  changeValue: (itemName: string, value: any) => any
}
export function FormFinancialInstallmentItem(props: FormFinancialInstallmentItemProps) {
  const { order } = props.item;
  return (
    <>
      <Row gutter={12}>
        <Col span={2}>
          <Form.Item
            name={['installments', order.toString(), 'order']}
            label={order === 1 ? 'Ordem' : ''}
            rules={[{ required: true }]}
          >
            <InputNumber  />
          </Form.Item>
        </Col>
        <Col span={8}>
          <DatePickerPtBr
            label={order === 1 ? 'Vencimento' : ''}
            name={['installments', order.toString(), 'dueDate']}
            required
          />
        </Col>
        <Col span={8}>
          <SelectPaymentMethod
            data={props.paymentMethods}
            label={order === 1 ? 'Método de Pagamento' : ''}
            name={['installments', order.toString(), 'paymentMethodId']}
          />
        </Col>
        <Col span={6}>
          <BrlInputNumber
            label={order === 1 ? 'Valor' : ''}
            name={['installments', order.toString(), 'value']}
            required
          />
        </Col>
      </Row>
    </>
  )
}