import {Alert, Form, message} from "antd";
import {DatePickerPtBr} from "../../../../core/component/date-picker-ptbr";
import TextArea from "antd/es/input/TextArea";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import {useFinancialEntryRepository} from "../../../../domain/repository/financial-entry.repository";
import {useCallback, useEffect, useState} from "react";
import {FinancialEntryEntity} from "../../../../domain/entity/financial-entry.entity";
import moment from "moment";

export type FormCancelEntryComponentProps = {
  entryId: number,
  onSaved: () => any,
}
export function FormCancelEntryComponent(props: FormCancelEntryComponentProps) {
  const [form] = Form.useForm();
  const [entry, setEntry] = useState<FinancialEntryEntity>();
  const [entryHasManyInstallments, setEntryHasManyInstallments] = useState(false);
  const { entryId } = props;
  const entryRepo = useFinancialEntryRepository();

  const loadEntry = useCallback(() => {
    entryRepo.find(props.entryId.toString())
      .then(({data}) => setEntry(data));
  }, [entryRepo, props.entryId]);
  
  useEffect(() => {
    loadEntry()
  }, []);

  const onFinish = useCallback((values: any) =>  {
    return entryRepo.cancelEntry(entryId, values.reason).then(res => {
      message.success(res.message);
      props.onSaved();
    }).catch(err => {
      message.error(err.message);
    })
  }, [entryId, entryRepo, props]);

  useEffect(() => {
    if (entry && entry.installments) {
      const installmentsNotCanceledQuantity = entry.installments.filter(w => w.paid).length;
      if (installmentsNotCanceledQuantity > 1) {
        setEntryHasManyInstallments(true);
        return;
      }
      const installment = entry.installments.filter(w => w.paid).pop();
      if (!installment) {
        return;
      }
      if (installment.paid) {
        form.setFields([
          { name: 'dateTime', value: moment(installment.writeOffDate) }
        ])
      }
    }
  }, [entry, form])

  return (
    <Form form={form} onFinish={onFinish} layout={'vertical'}>
      <Form.Item name={'reason'} rules={[{required: true}]} label={'Motivo Cancelamento'}>
        <TextArea style={{height: `150 !important`}} />
      </Form.Item>
      {
        entryHasManyInstallments ? (
          <Alert
            type={'warning'}
            message={'O Movimento contém parcelas baixadas.'}
            description={'Para cancelar o lançamento é necessário cancelar todas as baixas.'}
            showIcon
          />
        ) :
        entry?.isCanceled ? (
          <Alert type={'warning'} message={'O Movimento já á está cancelado'} showIcon />
        ) : (
          <FormActionsButtons />
        )
      }
    </Form>
  )
}