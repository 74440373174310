import {useRouteMatch} from "react-router-dom";
import {ModuleResource, ModuleResourceTypeEnum, ModuleRoot} from "../../../core/component/module/module.root";
import React from "react";
import {ListFinancialItem} from "./pages/list.financial-item";
import {CreateFinancialItem} from "./pages/create.financial-item";
import {EditFinancialItem} from "./pages/edit.financial-item";


export function FinancialItemModule() {
    const routeMatch = useRouteMatch();
    const resources: ModuleResource[] = [
        {
            type: ModuleResourceTypeEnum.CREATE,
            render: (props) => <CreateFinancialItem {...props} />
        },
        {
            type: ModuleResourceTypeEnum.EDIT,
            render: (props) => <EditFinancialItem {...props} />
        },
        {
            type: ModuleResourceTypeEnum.DEFAULT,
            render: (props) => <ListFinancialItem {...props} />
        },
    ]

    return (
        <ModuleRoot
            resource={routeMatch.url}
            moduleResources={resources}
        />
    );
}
