export function useTokenStorage() {

    const TOKEN_KEY = '__A12K';
    const REFRESH_TOKEN_KEY = '__A12A2K';

    function setRefreshToken(token: string): void {
        localStorage.setItem(REFRESH_TOKEN_KEY, token);
    }

    function setBearerToken(token: string): void {
        localStorage.setItem(TOKEN_KEY, token);
    }

    function getRefreshToken(): string | null {
        return localStorage.getItem(REFRESH_TOKEN_KEY);
    }

    function getBearerToken(): string | null {
        return localStorage.getItem(TOKEN_KEY);
    }

    function clearToken() {
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(REFRESH_TOKEN_KEY);
    }

    return {
        setRefreshToken,
        setBearerToken,
        getRefreshToken,
        getBearerToken,
        clearToken
    }
}
