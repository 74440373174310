import {FirmEntity} from "../../../domain/entity/firm.entity";
import {SelectBase} from "./select.base";

export type SelectFirmType = {
  data: FirmEntity[],
  label?: string,
  name?: string,
  required?: boolean;
  onChange?: (value: string | number) => void,
}

export function SelectFirm(props: SelectFirmType) {
  function getOpts() {
    return props.data.map(item => ({
      label: item.name,
      value: item.id,
    }))
  }
  return (
    <SelectBase
      label={props.label ?? 'Filial'}
      name={props.name ?? 'firmId'}
      data={getOpts()}
      onChange={props.onChange}
      required={props.required}
    />
  )
}