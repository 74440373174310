import {FinancialEntryInstallmentEntity} from "../../../../../domain/entity/financial-entry-installment.entity";
import {Descriptions, Divider, Form, Input, message, Table, Tabs, Tag} from "antd";
import moment from "moment";
import {useNumberFormatter} from "../../../../service/number-formatter.service";
import {useDateTimeUtil} from "../../../../../core/util/date-time.util";
import {useMemo} from "react";
import {
  calcAmountInstallmentViewModel,
  InstallmentStatusViewModel,
  InstallmentViewModel
} from "./installment.view-model";
import {FormActionsButtons} from "../../../../../core/component/form-actions-buttons";
import {useFinancialEntryRepository} from "../../../../../domain/repository/financial-entry.repository";

export type ConfirmRenegotiateComponentProps = {
  entryId: number,
  selectInstallments: FinancialEntryInstallmentEntity[],
  prevInstallments: InstallmentViewModel[],
  currentInstallments: FinancialEntryInstallmentEntity[],
  onFinish: () => any,
  onCancel: () => any,
}
export function ConfirmRenegotiateComponent(props: ConfirmRenegotiateComponentProps) {
  const { prevInstallments, currentInstallments, selectInstallments } = props;

  const financialEntryRepo = useFinancialEntryRepository();

  function onFinish() {
    return financialEntryRepo.renegotiateInstallments(
      props.entryId,
      selectInstallments.map(w => w.id),
      prevInstallments
    ).then(res => {
      message.success(res.message);
      props.onFinish()
    }).catch(err => {
      message.error(err.message)
    })
  }

  const installmentsToCancel = useMemo<InstallmentViewModel[]>(() => {
    return selectInstallments.map(w => ({
      ...w,
      dueDate: moment(w.dueDate),
      status: InstallmentStatusViewModel.TO_CANCEL,
    }))
  }, [selectInstallments]);

  const previewNewInstallments = useMemo<InstallmentViewModel[]>(() => {
    return prevInstallments.map(w => ({
      ...w,
      status: InstallmentStatusViewModel.RENEGOTIATED,
    }))
  }, [prevInstallments])

  const resultInstallments = useMemo<InstallmentViewModel[]>(() => {
    const selectedIds = selectInstallments.map(w => w.id);
    const getStatus = (w: FinancialEntryInstallmentEntity) => {
      if (w.paid) {
        return InstallmentStatusViewModel.WRITE_OFF;
      }
      if (w.canceled) {
        return InstallmentStatusViewModel.CANCELED;
      }
      if (selectedIds.includes(w.id)) {
        return InstallmentStatusViewModel.TO_CANCEL;
      }
      return InstallmentStatusViewModel.OPENED;
    }

    const temp: InstallmentViewModel[] = currentInstallments.map(w => ({
      ...w,
      dueDate: moment(w.dueDate),
      status: getStatus(w)
    }))

    return [
      ...temp,
      ...previewNewInstallments
    ]
  }, [currentInstallments, previewNewInstallments, selectInstallments])


  const totalResultValue = useMemo<number>(() => {
    return resultInstallments
      .filter(w => w.status !== InstallmentStatusViewModel.TO_CANCEL && w.status !== InstallmentStatusViewModel.CANCELED)
      .map(w => calcAmountInstallmentViewModel(w))
      .reduce((p,c) => p + c, 0)
  }, [resultInstallments])

  const totalPrevInstallments = useMemo<number>(() => {
    return previewNewInstallments
      .map(w => calcAmountInstallmentViewModel(w))
      .reduce((p,c) => p + c, 0)
  }, [previewNewInstallments])

  const totalInstallmentsToCancel = useMemo<number>(() => {
    return installmentsToCancel
      .map(w => calcAmountInstallmentViewModel(w))
      .reduce((p,c) => p + c, 0)
  }, [installmentsToCancel])

  return (
    <Form onFinish={onFinish}>
      <Divider />
      <Form.Item  name={'reason'}>
        <Input placeholder={'Informe o motivo da renegociação'} />
      </Form.Item>
      <Tabs>
        <Tabs.TabPane tab={'Resultado'} key={1}>
          <ListInstallments
            total={totalResultValue}
            data={resultInstallments}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={'Parcelas Para Cancelar'} key={2}>
          <ListInstallments
            total={totalInstallmentsToCancel}
            data={installmentsToCancel}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={'Novas Parcelas'} key={3}>
          <ListInstallments
            total={totalPrevInstallments}
            data={previewNewInstallments}
          />
        </Tabs.TabPane>
      </Tabs>
      <FormActionsButtons
        label={'Confirmar'}
        onCancel={props.onCancel}
        labelCancel={'Voltar'}
      />
    </Form>
  )
}

export function ListInstallments(props: { data: InstallmentViewModel[], total: number }) {
  const { decimalFormatter } = useNumberFormatter();
  const { convertToDatePtBr } = useDateTimeUtil();

  const Footer = () => (
    <>
      <Descriptions layout={'horizontal'}>
        <Descriptions.Item label={'Total Acumulado'} labelStyle={{fontWeight: 'bold'}}>
          {decimalFormatter.format(props.total)}
        </Descriptions.Item>
      </Descriptions>
    </>
  )

  const renderStatus = (status: InstallmentStatusViewModel) => {
    switch (status) {
      case InstallmentStatusViewModel.CANCELED:
        return <Tag color={'red'}>{status}</Tag>;
      case InstallmentStatusViewModel.TO_CANCEL:
        return <Tag color={'warning'}>{status}</Tag>;
      case InstallmentStatusViewModel.RENEGOTIATED:
        return <Tag color={'cyan'}>{status}</Tag>;
      default:
        return <Tag>{status}</Tag>;
    }
  }

  return (
    <Table
      dataSource={props.data}
      footer={() => <Footer />}
      columns={[
        {
          title: 'Parcela',
          dataIndex: 'order',
        },
        {
          title: 'Status',
          dataIndex: 'status',
          render: renderStatus,
        },
        {
          title: 'Vencimento',
          dataIndex: 'dueDate',
          render: convertToDatePtBr
        },
        {
          title: 'Valor',
          dataIndex: 'value',
          render: decimalFormatter.format
        },
        {
          title: 'Juros',
          dataIndex: 'fineAmount',
          render: decimalFormatter.format
        },
        {
          title: 'Multa',
          dataIndex: 'interestAmount',
          render: decimalFormatter.format
        },
        {
          title: 'Desconto',
          dataIndex: 'discountAmount',
          render: decimalFormatter.format
        },
        {
          title: 'Total',
          render: (_,row: InstallmentViewModel) => decimalFormatter.format(
            calcAmountInstallmentViewModel(row)
          )
        },
      ]}
    />
  )
}