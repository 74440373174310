import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {Col, DatePicker, Divider, Form, InputNumber, message, Row, Switch, Typography} from "antd";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import React, {useEffect, useState} from "react";
import {useCompetenceRepository} from "../../../../domain/repository/competence.repository";
import {useParams} from "react-router-dom";
import {CompetenceEntity} from "../../../../domain/entity/competence.entity";
import moment from "moment";
import {DatePickerPtBr} from "../../../../core/component/date-picker-ptbr";
import {MonthsEnum} from "../../../../domain/vo/months.enum";
import {useUpdateFieldsByYearService} from "../service/update-fields-by-year.service";
import {useParseMonthsCloseDateService} from "../service/parse-months-close-dates.service";

export function EditCompetence(props: ModuleRenderProps) {
    const [form] = Form.useForm();
    const [year, setYear] = useState<number>();
    const competenceRepository = useCompetenceRepository();
    const [competence, setCompetence] = useState<CompetenceEntity>();
    const { id } = useParams<any>();
    const updateFieldsByYear = useUpdateFieldsByYearService();
    const parseMonthsCloseDates = useParseMonthsCloseDateService();

    useEffect(() => {
        if (year) {
            updateFieldsByYear.setFields(year, form);
        }

    }, [year])

    useEffect(() => { competenceRepository.find(id).then(res => setCompetence(res.data)) }, [1]);
    useEffect(() => {
        if (competence) {
            form.setFields([
                { name: 'year', value: moment(`${competence.year}-01-01`) },
                { name: 'initialDate', value: moment(competence.initialDate) },
                { name: 'endDate', value: moment(competence.endDate) },
                { name: 'canUpdate', value: competence.canUpdate },
                { name: 'active', value: competence.active },
            ]);

            const months = [];
            for (let month of competence.monthsCloseDates) {
                months.push({ name: `month_${month.month}`, value: moment(month.date)})
            }
            form.setFields(months);
        }
    }, [competence])

    function onFinish(data: any) {

        const competenceEntity = {
            active: data.active,
            canUpdate: data.canUpdate,
            endDate: data.endDate.format(),
            initialDate: data.initialDate.format(),
            year: data.year.format('YYYY'),
            monthsCloseDates: parseMonthsCloseDates.parse(data)
        }

        competenceRepository.update(id, competenceEntity as CompetenceEntity)
            .then(res => {
                message.success(res.message);
                props.navigator.goToListPage();
            }).catch(err => {
                message.error(err.message);
        })
    }


    function parseYear(value: any) {
        setYear(parseInt(value.format('YYYY')))
    }

    return (
        <>
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Row gutter={12}>
                    <Col span={8}>
                        <Form.Item label="Ano" name={'year'} style={{width: '100%'}} rules={[{ required: true }]}>
                            <DatePicker picker={'year'} style={{width: '100%'}} onSelect={parseYear} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <DatePickerPtBr
                            label="Data Início"
                            name={'initialDate'}
                            required={true}
                        />
                    </Col>
                    <Col span={8}>
                        <DatePickerPtBr
                            label="Data Final"
                            name={'endDate'}
                            required={true}
                        />
                    </Col>
                </Row>

                <Divider />
                <Typography.Text>Selecionar a Data de Encerramento De Cada Mês: </Typography.Text>
                <Row gutter={12}>
                    {
                        MonthsEnum.getValues().map(month => (
                            <Col span={6}>
                                <DatePickerPtBr
                                    name={`month_${month.value}`}
                                    label={`${month.label}`}
                                    required={true}
                                />
                            </Col>
                        ))
                    }
                </Row>
                <Divider />

                <Row gutter={12}>
                    <Col span={24}>
                        <Form.Item name={'canUpdate'} valuePropName={'checked'}>
                            <Switch
                                checkedChildren={'Permitir Atualizações em Registros Existentes'}
                                unCheckedChildren={'Não Permitir Atualizações em Registros Existentes'}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={12}>
                    <Col span={24}>
                        <Form.Item name={'active'} valuePropName={'checked'} >
                            <Switch
                                checkedChildren={'Competência Ativa'}
                                unCheckedChildren={'Competência Inativa'}
                                size={'default'}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <FormActionsButtons
                    onCancel={props.navigator.goToDefaultPage}
                    isLoading={competenceRepository.loading}
                />
            </Form>
        </>

    )
}