import {SelectBase} from "../../../component/dropdown/select.base";
import {RecurrenceEntryPeriodEnum} from "../../../../domain/entity/recurrence-entry.entity";
import {recurrencePeriodTranslate} from "../service/recurrence-period.translate";

export function SelectRecurrencePeriod(props: any) {
  function getOpts() {
    return Object.values(RecurrenceEntryPeriodEnum).map(item => ({
      label: recurrencePeriodTranslate(item),
      value: item,
    }))
  }

  return (
    <SelectBase
      label={props.label ?? 'Frequência'}
      name={props.name ?? 'periodRecurrence'}
      data={getOpts()}
      onChange={props.onChange}
      required={props.required}
    />
  )
}