import { DreRowEntity } from "../../../../domain/entity/dre-row.entity";
import { useEffect, useState } from "react";
import { DreRowReleaseParamEntity } from "../../../../domain/entity/dre-row-release-param.entity";

export function useDreRowReleaseParamAdapterService() {
  const [dreRows, setDreRows] = useState<DreRowEntity[]>([]);
  const [dreRowReleaseParams, setDreRowReleaseParams] = useState<DreRowReleaseParamEntity[]>([]);

  useEffect(() => {
    const data: DreRowReleaseParamEntity[] = [];
    for (let dreRow of dreRows) {
      if (dreRow.dreRowReleaseParam) {

        data.push(...dreRow.dreRowReleaseParam);
      }
    }
    setDreRowReleaseParams(data)
  }, [dreRows])

  function setRows(rows: DreRowEntity[]) {
    setDreRows(rows);
  }

  function getRowBounded(
    budgetCategoryId: number,
    managerialAccountId: number
  ): DreRowReleaseParamEntity | null {
    const entity: DreRowReleaseParamEntity | undefined = dreRowReleaseParams.find(w =>
      w.budgetCategoryId === budgetCategoryId &&
      w.managerialAccountId === managerialAccountId);
    if (!entity) {
      return null;
    }
    entity.dreRow = dreRows.find(w => w.id === entity.dreRowId);
    return entity;
  }


  return {
    dreRowReleaseParams,
    dreRows,
    getRowBounded,
    setRows
  }
}