import {Badge, Checkbox, message, Modal, Switch, Table, Tag} from "antd";
import {useEffect, useState} from "react";
import {FirmEntity} from "../../../../domain/entity/firm.entity";
import {useFirmRepository} from "../../../../domain/repository/firm.repository";
import {useCostCenterCrud} from "../service/cost-center.crud";
import {useDocumentFormatter} from "../../../service/document.formatter.service";

export type ListFilialModalProps = {
    visible: boolean,
    costCenterId: number,
    onClose: () => void
}
export function ListFilialModal(props: ListFilialModalProps) {
    const [firms, setFirms] = useState<FirmEntity[]>([]);
    const [costCenterFirms, setCostCenterFirms] = useState<FirmEntity[]>([]);
    const documentFormatter = useDocumentFormatter();
    const firmsRepo = useFirmRepository();
    const costCenterCrud = useCostCenterCrud();

    function loadFirms() {
        firmsRepo
            .search()
            .then(res => setFirms(res.data));
    }

    function loadFirmsCostCenter() {
        costCenterCrud.getFirms(props.costCenterId)
            .then(res => setCostCenterFirms(res));
    }

    function isBound(id: number) {
        if (costCenterFirms.find(item => item.id === id)) {
            return true;
        }
        return false;
    }

    function onChange(value: any, firmId: number) {
        if(value) {
            costCenterCrud
                .bindFirm(props.costCenterId, firmId)
                .then(res => {
                    message.success(res.message);
                    loadFirmsCostCenter();
                })
        } else {
            costCenterCrud
                .removeFirm(props.costCenterId, firmId)
                .then(res => {
                    message.success(res.message);
                    loadFirmsCostCenter();
                })
        }
    }

    useEffect(() => {
        loadFirms()
        loadFirmsCostCenter();
    }, [1]);

    function renderName(name: string, matrix: boolean) {
        if (matrix) {
            return (
              <>
                  {name}
                  <Tag children={'matriz'} color={'success'} style={{marginLeft: 5}} />
              </>
            )
        }
        return name;
    }

    return (
        <Modal
            title={'Filiais do Centro de Custo'}
            width={750}
            visible={props.visible}
            onCancel={props.onClose}
        >
            <Table
                pagination={false}
                loading={costCenterCrud.loading}
                columns={[
                    {
                        title: 'Nome',
                        key: 'name',
                        dataIndex: 'name',
                        render: (val, row) => renderName(val, row.matrix === true ? true : false)
                    },
                    {
                        title: 'CNPJ',
                        key: 'document',
                        dataIndex: 'document',
                        render: (val) => documentFormatter.formatCNPJ(val)
                    },
                    {
                        title: 'Vinculado',
                        key: 'id',
                        dataIndex: 'id',
                        align: 'center',
                        render: (val) => (
                            <Checkbox
                                onChange={e => onChange(e.target.checked, val)}
                                checked={isBound(val)}
                            />
                        )
                    },

                ]}
                dataSource={firms}
            />
        </Modal>
    )
}