import {useRouteMatch} from "react-router-dom";
import {ModuleResource, ModuleResourceTypeEnum, ModuleRoot} from "../../../core/component/module/module.root";
import React from "react";
import {ListFinancialDocumentType} from "./pages/list.financial-document-type";
import {CreateFinancialDocumentType} from "./pages/create.financial-document-type";
import {EditFinancialDocumentType} from "./pages/edit.financial-document-type";


export function FinancialDocumentTypeModule() {
    const routeMatch = useRouteMatch();
    const resources: ModuleResource[] = [
        {
            type: ModuleResourceTypeEnum.CREATE,
            render: (props) => <CreateFinancialDocumentType {...props} />
        },
        {
            type: ModuleResourceTypeEnum.EDIT,
            render: (props) => <EditFinancialDocumentType {...props} />
        },
        {
            type: ModuleResourceTypeEnum.DEFAULT,
            render: (props) => <ListFinancialDocumentType {...props} />
        },
    ]

    return (
        <ModuleRoot
            resource={routeMatch.url}
            moduleResources={resources}
        />
    );
}
