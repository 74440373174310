import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {HeaderClientProvider} from "../component/header.client-provider";
import {Alert, Badge, Button, Divider, Form, Input, List, message, Modal, Table, Tag} from "antd";
import {Content} from "antd/es/layout/layout";
import {usePersonRepository} from "../../../../domain/repository/person.repository";
import {FormActionsButtons} from "../../../../core/component/form-actions-buttons";
import {useEffect, useState} from "react";
import {ImportEntity, ImportStatusEnum} from "../../../../domain/entity/import.entity";
import {ColumnsType} from "antd/lib/table";
import {useDateTimeUtil} from "../../../../core/util/date-time.util";
import {ImportMessageEntity} from "../../../../domain/entity/import-message.entity";
import {useMessageTranslateService} from "../../../../core/service/message.tranlate";
import {DownloadOutlined} from "@ant-design/icons";

export function ImportClientProvider(props: ModuleRenderProps) {
  const [imports, setImports] = useState<ImportEntity[]>([]);
  const [form] = Form.useForm();
  const [currentContent, setCurrentContent] = useState<string>();
  const personRepo = usePersonRepository();

  function getImports() {
    personRepo.getImporting().then(res => setImports(res.data));
  }

  useEffect(() => {
    getImports()
  }, [])

  function onFinish(data: any) {
    currentContent && personRepo.importPersons(currentContent).then(res => {
      message.success(res.message);
      getImports();
    })
  }

  function isValidCSV(content: string) {
    const rows = content.split('\n');
    if (!rows?.length) {
      return false;
    }
    const mandatoryColumns = [
      'nome',
      'tipo',
      'documento',
      'email',
      'telefone',
      'cliente',
      'fornecedor'
    ];

    const columns = rows[0].split(';');
    for(const mc of mandatoryColumns) {
      if (!columns.includes(mc)) {
        return false;
      }
    }
    return true;
  }

  function checkFile(event: any) {
    const file: File = event.target.files[0];
    file.text().then(content => {
      if(!isValidCSV(content)) {
        form.resetFields();
        alert('CSV Invalido');
        setCurrentContent(undefined);
      }
      setCurrentContent(content)
    })
  }


  return (
    <>
      <HeaderClientProvider
        title={'Importar Clientes / Provedores'}
        subtitle={''}
        mode={'form'}
      />
      <Divider />
      <Content>
        <Form form={form} onFinish={onFinish} layout={'vertical'} >
          <Form.Item name={'file'} label={'Selecione um Arquivo CSV para importacao'} rules={[{ required: true }]} extra={''}>
            <Input
              type={'file'}
              id={'file'} onChange={(event) => checkFile(event)}
            />
          </Form.Item>
          <FormActionsButtons
            moreAction={[
              <Button
                shape={'round'}
                icon={<DownloadOutlined />}
                onClick={() => personRepo.downloadImportModel()}
              >
                Modelo CSV
              </Button>
            ]}
          />
        </Form>
        <ListImporting imports={imports} />
      </Content>
    </>
  )
}

export function ListImporting(props: { imports: ImportEntity[] }) {
  const [messages, setMessages] = useState<ImportMessageEntity[]>([]);

  const personRepo = usePersonRepository();
  const { convertToDatePtBr } = useDateTimeUtil();
  const { translate } = useMessageTranslateService()

  function getImportingMessages(id: string | number) {
    personRepo.getImportingMessages(id).then(res => setMessages(res.data));
  }

  function parseStatus(status: ImportStatusEnum) {
      switch (status) {
        case ImportStatusEnum.FINISHED:
          return <Tag color={'green'}>Finalizado</Tag>;
        case ImportStatusEnum.PROGRESS:
          return <Tag color={'orange'}>Em Progresso</Tag>;
        case ImportStatusEnum.PENDING:
          return <Tag color={''}>Pendente</Tag>;
      }
  }

  const columns: ColumnsType<any> = [
    {
      title: 'Data',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: value => convertToDatePtBr(value)
    },
    {
      title: 'Registros',
      dataIndex: 'totalRows',
      key: 'totalRows',
    },
    {
      title: 'Status',
      dataIndex: 'statusImport',
      key: 'statusImport',
      render: parseStatus,
    },
    {
      title: 'Erros',
      dataIndex: 'id',
      key: 'id',
      render: id => (
        <Button
          onClick={() => getImportingMessages(id)}
          size={'small'} shape={'round'}
        >
          Ver Erros
        </Button>
      ),
    },
  ]

  return (
    <>
      <Table
        columns={columns}
        dataSource={props.imports}
      />

      {
        messages.length > 0 && (
          <Modal
            visible={true}
            onCancel={() => setMessages([])}
            title={'Mensagens Importação'}
            width={800}
          >
            <List
              pagination={{pageSize: 5}}
              dataSource={messages}
              renderItem={(item: ImportMessageEntity) => (
                  <div style={{marginBottom: 5}}>
                    <Alert
                      message={translate(item.description)}
                      description={JSON.stringify(item.meta)}
                      type={item.typeMessage as 'success' | 'error'}
                      showIcon
                    />
                  </div>

              )}
            />
          </Modal>
        )
      }
    </>
  )
}