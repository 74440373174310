import {useEffect, useState} from "react";
import {Button, Col, Divider, Form, Layout, Row, Select, Table,} from "antd";
import {BudgetReleaseEntity} from "../../../../domain/entity/budget-release.entity"
import {useBudgetRepository} from "../../../../domain/repository/budget.repository";
import {HeaderBudget} from "../component/header.budget";
import {BudgetEntity} from "../../../../domain/entity/budget.entity";
import {ModalReleases} from "../component/modal.releases";

export function ListReleaseBudget(props: any) {
    const [modalReleasesVisible, setModalReleasesVisible] = useState<boolean>(false);
    const [currentValues, setCurrentValues] = useState<Array<{month: number, value: number}>>([]);
    const [data, setData] = useState<BudgetReleaseEntity[]>([]);
    const [budgets, setBudgets] = useState<BudgetEntity[]>([]);
    const [currentBudgetId, setCurrentBudgetId] = useState<number>();

    const budgetRepo = useBudgetRepository();

    useEffect(() => {
        budgetRepo.search().then(res => setBudgets(res.data))
    }, [1]);

    useEffect(() => {
        if(currentBudgetId) {
            budgetRepo
                .getReleasesByBudget(currentBudgetId)
                .then(res => setData(res.data));
        }
    }, [currentBudgetId])

    function getBudgetsOpts() {
        return budgets.map(item => ({
            label: `Orçamento ${item.competence?.year} - ${item.version}`,
            value: item.id as number
        }))
    }

    function showModalReleases(values: any) {
        setCurrentValues(values);
        setModalReleasesVisible(true);
    }

    const columnsBudget = [
        {
            title: 'Filieal',
            dataIndex: 'firm' ,
            key: 'firm',
            render: (val: any) => val.name
        },
        {
            title: 'Centro de Custo',
            dataIndex: 'costCenter',
            key: 'costCenter',
            render: (val: any) => `${val.code} - ${val.name}`
        },
        {
            title: 'Linha DRE',
            dataIndex: 'dreRow',
            key: 'dreRow',
            render: (val: any) => `${val.name}`
        },
        {
            title: '',
            dataIndex: 'values',
            key: 'values',
            render: (val: any) => <Button shape='round' onClick={() => showModalReleases(val)} size='small' children='Lançamentos' />
        },
    ]
    
    return (
        <>
            <HeaderBudget 
                title='Pesqisar Lançamentos' 
                subtitle='Listagem de todos os lançamentos feitos' 
                mode='form' 
            /> 
            <Divider />
                <Row gutter={12} >                 
                    <Col span={8}>
                        <Form.Item label={'Orçamento'}>
                            <Select 
                                placeholder='Selecione o orçamento....'
                                style={{width: '100%'}} 
                                size='middle' 
                                options={getBudgetsOpts()}
                                value={currentBudgetId}
                                onSelect={val => setCurrentBudgetId(val as number)}
                            />

                        </Form.Item>
                    </Col>
                </Row>
            <Layout.Content>
                <Table 
                    columns={columnsBudget} 
                    dataSource={data} 
                    loading={budgetRepo.loading}
                />
            </Layout.Content>
            {
                modalReleasesVisible && (
                    <ModalReleases
                        visible={modalReleasesVisible}
                        values={currentValues}
                        setVisible={setModalReleasesVisible}
                    />
                )
            }
        </>
    );
}