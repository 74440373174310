export function assertNumber(value: any) {
  if (!value) {
    return 0;
  }
  return parseFloat(value.toString());
}

export function useNumberUtil() {
  function sumNumbers(value1?: any, value2?: any): number {
    return assertNumber(value1) + assertNumber(value2);
  }


  return {
    sumNumbers,
  }
}