import {Col, Form, InputNumber, Row} from "antd";
import {SelectPaymentMethod} from "../../../../component/dropdown/select.payment-method";
import {DatePickerPtBr} from "../../../../../core/component/date-picker-ptbr";
import {BrlInputNumber} from "../../../../../core/component/brl-input-number";
import {useEffect} from "react";
import {PaymentMethodEntity} from "../../../../../domain/entity/payment-method.entity";
import {InstallmentViewModel} from "./installment.view-model";

export type FormInstallmentComponentProps = {
  installment: InstallmentViewModel,
  setValues: (values: any) => any,
  paymentMethods: PaymentMethodEntity[],
  onChange: (item: InstallmentViewModel) => any,
}

export function FormInstallmentComponent(props: FormInstallmentComponentProps) {
  const { installment } = props;

  useEffect(() => {
    props.setValues([
      { name: ['prevInstallments',`${installment.id}`, 'order'], value: installment.order },
      { name: ['prevInstallments',`${installment.id}`, 'paymentMethodId'], value: installment.paymentMethodId },
      { name: ['prevInstallments',`${installment.id}`, 'dueDate'], value: installment.dueDate },
      { name: ['prevInstallments',`${installment.id}`, 'value'], value: installment.value },
      { name: ['prevInstallments',`${installment.id}`, 'interestAmount'], value: installment.interestAmount },
      { name: ['prevInstallments',`${installment.id}`, 'fineAmount'], value: installment.fineAmount },
      { name: ['prevInstallments',`${installment.id}`, 'discountAmount'], value: installment.discountAmount },
    ])
  }, [props, installment, props.setValues])

  return (
    <Row gutter={12}>
      <Col span={2}>
        <Form.Item name={['prevInstallments',`${installment.id}`, `order`]} label={'Parcela'}>
          <InputNumber style={{width: '100%'}} />
        </Form.Item>
      </Col>
      <Col span={5}>
        <SelectPaymentMethod
          data={props.paymentMethods}
          name={['prevInstallments',`${installment.id}`, 'paymentMethodId']}
        />
      </Col>
      <Col span={5}>
        <DatePickerPtBr
          name={['prevInstallments',`${installment.id}`, `dueDate`]}
          label={'Vencimento'}
          required
        />
      </Col>
      <Col span={3}>
        <BrlInputNumber label={'Valor'} name={['prevInstallments',`${installment.id}`,'value']} required />
      </Col>
      <Col span={3}>
        <BrlInputNumber
          label={'Juros'} name={['prevInstallments',`${installment.id}`,'interestAmount']} required />
      </Col>
      <Col span={3}>
        <BrlInputNumber label={'Multa'} name={['prevInstallments',`${installment.id}`,'fineAmount']} required />
      </Col>
      <Col span={3}>
        <BrlInputNumber label={'Desconto'} name={['prevInstallments',`${installment.id}`,'discountAmount']} required />
      </Col>
    </Row>
  )
}