export enum ManagerialAccountRowType {
  CREDIT = 'credit',
  DEBIT = 'debit',

}
export type ManagerialAccountEntity = {
  id: number;
  description: string;
  code: string;
  active: boolean;
  ledgerAccountId?: number;
  help?: string;
  type: ManagerialAccountRowType;
}

