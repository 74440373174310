import {HeaderDreRow} from "../component/header.dre-row";
import {Modal, Tabs} from "antd";
import {ListDreRow} from "./list.dre-row";
import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {ParametersDreRow} from "./parameters.dre-row";
import {useState} from "react";
import {Route, Switch, useHistory, useRouteMatch} from "react-router-dom";
import {CreateDrePage} from "./create.dre-row";

export function DreRowIndex(props: ModuleRenderProps) {
  const [key, setKey] = useState<number>(1);
  const routeMatch = useRouteMatch();
  const history = useHistory();

  function reload() {
    const temp = key;
    setKey(0);
    setKey(temp);
  }

  return (
    <>
      <HeaderDreRow />
      <Tabs type={'card'} onChange={key => setKey(parseInt(key))} >
        <Tabs.TabPane tab={'Configuração'} key={1}>
          { key == 1 && <ListDreRow {...props} /> }
        </Tabs.TabPane>
        <Tabs.TabPane tab={'Parametrização de Lançameto'} key={2}>
          {key === 2 && <ParametersDreRow {...props} />}
        </Tabs.TabPane>
      </Tabs>

      <Switch>
        <Route path={`${routeMatch.path}/create`}>
          <Modal
            visible={true}
            title={'Cadastrar Nova Linha'}
            footer={null}
            onCancel={() => history.goBack()}
          >
            <CreateDrePage
              navigator={props.navigator}
              onCreated={() => reload()}
            />
          </Modal>
        </Route>
      </Switch>
    </>
  )
}