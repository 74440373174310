import React from 'react';
import {AppModule} from "../core/component/skeleton/skeleton.content";
import {ProfileModule} from "./module/profile";
import {FirmModule} from "./module/firm";
import {CompetenceModule} from "./module/competence";
import {UserModule} from "./module/user";
import {CostCenterModule} from "./module/cost-center";
import {DreRowModule} from "./module/dre-row";
import {ClientModule} from "./module/client";
import {BudgetModule} from "./module/budget";
import {ManagerialModule} from "./module/managerial-accounts"
import {BudgetCategoryModule} from "./module/budget-category";
import {FinancialGroupModule} from "./module/financial-group";
import {FinancialClassModule} from "./module/financial-class";
import {FinancialItemModule} from "./module/financial-item";
import {ClientProviderModule} from "./module/client-provider";
import { SupplyUnitModule } from "./module/supply-unit";
import {FinancialEntryModule} from "./module/financial-entry";
import {FinancialDocumentTypeModule} from "./module/financial-document-type";
import {PaymentMethodModule} from "./module/payment-method";
import {ReportDreModule} from "./module/report";
import {BankAccountModule} from "./module/bank-account";
import { HomeModule } from "./module/home";
import {IntegrationModule} from "./module/integration";
import {CashFlowModule} from "./module/cash-flow";
import {RecurrenceModule} from "./module/recurrence";
import {LedgerAccountModule} from "./module/ledger-account";

export function getAppModules(prefix?: string): AppModule[] {
    return [
        {
            path: `${prefix}/profile`,
            render: () => <ProfileModule />
        },
        {
            path: `${prefix}/client`,
            render: () => <ClientModule/>
        },
        {
            path: `${prefix}/firm`,
            render: () => <FirmModule />
        },
        {
            path: `${prefix}/users`,
            render: () => <UserModule />
        },
        {
            path: `${prefix}/competence`,
            render: () => <CompetenceModule />
        },
        {
            path: `${prefix}/cost-center`,
            render: () => <CostCenterModule />
        },
        {
            path: `${prefix}/dre-row`,
            render: () => <DreRowModule />
        },
        {
            path: `${prefix}/budget`,
            render: () => <BudgetModule />
        },
        {
            path: `${prefix}/managerial-account`,
            render: () => <ManagerialModule />
        },
        {
            path: `${prefix}/budget-category`,
            render: () => <BudgetCategoryModule />
        },
        {
            path: `${prefix}/financial-group`,
            render: () => <FinancialGroupModule />
        },
        {
            path: `${prefix}/financial-class`,
            render: () => <FinancialClassModule />
        },
        {
            path: `${prefix}/financial-item`,
            render: () => <FinancialItemModule />
        },
        {
            path: `${prefix}/supply-unit`,
            render: () => <SupplyUnitModule />
        },
        {
            path: `${prefix}/client-provider`,
            render: () => <ClientProviderModule />
        },
        {
            path: `${prefix}/financial-entry`,
            render: () => <FinancialEntryModule />
        },
        {
            path: `${prefix}/financial-document-type`,
            render: () => <FinancialDocumentTypeModule />
        },
        {
            path: `${prefix}/payment-method`,
            render: () => <PaymentMethodModule />
        },
        {
            path: `${prefix}/report`,
            render: () => <ReportDreModule />
        },
        {
            path: `${prefix}/bank-account`,
            render: () => <BankAccountModule />
        },
        {
            path: `${prefix}/integration`,
            render: () => <IntegrationModule />
        },
        {
            path: `${prefix}/cash-flow`,
            render: () => <CashFlowModule />
        },
        {
            path: `${prefix}/recurrence`,
            render: () => <RecurrenceModule />
        },
        {
            path: `${prefix}/ledger-account`,
            render: () => <LedgerAccountModule />
        },
        {
            path: `${prefix}`,
            render: () => <HomeModule />
        },
    ]
}
