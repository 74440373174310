import {Button, Checkbox, Divider, message, Modal, Space, Table} from "antd";
import {useEffect, useState} from "react";
import {CostCenterEntity} from "../../../../domain/entity/cost-center.entity";
import {useCostCenterRepository} from "../../../../domain/repository/account.repository";
import {useUserCrud} from "../service/profile.crud";
import {RollbackOutlined} from '@ant-design/icons';

export type ListCostCenterModalProps = {
  visible: boolean,
  userId: number,
  onClose: () => void
}
export function ListCostCenterModal(props: ListCostCenterModalProps) {
  const [costCenter, setcostCenter] = useState<CostCenterEntity[]>([]);
  const [userCostCenter, setUserCostCenter] = useState<CostCenterEntity[]>([]);
  const costCenterRepo = useCostCenterRepository();
  const userCrud = useUserCrud();

  function loadCostCenter() {
    costCenterRepo
      .search()
      .then(res => setcostCenter(res.data));
  }

  function loadCostCenterUser() {
    userCrud.getCostCenter(props.userId)
      .then(res => setUserCostCenter(res));
  }

  function isBound(id: number) {
    if (userCostCenter.find(item => item.id === id)) {
      return true;
    }
    return false;
  }

  function onChange(value: any, costCenterId: number) {
    if(value) {
      userCrud
        .addCostCenter(props.userId, costCenterId)
        .then(res => {
          message.success(res.message);
          loadCostCenterUser();
        })
    } else {
      userCrud
        .removeCostCenter(props.userId, costCenterId)
        .then(res => {
          message.success(res.message);
          loadCostCenterUser();
        })
    }
  }

  useEffect(() => {
    loadCostCenter();
    loadCostCenterUser();
  }, [1]);

  return (
    <Modal
      title={'Centros de Custo do usuário'}
      width={750}
      visible={props.visible}
      footer={null}
      onCancel={props.onClose}
    >
      {/*<CostCenterTreeView*/}
      {/*  costCenters={costCenter}*/}
      {/*  selectedCostCenters={userCostCenter}*/}
      {/*/>*/}
      <Table
        pagination={false}
        loading={userCrud.loading}
        columns={[
          {
            title: 'Nome',
            key: 'name',
            dataIndex: 'name',
            render: (_, row) => `${row.code} - ${row.name}`
          },
          {
            title: 'Vinculado',
            key: 'id',
            dataIndex: 'id',
            align: 'center',
            render: (val) => (
              <Checkbox
                onChange={e => onChange(e.target.checked, val)}
                checked={isBound(val)}
              />
            )
          },

        ]}
        dataSource={costCenter}
      />
      <Divider />
      <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        <Space>
          <Button
            onClick={props.onClose}
            htmlType={'button'}
            icon={<RollbackOutlined />}
            shape={'round'}
            type={'ghost'}>
            Fechar
          </Button>
        </Space>
      </div>
    </Modal>
  )
}
